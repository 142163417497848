import React, { useEffect, useState } from 'react';

import { Button, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import InputField from 'common/material/InputField';
import DropDownAutoCom from 'common/material/DropDownAutoCom';

import { useCommonStyles } from 'styles/styles';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import TitleContainer from 'ui-component/dashboard/TitleContainer';
import TemplateQuestionnaireBuilder from '../components/questionnaireComponents/TemplateQuestionnaireBuilder';

import {
  requestPATCHUpdateQuestionnaireTemplate,
  requestPOSTSaveQuestionnaireTemplate,
} from 'redux/Actions/questionnaireTemplateAction';
import { requestFilterQuestionnaireTemplateTags } from 'redux/Actions/questionnaireTagAction';
import FormikTextField from 'common/formik/FormikTextField';

const ManageQuestionnaireTemplate = () => {
  const {
    classes: { clearBtn, mainTitle, updateBtn, saveBtn },
  } = useCommonStyles();
  /**
   * Uses react-roter-dom location, navigation
   */
  const location = useLocation();
  const navigate = useNavigate();

  const isEditMode = location.state?.isEditMode;
  const updateTemplateDetail = location.state?.templateDetail;

  /**
   * Redux integrations
   */
  const dispatch = useDispatch();

  // login user store
  const loginUserData = useSelector((state) => state.authenticationReducer?.loginData?.user);

  //_states
  const [templateFormData, setTemplateFormData] = useState({
    templateTitle: '',
    tags: [],
    questionnaires: [],
  });
  const [tagOptionsData, setTagOptionsData] = useState([]);
  //_validation schema
  const templateValidationSchema = Yup.object({
    templateTitle: Yup.string().required('Required !').nullable(),
    tags: Yup.array().nullable(),
    questionnaires: Yup.array().nullable(),
  });

  /**
   * Handle Generate Nested Touched value for Error Obj
   * @param {*} mainErrorKeyOrObject
   * @param {*} prevTouchedData
   * @returns
   */
  const handleNestedTouchEvents = (mainErrorKeyOrObject, prevTouchedData = {}) => {
    let touchedData = { ...prevTouchedData };
    Object.keys(mainErrorKeyOrObject).forEach((singleErrorKey) => {
      if (typeof mainErrorKeyOrObject[singleErrorKey] === 'object') {
        touchedData[singleErrorKey] = {};
        touchedData[singleErrorKey] = handleNestedTouchEvents(
          mainErrorKeyOrObject[singleErrorKey],
          touchedData[singleErrorKey]
        );
      } else if (typeof mainErrorKeyOrObject[singleErrorKey] === 'string') {
        touchedData[singleErrorKey] = !!mainErrorKeyOrObject[singleErrorKey];
      }
    });

    return touchedData;
  };
  /**
    /**
   * Handle save and add another
   * @param {*} event
   * @param {*} formik
   */
  const handleSaveAndAddAnother = (event, formik) => {
    formik
      .validateForm(formik.values)
      .then((errorObj) => {
        let tempErrorObj = { ...(errorObj || {}) };
        let tempTouched = { ...(formik.touched || {}) };
        Object.keys(tempErrorObj).forEach((singleKey) => {
          if (typeof tempErrorObj[singleKey] === 'object') {
            try {
              tempTouched[singleKey] = handleNestedTouchEvents(tempErrorObj[singleKey]);
            } catch (err) {}
          } else {
            tempTouched[singleKey] = !!tempErrorObj[singleKey];
          }
        });
        let isError = typeof tempErrorObj === 'object' && !!Object.keys(tempErrorObj)?.length;
        if (isError) {
          formik.setTouched(tempTouched, true);
        } else {
          onSubmit(formik?.values, formik, true);
        }
      })
      .catch((error) => {})
      .finally((value) => {});
  };
  /**
   * Handle on form submission
   * @param {*} values
   * @param {*} submitProps
   * @param {*} isAddAnother
   */
  const onSubmit = (values, submitProps, isAddAnother = false) => {
    if (isEditMode) {
      submitProps?.setSubmitting && submitProps.setSubmitting(true);

      const updateRequestDetail = {
        templateId: updateTemplateDetail?._id,
        templateTitle: values?.templateTitle,
        questionnaire: values?.questionnaires || [],
        tags: (Array.isArray(values.tags) && values.tags.map((tagDetail) => tagDetail?._id)) || [],
        rootUser: loginUserData?.rootUser,
      };
      const handleOnUpdateSuccessCallback = (response) => {
        setTemplateFormData({
          questionnaires: [],
          tags: [],
          templateTitle: '',
        });
        navigate(-1);
        submitProps?.setSubmitting && submitProps.setSubmitting(false);
      };
      const handleOnUpdateFailedCallback = (error) => {
        submitProps?.setSubmitting && submitProps.setSubmitting(false);
      };

      dispatch(
        requestPATCHUpdateQuestionnaireTemplate(
          updateRequestDetail,
          handleOnUpdateSuccessCallback,
          handleOnUpdateFailedCallback
        )
      );
    } else {
      submitProps?.setSubmitting && submitProps.setSubmitting(true);

      const saveRequestDetail = {
        templateTitle: values?.templateTitle,
        questionnaire: values?.questionnaires || [],
        tags: (Array.isArray(values.tags) && values.tags.map((tagDetail) => tagDetail?._id)) || [],
        rootUser: loginUserData?.rootUser,
      };

      const handleOnSaveSuccessCallback = (response) => {
        submitProps?.resetForm && submitProps.resetForm();
        if (!isAddAnother) {
          navigate(-1);
          setTemplateFormData({
            questionnaires: [],
            tags: [],
            templateTitle: '',
          });
        }
        submitProps?.setSubmitting && submitProps.setSubmitting(false);
      };
      const handleOnSaveFailureCallback = (error) => {
        submitProps?.setSubmitting && submitProps.setSubmitting(false);
      };
      dispatch(
        requestPOSTSaveQuestionnaireTemplate(
          saveRequestDetail,
          handleOnSaveSuccessCallback,
          handleOnSaveFailureCallback
        )
      );
    }
  };
  /**
   * Request for tags
   */
  useEffect(() => {
    const requestData = {
      rootUser: loginUserData?.rootUser,
    };
    const handleSearchTagsSuccessCallback = (response) => {
      if (Array.isArray(response?.data)) {
        setTagOptionsData(response?.data);
      }
    };
    dispatch(requestFilterQuestionnaireTemplateTags(requestData, handleSearchTagsSuccessCallback));
  }, [loginUserData?.rootUser]);
  /**
   * Effect for update initial tags, questionnaire
   */
  useEffect(() => {
    if (isEditMode && updateTemplateDetail?._id) {
      const updateDetailTags = updateTemplateDetail?.tags || [];
      const initialTagOptions = (tagOptionsData || []).filter((tagOption) =>
        updateDetailTags.find((tagDetail) => tagDetail?._id === tagOption?._id)
      );
      //questionnaire
      const initialQuestionnaires = updateTemplateDetail?.questionnaire || [];
      //set to the initial form state
      setTemplateFormData((prev) => ({
        ...prev,
        ...updateTemplateDetail,
        tags: initialTagOptions,
        questionnaires: initialQuestionnaires,
      }));
    }
  }, [isEditMode, tagOptionsData, updateTemplateDetail]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12}>
        <MainCard>
          <TitleContainer
            title={
              isEditMode
                ? `Update Template - ${updateTemplateDetail?.templateTitle}`
                : 'Create Questionnaire Template'
            }
            isPrimaryButton={false}
            isSecondaryButton={false}
            isSearchBox={false}
          />
        </MainCard>
      </Grid>
      <Grid container item xs={12} sx={{ overflowY: 'scroll', marginBottom: '4rem' }}>
        <Formik
          initialValues={{
            ...templateFormData,
          }}
          validationSchema={templateValidationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form
              style={{
                width: '100%',
              }}
            >
              <Grid container spacing={gridSpacing}>
                <Grid container item xs={12}>
                  <MainCard>
                    <Grid container spacing={gridSpacing} alignItems="center">
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={gridSpacing} alignItems="center">
                          <Grid item xs>
                            <Typography
                              variant="caption"
                              fontSize="1rem !important"
                              className={mainTitle}
                            >
                              {'Title: '}
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <FormikTextField
                              name="templateTitle"
                              type="text"
                              label={'Template Name*'}
                              required
                              disabled={formik?.isSubmitting}
                            />
                          </Grid>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={gridSpacing} alignItems="center">
                          <Grid item xs>
                            <Typography
                              variant="caption"
                              fontSize="1rem !important"
                              className={mainTitle}
                            >
                              {'Select Tags: '}
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <DropDownAutoCom
                              fieldName="tags"
                              onFieldBlur={formik.handleBlur}
                              label="Tags"
                              optionData={tagOptionsData || []}
                              setDropDownValue={(value) => {
                                if (Array.isArray(value)) {
                                  formik.setFieldValue('tags', value);
                                  formik.setFieldTouched('tags', true, true);
                                }
                              }}
                              dropDownValue={formik.values.tags}
                              textFieldMargin="none"
                              valueKey="_id"
                              labelKey="tagName"
                              multiple
                              ChipProps={{
                                color: 'primary',
                                size: 'medium',
                                variant: 'outlined',
                                sx: {
                                  marginLeft: '0.5rem !important',
                                },
                              }}
                              helperText={(formik.touched?.tags && formik.errors?.tags) || ''}
                              error={formik.errors?.tags && !!formik.touched.tags}
                            />
                          </Grid>
                        </Stack>
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
                <Grid container item xs={12}>
                  <MainCard>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12}>
                        <TemplateQuestionnaireBuilder />
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={(theme) => ({
                    height: '5rem',
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    paddingInline: '2.5rem',
                    paddingBottom: '1rem',
                    width: '100%',
                    backgroundColor: theme?.palette?.primary?.light,
                    zIndex: 15,
                  })}
                >
                  <Stack
                    flex={1}
                    direction="row"
                    justifyItems="flex-end"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={gridSpacing}
                  >
                    <Button
                      id="clear-emailTemplate-btn"
                      className={clearBtn}
                      onClick={formik?.handleReset}
                      variant="outlined"
                    >
                      Reset
                    </Button>
                    <LoadingButton
                      id={isEditMode ? 'update-emailTemplate-btn' : 'save-emailTemplate-btn'}
                      className={isEditMode ? updateBtn : saveBtn}
                      type="submit"
                      endIcon={<Save />}
                      loadingPosition="end"
                      variant="contained"
                      loading={formik.isSubmitting}
                    >
                      {isEditMode ? 'Update' : 'Save'}
                    </LoadingButton>
                    {!isEditMode && (
                      <LoadingButton
                        id="save-emailTemplate-btn"
                        className={saveBtn}
                        endIcon={<Save />}
                        loadingPosition="end"
                        variant="contained"
                        loading={formik.isSubmitting}
                        onClick={(event) => handleSaveAndAddAnother(event, formik)}
                      >
                        Save and add another
                      </LoadingButton>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default ManageQuestionnaireTemplate;
