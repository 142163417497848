import { memo, useMemo, useRef } from 'react';

import {
  AddRounded,
  ArrowDownward,
  ArrowUpward,
  BorderColorOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  PeopleOutlineRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Fade,
  Grid,
  Grow,
  Icon,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useFormikContext } from 'formik';

import FormikLabeledToggleSwitch from 'common/formik/FormikLabeledToggleSwitch';
import FormikTextField from 'common/formik/FormikTextField';
import SignatureField from 'common/SignatureField';
import FormikDatePicker from 'common/formik/FormikDatePicker';

import { gridSpacing } from 'store/constant';

import { ReactComponent as RequiredQuestionIcon } from 'assets/images/icons/required-question.svg';
import { ReactComponent as QuestionRemoveIcon } from 'assets/images/icons/question-delete.svg';

const SignatureQuestion = (props) => {
  const {
    questionData,
    questionDataIndex,
    isSelectedQuestionnaire,
    isSmallScreens,
    isSmallerScreens,
    isQuestionEdit,
    editData,
    setEditData,
    handleSaveQuestion,
    isPopoverOpen,
    popperData,
    fieldTypeBtnRef,
    handleOnFieldTypeBtnClick,
    handleGeFieldTypeData,
    fieldPersonTypeRef,
    handleOnPersonTypeBtnClick,
    partnerDetails,
    selectedClientDetail,
    isQuestionCaptionEdit,
    isShowClientAnswer,
    isShowPartnerAnswer,
    handleAddNewQuestionnaire,
    handleRemoveQuestion,
    handleMoveQuestion,
    handleSaveQuestionCaption,
    clientType,
    isTemplateBuilder,
  } = props;
  /**
   * Uses app theme
   */
  const theme = useTheme();
  const isLaptopScreens = useMediaQuery('(min-width:900px)');

  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  const formikValues = formikContext?.values;
  //_ client, partner signature field refs
  const clientSignatureRef = useRef(null);
  const partnerSignatureRef = useRef(null);

  //_memo field type button label
  const fieldTypeSelectorBtnLabel = useMemo(() => {
    if (isTemplateBuilder) {
      return questionData?.personType === 'CLIENT'
        ? clientType === 'LEAD'
          ? 'Lead'
          : 'Client'
        : questionData?.personType === 'PARTNER'
        ? 'Partner'
        : questionData?.personType === 'BOTH' && 'Both';
    } else {
      return (
        (questionData?.personType === 'CLIENT'
          ? `${selectedClientDetail?.firstName || ''} (${
              clientType === 'LEAD' ? 'Lead' : 'Client'
            })`
          : questionData?.personType === 'PARTNER'
          ? `${partnerDetails?.firstName || ''} (Partner)`
          : questionData?.personType === 'BOTH' && 'Both') ||
        `${selectedClientDetail?.firstName || ''} (${clientType === 'LEAD' ? 'Lead' : 'Client'})`
      );
    }
  }, [questionData, partnerDetails, selectedClientDetail, isTemplateBuilder, clientType]);

  /**
   * Signature default points
   */
  const defaultPoints = useMemo(() => {
    let points = {
      client: [],
      partner: [],
    };
    const formikValues = formikContext.values;
    let clientSignaturePoints = '[]';
    let partnerSignaturePoints = '[]';
    if (
      typeof formikValues?.questionnaires?.[questionDataIndex]?.answer?.['client']
        ?.signatureAgreementAnswers?.signaturePoints === 'string'
    ) {
      clientSignaturePoints =
        formikValues?.questionnaires?.[questionDataIndex]?.answer?.['client']
          ?.signatureAgreementAnswers?.signaturePoints;
    }
    if (
      typeof formikValues?.questionnaires?.[questionDataIndex]?.answer?.['partner']
        ?.signatureAgreementAnswers?.signaturePoints === 'string'
    ) {
      partnerSignaturePoints =
        formikValues?.questionnaires?.[questionDataIndex]?.answer?.['partner']
          ?.signatureAgreementAnswers?.signaturePoints;
    }
    if (clientSignaturePoints) {
      try {
        let clientPoints = JSON.parse(clientSignaturePoints);
        if (Array.isArray(clientPoints)) {
          points = {
            ...points,
            client: [...clientPoints].filter((arr) => arr?.length),
          };
        }
      } catch (error) {}
    }
    if (partnerSignaturePoints) {
      try {
        let partnerPoints = JSON.parse(partnerSignaturePoints);
        if (Array.isArray(partnerPoints)) {
          points = {
            ...points,
            partner: [...partnerPoints].filter((arr) => arr?.length),
          };
        }
      } catch (error) {}
    }

    return {...points};
  }, [formikContext?.values, questionDataIndex]);
  const leadAnswerGiven = !!(
    defaultPoints?.client?.length ||
    formikValues?.questionnaires?.[questionDataIndex]?.answer?.client?.signatureAgreementAnswers
      ?.name ||
    formikValues?.questionnaires?.[questionDataIndex]?.answer?.client?.signatureAgreementAnswers
      ?.date
  );
  const partnerAnswerGiven = !!(
    defaultPoints?.partner?.length ||
    formikValues?.questionnaires?.[questionDataIndex]?.answer?.partner?.signatureAgreementAnswers
      ?.name ||
    formikValues?.questionnaires?.[questionDataIndex]?.answer?.partner?.signatureAgreementAnswers
      ?.date
  );

  return (
    <Grid container spacing={gridSpacing} wrap="nowrap">
      <Tooltip
        title={isSelectedQuestionnaire ? '' : 'Click to select this question'}
        arrow
        placement="top"
      >
        <Grid
          item
          xs={true}
          sx={{
            cursor: 'pointer',
            border: (theme) =>
              '1px solid ' + theme.palette?.grey?.[isSelectedQuestionnaire ? 325 : 300],
            borderRadius: '0.5rem',
            WebkitTransition: 'border-radius 0.5s, box-shadow 0.75s, border 0.5s',
            boxShadow: (theme) =>
              isSelectedQuestionnaire
                ? `5px 0px 8px 0px ${
                    theme.palette?.grey?.[isSelectedQuestionnaire ? 325 : 300]
                  }, -4px 0px 0px 0px ${theme.palette.blue?.[350]}`
                : `0.25px 0px 8px 2px  ${
                    theme.palette?.grey?.[isSelectedQuestionnaire ? 325 : 300]
                  }`,
            paddingTop: '0 !important',
            paddingLeft: '0 !important',
            minHeight: '5rem',
            backgroundColor: (theme) => theme.palette.background?.paper,
          }}
        >
          <Grid
            container
            columnSpacing={gridSpacing}
            alignItems="flex-start"
            flexWrap={isLaptopScreens ? 'nowrap' : 'wrap-reverse'}
            sx={{ paddingLeft: '1rem !important' }}
          >
            <Grid item xs={true} sx={{ paddingTop: '1rem !important' }}>
              <Collapse in={!isQuestionEdit || !isSelectedQuestionnaire} timeout={300}>
                <Fade in={!isQuestionEdit || !isSelectedQuestionnaire} timeout={100}>
                  <Stack direction="row" flexWrap="nowrap" spacing={1} alignItems="center">
                    {!!questionData?.isRequired && (
                      <Tooltip title="Answer is required" arrow placement="top">
                        <Icon viewBox="0 0 36 36">
                          <SvgIcon
                            viewBox="0 0 36 36"
                            component={RequiredQuestionIcon}
                            sx={{ fontSize: '0.75rem', borderRadius: '0.2rem' }}
                          />
                        </Icon>
                      </Tooltip>
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        paddingRight: '1rem !important',
                        color: (theme) => theme.palette.text?.primary,
                      }}
                    >
                      {questionData?.question}
                    </Typography>
                  </Stack>
                </Fade>
              </Collapse>
              <Collapse in={!!isQuestionEdit && isSelectedQuestionnaire} timeout={300}>
                <Fade in={!!isQuestionEdit && isSelectedQuestionnaire} timeout={100}>
                  <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                    <TextField
                      fullWidth
                      sx={{
                        paddingRight: '1.25rem',
                        '& .MuiInputBase-root': {
                          '& .MuiInputBase-input': {
                            paddingBlock: '0.25rem !important',
                          },
                        },
                      }}
                      label="Agreement/Signature Heading"
                      value={editData?.question || ''}
                      onChange={(event) =>
                        setEditData((prev) => ({
                          ...prev,
                          question: event?.target?.value || '',
                        }))
                      }
                      variant="standard"
                      error={!editData?.question}
                      helperText={
                        !editData?.question ? 'Agreement/Signature heading is required!' : ' '
                      }
                      onBlur={(event) => handleSaveQuestion(event)}
                    />
                  </Stack>
                </Fade>
              </Collapse>
            </Grid>

            <Fade in={isSelectedQuestionnaire} unmountOnExit>
              <Grid item xs={12} md={'auto'} sx={{ paddingTop: '0 !important' }}>
                <Paper
                  sx={(theme) => ({
                    border: '1px solid ' + theme.palette.blue?.[350],
                    borderTop: 'none',
                    borderRadius: `0 0 ${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px !important`,

                    WebkitTransition: 'border-radius 0.5s, box-shadow 0.75s',
                    boxShadow: isSelectedQuestionnaire
                      ? `0px 1px 0px 0px ${theme.palette.blue?.[350]}`
                      : undefined,
                    height: '100%',
                    mx: '0.5rem',
                    scale: '0.75',
                    marginTop: isLaptopScreens
                      ? '-0.375rem !important'
                      : isSmallerScreens
                      ? '-1.125rem !important'
                      : '-0.375rem !important',
                    width: isSmallScreens ? '125%' : '100%',
                    marginLeft: isSmallScreens && '-15%',
                  })}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{
                      position: 'relative',
                      flexDirection: isSmallerScreens ? 'column' : 'row',
                    }}
                  >
                    <Tooltip
                      title="Toggle this to mark this question as required"
                      arrow
                      placement="top"
                    >
                      <Box>
                        <FormikLabeledToggleSwitch
                          name={`questionnaires[${questionDataIndex}].isRequired`}
                          label={'Required'}
                          labelPlacement="start"
                        />
                      </Box>
                    </Tooltip>
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={(theme) => ({ backgroundColor: theme.palette?.blue?.[350] })}
                    />
                    <Tooltip
                      title="Click here to change the type of this question."
                      arrow
                      placement="top"
                    >
                      <Button
                        id={`field-type-change-btn-${questionDataIndex}`}
                        fullWidth
                        variant="contained"
                        color="grey"
                        startIcon={<BorderColorOutlined fontSize="0.875rem" />}
                        endIcon={
                          isPopoverOpen && popperData?.action === 'EDIT_FIELD_TYPE' ? (
                            <KeyboardArrowUpRounded />
                          ) : (
                            <KeyboardArrowDownRounded />
                          )
                        }
                        sx={(theme) => ({
                          background: theme.palette.grey?.[50],
                          borderRadius: 0,
                          marginLeft: '0 !important',
                          minHeight: '3rem',
                          boxShadow: 'none',
                          minWidth: 'fit-content',
                          maxWidth: isSmallerScreens ? '100%' : undefined,
                          whiteSpace: 'pre-wrap',
                        })}
                        ref={fieldTypeBtnRef}
                        onClick={handleOnFieldTypeBtnClick}
                      >
                        {handleGeFieldTypeData('fieldTypeBtnLabel')}
                      </Button>
                    </Tooltip>
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={(theme) => ({
                        marginLeft: '0 !important',
                        backgroundColor: theme.palette?.blue?.[350],
                      })}
                    />
                    <Tooltip
                      title="Click here to change the person who will answer the question."
                      arrow
                      placement="top"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<PeopleOutlineRounded fontSize="0.875rem" />}
                        endIcon={
                          isPopoverOpen && popperData?.action === 'EDIT_PERSON_TYPE' ? (
                            <KeyboardArrowUpRounded />
                          ) : (
                            <KeyboardArrowDownRounded />
                          )
                        }
                        color="grey"
                        sx={(theme) => ({
                          background: theme.palette.grey?.[50],
                          borderRadius: isSmallerScreens
                            ? `0 0 ${theme?.shape.globalBorderRadius}px ${theme?.shape.globalBorderRadius}px`
                            : `0 0 ${theme?.shape.globalBorderRadius}px 0`,
                          marginLeft: '0 !important',
                          minHeight: '3rem',
                          boxShadow: 'none',
                          minWidth: 'fit-content',
                          maxWidth: isSmallerScreens ? '100%' : undefined,
                          whiteSpace: 'pre-wrap',
                        })}
                        ref={fieldPersonTypeRef}
                        onClick={handleOnPersonTypeBtnClick}
                      >
                        {fieldTypeSelectorBtnLabel}
                      </Button>
                    </Tooltip>
                  </Stack>
                </Paper>
              </Grid>
            </Fade>
          </Grid>
          <Grid container spacing={gridSpacing} alignItems="center">
            <Grid item xs={12} sx={{ paddingLeft: '2.25em !important' }}>
              <Collapse
                in={
                  (!isQuestionCaptionEdit || !isSelectedQuestionnaire) &&
                  !!questionData?.questionCaption
                }
                timeout={300}
              >
                <Fade
                  in={
                    (!isQuestionCaptionEdit || !isSelectedQuestionnaire) &&
                    !!questionData?.questionCaption
                  }
                  timeout={100}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    flexWrap="nowrap"
                    alignItems="center"
                    paddingTop="0.875rem"
                  >
                    <Typography
                      variant="caption"
                      component={
                        questionData?.questionCaption?.startsWith &&
                        (questionData.questionCaption.startsWith('https://') ||
                          questionData.questionCaption.startsWith('http://'))
                          ? 'a'
                          : 'span'
                      }
                      sx={{ paddingRight: '1rem' }}
                      href={
                        questionData?.questionCaption?.startsWith &&
                        (questionData.questionCaption.startsWith('https://') ||
                          questionData.questionCaption.startsWith('http://'))
                          ? questionData.questionCaption
                          : ''
                      }
                    >
                      {questionData?.questionCaption}
                    </Typography>
                  </Stack>
                </Fade>
              </Collapse>
              <Collapse
                in={
                  (!!isQuestionCaptionEdit || !questionData?.questionCaption) &&
                  isSelectedQuestionnaire
                }
                timeout={300}
              >
                <Fade
                  in={
                    (!!isQuestionCaptionEdit || !questionData?.questionCaption) &&
                    isSelectedQuestionnaire
                  }
                  timeout={100}
                >
                  <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                    <TextField
                      fullWidth
                      sx={{
                        paddingRight: '1.25rem',
                        '& .MuiInputBase-root': {
                          '& .MuiInputBase-input': {
                            paddingBlock: '0.25rem !important',
                          },
                        },
                      }}
                      label="Agreement/Signature Heading Caption"
                      value={editData?.questionCaption || ''}
                      onChange={(event) =>
                        setEditData((prev) => ({
                          ...prev,
                          questionCaption: event?.target?.value || '',
                        }))
                      }
                      placeholder="Enter caption for this agreement/signature here.."
                      variant="standard"
                      onBlur={(event) => handleSaveQuestionCaption(event)}
                    />
                  </Stack>
                </Fade>
              </Collapse>
            </Grid>

            <Grid item xs={12} id={`wrapper-${questionDataIndex}`}>
              <Collapse
                in={isShowPartnerAnswer || isShowClientAnswer}
                unmountOnExit
                sx={{
                  width: '100%',
                }}
              >
                <Grow
                  in={isShowPartnerAnswer || isShowClientAnswer}
                  style={{ transformOrigin: '0 0 0' }}
                >
                  <Fade in={isShowPartnerAnswer || isShowClientAnswer}>
                    <Grid
                      container
                      spacing={gridSpacing}
                      flexDirection={isSmallerScreens ? 'column' : 'row'}
                    >
                      <Grid item xs={12}>
                        <Divider
                          flexItem
                          orientation="horizontal"
                          variant="fullWidth"
                          sx={{ paddingTop: isSelectedQuestionnaire ? '2rem' : '1rem' }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Collapse
                          in={isShowClientAnswer}
                          unmountOnExit
                          // sx={{ display: 'flex', flex: 1, paddingTop: '0.875rem', maxWidth: '100%' }}
                        >
                          <Grow in={isShowClientAnswer}>
                            <Fade in={isShowClientAnswer}>
                              <Grid
                                container
                                sx={{
                                  paddingRight: '0.25rem',
                                  paddingBottom: '1rem',
                                  paddingInline: '1rem',
                                }}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Grid item xs="auto">
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      userSelect: 'none',
                                      display: 'flex',
                                      whiteSpace: 'pre-line',
                                      maxWidth: isSmallerScreens ? '10ch' : undefined,
                                      fontSize: leadAnswerGiven ? '0.875rem' : '0.75rem',
                                      color: (theme) =>
                                        leadAnswerGiven
                                          ? theme.palette.primary.dark
                                          : theme.palette.text.disabled,
                                    }}
                                  >
                                    Answers of{' '}
                                    {(!isTemplateBuilder && selectedClientDetail?.firstName) || ''}{' '}
                                    ({clientType === 'LEAD' ? 'Lead' : 'Client'})
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={(theme) => ({
                                    marginTop: '0.875rem',
                                    '& .MuiFormControl-root': {
                                      '& .MuiInputBase-root': {
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          backgroundColor: (theme) =>
                                            theme.palette?.grey?.[100] + ' !important',
                                          color: theme.palette.primary.dark,
                                          WebkitTextFillColor: theme.palette.primary.dark,
                                          resize: 'block',
                                        },
                                        '& .MuiInputBase-input.MuiInputBase-inputMultiline cs': {
                                          height: '2.125rem',
                                        },
                                      },
                                    },
                                  })}
                                >
                                  <Grid container spacing={gridSpacing}>
                                    <Grid
                                      item
                                      xs={12}
                                      md={'auto'}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: (isSmallScreens && 'center') || 'initial',
                                      }}
                                    >
                                      <Grid item xs="auto">
                                        <Box
                                          sx={{
                                            position: 'relative',
                                            display: 'flex',
                                            flexGrow: 0,
                                            scale: isSmallScreens ? '0.6' : '1',
                                          }}
                                        >
                                          <SignatureField
                                            readonly
                                            fill={theme.palette.primary.dark}
                                            id={'client-signature-' + questionDataIndex}
                                            defaultPoints={[...(defaultPoints.client || [])]}
                                            signatureFieldRef={clientSignatureRef}
                                          />
                                          <Fade in={!defaultPoints?.client?.length} unmountOnExit>
                                            <Typography
                                              sx={{
                                                width: '100%',
                                                position: 'absolute',
                                                top: '40%',
                                                textAlign: 'center',
                                                fontSize: '1.5rem',
                                                fontWeight: theme?.typography?.fontWeightMedium,
                                                color: theme?.palette?.grey?.[300],

                                                '-webkit-touch-callout': 'none' /* iOS Safari */,
                                                '-webkit-user-select': 'none' /* Safari */,
                                                '-moz-user-select':
                                                  'none' /* Old versions of Firefox */,
                                                '-ms-user-select':
                                                  'none' /* Internet Explorer/Edge */,
                                                'user-select':
                                                  'none' /* Non-prefixed version, currently
                                                supported by Chrome, Edge, Opera and Firefox */,
                                                pointerEvents: 'none',
                                              }}
                                            >
                                              Signature
                                            </Typography>
                                          </Fade>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} md>
                                      <Grid
                                        container
                                        alignItems="center"
                                        spacing={gridSpacing}
                                        justifyContent="flex-end"
                                      >
                                        <Grid item xs={12} sx={{ marginTop: '0.875rem' }}>
                                          <FormikTextField
                                            name={`questionnaires[${questionDataIndex}].answer.client.signatureAgreementAnswers.name`}
                                            disabled
                                            label="Name"
                                            placeholder="Name"
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <FormikDatePicker
                                            name={`questionnaires[${questionDataIndex}].answer.client.signatureAgreementAnswers.date`}
                                            disabled
                                            label="Date"
                                            placeholder="Signed Date"
                                            value={
                                              formikContext?.values?.questionnaires?.[
                                                questionDataIndex
                                              ]?.answer?.client?.signatureAgreementAnswers?.date
                                            }
                                            setFieldValue={formikContext?.setFieldValue}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Fade>
                          </Grow>
                        </Collapse>
                        <Collapse
                          in={isShowPartnerAnswer}
                          unmountOnExit
                          sx={{
                            display: 'flex',
                            flex: 1,
                            paddingTop: '0.875rem',
                            maxWidth: '100%',
                          }}
                        >
                          <Grow in={isShowPartnerAnswer}>
                            <Fade in={isShowPartnerAnswer}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Divider flexItem orientation="horizontal" variant="fullWidth" />
                                </Grid>
                                <Grid
                                  container
                                  sx={{
                                    paddingRight: '0.25rem',
                                    paddingBottom: '1rem',
                                    paddingInline: '1rem',
                                    paddingTop: '1rem',
                                  }}
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Grid item xs={'auto'}>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        userSelect: 'none',
                                        display: 'flex',
                                        whiteSpace: 'pre-line',
                                        maxWidth: isSmallerScreens ? '10ch' : undefined,
                                        fontSize: partnerAnswerGiven ? '0.875rem' : '0.75rem',
                                        color: (theme) =>
                                          partnerAnswerGiven
                                            ? theme.palette.primary.dark
                                            : theme.palette.text.disabled,
                                      }}
                                    >
                                      Answers of{' '}
                                      {(!isTemplateBuilder && partnerDetails?.firstName) || ''}{' '}
                                      (Partner)
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={(theme) => ({
                                      marginTop: '0.875rem',
                                      '& .MuiFormControl-root': {
                                        '& .MuiInputBase-root': {
                                          '& .MuiInputBase-input.Mui-disabled': {
                                            backgroundColor: (theme) =>
                                              theme.palette?.grey?.[100] + ' !important',
                                            color: theme.palette.primary.dark,
                                            WebkitTextFillColor: theme.palette.primary.dark,
                                            resize: 'block',
                                          },
                                          '& .MuiInputBase-input.MuiInputBase-inputMultiline cs': {
                                            height: '2.125rem',
                                          },
                                        },
                                      },
                                    })}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sx={(theme) => ({
                                        '& .MuiFormControl-root': {
                                          '& .MuiInputBase-root': {
                                            '& .MuiInputBase-input.Mui-disabled': {
                                              backgroundColor: (theme) =>
                                                theme.palette?.grey?.[100] + ' !important',
                                              color: theme.palette.primary.dark,
                                              WebkitTextFillColor: theme.palette.primary.dark,
                                              resize: 'block',
                                            },
                                            '& .MuiInputBase-input.MuiInputBase-inputMultiline cs':
                                              {
                                                height: '2.125rem',
                                              },
                                          },
                                        },
                                      })}
                                    >
                                      <Grid container spacing={gridSpacing}>
                                        <Grid
                                          item
                                          xs={12}
                                          md={'auto'}
                                          sx={{
                                            display: 'flex',
                                            justifyContent:
                                              (isSmallScreens && 'center') || 'initial',
                                          }}
                                        >
                                          <Grid item xs="auto">
                                            <Box
                                              sx={{
                                                position: 'relative',
                                                display: 'flex',
                                                flexGrow: 0,
                                                scale: isSmallScreens ? '0.6' : '1',
                                              }}
                                            >
                                              <SignatureField
                                                readonly
                                                fill={theme.palette.primary.dark}
                                                id={'partner-signature-' + questionDataIndex}
                                                defaultPoints={[...(defaultPoints.partner || [])]}
                                                signatureFieldRef={partnerSignatureRef}
                                                />
                                              <Fade
                                                in={!defaultPoints?.partner?.length}
                                                unmountOnExit
                                              >
                                                <Typography
                                                  sx={{
                                                    width: '100%',
                                                    position: 'absolute',
                                                    top: '40%',
                                                    textAlign: 'center',
                                                    fontSize: '1.5rem',
                                                    fontWeight: theme?.typography?.fontWeightMedium,
                                                    color: theme?.palette?.grey?.[300],

                                                    '-webkit-touch-callout':
                                                      'none' /* iOS Safari */,
                                                    '-webkit-user-select': 'none' /* Safari */,
                                                    '-moz-user-select':
                                                      'none' /* Old versions of Firefox */,
                                                    '-ms-user-select':
                                                      'none' /* Internet Explorer/Edge */,
                                                    'user-select':
                                                      'none' /* Non-prefixed version, currently
                                                  supported by Chrome, Edge, Opera and Firefox */,
                                                    pointerEvents: 'none',
                                                  }}
                                                >
                                                  Signature
                                                </Typography>
                                              </Fade>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12} md>
                                          <Grid
                                            container
                                            alignItems="center"
                                            spacing={gridSpacing}
                                            justifyContent="flex-end"
                                          >
                                            <Grid item xs={12} sx={{ marginTop: '0.875rem' }}>
                                              <FormikTextField
                                                name={`questionnaires[${questionDataIndex}].answer.partner.signatureAgreementAnswers.name`}
                                                disabled
                                                label="Name"
                                                placeholder="Name"
                                              />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <FormikDatePicker
                                                name={`questionnaires[${questionDataIndex}].answer.partner.signatureAgreementAnswers.date`}
                                                disabled
                                                label="Date"
                                                placeholder="Signed Date"
                                                value={
                                                  formikContext?.values?.questionnaires?.[
                                                    questionDataIndex
                                                  ]?.answer?.partner?.signatureAgreementAnswers
                                                    ?.date
                                                }
                                                setFieldValue={formikContext?.setFieldValue}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Fade>
                          </Grow>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Fade>
                </Grow>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
      <Fade in={isSelectedQuestionnaire}>
        <Grid item xs={'auto'}>
          <Paper sx={{ marginTop: '-16px' }}>
            <Grid container direction="column" alignItems="center" justifyContent={'center'}>
              <Grid item xs="auto">
                <Tooltip title="Add new question" arrow placement="left">
                  <IconButton
                    color="primary"
                    id={`addFieldBtn-${questionDataIndex}`}
                    onClick={(event) =>
                      handleAddNewQuestionnaire &&
                      handleAddNewQuestionnaire(event, questionDataIndex)
                    }
                  >
                    <AddRounded />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs="auto">
                <Tooltip title="Remove this question" arrow placement="left">
                  <IconButton
                    id={`removeFieldBtn-${questionDataIndex}`}
                    onClick={(event) =>
                      handleRemoveQuestion &&
                      handleRemoveQuestion(
                        true,
                        { actionDataIndex: questionDataIndex, ...(questionData || {}) },
                        questionDataIndex
                      )
                    }
                  >
                    <SvgIcon viewBox="0 0 24 24" component={QuestionRemoveIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              {questionDataIndex > 1 && (
                <Grid item xs="auto">
                  <Tooltip title="Move this question Up" arrow placement="left">
                    <IconButton
                      id={`removeFieldBtn-${questionDataIndex}`}
                      onClick={(event) => handleMoveQuestion('UP', questionDataIndex, event)}
                    >
                      <ArrowUpward />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {questionDataIndex < formikValues?.questionnaires?.length - 1 && (
                <Grid item xs="auto">
                  <Tooltip title="Move this question Down" arrow placement="left">
                    <IconButton
                      id={`removeFieldBtn-${questionDataIndex}`}
                      onClick={(event) => handleMoveQuestion('DOWN', questionDataIndex, event)}
                    >
                      <ArrowDownward />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default memo(SignatureQuestion);
