import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Save Company Client Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestSaveCompanyClient =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveCompanyClientRequest' });

    const requestData = {
      url: API_URL.company.companyProfile.POST_CREATE_COMPANY_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveCompanyClientSuccess', payload: response.data });
      const message = `Company is Saved Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveCompanyClientFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Request Company Client List
 * @param {Function} handleClientListSuccessCallback
 * @param {Function} handleClientListFailedCallback
 * @returns
 */
export const requestCompanyClientList =
  (handleClientListSuccessCallback, handleClientListFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllCompanyClientsRequest' });

    const requestData = {
      url: API_URL.company.companyClientList.GET_ALL_COMPANY_CLIENTS,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllCompanyClientsSuccess', payload: response.data });

      handleClientListSuccessCallback && handleClientListSuccessCallback(response.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllCompanyClientsFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleClientListFailedCallback && handleClientListFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle Request Find Company Detail by Id
 * @param {object} requestBody
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns void
 */
export const requestCompanyClientDetail =
  (requestBody, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getCompanyClientByIdRequest' });

    const requestData = {
      url: API_URL.company.companyProfile.GET_COMPANY_BY_ID.replace(
        '{companyId}',
        requestBody?.companyId
      ),
      method: 'GET',
    };
    const successCallback = (response) => {
      handleRequestSuccessCallback?.(response);
      dispatch({ type: 'getCompanyClientByIdRequestSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      handleRequestFailedCallback && handleRequestFailedCallback(error);
      dispatch({ type: 'getCompanyClientByIdRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Update Company Basic Profile Detail
 * @param {object} clientUpdaterRequestData {requestBody, clientDetail}
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestUpdateCompanyClient =
  (
    clientUpdaterRequestData,
    handleRequestSuccessCallback = null,
    handleRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateCompanyClientRequest' });
    let { requestBody, companyDetail, customSuccessMessage } = clientUpdaterRequestData;
    let companyId = companyDetail?._id;
    const requestData = {
      url: API_URL.company.companyProfile.PATCH_UPDATE_COMPANY_BY_ID.replace(
        '{companyId}',
        companyId
      ),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateCompanyClientRequestSuccess',
        payload: response.data,
      });
      const message = customSuccessMessage
        ? customSuccessMessage
        : `Company is Updated Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleRequestSuccessCallback && handleRequestSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateCompanyClientRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleRequestFailedCallback && handleRequestFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save company director
 * @param {*} requestDetails
 * @param {*} handleSaveDirectorSuccessCallback
 * @param {*} handleSaveDirectorFailedCallback
 * @returns
 */
export const requestSaveCompanyDirector =
  (
    requestDetails,
    handleSaveDirectorSuccessCallback = null,
    handleSaveDirectorFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveCompanyDirector' });

    const { companyId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.company.companyProfile.POST_CREATE_COMPANY_DIRECTOR.replace(
        '{companyId}',
        companyId
      ),
      method: 'POST',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'postSaveCompanyDirectorSuccess',
        payload: response.data,
      });
      const message = `Director is saved successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveDirectorSuccessCallback && handleSaveDirectorSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'postSaveCompanyDirectorFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveDirectorFailedCallback && handleSaveDirectorFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Update company director
 * @param {*} requestDetails
 * @param {*} handleUpdateDirectorSuccessCallback
 * @param {*} handleUpdateDirectorFailedCallback
 * @returns
 */
export const requestUpdateCompanyDirector =
  (
    requestDetails,
    handleUpdateDirectorSuccessCallback = null,
    handleUpdateDirectorFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateCompanyDirector' });

    const { companyId, directorId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.company.companyProfile.PATCH_UPDATE_COMPANY_DIRECTOR_BY_ID.replace(
        '{companyId}',
        companyId
      ).replace('{directorId}', directorId),
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateCompanyDirectorSuccess',
        payload: response.data,
      });
      const message = `Director is updated successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleUpdateDirectorSuccessCallback && handleUpdateDirectorSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateCompanyDirectorFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateDirectorFailedCallback && handleUpdateDirectorFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete company director
 * @param {*} requestDetails
 * @param {*} deleteRequestSuccessCallback
 * @param {*} handleSaveDirectorFailedCallback
 * @returns
 */
export const requestDeleteCompanyDirector =
  (
    requestDetails,
    handleDeleteRequestSuccessCallback = null,
    handleDeleteRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteCompanyDirector' });

    const { companyId, directorId } = requestDetails;

    const requestData = {
      url: API_URL.company.companyProfile.DELETE_COMPANY_DIRECTOR_BY_ID.replace(
        '{companyId}',
        companyId
      ).replace('{directorId}', directorId),
      method: 'DELETE',
    };

    const successCallback = (response) => {
      dispatch({
        type: 'deleteCompanyDirectorSuccess',
        payload: response.data,
      });
      const message = `Director is deleted successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleDeleteRequestSuccessCallback && handleDeleteRequestSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'deleteCompanyDirectorFailed', payload: error });
      const message =
        error?.response?.data?.name ||
        (error?.response?.data?.message === 'The director already added to an shareholder!' &&
          'The director already added to an shareholder!') ||
        `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteRequestFailedCallback && handleDeleteRequestFailedCallback(error);
    };

    http_request(requestData, successCallback, errorCallback);
  };
/**
 * request save company shareholder
 * @param {*} requestDetails
 * @param {*} handleUpdateShareholderSuccessCallback
 * @param {*} handleUpdateShareholderFailedCallback
 * @returns
 */
export const requestSaveCompanyShareholder =
  (
    requestDetails,
    handleSaveShareholderSuccessCallback = null,
    handleSaveShareholderFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveCompanyDirector' });

    const { companyId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.company.companyProfile.POST_CREATE_COMPANY_SHAREHOLDER.replace(
        '{companyId}',
        companyId
      ),
      method: 'POST',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'postSaveCompanyShareholderSuccess',
        payload: response.data,
      });
      const message = `Shareholder is saved successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveShareholderSuccessCallback && handleSaveShareholderSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'postSaveCompanyShareholderFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveShareholderFailedCallback && handleSaveShareholderFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };
/**
 * request update company shareholder
 * @param {*} requestDetails
 * @param {*} handleUpdateShareholderSuccessCallback
 * @param {*} handleUpdateShareholderFailedCallback
 * @returns
 */
export const requestUpdateCompanyShareholder =
  (
    requestDetails,
    handleUpdateShareholderSuccessCallback = null,
    handleUpdateShareholderFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateCompanyDirector' });

    const { companyId, shareholderId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.company.companyProfile.PATCH_UPDATE_COMPANY_SHAREHOLDER_BY_ID.replace(
        '{companyId}',
        companyId
      ).replace('{shareholderId}', shareholderId),
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateCompanyShareholderSuccess',
        payload: response.data,
      });
      const message = `Shareholder is updated successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleUpdateShareholderSuccessCallback &&
        handleUpdateShareholderSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateCompanyShareholderFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateShareholderFailedCallback && handleUpdateShareholderFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete company shareholder
 * @param {*} requestDetails
 * @param {*} deleteRequestSuccessCallback
 * @param {*} handleSaveShareholderFailedCallback
 * @returns
 */
export const requestDeleteCompanyShareholder =
  (
    requestDetails,
    handleDeleteRequestSuccessCallback = null,
    handleDeleteRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteCompanyShareholder' });

    const { companyId, shareholderId } = requestDetails;

    const requestData = {
      url: API_URL.company.companyProfile.DELETE_COMPANY_SHAREHOLDER_BY_ID.replace(
        '{companyId}',
        companyId
      ).replace('{shareholderId}', shareholderId),
      method: 'DELETE',
    };

    const successCallback = (response) => {
      dispatch({
        type: 'deleteCompanyShareholderSuccess',
        payload: response.data,
      });
      const message = `Shareholder is deleted successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleDeleteRequestSuccessCallback && handleDeleteRequestSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'deleteCompanyShareholderFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteRequestFailedCallback && handleDeleteRequestFailedCallback(error);
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save Multiple Company Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */

export const requestSaveMultipleCompany =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveMultipleCompanyRequest' });

    const requestData = {
      url: API_URL.company.companyProfile.POST_CREATE_MULTIPLE_COMPANY_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveMultipleCompanySuccess', payload: response.data });
      const successMessage = `Clients have been saved successfully.`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveMultipleCompanyFailed', payload: error });
      let message = error?.response?.data?.name || 'Clients&apos; Save has Failed';
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * filter Company  Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestFilterCompany =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postFilterCompanyRequest' });

    const requestData = {
      url: API_URL.company.companyClientList.FILTER_COMPANY,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postFilterCompanySuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postFilterCompanyFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * add linked entities company
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const addLinkedEntitiesCompany =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'addLinkedEntitiesCompanyRequest' });

    const requestData = {
      url: API_URL.company.companyClientList.ADD_LINKED_ENTITIES,
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({ type: 'addLinkedEntitiesCompanySuccess', payload: response.data });
      const requestDetail = {
        companyId: requestBody.companyId,
      };
      handleSaveClientSuccessCallback();
      !requestBody?.isNotGetting && dispatch(requestCompanyClientDetail(requestDetail));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'addLinkedEntitiesCompanyFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback?.(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * delete linked entities company
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const deleteLinkedEntitiesCompany =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteLinkedEntitiesCompanyRequest' });

    const requestData = {
      url: API_URL.company.companyClientList.DELETE_LINKED_ENTITIES,
      method: 'DELETE',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteLinkedEntitiesCompanySuccess', payload: response.data });
      const requestDetail = {
        companyId: requestBody.companyId,
      };
      dispatch(requestCompanyClientDetail(requestDetail));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteLinkedEntitiesCompanyFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback?.(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
