import { formatDuration, intervalToDuration, isValid } from 'date-fns';

/**
 * Get Age from Date of Birth
 * @param {Date} dateOfBirth
 * @param {Boolean} isShortFormat
 * @returns {string} Age
 */
const getAgeFromDOB = (dateOfBirth, isShortFormat = true) => {
  if (dateOfBirth && isValid(dateOfBirth)) {
    const formatDistanceLocale = {
      xYears: '{{count}}Y',
      xMonths: '{{count}}M',
      xDays: '{{count}}D',
    };
    const shortEnDOBLocale = {
      formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count),
    };
    let dobAgeInterval = intervalToDuration({
      start: dateOfBirth || new Date(),
      end: new Date(),
    });
    let ageFormatted = formatDuration(dobAgeInterval, {
      format: ['years', 'months', 'days'],
      locale: isShortFormat ? shortEnDOBLocale : null,
      zero: true,
    });
    return ageFormatted;
  }
  return '';
};

export { getAgeFromDOB };
