import { createReducer } from '@reduxjs/toolkit';

const calculationEconomicVariableInitialState = {
  //Create New Economic Variable Setting
  isSaveEconomicVariableSettingLoading: false,
  saveEconomicVariableSettingSuccessData: {},
  saveEconomicVariableSettingFailedData: {},

  //get Economic Variable Settings
  isGetEconomicVariableSettingsLoading: false,
  economicVariableSettingsSuccessData: {},
  economicVariableSettingsFailedData: {},

  //update Economic Variable Setting
  isUpdateEconomicVariableSettingLoading: false,
  updateEconomicVariableSettingSuccessData: {},
  updateEconomicVariableSettingFailedData: {},
};
/**
 * Economic Variable Setting Reducer
 */
export const economicVariableSettingsReducer = createReducer(
  calculationEconomicVariableInitialState,
  {
    // save Economic Variable Setting
    postSaveEconomicVariableSettingRequest: (state) => {
      state.isSaveEconomicVariableSettingLoading = true;
      state.saveEconomicVariableSettingSuccessData = {};
      state.saveEconomicVariableSettingFailedData = {};
    },
    postSaveEconomicVariableSettingRequestSuccess: (state, action) => {
      state.isSaveEconomicVariableSettingLoading = false;
      state.saveEconomicVariableSettingSuccessData = action?.payload;
      state.saveEconomicVariableSettingFailedData = {};
    },
    postSaveEconomicVariableSettingRequestFailed: (state, action) => {
      state.isSaveEconomicVariableSettingLoading = false;
      state.saveEconomicVariableSettingSuccessData = {};
      state.saveEconomicVariableSettingFailedData = action?.payload;
    },

    //get Economic Variable Settings
    getEconomicVariableSettingRequest: (state) => {
      state.isGetEconomicVariableSettingsLoading = true;
      state.economicVariableSettingsSuccessData = {};
      state.economicVariableSettingsFailedData = {};
    },
    getEconomicVariableSettingRequestSuccess: (state, action) => {
      state.isGetEconomicVariableSettingsLoading = false;
      state.economicVariableSettingsSuccessData = action.payload;
      state.economicVariableSettingsFailedData = {};
    },
    getEconomicVariableSettingRequestFailed: (state, action) => {
      state.isGetEconomicVariableSettingsLoading = false;
      state.economicVariableSettingsSuccessData = {};
      state.economicVariableSettingsFailedData = action.payload;
    },

    //update Economic Variable Setting
    updateEconomicVariableSettingRequest: (state) => {
      state.isUpdateEconomicVariableSettingLoading = true;
      state.updateEconomicVariableSettingSuccessData = {};
      state.updateEconomicVariableSettingFailedData = {};
    },
    updateEconomicVariableSettingRequestSuccess: (state, action) => {
      state.isUpdateEconomicVariableSettingLoading = false;
      state.updateEconomicVariableSettingSuccessData = action.payload;
      state.updateEconomicVariableSettingFailedData = {};
    },
    updateEconomicVariableSettingRequestFailed: (state, action) => {
      state.isUpdateEconomicVariableSettingLoading = false;
      state.updateEconomicVariableSettingSuccessData = {};
      state.updateEconomicVariableSettingFailedData = action.payload;
    },
  }
);
