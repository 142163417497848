import { createReducer } from '@reduxjs/toolkit';

const webFormSettingsInitialState = {
  //Create New Web Form Setting
  isSaveWebFormSettingLoading: false,
  saveWebFormSettingSuccessData: {},
  saveWebFormSettingFailedData: {},

  //get Web Form Settings
  isGetWebFormSettingsLoading: false,
  webFormSettingsSuccessData: {},
  webFormSettingsFailedData: {},

  //update web form setting
  isUpdateWebFormSettingLoading: false,
  updateWebFormSettingSuccessData: {},
  updateWebFormSettingFailedData: {},
};

//web form setting reducer
export const webFormSettingsReducer = createReducer(webFormSettingsInitialState, {
  // save web form setting
  postSaveWebFormSettingRequest: (state) => {
    state.isSaveWebFormSettingLoading = true;
    state.saveWebFormSettingSuccessData = {};
    state.saveWebFormSettingFailedData = {};
  },
  postSaveWebFormSettingRequestSuccess: (state, action) => {
    state.isSaveWebFormSettingLoading = false;
    state.saveWebFormSettingSuccessData = action?.payload;
    state.saveWebFormSettingFailedData = {};
  },
  postSaveWebFormSettingRequestFailed: (state, action) => {
    state.isSaveWebFormSettingLoading = false;
    state.saveWebFormSettingSuccessData = {};
    state.saveWebFormSettingFailedData = action?.payload;
  },

  //get web form settings
  getWebFormSettingRequest: (state) => {
    state.isGetWebFormSettingsLoading = true;
    state.webFormSettingsSuccessData = {};
    state.webFormSettingsFailedData = {};
  },
  getWebFormSettingRequestSuccess: (state, action) => {
    state.isGetWebFormSettingsLoading = false;
    state.webFormSettingsSuccessData = action.payload;
    state.webFormSettingsFailedData = {};
  },
  getWebFormSettingRequestFailed: (state, action) => {
    state.isGetWebFormSettingsLoading = false;
    state.webFormSettingsSuccessData = {};
    state.webFormSettingsFailedData = action.payload;
  },

  //update web form setting
  updateWebFormSettingRequest: (state) => {
    state.isUpdateWebFormSettingLoading = true;
    state.updateWebFormSettingSuccessData = {};
    state.updateWebFormSettingFailedData = {};
  },
  updateWebFormSettingRequestSuccess: (state, action) => {
    state.isUpdateWebFormSettingLoading = false;
    state.updateWebFormSettingSuccessData = action.payload;
    state.updateWebFormSettingFailedData = {};
  },
  updateWebFormSettingRequestFailed: (state, action) => {
    state.isUpdateWebFormSettingLoading = false;
    state.updateWebFormSettingSuccessData = {};
    state.updateWebFormSettingFailedData = action.payload;
  },
});
