import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Divider, Fade, Grid, LinearProgress, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import ConfirmationModal from 'common/ConfirmationModal';
import InputField from 'common/material/InputField';
import DropDownAutoCom from 'common/material/DropDownAutoCom';

import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';
import MainQuestionnaireListItem from 'views/questionnaire/components/MainQuestionnaireListItem';
import AdvisorQuestionnaireListItem from 'views/questionnaire/components/AdvisorQuestionnaireListItem';
import AssignTemplateModal from 'modal/client/questionnaire/AssignTemplateModal';
import MainCard from 'ui-component/cards/MainCard';
import TitleContainer from 'ui-component/dashboard/TitleContainer';
import LoadingSpinner from 'ui-component/LoadingSpinner';

import {
  requestDeleteQuestionnaire,
  requestFilterClientQuestionnaires,
  requestRejectQuestionnaire,
  requestReviewQuestionnaire,
} from 'redux/Actions/clientQuestionnairesAction';
import { handleModals } from 'redux/Actions/handleModalAction';
import { handleNotification } from 'redux/Actions/handleNotificationAction';
import { requestFilterQuestionnaireTemplateTags } from 'redux/Actions/questionnaireTagAction';

import * as deleteAnimationData from 'assets/animation/delete.json';
import * as rejectAnimationData from 'assets/animation/reject-circle.json';
import * as reviewAnimationData from 'assets/animation/questionnaire-template-animation.json';

const ListClientQuestionnaire = (props) => {
  const {
    classes: { mainTitle, labelRed, conformationContentText },
  } = useCommonStyles();
  /**
   *
   */
  const location = useLocation();
  const clientData = location.state?.clientData;
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  //_login user store
  const loginUserData = useSelector((state) => state.authenticationReducer?.loginData?.user);
  //_ assign template modal store
  const assignTemplateModalData = useSelector(
    (state) => state?.modalReducer?.CLIENT_TEMPLATE_ASSIGN_MODAL
  );
  //_ questionnaire tag store
  const questionnaireTemplateTagReducer = useSelector(
    (state) => state?.questionnaireTemplateTagReducer
  );
  const isGetAllQuestionnaireTemplateTagsLoading =
    questionnaireTemplateTagReducer?.isGetAllQuestionnaireTemplateTagsLoading;
  //_ client questionnaire reducer (common for lead/client)
  const clientQuestionnairesReducer = useSelector((state) => state?.clientQuestionnairesReducer);
  const isUpdateClientQuestionnaireLoading =
    clientQuestionnairesReducer?.isUpdateClientQuestionnaireLoading;
  const isDeleteClientQuestionnaireQuestionRequestLoading =
    clientQuestionnairesReducer?.isDeleteClientQuestionnaireQuestionRequestLoading;
  const isFilterClientQuestionnaireRequestLoading =
    clientQuestionnairesReducer?.isFilterClientQuestionnaireRequestLoading;

  //_states
  const [advisorQuestionnairesData, setAdvisorQuestionnairesData] = useState({
    data: [],
  });
  const [mainQuestionnaire, setMainQuestionnaire] = useState({});
  const [questionnaireFilters, setQuestionnaireFilters] = useState({
    tags: [],
    questionnaireTitle: '',
  });
  const [templateTagsData, setTemplateTagsData] = useState({
    tagOptions: [],
  });
  const [isMounted, setIsMounted] = useState(false);

  const [confirmationModalData, setConfirmationModalData] = useState({
    isOpened: false,
    header: 'Delete Questionnaire',
    content: 'Do you want to delete this questionnaire?',
    action: 'DELETE_QUESTIONNAIRE',
    modalData: null,
    isLoading: false,
  });
  //_page loading state
  const isLoading = useMemo(() => {
    return !!isGetAllQuestionnaireTemplateTagsLoading;
  }, [isGetAllQuestionnaireTemplateTagsLoading]);
  //_list loading state
  const isListLoading = useMemo(() => {
    return !!(
      isUpdateClientQuestionnaireLoading ||
      isDeleteClientQuestionnaireQuestionRequestLoading ||
      isFilterClientQuestionnaireRequestLoading
    );
  }, [
    isUpdateClientQuestionnaireLoading,
    isDeleteClientQuestionnaireQuestionRequestLoading,
    isFilterClientQuestionnaireRequestLoading,
  ]);
  /**
   * Handle open assign template modal
   */
  const handleOnAssignQuestionnaireTemplate = useCallback(() => {
    dispatch(
      handleModals(true, 'CLIENT_TEMPLATE_ASSIGN_MODAL', {
        clientData,
      })
    );
  }, [clientData]);

  /**
   * Handle filter questionnaire
   */
  const handleFilterQuestionnaire = useCallback(() => {
    const filterDetails = {
      clientId: clientData?._id,
      filterRequestData: {
        includeMainQuestionnaire: true,
      },
    };

    if (questionnaireFilters?.tags?.length && Array.isArray(questionnaireFilters.tags)) {
      filterDetails.filterRequestData.tags = questionnaireFilters.tags.map(
        (tagDetail) => tagDetail?._id
      );
    }
    if (questionnaireFilters?.questionnaireTitle?.length >= 3) {
      filterDetails.filterRequestData.questionnaireTitle = questionnaireFilters?.questionnaireTitle;
    }
    const handleOnFilterRequestSuccessCallback = (response) => {
      const advisorQuestionnaires = (response || []).filter(
        (detail) => !!detail?.questionnaireTemplate
      );
      const mainQuestionnaireDetail = (response || []).find(
        (detail) =>
          detail.questionnaireTitle === 'Main Questionnaire' && !detail.questionnaireTemplate
      );
      setAdvisorQuestionnairesData((prev) => ({
        ...prev,
        data: advisorQuestionnaires,
      }));
      setMainQuestionnaire(mainQuestionnaireDetail);
    };
    dispatch(
      requestFilterClientQuestionnaires(filterDetails, handleOnFilterRequestSuccessCallback)
    );
  }, [clientData, questionnaireFilters?.questionnaireTitle, questionnaireFilters?.tags]);
  /**
   * Handle close assign template modal
   */
  const handleCloseAssignTemplateModal = useCallback(() => {
    dispatch(handleModals(false, 'CLIENT_TEMPLATE_ASSIGN_MODAL', null));
  }, []);
  /**
   * Close confirmation modal
   */
  const handleCloseConfirmationAction = useCallback(() => {
    setConfirmationModalData((prev) => ({
      ...prev,
      isOpened: false,
      modalData: null,
      isLoading: false,
    }));
  }, []);
  /**
   * Handle confirm confirmation
   */
  const handleConfirmConfirmationAction = useCallback(() => {
    const handleOnRequestSuccessCallback = (response) => {
      handleCloseConfirmationAction();
      handleFilterQuestionnaire();
    };
    const handleOndRequestFailedCallback = () => {
      setConfirmationModalData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    };
    //_request action questionnaire
    if (confirmationModalData?.action === 'DELETE_QUESTIONNAIRE') {
      const requestDetails = {
        questionnaireRootId: confirmationModalData?.modalData?.questionnaireDetail?._id,
      };
      if (!confirmationModalData?.modalData?.questionnaireDetail?._id) {
        dispatch(handleNotification('Please try again.', 'error'));
      }
      setConfirmationModalData((prev) => ({
        ...prev,
        isLoading: true,
      }));
      dispatch(
        requestDeleteQuestionnaire(
          requestDetails,
          handleOnRequestSuccessCallback,
          handleOndRequestFailedCallback
        )
      );
    } else if (confirmationModalData?.action === 'REJECT_QUESTIONNAIRE') {
      const requestDetails = {
        questionnaireId: confirmationModalData?.modalData?.questionnaireDetail?._id,
        advisorComments: confirmationModalData?.modalData?.commentary || '',
      };
      if (!requestDetails?.questionnaireId) {
        dispatch(handleNotification('Please try again!', 'error'));
        handleCloseConfirmationAction();
      }
      dispatch(
        requestRejectQuestionnaire(
          requestDetails,
          handleOnRequestSuccessCallback,
          handleOndRequestFailedCallback
        )
      );
    } else if (confirmationModalData?.action === 'REVIEW_QUESTIONNAIRE') {
      const requestDetails = {
        questionnaireId: confirmationModalData?.modalData?.questionnaireDetail?._id,
        advisorComments: confirmationModalData?.modalData?.commentary || '',
      };
      if (!requestDetails?.questionnaireId) {
        dispatch(handleNotification('Please try again!', 'error'));
        handleCloseConfirmationAction();
      }
      dispatch(
        requestReviewQuestionnaire(
          requestDetails,
          handleOnRequestSuccessCallback,
          handleOndRequestFailedCallback
        )
      );
    }
  }, [confirmationModalData]);
  /**
   * Request Delete questionnaire
   * @param {*} event
   * @param {*} questionnaireDetail
   */
  const handleRequestRemoveAdvisorQuestionnaire = useCallback((event, questionnaireDetail) => {
    setConfirmationModalData((prev) => ({
      ...prev,
      isOpened: true,
      action: 'DELETE_QUESTIONNAIRE',
      content: "Do you want to delete this questionnaire?",
      header: "Delete questionnaire",
      modalData: { questionnaireDetail },
      isLoading: false,
    }));
  }, []);
  /**
   * Handle on search by title filter change
   * @param {*} event
   */
  const handleOnTemplateSearchChange = useCallback((event) => {
    setQuestionnaireFilters((prev) => ({
      ...prev,
      questionnaireTitle: event?.target?.value || '',
    }));
  }, []);

  const ConfirmationCustomContent = memo((props) => {
    const { content, setCommentary } = props;
    return (
      <Grid container spacing={gridSpacing} paddingTop="2rem">
        <Grid item xs={12}>
          <Typography className={conformationContentText}>{content}</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputField id="commentary-field" label="Comment" onChange={setCommentary} />
        </Grid>
      </Grid>
    );
  });
  /**
   * Handle set commentary
   */
  const handleSetCommentary = useCallback((event) => {
    setConfirmationModalData((prev) => ({
      ...prev,
      modalData: {
        ...(prev?.modalData || {}),
        commentary: event?.target?.value || '',
      },
    }));
  }, []);

  /**
   * Handle reject questionnaire confirmation
   */
  const handleRejectQuestionnaire = useCallback((event, questionnaireDetail) => {
    setConfirmationModalData((prev) => ({
      ...prev,
      header: 'Reject Questionnaire',
      content: (
        <ConfirmationCustomContent
          content="Do you want to reject the selected questionnaire? If you have any commentary for client, please provide in following field."
          setCommentary={handleSetCommentary}
        />
      ),
      action: 'REJECT_QUESTIONNAIRE',
      modalData: {
        questionnaireDetail,
        commentary: '',
      },
      isOpened: true,
    }));
  }, []);
  /**
   * Handle review questionnaire confirmation
   */
  const handleReviewQuestionnaire = useCallback((event, questionnaireDetail) => {
    setConfirmationModalData((prev) => ({
      ...prev,
      header: 'Review Questionnaire',
      content: (
        <ConfirmationCustomContent
          content="Do you want to review the selected questionnaire? If you have any commentary for client, please provide in following field."
          setCommentary={handleSetCommentary}
        />
      ),
      action: 'REVIEW_QUESTIONNAIRE',
      modalData: {
        questionnaireDetail,
      },
      isOpened: true,
    }));
  }, []);
  /**
   * Request for tags
   */
  useEffect(() => {
    if (isMounted) {
      const requestData = {
        rootUser: loginUserData?.rootUser,
      };
      const handleSearchTagsSuccessCallback = (response) => {
        if (Array.isArray(response?.data)) {
          setTemplateTagsData((prev) => ({ ...prev, tagOptions: response?.data }));
        }
      };
      dispatch(
        requestFilterQuestionnaireTemplateTags(requestData, handleSearchTagsSuccessCallback)
      );
    }
  }, [loginUserData?.rootUser, isMounted]);
  /**
   * Effect on filter change
   */
  useEffect(() => {
    if (isMounted) {
      if (
        !questionnaireFilters?.questionnaireTitle ||
        questionnaireFilters?.questionnaireTitle?.length >= 3
      ) {
        handleFilterQuestionnaire();
      }
    }
  }, [questionnaireFilters?.questionnaireTitle]);
  /**
   * Effect on filter change
   */
  useEffect(() => {
    if (isMounted) {
      handleFilterQuestionnaire();
    }
  }, [questionnaireFilters?.tags, isMounted]);
  /**
   * Effect on mount
   */
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <Grid container spacing={gridSpacing}>
      {!!isLoading && <LoadingSpinner isLoadingSpinner={!!isLoading} />}
      <AssignTemplateModal
        isOpened={!!assignTemplateModalData?.modalStatus}
        handleClose={handleCloseAssignTemplateModal}
        handleOnAssignSuccessCallback={handleFilterQuestionnaire}
        clientDetail={assignTemplateModalData?.modalData?.clientData}
      />
      <ConfirmationModal
        isConfirmationModal={!!confirmationModalData?.isOpened}
        closeConfirmationAction={handleCloseConfirmationAction}
        modalConfirmAction={handleConfirmConfirmationAction}
        confirmationModalHeader={confirmationModalData.header}
        confirmationModalContent={confirmationModalData.content}
        img={
          confirmationModalData?.action === 'DELETE_QUESTIONNAIRE'
            ? deleteAnimationData
            : confirmationModalData?.action === 'REJECT_QUESTIONNAIRE'
            ? rejectAnimationData
            : reviewAnimationData
        }
        loading={confirmationModalData?.isLoading}
        lottieAnimProps={{ loop: false }}
      />
      <Grid container item xs={12}>
        <MainCard>
          <TitleContainer
            title={'Client Questionnaires'}
            isPrimaryButton={false}
            isSecondaryButton={false}
            isSearchBox={false}
          />
        </MainCard>
      </Grid>
      <Grid container item xs={12}>
        <MainCard
          contentSX={{
            paddingInline: '0 !important',
          }}
        >
          <Grid container rowGap={gridSpacing}>
            <Grid item xs={12} sx={{ paddingInline: '1rem' }}>
              <MainQuestionnaireListItem
                questionnaireDetail={mainQuestionnaire}
                handleRejectQuestionnaire={handleRejectQuestionnaire}
                handleReviewQuestionnaire={handleReviewQuestionnaire}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Fade in={isListLoading}>
                <LinearProgress variant="indeterminate" />
              </Fade>
            </Grid>
            <Grid item xs={12} sx={{ paddingInline: '1rem' }}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={gridSpacing}>
                    <Grid item xs>
                      <Typography className={mainTitle}>Advisor Assigned</Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Grid container alignItems="center" justifyContent="flex-end">
                        <Button
                          id="client-template-assign"
                          onClick={handleOnAssignQuestionnaireTemplate}
                          variant="contained"
                          color="primary"
                        >
                          Assign questionnaire template
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing} alignItems="center1">
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="search-questionnaire-title"
                        label="Questionnaire Title"
                        placeholder="Search here.."
                        onChange={handleOnTemplateSearchChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DropDownAutoCom
                        id="questionnaire-tags-search"
                        label="Tags"
                        optionData={templateTagsData?.tagOptions || []}
                        setDropDownValue={(value) => {
                          if (Array.isArray(value)) {
                            setQuestionnaireFilters((prev) => ({
                              ...prev,
                              tags: value,
                            }));
                          }
                        }}
                        dropDownValue={questionnaireFilters?.tags}
                        textFieldMargin="none"
                        valueKey="_id"
                        labelKey="tagName"
                        multiple
                        ChipProps={{
                          color: 'primary',
                          size: 'medium',
                          variant: 'outlined',
                          sx: {
                            marginLeft: '0.5rem !important',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {!!advisorQuestionnairesData?.data?.length ? (
                    <Grid container spacing={gridSpacing}>
                      {advisorQuestionnairesData.data.map(
                        (questionnaireDetail, questionnaireIndex) => (
                          <Grid item xs={12} key={`${questionnaireIndex}-advisor-template-wrapper`}>
                            <AdvisorQuestionnaireListItem
                              key={`${questionnaireIndex}-advisor-template`}
                              questionnaireDetail={questionnaireDetail}
                              handleRemoveAdvisorQuestionnaire={
                                handleRequestRemoveAdvisorQuestionnaire
                              }
                              handleRejectQuestionnaire={handleRejectQuestionnaire}
                              handleReviewQuestionnaire={handleReviewQuestionnaire}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  ) : (
                    <Grid container item xs={12} justifyContent="center">
                      <Grid item xs="auto" textAlign="center">
                        <Typography
                          className={labelRed}
                          sx={{
                            minHeight: '12rem',
                          }}
                        >
                          No Records
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default ListClientQuestionnaire;
