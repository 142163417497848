import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  boards: [],
  board: null,
  dashboardLoading: false,
  error: {},
  allUsers: [],
};

export const kanbanBoardReducer = createReducer(initialState, {
  //create board
  createKanbanBoardRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  createKanbanBoardSuccess: (state, action) => {
    return {
      ...state,
      boards: [action.payload, ...state.boards],
      dashboardLoading: false,
    };
  },
  createKanbanBoardFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  //get boards
  getKanbanBoardRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
      board: null,
    };
  },
  getKanbanBoardSuccess: (state, action) => {
    return {
      ...state,
      boards: action.payload,
      dashboardLoading: false,
      board: null,
    };
  },
  getKanbanBoardFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
      board: null,
    };
  },

  //get board by id
  getKanbanBoardByIdRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  getKanbanBoardByIdSuccess: (state, action) => {
    return {
      ...state,
      board: { ...state.board, ...action.payload },
      dashboardLoading: false,
    };
  },
  getKanbanBoardByIdFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  //get board Activity
  getKanbanBoardActivityRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  getKanbanBoardActivitySuccess: (state, action) => {
    return {
      ...state,
      board: { ...state.board, activity: action.payload },
      dashboardLoading: false,
    };
  },
  getKanbanBoardActivityFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  //change Board Title
  changeKanbanBoardTitleRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  changeKanbanBoardTitleSuccess: (state, action) => {
    return {
      ...state,
      board: { ...state.board, ...action.payload },
    };
  },
  changeKanbanBoardTitleFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // add Board Member
  addBoardMemberRequest: (state) => {
    return {
      ...state,
      dashboardLoading: false,
    };
  },
  addBoardMemberSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        members: action.payload,
      },
      dashboardLoading: false,
    };
  },
  addBoardMemberFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // add card
  crateCardRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  crateCardSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        listObjects: state.board.listObjects.map((list) =>
          list._id === action.payload.listId
            ? { ...list, cards: [...list.cards, action.payload.cardId] }
            : list
        ),
      },
      dashboardLoading: false,
    };
  },
  crateCardFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // get card
  getCardByIdRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  getCardByIdSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: [...state.board.cardObjects, action.payload],
      },
      dashboardLoading: false,
    };
  },
  getCardByIdFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // edit Card
  editCardRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  editCardSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: state.board.cardObjects.map((card) =>
          card._id === action.payload._id ? action.payload : card
        ),
      },
      dashboardLoading: false,
    };
  },
  editCardFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // archive
  archiveCardRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  archiveCardSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: state.board.cardObjects.map((card) =>
          card._id === action.payload._id ? action.payload : card
        ),
      },
      dashboardLoading: false,
    };
  },
  archiveCardFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // move Card
  moveCardRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  moveCardSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        listObjects: state.board.listObjects.map((list) =>
          list._id === action.payload.from._id
            ? action.payload.from
            : list._id === action.payload.to._id
            ? action.payload.to
            : list
        ),
        cardObjects: state.board.cardObjects.filter(
          (card) =>
            card._id !== action.payload.cardId || action.payload.to._id === action.payload.from._id
        ),
      },
      dashboardLoading: false,
    };
  },
  moveCardFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // add or Remove Member
  addRemoveMemberRequest: (state) => {
    return {
      ...state,
      dashboardLoading: false,
    };
  },
  addRemoveMemberSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: state.board.cardObjects.map((card) =>
          card._id === action.payload._id ? action.payload : card
        ),
      },
      dashboardLoading: false,
    };
  },
  addRemoveMemberFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // delete Card
  deleteCardRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  deleteCardSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: state.board.cardObjects.filter((card) => card._id !== action.payload),
        listObjects: state.board.listObjects.map((list) =>
          list.cards.includes(action.payload)
            ? { ...list, cards: list.cards.filter((card) => card !== action.payload) }
            : list
        ),
      },
      dashboardLoading: false,
    };
  },
  deleteCardFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // create CheckList
  createCheckListRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  createCheckListSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: state.board.cardObjects.map((card) =>
          card._id === action.payload._id ? action.payload : card
        ),
      },
      dashboardLoading: false,
    };
  },
  createCheckListFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // create CheckList
  editCheckListItemTextRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  editCheckListItemTextSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: state.board.cardObjects.map((card) =>
          card._id === action.payload._id ? action.payload : card
        ),
      },
      dashboardLoading: false,
    };
  },
  editCheckListItemTextFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // create CheckList
  changeCheckListStatusRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  changeCheckListStatusSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: state.board.cardObjects.map((card) =>
          card._id === action.payload._id ? action.payload : card
        ),
      },
      dashboardLoading: false,
    };
  },
  changeCheckListStatusFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // delete Check List
  deleteCheckListRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  deleteCheckListSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        cardObjects: state.board.cardObjects.map((card) =>
          card._id === action.payload._id ? action.payload : card
        ),
      },
      dashboardLoading: false,
    };
  },
  deleteCheckListFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // create list
  createListRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  createListSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        lists: [...state.board.lists, action.payload._id],
      },
    };
  },
  createListFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // get List By Id
  getListByIdRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  getListByIdSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        listObjects: [...state.board.listObjects, action.payload],
      },
      dashboardLoading: false,
    };
  },
  getListByIdFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // edit List Title
  editListTitleRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  editListTitleSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        listObjects: state.board.listObjects.map((list) =>
          list._id === action.payload._id ? action.payload : list
        ),
      },
      dashboardLoading: false,
    };
  },
  editListTitleFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // Archive/Unarchive a list
  archiveUnarchiveListRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  archiveUnarchiveListSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        listObjects: state.board.listObjects.map((list) =>
          list._id === action.payload._id ? action.payload : list
        ),
      },
      dashboardLoading: false,
    };
  },
  archiveUnarchiveListFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // move list
  moveListRequest: (state) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  moveListSuccess: (state, action) => {
    return {
      ...state,
      board: {
        ...state.board,
        lists: action.payload,
      },
      dashboardLoading: false,
    };
  },
  moveListFailed: (state, action) => {
    return {
      ...state,
      error: action.payload,
      dashboardLoading: false,
    };
  },

  // get user
  getUsersRequest: (state) => {
    return {
      ...state,
      allUsers: [],
      dashboardLoading: false,
    };
  },
  getUsersSuccess: (state, action) => {
    return {
      ...state,
      allUsers: action.payload,
      dashboardLoading: false,
    };
  },
  getUsersFailed: (state, action) => {
    return {
      ...state,
      allUsers: [],
      dashboardLoading: false,
    };
  },
});
