import { useState, useRef, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSettings, IconUser, IconUsers } from '@tabler/icons';
import { handleSignOut } from 'redux/Actions/authenticationAction';
// import { requestGetAdvisorProfileSetting } from 'redux/Actions/advisorProfileSettingsAction';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  // redux integrations
  const dispatch = useDispatch();
  // User Detail
  const {
    loginData: { user },
  } = useSelector((state) => state.authenticationReducer);
  //my profile store
  const { advisorProfileSettingsSuccessData } = useSelector(
    (state) => state.advisorProfileSettingsReducer
  );
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  /**
   * Logged in user's Profile Name
   */
  const profileName = useMemo(() => {
    let loggedInUserName =
      (user?.surName && user?.firstName + ' ' + user?.surName) || user?.firstName;
    let loggedInUserProfileName =
      (advisorProfileSettingsSuccessData?.surName &&
        advisorProfileSettingsSuccessData?.firstName +
          ' ' +
          advisorProfileSettingsSuccessData?.surName) ||
      advisorProfileSettingsSuccessData?.firstName;

    return loggedInUserProfileName || loggedInUserName;
  }, [advisorProfileSettingsSuccessData, user]);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  /**
   * On Sign Out clicked
   */
  const handleLogout = () => {
    dispatch(handleSignOut(navigate));
  };
  /**
   * Close Profile Popper
   * @param {MouseEvent} event
   * @returns
   */
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  /**
   * Effect on open
   */
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={
              (advisorProfileSettingsSuccessData?.profilePicture &&
                advisorProfileSettingsSuccessData?.profilePicture +
                  '?' +
                  new Date().getMilliseconds()) ||
              User1
            }
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer',
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Typography variant="h4" textTransform="capitalize">
                        Your code : {user?.accountCode}
                      </Typography>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="subtitle1">
                          Good{' '}
                          {new Date().getHours() < 12
                            ? 'Morning'
                            : new Date().getHours() < 17 && new Date().getHours() >= 12
                            ? 'Afternoon'
                            : new Date().getHours() >= 17 && 'Evening'}
                          ,
                        </Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400 }}
                          textTransform="capitalize"
                        >
                          {profileName}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle1" textTransform="capitalize">
                        {advisorProfileSettingsSuccessData?.userType || user?.userType}
                        <Typography variant="subtitle2" component="span" textTransform="initial">
                          - {advisorProfileSettingsSuccessData?.companyName || user?.companyName}
                        </Typography>
                      </Typography>
                    </Stack>
                    {/* <OutlinedInput
                      sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                      id="input-search-profile"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      placeholder="Search profile options"
                      startAdornment={
                        <InputAdornment position="start">
                          <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                        </InputAdornment>
                      }
                      aria-describedby="search-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    /> */}
                    {/* <Divider /> */}
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: '100%',
                      maxHeight: 'calc(100vh - 250px)',
                      overflowX: 'hidden',
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Divider />
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%',
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={true}
                          onClick={(event) => handleListItemClick(event, 0, '/main/myProfile')}
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={<Typography variant="body2">My Profile</Typography>}
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={true}
                          onClick={(event) => handleListItemClick(event, 0, '/main/settings')}
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={<Typography variant="body2">Settings</Typography>}
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={true}
                          onClick={(event) =>
                            handleListItemClick(event, 1, '/main/clientList/individual')
                          }
                        >
                          <ListItemIcon>
                            <IconUsers stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid container spacing={1} justifyContent="space-between">
                                <Grid item>
                                  <Typography variant="body2">Clients</Typography>
                                </Grid>
                                <Grid item>
                                  {/* <Chip
                                    label="488"
                                    size="small"
                                    sx={{
                                      bgcolor: theme.palette.warning.dark,
                                      color: theme.palette.background.default,
                                    }}
                                  /> */}
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={true}
                          onClick={(event) =>
                            handleListItemClick(event, 1, '/main/feedback/general')
                          }
                        >
                          <ListItemIcon>
                            <IconUsers stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid container spacing={1} justifyContent="space-between">
                                <Grid item>
                                  <Typography variant="body2">Submit feedback</Typography>
                                </Grid>
                                <Grid item></Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={true}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
