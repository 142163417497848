import { Grid, IconButton, Paper, SvgIcon, Tooltip, Typography } from '@mui/material';
import { DoNotDisturb, FactCheckOutlined } from '@mui/icons-material';

import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';

import { editSvg } from 'assets/svg/svgImg';

const boxStyle = {
  cursor: 'pointer',
};

const MainQuestionnaireListItem = (props) => {
  const {
    questionnaireDetail,
    handleRejectQuestionnaire,
    handleReviewQuestionnaire,
    isLead,
  } = props;

  const {
    classes: {
      listContainer,
      mainTitle,
      labelBlue,
      labelGreen,
      labelOrange,
      labelRed,
      labelYellow,
    },
  } = useCommonStyles();

  /**
   * Uses react router dom navigation
   */
  const navigate = useNavigate();
  const location = useLocation();
  const clientData = location.state?.clientData;
  //client reducer
  const clientReducer = useSelector((state) => state.clientReducer);
  const { selectedClientDetail } = clientReducer;
  //_ status label class
  const labelClass = useMemo(() => {
    if (questionnaireDetail?.status === 'In Progress') {
      return labelOrange;
    } else if (questionnaireDetail?.status === 'Not Submitted') {
      return labelYellow;
    } else if (questionnaireDetail?.status === 'Submitted') {
      return labelBlue;
    } else if (questionnaireDetail?.status === 'Rejected') {
      return labelRed;
    } else if (questionnaireDetail?.status === 'Reviewed') {
      return labelGreen;
    }
    return labelBlue;
  }, [questionnaireDetail?.status]);

  /**
   * Handle edit questionnaire
   */
  const handleEditMainQuestionnaire = useCallback(() => {
    const navPath = isLead ? 'lead/lead-dashboard/questionnaires' : 'client/questionnaires';
    
    navigate(`/main/${navPath}/${questionnaireDetail?._id}`, {
      state: {
        clientData:
          (selectedClientDetail?._id && selectedClientDetail) || clientData,
        questionnaireDetail,
      },
    });
  }, [questionnaireDetail, clientData]);
  const isRejectable = useMemo(() => {
    if (questionnaireDetail?.status === 'Submitted' || questionnaireDetail?.status === 'Reviewed') {
      return true;
    }
    return false;
  }, [questionnaireDetail?.status]);

  const isReviewable = useMemo(() => {
    if (questionnaireDetail?.status === 'Submitted' || questionnaireDetail?.status === 'Rejected') {
      return true;
    }
    return false;
  }, [questionnaireDetail?.status]);
  return (
    <Paper
      className={listContainer}
      variant="outlined"
      sx={{ padding: '1rem !important', cursor: 'default !important' }}
    >
      <Grid container item xs={12} spacing={gridSpacing} alignItems="center">
        <Grid item xs={6}>
          <Typography
            className={mainTitle}
            sx={{ fontWeight: '500 !important', fontSize: '1rem !important' }}
          >
            Main Questionnaire
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Typography className={labelClass}>{questionnaireDetail?.status || 'N/A'}</Typography>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs="auto">
              <Tooltip title="Edit">
                <IconButton size="medium" sx={boxStyle} onClick={handleEditMainQuestionnaire}>
                  <SvgIcon fontSize="small">{editSvg}</SvgIcon>
                </IconButton>
              </Tooltip>
            </Grid>

            {isRejectable && (
              <Tooltip title="Reject Questionnaire">
                <Grid item xs="auto">
                  <IconButton
                    disabled={!isRejectable}
                    onClick={(event) =>
                      handleRejectQuestionnaire &&
                      handleRejectQuestionnaire(event, questionnaireDetail)
                    }
                  >
                    <DoNotDisturb color="error" />
                  </IconButton>
                </Grid>
              </Tooltip>
            )}
            {isReviewable && (
              <Tooltip title="Mark as Reviewed">
                <Grid item xs="auto">
                  <IconButton
                    disabled={!isReviewable}
                    onClick={(event) =>
                      handleReviewQuestionnaire &&
                      handleReviewQuestionnaire(event, questionnaireDetail)
                    }
                    sx={{
                      color: (theme) => theme?.palette?.green?.[600],
                    }}
                  >
                    <FactCheckOutlined color="inherit" />
                  </IconButton>
                </Grid>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MainQuestionnaireListItem;
