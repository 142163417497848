import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Save Partnership Client Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestSavePartnershipClient =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSavePartnershipClientRequest' });

    const requestData = {
      url: API_URL.partnership.partnershipProfile.POST_SAVE_PARTNERSHIP_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSavePartnershipClientSuccess', payload: response.data });
      const message = `Partnership is Saved Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSavePartnershipClientFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Request Partnership Client List
 * @param {Function} handleClientListSuccessCallback
 * @param {Function} handleClientListFailedCallback
 * @returns
 */
export const requestPartnershipClientList =
  (handleClientListSuccessCallback, handleClientListFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllPartnershipClientsRequest' });

    const requestData = {
      url: API_URL.partnership.partnershipClientList.GET_ALL_PARTNERSHIP_CLIENTS,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllPartnershipClientsSuccess', payload: response.data });

      handleClientListSuccessCallback && handleClientListSuccessCallback(response.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllPartnershipClientsFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleClientListFailedCallback && handleClientListFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle Request Find Partnership Detail by Id
 * @param {object} requestBody
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns void
 */
export const requestPartnershipClientDetail =
  (requestBody, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getPartnershipClientByIdRequest' });

    const requestData = {
      url: API_URL.partnership.partnershipProfile.GET_FIND_PARTNERSHIP_CLIENT_BY_ID.replace(
        '{partnershipId}',
        requestBody?.partnershipId
      ),
      method: 'GET',
    };
    const successCallback = (response) => {
      handleRequestSuccessCallback && handleRequestSuccessCallback(response);
      dispatch({ type: 'getPartnershipClientByIdRequestSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      handleRequestFailedCallback && handleRequestFailedCallback(error);
      dispatch({ type: 'getPartnershipClientByIdRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Update Partnership Basic Profile Detail
 * @param {object} clientUpdaterRequestData {requestBody, clientDetail}
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestUpdatePartnershipClient =
  (
    clientUpdaterRequestData,
    handleRequestSuccessCallback = null,
    handleRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdatePartnershipClientRequest' });
    let { requestBody, partnershipDetail, customSuccessMessage } = clientUpdaterRequestData;
    let partnershipId = partnershipDetail?._id;
    const requestData = {
      url: API_URL.partnership.partnershipProfile.PATCH_UPDATE_PARTNERSHIP_CLIENT_BY_ID.replace(
        '{partnershipId}',
        partnershipId
      ),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdatePartnershipClientRequestSuccess',
        payload: response.data,
      });
      const message = customSuccessMessage
        ? customSuccessMessage
        : `Partnership is Updated Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleRequestSuccessCallback && handleRequestSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdatePartnershipClientRequestFailed', payload: error });

      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleRequestFailedCallback && handleRequestFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Add Partnership data
 * @param {object} partnershipData
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestAddPartnershipData =
  (partnershipData, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postPartnershipDataRequest' });

    let { requestBody, partnershipId } = partnershipData;

    const requestData = {
      url: API_URL.partnership.partnershipProfile.ADD_PARTNERSHIP_DATA.replace(
        '{partnershipId}',
        partnershipId
      ),
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({
        type: 'postPartnershipDataSuccess',
        payload: response.data,
      });
      const message = `Partnership is Added Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleRequestSuccessCallback && handleRequestSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postPartnershipDataFailed', payload: error });

      const message = error?.response?.data?.message || `Something went wrong. Try again !`;

      Store.dispatch(handleNotification(message, 'error'));

      handleRequestFailedCallback && handleRequestFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * request update partnership data
 * @param {*} requestDetails
 * @param {*} handleSuccessCallback
 * @param {*} handleFailedCallback
 * @returns
 */
export const requestUpdatePartnershipData =
  (requestDetails, handleSuccessCallback = null, handleFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'updatePartnershipDataRequest' });

    const { partnershipId, partnershipDataId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.partnership.partnershipProfile.PATCH_UPDATE_PARTNERSHIP_DATA_BY_ID.replace(
        '{partnershipId}',
        partnershipId
      ).replace('{partnershipDataId}', partnershipDataId),
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'updatePartnershipDataSuccess',
        payload: response.data,
      });
      const message = `Partnership is updated successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSuccessCallback && handleSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'updatePartnershipDataFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleFailedCallback && handleFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete partnership data
 * @param {*} requestDetails
 * @param {*} handleSuccessCallback
 * @param {*} handleFailedCallback
 * @returns
 */
export const requestDeletePartnershipData =
  (requestDetails, handleSuccessCallback = null, handleFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deletePartnershipDataRequest' });

    const { partnershipId, partnershipDataId } = requestDetails;

    const requestData = {
      url: API_URL.partnership.partnershipProfile.DELETE_PARTNERSHIP_DATA_BY_ID.replace(
        '{partnershipId}',
        partnershipId
      ).replace('{partnershipDataId}', partnershipDataId),
      method: 'DELETE',
    };

    const successCallback = (response) => {
      dispatch({
        type: 'deletePartnershipDataSuccess',
        payload: response.data,
      });
      const message = `Partnership is deleted successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSuccessCallback && handleSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'deletePartnershipDataFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleFailedCallback && handleFailedCallback(error);
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 *  Save Multiple partnership Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */

export const requestSaveMultiplePartnership =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveMultiplePartnershipRequest' });

    const requestData = {
      url: API_URL.partnership.partnershipProfile.POST_SAVE_MULTIPLE_PARTNERSHIP_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveMultiplePartnershipSuccess', payload: response.data });
      const successMessage = `Clients have been saved successfully.`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveMultiplePartnershipFailed', payload: error });
      let message = error?.response?.data?.name || 'Clients&apos; Save has Failed';
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * filter Partnership  Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestFilterPartnership =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postFilterPartnershipRequest' });

    const requestData = {
      url: API_URL.partnership.partnershipClientList.FILTER_PARTNERSHIP,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postFilterPartnershipSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postFilterPartnershipFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * add linked entities partnership
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const addLinkedEntitiesPartnership =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'addLinkedEntitiesPartnershipRequest' });

    const requestData = {
      url: API_URL.partnership.partnershipClientList.ADD_LINKED_ENTITIES,
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({ type: 'addLinkedEntitiesPartnershipSuccess', payload: response.data });
      const requestDetail = {
        partnershipId: requestBody.partnershipId,
      };
      handleSaveClientSuccessCallback();
      !requestBody?.isNotGetting && dispatch(requestPartnershipClientDetail(requestDetail));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'addLinkedEntitiesPartnershipFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback?.(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * delete linked entities partnership
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const deleteLinkedEntitiesPartnership =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteLinkedEntitiesPartnershipRequest' });

    const requestData = {
      url: API_URL.partnership.partnershipClientList.DELETE_LINKED_ENTITIES,
      method: 'DELETE',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteLinkedEntitiesPartnershipSuccess', payload: response.data });
      const requestDetail = {
        partnershipId: requestBody.partnershipId,
      };
      dispatch(requestPartnershipClientDetail(requestDetail));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteLinkedEntitiesPartnershipFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback?.(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
