import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New Lead
  saveTaskRequestLoading: false,
  saveTaskData: {},
  saveTaskFailed: {},

  isTasksListLoading: false,
  tasksListData: null,
  tasksListFailed: null,

  isTaskUpdateProcessing: false,
  taskUpdateSuccessData: {},
  taskUpdateFailedData: {},

  isFindTaskRequestLoading: false,
  findTaskSuccessData: {},
  findTaskFailedData: {},

  isDeleteTaskRequestLoading: false,
  deleteTaskSuccessData: {},
  deleteTaskFailedData: {},

  isSearchTasksRequestLoading: false,
  searchTasksRequestSuccessData: {},
  searchTasksRequestFailedData: {},

  isFindAllTaskAssigneesLoading: false,
  tasksAssigneesSuccessData: [],
  tasksAssigneesFailedData: {}
};

export const tasksReducer = createReducer(initialState, {
  //save tasks
  postSaveTaskRequest: (state) => {
    state.saveTaskRequestLoading = true;
    state.saveTaskData = {};
    state.saveTaskFailed = {};
  },
  postSaveTaskSuccess: (state, action) => {
    state.saveTaskRequestLoading = false;
    state.saveTaskData = action.payload;
    state.saveTaskFailed = {};
  },
  postSaveTaskFailed: (state, action) => {
    state.saveTaskRequestLoading = false;
    state.saveTaskData = {};
    state.saveTaskFailed = action.payload;
  },

  //get all tasks
  getAllTasksRequest: (state) => {
    state.isTasksListLoading = true;
    state.tasksListData = [];
    state.tasksListFailed = {};
  },
  getAllTasksRequestSuccess: (state, action) => {
    state.isTasksListLoading = false;
    state.tasksListData = action.payload;
    state.tasksListFailed = {};
  },
  getAllTasksRequestFailed: (state, action) => {
    state.isTasksListLoading = false;
    state.tasksListData = [];
    state.tasksListFailed = action.payload;
  },

  //update task
  putTaskUpdateRequest: (state) => {
    state.isTaskUpdateProcessing = true;
    state.taskUpdateSuccessData = {};
    state.taskUpdateFailedData = {};
  },
  putTaskUpdateSuccess: (state, action) => {
    state.isTaskUpdateProcessing = false;
    state.taskUpdateSuccessData = action.payload;
    state.taskUpdateFailedData = {};
  },
  putTaskUpdateFailed: (state, action) => {
    state.isTaskUpdateProcessing = false;
    state.taskUpdateSuccessData = {};
    state.taskUpdateFailedData = action.payload;
  },

  //find task by task id
  getFindTaskRequest: (state, action) => {
    state.isFindTaskRequestLoading = true;
    state.findTaskSuccessData = {};
    state.findTaskFailedData = {};
  },
  getFindTaskRequestSuccess: (state, action) => {
    state.isFindTaskRequestLoading = false;
    state.findTaskSuccessData = action.payload;
    state.findTaskFailedData = {};
  },
  getFindTaskRequestFailed: (state, action) => {
    state.isFindTaskRequestLoading = false;
    state.findTaskSuccessData = {};
    state.findTaskFailedData = action.payload;
  },

  //delete task
  deleteTaskRequest: (state) => {
    state.isDeleteTaskRequestLoading = true;
    state.deleteTaskSuccessData = {};
    state.deleteTaskFailedData = {};
  },
  deleteTaskRequestSuccess: (state, action) => {
    state.isDeleteTaskRequestLoading = false;
    state.deleteTaskSuccessData = action.payload;
    state.deleteTaskFailedData = {};
  },
  deleteTaskRequestFailed: (state, action) => {
    state.isDeleteTaskRequestLoading = false;
    state.deleteTaskSuccessData = {};
    state.deleteTaskFailedData = action.payload;
  },

  //post search tasks (filter request)
  postSearchTasksRequest: (state) => {
    state.isSearchTasksRequestLoading = true;
    state.searchTasksRequestSuccessData = {};
    state.searchTasksRequestFailedData = {};
  },
  postSearchTasksRequestSuccess: (state, action) => {
    state.isSearchTasksRequestLoading = false;
    state.searchTasksRequestSuccessData = action.payload;
    state.searchTasksRequestFailedData = {};
  },
  postSearchTasksRequestFailed: (state, action) => {
    state.isSearchTasksRequestLoading = false;
    state.searchTasksRequestSuccessData = {};
    state.searchTasksRequestFailedData = action.payload;
  },

  // get tasks assignees
  getTasAssigneesRequest: (state) => {
    state.isFindAllTaskAssigneesLoading = true;
    state.tasksAssigneesSuccessData = [];
    state.tasksAssigneesFailedData = {};
  },
  getTasAssigneesRequestSuccess: (state, action) => {
    state.isFindAllTaskAssigneesLoading = false;
    state.tasksAssigneesSuccessData = action.payload;
    state.tasksAssigneesFailedData = {};
  },
  getTasAssigneesRequestFailed: (state, action) => {
    state.isFindAllTaskAssigneesLoading = false;
    state.tasksAssigneesSuccessData = [];
    state.tasksAssigneesFailedData = action.payload;
  },
});
