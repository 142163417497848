import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New Lead
  projectSuperannuationProcessing: false,
  projectSuperannuationData: {},
  projectSuperannuationFailed: {},
};

export const superannuationReducer = createReducer(initialState, {
  //superannuation data projection
  projectSuperannuationRequest: (state) => {
    state.projectSuperannuationProcessing = true;
    state.projectSuperannuationData = {};
    state.projectSuperannuationFailed = {};
  },
  projectSuperannuationRequestSuccess: (state, action) => {
    state.projectSuperannuationProcessing = false;
    state.projectSuperannuationData = action.payload;
    state.projectSuperannuationFailed = {};
  },
  projectSuperannuationRequestFailed: (state, action) => {
    state.projectSuperannuationProcessing = false;
    state.projectSuperannuationData = {};
    state.projectSuperannuationFailed = action.payload;
  },
});
