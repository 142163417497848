import { Button, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Edit, SaveOutlined } from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

import CommonModal from 'common/material/CommonModal';
import { gridSpacing } from 'store/constant';

import { useCommonStyles } from 'styles/styles';

import ColumnConfigureListItem from './components/ColumnConfigureListItem';
import { useCallback, useEffect, useState } from 'react';
import { formatStringToCamelCase } from 'utils/stringFormat';

const QuestionTableConfigureForm = (props) => {
  const {
    questionData,
    actionDataIndex,
    handleDialogOpened,
    isEditMode,
    tableAnswerModalData,
    isOpened,
  } = props;
  /**
   * Uses common classes
   */
  const { classes: commonClasses } = useCommonStyles();
  /**
   * Main Formik Context
   */
  const formikContext = useFormikContext();
  //_states
  const [questionnaireFieldInitialValues, setQuestionnaireFieldInitialValues] = useState({
    columnData: [
      {
        headerName: '',
        type: '',
      },
    ],
  });
  const [isMounted, setIsMounted] = useState(false);

  //validates table unique headings (object should have one key of same name)
  Yup.addMethod(Yup.string, 'validateUniqueHeadings', function (message) {
    return this.test('validateUniqueHeadings', message, function (value, ctx) {
      const {
        path,
        createError,
        parent,
        options: { from },
      } = this;

      let errorMessage = '';
      let parentFormData = from?.[(+from?.length || 1) - 1]?.value;
      const headerNames = parentFormData?.columnData?.map?.((item) =>
        formatStringToCamelCase(item.headerName)
      );
      let camelCasedColumnName = formatStringToCamelCase(value);
      // Check if the current headerName exists elsewhere in the array
      let isUnique =
        headerNames.indexOf(camelCasedColumnName) === headerNames.lastIndexOf(camelCasedColumnName);

      if (!isUnique) {
        errorMessage = message;
      } else {
        return true;
      }

      if (errorMessage) {
        // if (isNotification) {
        //   dispatch(handleNotification(notificationMessage, 'error'));
        // }
        return createError({
          path: path,
          message: errorMessage,
        });
      } else {
        return true;
      }
    });
  });
  //_questionnaire field validator
  const questionnaireFieldValidationSchema = Yup.object({
    columnData: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string().nullable().required('Required!'),
          headerName: Yup.string()
            .nullable()
            .required('Required!')
            .validateUniqueHeadings('Column name already exists!'),
        })
      )
      .nullable(),
  });
  /**
   * Handle Generate Nested Touched value for Error Obj
   * @param {*} mainErrorKeyOrObject
   * @param {*} prevTouchedData
   * @returns
   */
  const handleNestedTouchEvents = useCallback((mainErrorKeyOrObject, prevTouchedData = {}) => {
    let touchedData = { ...prevTouchedData };
    Object.keys(mainErrorKeyOrObject).forEach((singleErrorKey) => {
      if (typeof mainErrorKeyOrObject[singleErrorKey] === 'object') {
        touchedData[singleErrorKey] = {};
        touchedData[singleErrorKey] = handleNestedTouchEvents(
          mainErrorKeyOrObject[singleErrorKey],
          touchedData[singleErrorKey]
        );
      } else if (typeof mainErrorKeyOrObject[singleErrorKey] === 'string') {
        touchedData[singleErrorKey] = !!mainErrorKeyOrObject[singleErrorKey];
      }
    });

    return touchedData;
  }, []);
  /**
   * Handle add new configure table header
   */
  const handleOnAddNewConfigureTableHeader = useCallback(
    (addAfterIndex, formik) => {
      formik
        .validateForm(formik.values)
        .then((errorObj) => {
          let tempErrorObj = { ...(errorObj || {}) };
          let tempTouched = { ...(formik.touched || {}) };
          Object.keys(tempErrorObj).forEach((singleKey) => {
            if (typeof tempErrorObj[singleKey] === 'object') {
              try {
                tempTouched[singleKey] = handleNestedTouchEvents(tempErrorObj[singleKey]);
              } catch (err) {}
            } else {
              tempTouched[singleKey] = !!tempErrorObj[singleKey];
            }
          });
          let isError = typeof tempErrorObj === 'object' && !!Object.keys(tempErrorObj)?.length;
          if (isError) {
            formik.setTouched(tempTouched, true);
          } else {
            const newColumnData = [...(formik?.values?.columnData || [])];
            newColumnData.splice?.(addAfterIndex + 1, 0, {
              headerName: '',
              type: 'string',
            });
            formik.setFieldValue?.('columnData', newColumnData);
          }
        })
        .catch((error) => {})
        .finally((value) => {});
    },
    [handleNestedTouchEvents]
  );

  /**
   * Handle remove column at index
   */
  const handleOnRemoveHeader = useCallback(
    (removeOnIndex, formik) => {
      const newColumnData = [...(formik?.values?.columnData || [])];
      newColumnData.splice?.(removeOnIndex, 1);
      formik.setFieldValue?.('columnData', newColumnData);
    },
    [handleNestedTouchEvents]
  );
  /**
   * On form submit
   * @param {*} values
   * @param {*} submitProps
   */
  const onSubmit = (values, submitProps) => {
    //questionnaireData, actionDataIndex, handleDialogOpened
    const columnData = (values?.columnData || []).map((columnField) => ({
      ...columnField,
      field: formatStringToCamelCase(columnField.headerName),
      headerName: columnField.headerName,
      flex: 1,
    }));
    submitProps?.setSubmitting?.(true);
    let questionnaires = [...(formikContext.values?.questionnaires || [])];
    const prevAnswerRows =
      questionnaires?.[actionDataIndex]?.answer?.[
        tableAnswerModalData?.isPartner ? 'partner' : 'client'
      ]?.multipleTabularAnswers?.[1] || [];
    if (!tableAnswerModalData?.isPartner) {
      questionnaires[actionDataIndex] = {
        ...questionnaires[actionDataIndex],
        answerFieldKeys: ['multipleTabularAnswers'],
        answer: {
          ...(questionnaires?.[actionDataIndex]?.answer || {}),
          client: {
            ...(questionnaires?.[actionDataIndex]?.answer?.client || {}),
            multipleTabularAnswers: [columnData || [], [...prevAnswerRows]],
          },
        },
      };
    } else {
      questionnaires[actionDataIndex] = {
        ...questionnaires[actionDataIndex],
        answerFieldKeys: ['multipleTabularAnswers'],
        answer: {
          ...(questionnaires?.[actionDataIndex]?.answer || {}),
          partner: {
            ...(questionnaires?.[actionDataIndex]?.answer?.client || {}),
            multipleTabularAnswers: [columnData || [], [...prevAnswerRows]],
          },
        },
      };
    }
    formikContext.setFieldValue(`questionnaires`, questionnaires);
    submitProps?.setSubmitting?.(false);
    handleDialogOpened(false);
  };
  /**
   * Effect on open
   */
  useEffect(() => {
    if (isMounted && isOpened) {
      let initialColumnData =
        questionData?.answer?.[tableAnswerModalData?.isPartner ? 'partner' : 'client']
          ?.multipleTabularAnswers?.[0] || [];
      if (initialColumnData?.[0]?.headerName && Array.isArray(initialColumnData)) {
        setQuestionnaireFieldInitialValues((prev) => ({
          ...prev,
          columnData: initialColumnData,
        }));
      }
    }
  }, [isMounted, isOpened]);
  /**
   * Effect on mounted
   */
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <Formik
      validationSchema={questionnaireFieldValidationSchema}
      initialValues={questionnaireFieldInitialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <section>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing} alignItems="center">
                {formik?.values?.columnData?.map((columnDetail, columnIndex) => (
                  <Grid item xs={12} key={`${columnIndex}-table-column-configure-wrapper`}>
                    <ColumnConfigureListItem
                      fieldIndex={columnIndex}
                      columnDetail={columnDetail}
                      handleOnAddNewConfigureTableHeader={(addAfterIndex) =>
                        handleOnAddNewConfigureTableHeader(addAfterIndex, formik)
                      }
                      handleOnRemoveHeader={(removeOnIndex) =>
                        handleOnRemoveHeader(removeOnIndex, formik)
                      }
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing} alignItems="center" justifyContent="center">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      sx={{ marginTop: '1rem' }}
                      onClick={() =>
                        handleOnAddNewConfigureTableHeader(
                          formik.values?.columnData?.length >= 1
                            ? formik.values?.columnData?.length - 1
                            : 0,
                          formik
                        )
                      }
                    >
                      Add new header
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={gridSpacing} justifyContent="flex-end">
                <Button
                  id={'rest-btn'}
                  type="reset"
                  variant={'outlined'}
                  onClick={(event) => formik?.resetForm && formik.resetForm(event)}
                >
                  Reset
                </Button>
                <LoadingButton
                  className={commonClasses?.[isEditMode ? 'updateBtn' : 'saveBtn']}
                  id={'save-btn'}
                  endIcon={isEditMode ? <Edit /> : <SaveOutlined />}
                  loading={!!formik?.isSubmitting}
                  disabled={!!formik?.isSubmitting}
                  loadingPosition="end"
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  {isEditMode ? 'Update' : 'Save'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </section>
      )}
    </Formik>
  );
};

const ConfigureTableAnswerFormatModal = (props) => {
  const {
    isOpened,
    questionData,
    actionDataIndex,
    tableAnswerModalData,
    handleDialogOpened,
    isEditMode,
  } = props;

  return (
    <CommonModal
      open={isOpened}
      dialogTitle="Edit columns"
      dialogContent={
        <QuestionTableConfigureForm
          questionData={questionData}
          actionDataIndex={actionDataIndex}
          handleDialogOpened={handleDialogOpened}
          isEditMode={isEditMode}
          tableAnswerModalData={tableAnswerModalData}
          isOpened={isOpened}
        />
      }
      handleDialogOpened={handleDialogOpened}
      fullWidth
    />
  );
};

export default ConfigureTableAnswerFormatModal;
