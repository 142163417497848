import { Button, Collapse, Fade, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SaveOutlined } from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { isNil, isEmpty } from 'ramda';
import { useLocation } from 'react-router-dom';

import { gridSpacing } from 'store/constant';

import CommonModal from 'common/material/CommonModal';
import FormikSelectNew from 'common/formik/FormikSelectNew';
import FormikTextField from 'common/formik/FormikTextField';
import FormikCheckbox from 'common/formik/FormikCheckbox';

import { useCommonStyles } from 'styles/styles';

import { requestUpdateLeadQuestionnaire } from 'redux/Actions/leadQuestionnairesAction';
import { requestGetLeadQuestionnaire } from 'redux/Actions/leadQuestionnairesAction';
import DynamicResetRequiredOnMultiAnswer from './formikComponents/DynamicResetRequiredOnMultiAnswer';

const QuestionnaireFieldForm = (props) => {
  const {
    questionnaireEditData,
    actionDataIndex,
    handleDialogOpened,
    component,
    isLeadOrClient,
    isTemplateBuilder,
  } = props;
  /**
   * Uses common classes
   */
  const { classes: commonClasses } = useCommonStyles();
  /**
   * Uses react-router-dom location
   */
  const location = useLocation();

  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  //questionnaire store
  const leadQuestionnairesReducer = useSelector((state) => state?.leadQuestionnairesReducer);
  const leadQuestionnairesSuccessData = leadQuestionnairesReducer?.leadQuestionnairesSuccessData;
  // lead store
  const leadsReducer = useSelector((state) => state?.leadsReducer);
  const findLeadRequestSuccessData = leadsReducer?.findLeadRequestSuccessData;

  const clientReducer = useSelector((state) => state?.clientReducer);
  const selectedClientDetail = clientReducer?.selectedClientDetail;
  /**
   * Main Formik Context
   */
  const formikContext = useFormikContext();

  const questionnaireFieldInitialValues = {
    personType: isTemplateBuilder
      ? 'BOTH'
      : (isLeadOrClient === 'LEAD' ? findLeadRequestSuccessData : selectedClientDetail)?.hasPartner
      ? 'BOTH'
      : 'CLIENT',
    component: component,

    headerType: 'section',
    headerTitle: '',

    fieldType: 'boolean',
    question: '',
    questionCaption: '',
    isRequired: false,
  };
  const questionnaireFieldValidationSchema = Yup.object({
    personType: Yup.string()
      .oneOf(['BOTH', 'CLIENT', 'PARTNER'], 'Invalid Value !')
      .default('BOTH')
      .required('Required !')
      .nullable(),

    component: Yup.string()
      .oneOf(['header', 'input'], 'Invalid Value !')
      .default('header')
      .required('Required !')
      .nullable(),

    headerType: Yup.string()
      .when('component', {
        is: (component) => component === 'header',
        then: Yup.string()
          .oneOf(['section'], 'Invalid Value !')
          .default('section')
          .required('Required !')
          .nullable(),
        otherwise: Yup.string().nullable(),
      })
      .nullable(),
    headerTitle: Yup.string()
      .when('component', {
        is: (component) => component === 'header',
        then: Yup.string().required('Required !').nullable(),
        otherwise: Yup.string().nullable(),
      })
      .nullable(),

    fieldType: Yup.string()
      .when('component', {
        is: (component) => component === 'input',
        then: Yup.string()
          .oneOf(
            [
              'boolean',
              'text',
              'booleanAndText',
              'multipleTabular',
              'multipleFormat',
              'signatureAgreement',
            ],
            'Invalid Value !'
          )
          .default('boolean')
          .required('Required !')
          .nullable(),
        otherwise: Yup.string().nullable(),
      })
      .nullable(),
    question: Yup.string()
      .when('component', {
        is: (component) => component === 'input',
        then: Yup.string().required('Required !').nullable(),
        otherwise: Yup.string().nullable(),
      })
      .nullable(),
    questionCaption: Yup.string().nullable(),
    isRequired: Yup.boolean()
      .when('component', {
        is: (component) => component === 'input',
        then: Yup.boolean().required('Required !').nullable(),
        otherwise: Yup.boolean().nullable(),
      })
      .nullable(),
  });
  /**
   * Get Questionnaires
   */
  const handleGetAllQuestionnaireDetails = () => {
    const requestDetails = {
      leadId: location?.state?._id,
    };
    dispatch(requestGetLeadQuestionnaire(requestDetails));
  };

  /**
   * On form submit
   * @param {*} values
   * @param {*} submitProps
   */
  const onSubmit = (values, submitProps) => {
    //questionnaireData, actionDataIndex, handleDialogOpened
    let questionnaireData = [...(formikContext?.values?.questionnaires || [])];
    if (
      !isNil(questionnaireData) &&
      Array.isArray(questionnaireData) &&
      !isNil(values) &&
      !isEmpty(values)
    ) {
      submitProps?.setSubmitting && submitProps.setSubmitting(true);

      let newFieldData = {
        personType: values?.personType,
        component: values?.component,
      };
      if (values?.component === 'header') {
        newFieldData.headerTitle = values?.headerTitle;
        newFieldData.headerType = values?.headerType;
      } else if (values?.component === 'input') {
        newFieldData.fieldType = values?.fieldType;
        newFieldData.question = values?.question;
        newFieldData.questionCaption = values?.questionCaption;
        newFieldData.isRequired = values?.isRequired;

        if (values?.fieldType === 'boolean') {
          newFieldData.answerFieldKeys = ['booleanAnswer'];
          newFieldData.answer = {
            client: {
              booleanAnswer: null,
            },
            partner: {
              booleanAnswer: null,
            },
          };
        } else if (values?.fieldType === 'text') {
          newFieldData.answerFieldKeys = ['stringAnswer'];
          newFieldData.answer = {
            client: {
              stringAnswer: '',
            },
            partner: {
              stringAnswer: '',
            },
          };
        } else if (values?.fieldType === 'booleanAndText') {
          newFieldData.answerFieldKeys = ['booleanAnswer', 'stringAnswer'];
          newFieldData.answer = {
            client: {
              booleanAnswer: null,
              stringAnswer: '',
            },
            partner: {
              booleanAnswer: null,
              stringAnswer: '',
            },
          };
        } else if (values?.fieldType === 'multipleTabular') {
          newFieldData.answerFieldKeys = ['multipleTabularAnswers'];
          newFieldData.answer = {
            client: {
              multipleTabularAnswers: [],
            },
            partner: {
              multipleTabularAnswers: [],
            },
          };
        } else if (values?.fieldType === 'multipleFormat') {
          newFieldData.answerFieldKeys = ['multipleFormatAnswers'];
          newFieldData.answer = {
            client: {
              multipleFormatAnswers: [],
            },
            partner: {
              multipleFormatAnswers: [],
            },
          };
        } else if (values?.fieldType === 'signatureAgreement') {
          newFieldData.answerFieldKeys = ['signatureAgreementAnswers'];
          newFieldData.answer = {
            client: {
              signatureAgreementAnswers: {
                name: '',
                date: '',
                signaturePoints: '',
              },
            },
            partner: {
              signatureAgreementAnswers: {
                name: '',
                date: '',
                signaturePoints: '',
              },
            },
          };
        }
      }
      //   if (actionDataIndex === questionnaireData?.length - 1) {
      //     questionnaireData = [...questionnaireData, newFieldData];
      //   } else {
      if (questionnaireEditData?.mode !== 'ADD_NEW_ON_UPDATE') {
        questionnaireData.splice(actionDataIndex + 1, 0, newFieldData);
        questionnaireData = questionnaireData.filter((questionData) => !!questionData?.component);

        formikContext?.setFieldValue &&
          formikContext.setFieldValue('questionnaires', questionnaireData);
        formikContext?.setFieldTouched && formikContext.setFieldTouched('questionnaires', true);
        handleDialogOpened && handleDialogOpened(false);

        submitProps?.setSubmitting && submitProps.setSubmitting(false);
      } else {
        //update mode

        questionnaireEditData?.answer && (newFieldData.answer = questionnaireEditData?.answer);
        questionnaireEditData?.answerFieldKeys &&
          (newFieldData.answerFieldKeys = questionnaireEditData?.answerFieldKeys);
        // let questionnairesData = leadQuestionnairesSuccessData?.questionnaires;
        if (questionnaireEditData?.mode === 'ADD_NEW_ON_UPDATE') {
          questionnaireData.splice(actionDataIndex + 1, 0, newFieldData);
          let questionnaireNewSaveData = {
            requestBody: questionnaireData,
            questionnaireDataId: leadQuestionnairesSuccessData?._id,
            questionnaireId: questionnaireEditData?._id,
          };
          const handleUpdateRequestSuccessCallback = (response) => {
            submitProps?.setSubmitting && submitProps.setSubmitting(false);
            handleDialogOpened && handleDialogOpened(false);
            handleGetAllQuestionnaireDetails();
          };
          const handleUpdateRequestFailedCallback = (error) => {
            submitProps?.setSubmitting && submitProps.setSubmitting(false);
          };
          dispatch(
            requestUpdateLeadQuestionnaire(
              questionnaireNewSaveData,
              handleUpdateRequestSuccessCallback,
              handleUpdateRequestFailedCallback
            )
          );
        } else {
          questionnaireData.splice(actionDataIndex, 1, newFieldData);
          let newQuestionnairesRequestData = {
            requestBody: questionnaireData, // newFieldData if only updating the question
            questionnaireDataId: leadQuestionnairesSuccessData?._id,
            questionnaireId: questionnaireEditData?._id,
          };

          const handleRequestSuccessCallback = (response) => {
            submitProps?.setSubmitting && submitProps.setSubmitting(false);
            handleDialogOpened && handleDialogOpened(false);
            handleGetAllQuestionnaireDetails();
          };
          const handleRequestFailedCallback = (response) => {
            submitProps?.setSubmitting && submitProps.setSubmitting(false);
          };
          dispatch(
            requestUpdateLeadQuestionnaire(
              newQuestionnairesRequestData,
              handleRequestSuccessCallback,
              handleRequestFailedCallback
            )
          );
        }
      }
    }
  };

  return (
    <Formik
      validationSchema={questionnaireFieldValidationSchema}
      initialValues={questionnaireFieldInitialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <section>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} lg={6}>
              <FormikSelectNew
                label={'Person Type'}
                name={'personType'}
                options={[
                  {
                    value: 'CLIENT',
                    name: isTemplateBuilder
                      ? 'Client'
                      : isLeadOrClient === 'LEAD'
                      ? `Lead${
                          (!!findLeadRequestSuccessData?.firstName &&
                            ` ( ${findLeadRequestSuccessData?.firstName} )`) ||
                          ''
                        }`
                      : `Client${
                          (!!selectedClientDetail?.firstName &&
                            ` ( ${selectedClientDetail?.firstName} )`) ||
                          ''
                        }`,
                  },
                  ...(!isTemplateBuilder &&
                  !!(isLeadOrClient === 'LEAD' ? findLeadRequestSuccessData : selectedClientDetail)
                    ?.hasPartner
                    ? [
                        {
                          value: 'PARTNER',
                          name: `Partner${
                            isLeadOrClient === 'LEAD'
                              ? !!findLeadRequestSuccessData?.partner?.firstName
                                ? ` ( ${findLeadRequestSuccessData?.partner?.firstName} )`
                                : ''
                              : !!selectedClientDetail?.partner?.firstName
                              ? ` ( ${selectedClientDetail?.partner?.firstName} )`
                              : ''
                          }`,
                        },
                        {
                          value: 'BOTH',
                          name: 'Both',
                        },
                      ]
                    : isTemplateBuilder
                    ? [
                        {
                          value: 'PARTNER',
                          name: 'Partner',
                        },
                        {
                          value: 'BOTH',
                          name: 'Both',
                        },
                      ]
                    : []),
                ]}
                value={'value'}
                selectLabel={'name'}
                required
                placeholder={'Please Select !'}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormikSelectNew
                label={'Component Type'}
                name={'component'}
                options={[
                  {
                    value: 'header',
                    name: 'Header',
                  },
                  {
                    value: 'input',
                    name: 'Input Field',
                  },
                ]}
                value={'value'}
                selectLabel={'name'}
                required
                placeholder={'Please Select !'}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              {formik?.values?.component === 'header' ? (
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <FormikTextField name="headerTitle" type="text" label={'Header Title'} />
                  </Grid>
                </Grid>
              ) : (
                formik?.values?.component === 'input' && (
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={6}>
                      <DynamicResetRequiredOnMultiAnswer />
                      <FormikSelectNew
                        label={'Field Type'}
                        name={'fieldType'}
                        options={[
                          {
                            value: 'boolean',
                            name: 'Yes/No',
                          },
                          {
                            value: 'text',
                            name: 'Text',
                          },
                          {
                            value: 'booleanAndText',
                            name: 'Yes/No with Text Field',
                          },
                          {
                            value: 'multipleTabular',
                            name: 'Table',
                          },
                          {
                            value: 'multipleFormat',
                            name: 'Multiple Answers',
                          },
                          {
                            value: 'signatureAgreement',
                            name: 'Agreement/Signature',
                          },
                        ]}
                        value={'value'}
                        selectLabel={'name'}
                        required
                        placeholder={'Please Select !'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="question"
                        type="text"
                        label={
                          formik.values?.fieldType === 'signatureAgreement'
                            ? 'Agreement/Signature Heading'
                            : 'Question'
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="questionCaption"
                        type="text"
                        label={
                          formik.values?.fieldType === 'signatureAgreement'
                            ? 'Agreement/Signature Heading Caption'
                            : 'Question Caption'
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={formik.values?.fieldType !== 'multipleFormat'} timeout={300}>
                        <Fade in={formik.values?.fieldType !== 'multipleFormat'}>
                          <Stack direction="row">
                            <FormikCheckbox
                              name="isRequired"
                              label={'Is Required Answer'}
                              isErrorMessage
                              disabled={
                                formik.values?.fieldType === 'multipleFormat' ||
                                formik?.isSubmitting
                              }
                            />
                          </Stack>
                        </Fade>
                      </Collapse>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={gridSpacing} justifyContent="flex-end">
                <Button
                  id={'rest-btn'}
                  type="reset"
                  variant={'outlined'}
                  onClick={(event) => formik?.resetForm && formik.resetForm(event)}
                >
                  Reset
                </Button>
                <LoadingButton
                  className={commonClasses?.['saveBtn']}
                  id={'save-btn'}
                  endIcon={<SaveOutlined />}
                  loading={!!formik?.isSubmitting}
                  disabled={!!formik?.isSubmitting}
                  loadingPosition="end"
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  {'Save'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </section>
      )}
    </Formik>
  );
};

const CreateQuestionnaireOrSectionModal = (props) => {
  const {
    isOpened,
    questionnaireData,
    actionDataIndex = questionnaireData?.length - 1 || 0,
    handleDialogOpened,
    isEditMode,
    component = 'input',
    isLeadOrClient = 'LEAD',
    isTemplateBuilder,
  } = props;
  // component -> [input, header]

  return (
    <CommonModal
      open={isOpened}
      dialogTitle={
        component === 'input'
          ? 'Please enter question details'
          : 'Please enter the header section details'
      }
      dialogContent={
        <QuestionnaireFieldForm
          questionnaireEditData={questionnaireData}
          actionDataIndex={actionDataIndex}
          handleDialogOpened={handleDialogOpened}
          isEditMode={isEditMode}
          component={component}
          isLeadOrClient={isLeadOrClient}
          isTemplateBuilder={isTemplateBuilder}
        />
      }
      handleDialogOpened={handleDialogOpened}
      fullWidth
    />
  );
};

export default CreateQuestionnaireOrSectionModal;
