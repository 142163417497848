import { createReducer } from '@reduxjs/toolkit';

const superannuationSettingsInitialState = {
  //Create New Investor Profile
  isSaveSuperannuationSettingLoading: false,
  saveSuperannuationSettingSuccessData: {},
  saveSuperannuationSettingFailedData: {},

  //get investor profiles
  isGetSuperannuationSettingsLoading: false,
  superannuationSettingsSuccessData: {},
  superannuationSettingsFailedData: {},

  //update investor profile
  isUpdateSuperannuationSettingLoading: false,
  updateSuperannuationSettingSuccessData: {},
  updateSuperannuationSettingFailedData: {},
};

//investor profile reducer
export const superannuationSettingsReducer = createReducer(superannuationSettingsInitialState, {
  // save investor profile
  postSaveSuperannuationSettingRequest: (state) => {
    state.isSaveSuperannuationSettingLoading = true;
    state.saveSuperannuationSettingSuccessData = {};
    state.saveSuperannuationSettingFailedData = {};
  },
  postSaveSuperannuationSettingRequestSuccess: (state, action) => {
    state.isSaveSuperannuationSettingLoading = false;
    state.saveSuperannuationSettingSuccessData = action?.payload;
    state.saveSuperannuationSettingFailedData = {};
  },
  postSaveSuperannuationSettingRequestFailed: (state, action) => {
    state.isSaveSuperannuationSettingLoading = false;
    state.saveSuperannuationSettingSuccessData = {};
    state.saveSuperannuationSettingFailedData = action?.payload;
  },

  //get investor profiles
  getSuperannuationSettingRequest: (state) => {
    state.isGetSuperannuationSettingsLoading = true;
    state.superannuationSettingsSuccessData = {};
    state.superannuationSettingsFailedData = {};
  },
  getSuperannuationSettingRequestSuccess: (state, action) => {
    state.isGetSuperannuationSettingsLoading = false;
    state.superannuationSettingsSuccessData = action.payload;
    state.superannuationSettingsFailedData = {};
  },
  getSuperannuationSettingRequestFailed: (state, action) => {
    state.isGetSuperannuationSettingsLoading = false;
    state.superannuationSettingsSuccessData = {};
    state.superannuationSettingsFailedData = action.payload;
  },

  //update investor profile
  updateSuperannuationSettingRequest: (state) => {
    state.isUpdateSuperannuationSettingLoading = true;
    state.updateSuperannuationSettingSuccessData = {};
    state.updateSuperannuationSettingFailedData = {};
  },
  updateSuperannuationSettingRequestSuccess: (state, action) => {
    state.isUpdateSuperannuationSettingLoading = false;
    state.updateSuperannuationSettingSuccessData = action.payload;
    state.updateSuperannuationSettingFailedData = {};
  },
  updateSuperannuationSettingRequestFailed: (state, action) => {
    state.isUpdateSuperannuationSettingLoading = false;
    state.updateSuperannuationSettingSuccessData = {};
    state.updateSuperannuationSettingFailedData = action.payload;
  },
});
