import { createReducer } from '@reduxjs/toolkit';

const clientAppInitialState = {
  //Create New Client App Setting
  saveClientAppSettingLoading: false,
  saveClientAppSettingSuccessData: {},
  saveClientAppSettingFailedData: {},

  //get Client app Setting
  clientAppSettingRequestLoading: false,
  clientAppSettingRequestSuccessData: {},
  clientAppSettingRequestFailedData: {},

  //update Client App Setting
  updateClientAppSettingLoading: false,
  updateClientAppSettingSuccessData: {},
  updateClientAppSettingFailedData: {},

  //delete Client App Setting
  deleteClientAppSettingLoading: false,
  deleteClientAppSettingSuccessData: {},
  deleteClientAppSettingFailedData: {},
};

//Client App Setting reducer
export const clientAppSettingsReducer = createReducer(clientAppInitialState, {
  // save Client App Setting
  postSaveClientAppSettingRequest: (state) => {
    state.saveClientAppSettingLoading = true;
    state.saveClientAppSettingSuccessData = {};
    state.saveClientAppSettingFailedData = {};
  },
  postSaveClientAppSettingRequestSuccess: (state, action) => {
    state.saveClientAppSettingLoading = false;
    state.saveClientAppSettingSuccessData = action?.payload;
    state.saveClientAppSettingFailedData = {};
  },
  postSaveClientAppSettingRequestFailed: (state, action) => {
    state.saveClientAppSettingLoading = false;
    state.saveClientAppSettingSuccessData = {};
    state.saveClientAppSettingFailedData = action?.payload;
  },

  //get Client app Setting
  getClientAppSettingRequest: (state) => {
    state.clientAppSettingRequestLoading = true;
    state.clientAppSettingRequestSuccessData = {};
    state.clientAppSettingRequestFailedData = {};
  },
  getClientAppSettingRequestSuccess: (state, action) => {
    state.clientAppSettingRequestLoading = false;
    state.clientAppSettingRequestSuccessData = action.payload;
    state.clientAppSettingRequestFailedData = {};
  },
  getClientAppSettingRequestFailed: (state, action) => {
    state.clientAppSettingRequestLoading = false;
    state.clientAppSettingRequestSuccessData = {};
    state.clientAppSettingRequestFailedData = action.payload;
  },

  //update Client App Setting
  updateClientAppSettingRequest: (state) => {
    state.updateClientAppSettingLoading = true;
    state.updateClientAppSettingSuccessData = {};
    state.updateClientAppSettingFailedData = {};
  },
  updateClientAppSettingRequestSuccess: (state, action) => {
    state.updateClientAppSettingLoading = false;
    state.updateClientAppSettingSuccessData = action.payload;
    state.updateClientAppSettingFailedData = {};
  },
  updateClientAppSettingRequestFailed: (state, action) => {
    state.updateClientAppSettingLoading = false;
    state.updateClientAppSettingSuccessData = {};
    state.updateClientAppSettingFailedData = action.payload;
  },

  //delete Client App Setting
  deleteClientAppSettingRequest: (state) => {
    state.deleteClientAppSettingLoading = true;
    state.deleteClientAppSettingSuccessData = {};
    state.deleteClientAppSettingFailedData = {};
  },
  deleteClientAppSettingRequestSuccess: (state, action) => {
    state.deleteClientAppSettingLoading = false;
    state.deleteClientAppSettingSuccessData = action.payload;
    state.deleteClientAppSettingFailedData = {};
  },
  deleteClientAppSettingRequestFailed: (state, action) => {
    state.deleteClientAppSettingLoading = false;
    state.deleteClientAppSettingSuccessData = {};
    state.deleteClientAppSettingFailedData = action.payload;
  },
});
