import { createReducer } from '@reduxjs/toolkit';

const emailNotificationSettingsInitialState = {
  //Create New Email Notification Setting
  isSaveEmailNotificationSettingLoading: false,
  saveEmailNotificationSettingSuccessData: {},
  saveEmailNotificationSettingFailedData: {},

  //get Email Notification Settings
  isGetEmailNotificationSettingsLoading: false,
  emailNotificationSettingsSuccessData: {},
  emailNotificationSettingsFailedData: {},

  //update email notification setting
  isUpdateEmailNotificationSettingLoading: false,
  updateEmailNotificationSettingSuccessData: {},
  updateEmailNotificationSettingFailedData: {},
};

//email notification setting reducer
export const emailNotificationSettingsReducer = createReducer(
  emailNotificationSettingsInitialState,
  {
    // save email notification setting
    postSaveEmailNotificationSettingRequest: (state) => {
      state.isSaveEmailNotificationSettingLoading = true;
      state.saveEmailNotificationSettingSuccessData = {};
      state.saveEmailNotificationSettingFailedData = {};
    },
    postSaveEmailNotificationSettingRequestSuccess: (state, action) => {
      state.isSaveEmailNotificationSettingLoading = false;
      state.saveEmailNotificationSettingSuccessData = action?.payload;
      state.saveEmailNotificationSettingFailedData = {};
    },
    postSaveEmailNotificationSettingRequestFailed: (state, action) => {
      state.isSaveEmailNotificationSettingLoading = false;
      state.saveEmailNotificationSettingSuccessData = {};
      state.saveEmailNotificationSettingFailedData = action?.payload;
    },

    //get email notification settings
    getEmailNotificationSettingRequest: (state) => {
      state.isGetEmailNotificationSettingsLoading = true;
      state.emailNotificationSettingsSuccessData = {};
      state.emailNotificationSettingsFailedData = {};
    },
    getEmailNotificationSettingRequestSuccess: (state, action) => {
      state.isGetEmailNotificationSettingsLoading = false;
      state.emailNotificationSettingsSuccessData = action.payload;
      state.emailNotificationSettingsFailedData = {};
    },
    getEmailNotificationSettingRequestFailed: (state, action) => {
      state.isGetEmailNotificationSettingsLoading = false;
      state.emailNotificationSettingsSuccessData = {};
      state.emailNotificationSettingsFailedData = action.payload;
    },

    //update email notification setting
    updateEmailNotificationSettingRequest: (state) => {
      state.isUpdateEmailNotificationSettingLoading = true;
      state.updateEmailNotificationSettingSuccessData = {};
      state.updateEmailNotificationSettingFailedData = {};
    },
    updateEmailNotificationSettingRequestSuccess: (state, action) => {
      state.isUpdateEmailNotificationSettingLoading = false;
      state.updateEmailNotificationSettingSuccessData = action.payload;
      state.updateEmailNotificationSettingFailedData = {};
    },
    updateEmailNotificationSettingRequestFailed: (state, action) => {
      state.isUpdateEmailNotificationSettingLoading = false;
      state.updateEmailNotificationSettingSuccessData = {};
      state.updateEmailNotificationSettingFailedData = action.payload;
    },
  }
);
