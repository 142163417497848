import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New Client [Trust]
  saveTrustClientRequestLoading: false,
  saveTrustClientData: {},
  saveTrustClientFailed: {},

  //Delete Trust
  deleteTrustClientRequestLoading: false,
  deleteTrustClientData: {},
  deleteTrustClientFailed: {},

  //Request Client List
  isTrustClientListRequestLoading: false,
  trustClientListData: {},
  trustClientListFailed: {},

  //selected client detail
  isTrustClientDetailRequestLoading: false,
  selectedTrustClientDetail: {},
  selectedTrustClientDetailFailed: {},

  //update Trust Client detail
  isUpdateTrustClientRequestLoading: false,
  updateTrustClientData: {},
  updateTrustClientFailed: {},

  // get trust detail for trust dashboard
  isTrustClientDashboardStatDetailRequestLoading: false,
  selectedTrustClientDashboardStatDetail: {},
  selectedTrustClientDashboardStatDetailFailed: {},

  //save trust beneficiary
  isSaveTrustBeneficiaryRequestLoading: false,
  saveTrustBeneficiaryRequestSuccessData: null,
  saveTrustBeneficiaryRequestFailedData: null,

  //update trust beneficiary
  isUpdateTrustBeneficiaryRequestLoading: false,
  updateTrustBeneficiaryRequestSuccessData: null,
  updateTrustBeneficiaryRequestFailedData: null,

  //delete beneficiary trustee
  isDeleteTrustBeneficiaryLoading: false,
  deleteTrustBeneficiarySuccessData: null,
  deleteTrustBeneficiaryFailedData: null,

  //save individual trustee
  isSaveIndividualTrusteeRequestLoading: false,
  saveIndividualTrusteeRequestSuccessData: null,
  saveIndividualTrusteeRequestFailedData: null,

  //update individual trustee
  isUpdateIndividualTrusteeRequestLoading: false,
  updateIndividualTrusteeRequestSuccessData: null,
  updateIndividualTrusteeRequestFailedData: null,

  //update individual trustee
  isDeleteIndividualTrusteeRequestLoading: false,
  deleteIndividualTrusteeRequestSuccessData: null,
  deleteIndividualTrusteeRequestFailedData: null,

  //save corporate trustee director
  isSaveCorporateTrusteeDirectorRequestLoading: false,
  saveCorporateTrusteeDirectorRequestSuccessData: null,
  saveCorporateTrusteeDirectorRequestFailedData: null,

  //update corporate trustee director
  isUpdateCorporateTrusteeDirectorRequestLoading: false,
  updateCorporateTrusteeDirectorRequestSuccessData: null,
  updateCorporateTrusteeDirectorRequestFailedData: null,

  //delete corporate trustee director
  isDeleteCorporateTrusteeDirectorRequestLoading: false,
  deleteCorporateTrusteeDirectorRequestSuccessData: null,
  deleteCorporateTrusteeDirectorRequestFailedData: null,

  //save multiple trust
  saveMultipleTrustRequestLoading: false,
  saveMultipleTrustClientData: {},
  saveMultipleTrustClientFailed: {},

  //save corporate trustee shareholder
  isSaveCorporateTrusteeShareholderRequestLoading: false,
  saveCorporateTrusteeShareholderRequestSuccessData: null,
  saveCorporateTrusteeShareholderRequestFailedData: null,

  //update corporate trustee shareholder
  isUpdateCorporateTrusteeShareholderRequestLoading: false,
  updateCorporateTrusteeShareholderRequestSuccessData: null,
  updateCorporateTrusteeShareholderRequestFailedData: null,

  //delete corporate trustee shareholder
  isDeleteCorporateTrusteeShareholderRequestLoading: false,
  deleteCorporateTrusteeShareholderRequestSuccessData: null,
  deleteCorporateTrusteeShareholderRequestFailedData: null,

  //filter Trust
  filterDataTrustLoading: false,
  filterDataTrustSuccess: [],
  filterDataTrustFailed: {},

  //add Trust Entities
  addLinkedEntitiesTrustLoading: false,
  addLinkedEntitiesTrustSuccess: null,
  addLinkedEntitiesTrustFailed: {},

  //delete Trust Entities
  deleteLinkedEntitiesTrustLoading: false,
  deleteLinkedEntitiesTrustSuccess: null,
  deleteLinkedEntitiesTrustFailed: {},
};

export const trustClientReducer = createReducer(initialState, {
  //save client
  postSaveTrustClientRequest: (state) => {
    state.saveTrustClientRequestLoading = true;
    state.saveTrustClientData = {};
    state.saveTrustClientFailed = {};
  },
  postSaveTrustClientSuccess: (state, action) => {
    state.saveTrustClientRequestLoading = false;
    state.saveTrustClientData = action.payload;
    state.saveTrustClientFailed = {};
  },
  postSaveTrustClientFailed: (state, action) => {
    state.saveTrustClientRequestLoading = false;
    state.saveTrustClientData = {};
    state.saveTrustClientFailed = action.payload;
  },

  //delete trust
  deleteTrustClientRequest: (state) => {
    state.deleteTrustClientRequestLoading = true;
    state.deleteTrustClientData = {};
    state.deleteTrustClientFailed = {};
  },
  deleteTrustClientSuccess: (state, action) => {
    state.deleteTrustClientRequestLoading = false;
    state.deleteTrustClientData = action.payload;
    state.deleteTrustClientFailed = {};
  },
  deleteTrustClientFailed: (state, action) => {
    state.deleteTrustClientRequestLoading = false;
    state.deleteTrustClientData = {};
    state.deleteTrustClientFailed = action.payload;
  },

  //client List
  getAllTrustClientsRequest: (state) => {
    state.isTrustClientListRequestLoading = true;
    state.trustClientListData = {};
    state.trustClientListFailed = {};
  },
  getAllTrustClientsSuccess: (state, action) => {
    state.isTrustClientListRequestLoading = false;
    state.trustClientListData = action.payload;
    state.trustClientListFailed = {};
  },
  getAllTrustClientsFailed: (state, action) => {
    state.isTrustClientListRequestLoading = false;
    state.trustClientListData = {};
    state.trustClientListFailed = action.payload;
  },

  //find client detail
  getTrustClientByIdRequest: (state) => {
    state.isTrustClientDetailRequestLoading = true;
    state.selectedTrustClientDetail = {};
    state.selectedTrustClientDetailFailed = {};
  },
  getTrustClientByIdRequestSuccess: (state, action) => {
    state.isTrustClientDetailRequestLoading = false;
    state.selectedTrustClientDetail = action.payload;
    state.selectedTrustClientDetailFailed = {};
  },
  getTrustClientByIdRequestFailed: (state, action) => {
    state.isTrustClientDetailRequestLoading = false;
    state.selectedTrustClientDetail = {};
    state.selectedTrustClientDetailFailed = action.payload;
  },

  //update client
  patchUpdateTrustClientRequest: (state) => {
    state.isUpdateTrustClientRequestLoading = true;
    state.updateTrustClientData = {};
    state.updateTrustClientFailed = {};
  },
  patchUpdateTrustClientRequestSuccess: (state, action) => {
    state.isUpdateTrustClientRequestLoading = false;
    state.updateTrustClientData = action.payload;
    state.updateTrustClientFailed = {};
  },
  patchUpdateTrustClientRequestFailed: (state, action) => {
    state.isUpdateTrustClientRequestLoading = false;
    state.updateTrustClientData = {};
    state.updateTrustClientFailed = action.payload;
  },

  //find client detail
  getTrustClientDashboardStatByIdRequest: (state) => {
    state.isTrustClientDashboardStatDetailRequestLoading = true;
    state.selectedTrustClientDashboardStatDetail = {};
    state.selectedTrustClientDashboardStatDetailFailed = {};
  },
  getTrustClientDashboardStatByIdRequestSuccess: (state, action) => {
    state.isTrustClientDashboardStatDetailRequestLoading = false;
    state.selectedTrustClientDashboardStatDetail = action.payload;
    state.selectedTrustClientDashboardStatDetailFailed = {};
  },
  getTrustClientDashboardStatByIdRequestFailed: (state, action) => {
    state.isTrustClientDashboardStatDetailRequestLoading = false;
    state.selectedTrustClientDashboardStatDetail = {};
    state.selectedTrustClientDashboardStatDetailFailed = action.payload;
  },

  postSaveTrustBeneficiary: (state) => {
    state.isSaveTrustBeneficiaryRequestLoading = true;
    state.saveTrustBeneficiaryRequestSuccessData = null;
    state.saveTrustBeneficiaryRequestFailedData = null;
  },
  postSaveTrustBeneficiarySuccess: (state, action) => {
    state.isSaveTrustBeneficiaryRequestLoading = false;
    state.saveTrustBeneficiaryRequestSuccessData = action.payload;
    state.saveTrustBeneficiaryRequestFailedData = null;
  },
  postSaveTrustBeneficiaryFailed: (state, action) => {
    state.isSaveTrustBeneficiaryRequestLoading = false;
    state.saveTrustBeneficiaryRequestSuccessData = null;
    state.saveTrustBeneficiaryRequestFailedData = action.payload;
  },

  patchUpdateTrustBeneficiary: (state) => {
    state.isUpdateTrustBeneficiaryRequestLoading = true;
    state.updateTrustBeneficiaryRequestSuccessData = null;
    state.updateTrustBeneficiaryRequestFailedData = null;
  },
  patchUpdateTrustBeneficiarySuccess: (state, action) => {
    state.isUpdateTrustBeneficiaryRequestLoading = false;
    state.updateTrustBeneficiaryRequestSuccessData = action.payload;
    state.updateTrustBeneficiaryRequestFailedData = null;
  },
  patchUpdateTrustBeneficiaryFailed: (state, action) => {
    state.isUpdateTrustBeneficiaryRequestLoading = false;
    state.updateTrustBeneficiaryRequestSuccessData = null;
    state.updateTrustBeneficiaryRequestFailedData = action.payload;
  },

  deleteTrustBeneficiary: (state) => {
    state.isDeleteTrustBeneficiaryLoading = true;
    state.deleteTrustBeneficiarySuccessData = null;
    state.deleteTrustBeneficiaryFailedData = null;
  },
  deleteTrustBeneficiarySuccess: (state, action) => {
    state.isDeleteTrustBeneficiaryLoading = false;
    state.deleteTrustBeneficiarySuccessData = action?.payload;
    state.deleteTrustBeneficiaryFailedData = null;
  },
  deleteTrustBeneficiaryFailed: (state, action) => {
    state.isDeleteTrustBeneficiaryLoading = false;
    state.deleteTrustBeneficiarySuccessData = null;
    state.deleteTrustBeneficiaryFailedData = action?.payload;
  },

  postSaveIndividualTrusteeRequest: (state) => {
    state.isSaveIndividualTrusteeRequestLoading = true;
    state.saveIndividualTrusteeRequestSuccessData = null;
    state.saveIndividualTrusteeRequestFailedData = null;
  },
  postSaveIndividualTrusteeRequestSuccess: (state, action) => {
    state.isSaveIndividualTrusteeRequestLoading = false;
    state.saveIndividualTrusteeRequestSuccessData = action.payload;
    state.saveIndividualTrusteeRequestFailedData = null;
  },
  postSaveIndividualTrusteeRequestFailed: (state, action) => {
    state.isSaveIndividualTrusteeRequestLoading = false;
    state.saveIndividualTrusteeRequestSuccessData = null;
    state.saveIndividualTrusteeRequestFailedData = action.payload;
  },

  patchUpdateIndividualTrusteeRequest: (state) => {
    state.isUpdateIndividualTrusteeRequestLoading = true;
    state.updateIndividualTrusteeRequestSuccessData = null;
    state.updateIndividualTrusteeRequestFailedData = null;
  },
  patchUpdateIndividualTrusteeRequestSuccess: (state, action) => {
    state.isUpdateIndividualTrusteeRequestLoading = false;
    state.updateIndividualTrusteeRequestSuccessData = action.payload;
    state.updateIndividualTrusteeRequestFailedData = null;
  },
  patchUpdateIndividualTrusteeRequestFailed: (state, action) => {
    state.isUpdateIndividualTrusteeRequestLoading = false;
    state.updateIndividualTrusteeRequestSuccessData = null;
    state.updateIndividualTrusteeRequestFailedData = action.payload;
  },

  deleteIndividualTrusteeRequest: (state) => {
    state.isDeleteIndividualTrusteeRequestLoading = true;
    state.deleteIndividualTrusteeRequestSuccessData = null;
    state.deleteIndividualTrusteeRequestFailedData = null;
  },
  deleteIndividualTrusteeRequestSuccess: (state, action) => {
    state.isDeleteIndividualTrusteeRequestLoading = false;
    state.deleteIndividualTrusteeRequestSuccessData = action.payload;
    state.deleteIndividualTrusteeRequestFailedData = null;
  },
  deleteIndividualTrusteeRequestFailed: (state, action) => {
    state.isDeleteIndividualTrusteeRequestLoading = false;
    state.deleteIndividualTrusteeRequestSuccessData = null;
    state.deleteIndividualTrusteeRequestFailedData = action.payload;
  },

  //save corporate trustee director
  postSaveCorporateTrusteeDirectorRequest: (state) => {
    state.isSaveCorporateTrusteeDirectorRequestLoading = true;
    state.saveCorporateTrusteeDirectorRequestSuccessData = null;
    state.saveCorporateTrusteeDirectorRequestFailedData = null;
  },
  postSaveCorporateTrusteeDirectorRequestSuccess: (state, action) => {
    state.isSaveCorporateTrusteeDirectorRequestLoading = false;
    state.saveCorporateTrusteeDirectorRequestSuccessData = action?.payload;
    state.saveCorporateTrusteeDirectorRequestFailedData = null;
  },
  postSaveCorporateTrusteeDirectorRequestFailed: (state, action) => {
    state.isSaveCorporateTrusteeDirectorRequestLoading = false;
    state.saveCorporateTrusteeDirectorRequestSuccessData = null;
    state.saveCorporateTrusteeDirectorRequestFailedData = action?.payload;
  },

  //update corporate trustee director
  patchUpdateCorporateTrusteeDirectorRequest: (state) => {
    state.isUpdateCorporateTrusteeDirectorRequestLoading = true;
    state.updateCorporateTrusteeDirectorRequestSuccessData = null;
    state.updateCorporateTrusteeDirectorRequestFailedData = null;
  },
  patchUpdateCorporateTrusteeDirectorRequestSuccess: (state, action) => {
    state.isUpdateCorporateTrusteeDirectorRequestLoading = false;
    state.updateCorporateTrusteeDirectorRequestSuccessData = action?.payload;
    state.updateCorporateTrusteeDirectorRequestFailedData = null;
  },
  patchUpdateCorporateTrusteeDirectorRequestFailed: (state, action) => {
    state.isUpdateCorporateTrusteeDirectorRequestLoading = false;
    state.updateCorporateTrusteeDirectorRequestSuccessData = null;
    state.updateCorporateTrusteeDirectorRequestFailedData = action?.payload;
  },

  //delete corporate trustee director
  deleteCorporateTrusteeDirectorRequest: (state) => {
    state.isDeleteCorporateTrusteeDirectorRequestLoading = true;
    state.deleteCorporateTrusteeDirectorRequestSuccessData = null;
    state.deleteCorporateTrusteeDirectorRequestFailedData = null;
  },
  deleteCorporateTrusteeDirectorRequestSuccess: (state, action) => {
    state.isDeleteCorporateTrusteeDirectorRequestLoading = false;
    state.deleteCorporateTrusteeDirectorRequestSuccessData = action?.payload;
    state.deleteCorporateTrusteeDirectorRequestFailedData = null;
  },
  deleteCorporateTrusteeDirectorRequestFailed: (state, action) => {
    state.isDeleteCorporateTrusteeDirectorRequestLoading = false;
    state.deleteCorporateTrusteeDirectorRequestSuccessData = null;
    state.deleteCorporateTrusteeDirectorRequestFailedData = action?.payload;
  },

  //save Multiple trust
  postSaveMultipleTrustRequest: (state) => {
    state.saveMultipleTrustRequestLoading = true;
    state.saveMultipleTrustClientData = {};
    state.saveMultipleTrustClientFailed = {};
  },
  postSaveMultipleTrustSuccess: (state, action) => {
    state.saveMultipleTrustRequestLoading = false;
    state.saveMultipleTrustClientData = action.payload;
    state.saveMultipleTrustClientFailed = {};
  },
  postSaveMultipleTrustFailed: (state, action) => {
    state.saveMultipleTrustRequestLoading = false;
    state.saveMultipleTrustClientData = {};
    state.saveMultipleTrustClientFailed = action.payload;
  },

  //save corporate trustee Shareholder
  postSaveCorporateTrusteeShareholderRequest: (state) => {
    state.isSaveCorporateTrusteeShareholderRequestLoading = true;
    state.saveCorporateTrusteeShareholderRequestSuccessData = null;
    state.saveCorporateTrusteeShareholderRequestFailedData = null;
  },
  postSaveCorporateTrusteeShareholderRequestSuccess: (state, action) => {
    state.isSaveCorporateTrusteeShareholderRequestLoading = false;
    state.saveCorporateTrusteeShareholderRequestSuccessData = action?.payload;
    state.saveCorporateTrusteeShareholderRequestFailedData = null;
  },
  postSaveCorporateTrusteeShareholderRequestFailed: (state, action) => {
    state.isSaveCorporateTrusteeShareholderRequestLoading = false;
    state.saveCorporateTrusteeShareholderRequestSuccessData = null;
    state.saveCorporateTrusteeShareholderRequestFailedData = action?.payload;
  },

  //update corporate trustee Shareholder
  patchUpdateCorporateTrusteeShareholderRequest: (state) => {
    state.isUpdateCorporateTrusteeShareholderRequestLoading = true;
    state.updateCorporateTrusteeShareholderRequestSuccessData = null;
    state.updateCorporateTrusteeShareholderRequestFailedData = null;
  },
  patchUpdateCorporateTrusteeShareholderRequestSuccess: (state, action) => {
    state.isUpdateCorporateTrusteeShareholderRequestLoading = false;
    state.updateCorporateTrusteeShareholderRequestSuccessData = action?.payload;
    state.updateCorporateTrusteeShareholderRequestFailedData = null;
  },
  patchUpdateCorporateTrusteeShareholderRequestFailed: (state, action) => {
    state.isUpdateCorporateTrusteeShareholderRequestLoading = false;
    state.updateCorporateTrusteeShareholderRequestSuccessData = null;
    state.updateCorporateTrusteeShareholderRequestFailedData = action?.payload;
  },

  //delete corporate trustee Shareholder
  deleteCorporateTrusteeShareholderRequest: (state) => {
    state.isDeleteCorporateTrusteeShareholderRequestLoading = true;
    state.deleteCorporateTrusteeShareholderRequestSuccessData = null;
    state.deleteCorporateTrusteeShareholderRequestFailedData = null;
  },
  deleteCorporateTrusteeShareholderRequestSuccess: (state, action) => {
    state.isDeleteCorporateTrusteeShareholderRequestLoading = false;
    state.deleteCorporateTrusteeShareholderRequestSuccessData = action?.payload;
    state.deleteCorporateTrusteeShareholderRequestFailedData = null;
  },
  deleteCorporateTrusteeShareholderRequestFailed: (state, action) => {
    state.isDeleteCorporateTrusteeShareholderRequestLoading = false;
    state.deleteCorporateTrusteeShareholderRequestSuccessData = null;
    state.deleteCorporateTrusteeShareholderRequestFailedData = action?.payload;
  },

  //filter  Trust
  postFilterTrustRequest: (state) => {
    state.filterDataTrustLoading = true;
    state.filterDataTrustSuccess = [];
    state.filterDataTrustFailed = {};
  },
  postFilterTrustSuccess: (state, action) => {
    state.filterDataTrustLoading = false;
    state.filterDataTrustSuccess = action.payload;
    state.filterDataTrustFailed = {};
  },
  postFilterTrustFailed: (state, action) => {
    state.filterDataTrustLoading = false;
    state.filterDataTrustSuccess = [];
    state.filterDataTrustFailed = action.payload;
  },

  //add linked entities Trust
  addLinkedEntitiesTrustRequest: (state) => {
    state.addLinkedEntitiesTrustLoading = true;
    state.addLinkedEntitiesTrustSuccess = [];
    state.addLinkedEntitiesTrustFailed = {};
  },
  addLinkedEntitiesTrustSuccess: (state, action) => {
    state.addLinkedEntitiesTrustLoading = false;
    state.addLinkedEntitiesTrustSuccess = action.payload;
    state.addLinkedEntitiesTrustFailed = {};
  },
  addLinkedEntitiesTrustFailed: (state, action) => {
    state.addLinkedEntitiesTrustLoading = false;
    state.addLinkedEntitiesTrustSuccess = [];
    state.addLinkedEntitiesTrustFailed = action.payload;
  },

  //delete linked entities Trust
  deleteLinkedEntitiesTrustRequest: (state) => {
    state.deleteLinkedEntitiesTrustLoading = true;
    state.deleteLinkedEntitiesTrustSuccess = [];
    state.deleteLinkedEntitiesTrustFailed = {};
  },
  deleteLinkedEntitiesTrustSuccess: (state, action) => {
    state.deleteLinkedEntitiesTrustLoading = false;
    state.deleteLinkedEntitiesTrustSuccess = action.payload;
    state.deleteLinkedEntitiesTrustFailed = {};
  },
  deleteLinkedEntitiesTrustFailed: (state, action) => {
    state.deleteLinkedEntitiesTrustLoading = false;
    state.addLinkedEntitiesTrustSuccess = [];
    state.deleteLinkedEntitiesTrustFailed = action.payload;
  },
});
