import { createReducer } from '@reduxjs/toolkit';

const insuranceModelingSettingsInitialState = {
  //Create New Insurance Modeling Setting
  isSaveInsuranceModelingSettingLoading: false,
  saveInsuranceModelingSettingSuccessData: {},
  saveInsuranceModelingSettingFailedData: {},

  //get Insurance Modeling Settings
  isGetInsuranceModelingSettingsLoading: false,
  insuranceModelingSettingsSuccessData: {},
  insuranceModelingSettingsFailedData: {},

  //update Insurance Modeling Setting
  isUpdateInsuranceModelingSettingLoading: false,
  updateInsuranceModelingSettingSuccessData: {},
  updateInsuranceModelingSettingFailedData: {},

  //upload company logo
  isUploadCompanyLogoRequestLoading: false,
  uploadCompanyLogoRequestSuccessData: {},
  uploadCompanyLogoRequestFailedData: {},
};
/**
 * Insurance Modeling Setting Reducer
 */
export const insuranceModellingSettingsReducer = createReducer(
  insuranceModelingSettingsInitialState,
  {
    // save Insurance Modeling Setting
    postSaveInsuranceModelingSettingRequest: (state) => {
      state.isSaveInsuranceModelingSettingLoading = true;
      state.saveInsuranceModelingSettingSuccessData = {};
      state.saveInsuranceModelingSettingFailedData = {};
    },
    postSaveInsuranceModelingSettingRequestSuccess: (state, action) => {
      state.isSaveInsuranceModelingSettingLoading = false;
      state.saveInsuranceModelingSettingSuccessData = action?.payload;
      state.saveInsuranceModelingSettingFailedData = {};
    },
    postSaveInsuranceModelingSettingRequestFailed: (state, action) => {
      state.isSaveInsuranceModelingSettingLoading = false;
      state.saveInsuranceModelingSettingSuccessData = {};
      state.saveInsuranceModelingSettingFailedData = action?.payload;
    },

    //get Insurance Modeling Settings
    getInsuranceModelingSettingRequest: (state) => {
      state.isGetInsuranceModelingSettingsLoading = true;
      state.insuranceModelingSettingsSuccessData = {};
      state.insuranceModelingSettingsFailedData = {};
    },
    getInsuranceModelingSettingRequestSuccess: (state, action) => {
      state.isGetInsuranceModelingSettingsLoading = false;
      state.insuranceModelingSettingsSuccessData = action.payload;
      state.insuranceModelingSettingsFailedData = {};
    },
    getInsuranceModelingSettingRequestFailed: (state, action) => {
      state.isGetInsuranceModelingSettingsLoading = false;
      state.insuranceModelingSettingsSuccessData = {};
      state.insuranceModelingSettingsFailedData = action.payload;
    },

    //update Insurance Modeling Setting
    updateInsuranceModelingSettingRequest: (state) => {
      state.isUpdateInsuranceModelingSettingLoading = true;
      state.updateInsuranceModelingSettingSuccessData = {};
      state.updateInsuranceModelingSettingFailedData = {};
    },
    updateInsuranceModelingSettingRequestSuccess: (state, action) => {
      state.isUpdateInsuranceModelingSettingLoading = false;
      state.updateInsuranceModelingSettingSuccessData = action.payload;
      state.updateInsuranceModelingSettingFailedData = {};
    },
    updateInsuranceModelingSettingRequestFailed: (state, action) => {
      state.isUpdateInsuranceModelingSettingLoading = false;
      state.updateInsuranceModelingSettingSuccessData = {};
      state.updateInsuranceModelingSettingFailedData = action.payload;
    },

    //upload company logo
    uploadOrganizationLogoRequest: (state) => {
      state.isUploadCompanyLogoRequestLoading = true;
      state.uploadCompanyLogoRequestSuccessData = {};
      state.uploadCompanyLogoRequestFailedData = {};
    },
    uploadOrganizationLogoRequestSuccess: (state, action) => {
      state.isUploadCompanyLogoRequestLoading = false;
      state.uploadCompanyLogoRequestSuccessData = action.payload;
      state.uploadCompanyLogoRequestFailedData = {};
    },
    uploadOrganizationLogoRequestFailed: (state, action) => {
      state.isUploadCompanyLogoRequestLoading = false;
      state.uploadCompanyLogoRequestSuccessData = {};
      state.uploadCompanyLogoRequestFailedData = action.payload;
    },
  }
);
