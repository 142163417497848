
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    //Create new financial position
    saveSMSFFinancialPositionRequestLoading: false,
    saveSMSFFinancialPositionData: {},
    saveSMSFFinancialPositionFailed: {},

    //
    isSMSFFinancialPositionsListLoading: false,
    smsfFinancialPositionsListData: null,
    smsfFinancialPositionsListFailed: null,

    isSMSFFinancialPositionUpdateRequestLoading: false,
    smsfFinancialPositionUpdateRequestSuccessData: {},
    smsfFinancialPositionUpdateRequestFailedData: {},

    isSMSFFinancialPositionDeleteRequestLoading: false,
    smsfFinancialPositionDeleteRequestSuccessData: {},
    smsfFinancialPositionDeleteRequestFailedData: {},

    isSMSFFinancialActivePositionUpdateRequestLoading: false,
    smsfFinancialActivePositionUpdateRequestSuccessData: {},
    smsfFinancialActivePositionUpdateRequestFailedData: {},

    isGetFindSMSFFinancialPositionRequest: false,
    smsfFindFinancialPositionSuccessData: {},
    smsfFindFinancialPositionFailedData: {},

};

export const smsfSMSFFinancialPositionsReducer = createReducer(initialState, {
    //save financial Positions
    postSaveSMSFFinancialPositionRequest: (state) => {
        state.saveSMSFFinancialPositionRequestLoading = true;
        state.saveSMSFFinancialPositionData = {};
        state.saveSMSFFinancialPositionFailed = {};
    },
    postSaveSMSFFinancialPositionSuccess: (state, action) => {
        state.saveSMSFFinancialPositionRequestLoading = false;
        state.saveSMSFFinancialPositionData = action.payload;
        state.saveSMSFFinancialPositionFailed = {};
    },
    postSaveSMSFFinancialPositionFailed: (state, action) => {
        state.saveSMSFFinancialPositionRequestLoading = false;
        state.saveSMSFFinancialPositionData = {};
        state.saveSMSFFinancialPositionFailed = action.payload;
    },

    //get all financial Positions
    getSMSFAllFinancialPositionsRequest: (state) => {
        state.isSMSFFinancialPositionsListLoading = true;
        state.smsfFinancialPositionsListData = null;
        state.smsfFinancialPositionsListFailed = {};
    },
    getSMSFAllFinancialPositionsSuccess: (state, action) => {
        state.isSMSFFinancialPositionsListLoading = false;
        state.smsfFinancialPositionsListData = action.payload;
        state.smsfFinancialPositionsListFailed = {};
    },
    getSMSFAllFinancialPositionsFailed: (state, action) => {
        state.isSMSFFinancialPositionsListLoading = false;
        state.smsfFinancialPositionsListData = null;
        state.smsfFinancialPositionsListFailed = action.payload;
    },

    //update a financial Position
    patchUpdateSMSFFinancialPositionRequest: (state) => {
        state.isSMSFFinancialPositionUpdateRequestLoading = true;
        state.smsfFinancialPositionUpdateRequestSuccessData = [];
        state.smsfFinancialPositionUpdateRequestFailedData = {};
    },
    patchUpdateSMSFFinancialPositionRequestSuccess: (state, action) => {
        state.isSMSFFinancialPositionUpdateRequestLoading = false;
        state.smsfFinancialPositionUpdateRequestSuccessData = action.payload;
        state.smsfFinancialPositionUpdateRequestFailedData = {};
    },
    patchUpdateSMSFFinancialPositionRequestFailed: (state, action) => {
        state.isSMSFFinancialPositionUpdateRequestLoading = false;
        state.smsfFinancialPositionUpdateRequestSuccessData = [];
        state.smsfFinancialPositionUpdateRequestFailedData = action.payload;
    },

    //delete a financial Position

    deleteSMSFFinancialPositionRequest: (state) => {
        state.isSMSFFinancialPositionDeleteRequestLoading = true;
        state.smsfFinancialPositionDeleteRequestSuccessData = [];
        state.smsfFinancialPositionDeleteRequestFailedData = {};
    },
    deleteSMSFFinancialPositionRequestSuccess: (state, action) => {
        state.isSMSFFinancialPositionDeleteRequestLoading = false;
        state.smsfFinancialPositionDeleteRequestSuccessData = action.payload;
        state.smsfFinancialPositionDeleteRequestFailedData = {};
    },
    deleteSMSFFinancialPositionRequestFailed: (state, action) => {
        state.isSMSFFinancialPositionDeleteRequestLoading = false;
        state.smsfFinancialPositionDeleteRequestSuccessData = [];
        state.smsfFinancialPositionDeleteRequestFailedData = action.payload;
    },

    //change active Position
    patchUpdateActiveSMSFFinancialPositionRequest: (state) => {
        state.isSMSFFinancialActivePositionUpdateRequestLoading = true;
        state.smsfFinancialActivePositionUpdateRequestSuccessData = {};
        state.smsfFinancialActivePositionUpdateRequestFailedData = {};
    },
    patchUpdateActiveSMSFFinancialPositionRequestSuccess: (state, action) => {
        state.isSMSFFinancialActivePositionUpdateRequestLoading = false;
        state.smsfFinancialActivePositionUpdateRequestSuccessData = action.payload;
        state.smsfFinancialActivePositionUpdateRequestFailedData = {};
    },
    patchUpdateActiveSMSFFinancialPositionRequestFailed: (state, action) => {
        state.isSMSFFinancialActivePositionUpdateRequestLoading = false;
        state.smsfFinancialActivePositionUpdateRequestSuccessData = {};
        state.smsfFinancialActivePositionUpdateRequestFailedData = action.payload;
    },

    //change active Position
    getFindSMSFFinancialPositionRequest: (state) => {
        state.isGetFindSMSFFinancialPositionRequest = true;
        state.smsfFindFinancialPositionSuccessData = {};
        state.smsfFindFinancialPositionFailedData = {};
    },
    getFindSMSFFinancialPositionRequestSuccess: (state, action) => {
        state.isGetFindSMSFFinancialPositionRequest = false;
        state.smsfFindFinancialPositionSuccessData = action.payload;
        state.smsfFindFinancialPositionFailedData = {};
    },
    getFindSMSFFinancialPositionRequestFailed: (state, action) => {
        state.isGetFindSMSFFinancialPositionRequest = false;
        state.smsfFindFinancialPositionSuccessData = {};
        state.smsfFindFinancialPositionFailedData = action.payload;
    },


});
