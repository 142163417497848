import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    //Project basic Investment
    projectBasicInvestmentProcessing: false,
    projectBasicInvestmentData: {},
    projectBasicInvestmentFailed: {},
};

export const basicInvestmentProjectionReducer = createReducer(initialState, {
    //Basic Investment data projection
    projectBasicInvestmentRequest: (state) => {
        state.projectBasicInvestmentProcessing = true;
        state.projectBasicInvestmentData = {};
        state.projectBasicInvestmentFailed = {};
    },
    projectBasicInvestmentRequestSuccess: (state, action) => {
        state.projectBasicInvestmentProcessing = false;
        state.projectBasicInvestmentData = action.payload;
        state.projectBasicInvestmentFailed = {};
    },
    projectBasicInvestmentRequestFailed: (state, action) => {
        state.projectBasicInvestmentProcessing = false;
        state.projectBasicInvestmentData = {};
        state.projectBasicInvestmentFailed = action.payload;
    },
});
