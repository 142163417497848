import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New Client [Partnership]
  savePartnershipClientRequestLoading: false,
  savePartnershipClientData: {},
  savePartnershipClientFailed: {},

  //Request Client List
  isPartnershipClientListRequestLoading: false,
  partnershipClientListData: {},
  partnershipClientListFailed: {},

  //selected client detail
  isPartnershipClientDetailRequestLoading: false,
  selectedPartnershipClientDetail: {},
  selectedPartnershipClientDetailFailed: {},

  //update Partnership Client detail
  isUpdatePartnershipClientRequestLoading: false,
  updatePartnershipClientData: {},
  updatePartnershipClientFailed: {},

  // get partnership detail for partnership dashboard
  isPartnershipClientDashboardStatDetailRequestLoading: false,
  selectedPartnershipClientDashboardStatDetail: {},
  selectedPartnershipClientDashboardStatDetailFailed: {},

  saveMultiplePartnershipRequestLoading: false,
  saveMultiplePartnershipClientData: {},
  saveMultiplePartnershipClientFailed: {},

  // save partnership data
  savePartnershipDataLoading: false,
  savePartnershipData: {},
  savePartnershipDataFailed: {},

  // update partnership data
  updatePartnershipDataLoading: false,
  updatePartnershipData: {},
  updatePartnershipDataFailed: {},

  // delete partnership data
  deletePartnershipDataLoading: false,
  deletePartnershipData: {},
  deletePartnershipDataFailed: {},

  //filter Partnership
  filterDataPartnershipLoading: false,
  filterDataPartnershipSuccess: [],
  filterDataPartnershipFailed: {},

  //add Partnership Entities
  addLinkedEntitiesPartnershipLoading: false,
  addLinkedEntitiesPartnershipSuccess: null,
  addLinkedEntitiesPartnershipFailed: {},

  //delete Partnership Entities
  deleteLinkedEntitiesPartnershipLoading: false,
  deleteLinkedEntitiesPartnershipSuccess: null,
  deleteLinkedEntitiesPartnershipFailed: {},
};

export const partnershipClientReducer = createReducer(initialState, {
  //save client
  postSavePartnershipClientRequest: (state) => {
    state.savePartnershipClientRequestLoading = true;
    state.savePartnershipClientData = {};
    state.savePartnershipClientFailed = {};
  },
  postSavePartnershipClientSuccess: (state, action) => {
    state.savePartnershipClientRequestLoading = false;
    state.savePartnershipClientData = action.payload;
    state.savePartnershipClientFailed = {};
  },
  postSavePartnershipClientFailed: (state, action) => {
    state.savePartnershipClientRequestLoading = false;
    state.savePartnershipClientData = {};
    state.savePartnershipClientFailed = action.payload;
  },

  //client List
  getAllPartnershipClientsRequest: (state) => {
    state.isPartnershipClientListRequestLoading = true;
    state.partnershipClientListData = {};
    state.partnershipClientListFailed = {};
  },
  getAllPartnershipClientsSuccess: (state, action) => {
    state.isPartnershipClientListRequestLoading = false;
    state.partnershipClientListData = action.payload;
    state.partnershipClientListFailed = {};
  },
  getAllPartnershipClientsFailed: (state, action) => {
    state.isPartnershipClientListRequestLoading = false;
    state.partnershipClientListData = {};
    state.partnershipClientListFailed = action.payload;
  },

  //find client detail
  getPartnershipClientByIdRequest: (state) => {
    state.isPartnershipClientDetailRequestLoading = true;
    state.selectedPartnershipClientDetail = {};
    state.selectedPartnershipClientDetailFailed = {};
  },
  getPartnershipClientByIdRequestSuccess: (state, action) => {
    state.isPartnershipClientDetailRequestLoading = false;
    state.selectedPartnershipClientDetail = action.payload;
    state.selectedPartnershipClientDetailFailed = {};
  },
  getPartnershipClientByIdRequestFailed: (state, action) => {
    state.isPartnershipClientDetailRequestLoading = false;
    state.selectedPartnershipClientDetail = {};
    state.selectedPartnershipClientDetailFailed = action.payload;
  },

  //update client
  patchUpdatePartnershipClientRequest: (state) => {
    state.isUpdatePartnershipClientRequestLoading = true;
    state.updatePartnershipClientData = {};
    state.updatePartnershipClientFailed = {};
  },
  patchUpdatePartnershipClientRequestSuccess: (state, action) => {
    state.isUpdatePartnershipClientRequestLoading = false;
    state.updatePartnershipClientData = action.payload;
    state.updatePartnershipClientFailed = {};
  },
  patchUpdatePartnershipClientRequestFailed: (state, action) => {
    state.isUpdatePartnershipClientRequestLoading = false;
    state.updatePartnershipClientData = {};
    state.updatePartnershipClientFailed = action.payload;
  },

  //find client detail
  getPartnershipClientDashboardStatByIdRequest: (state) => {
    state.isPartnershipClientDashboardStatDetailRequestLoading = true;
    state.selectedPartnershipClientDashboardStatDetail = {};
    state.selectedPartnershipClientDashboardStatDetailFailed = {};
  },
  getPartnershipClientDashboardStatByIdRequestSuccess: (state, action) => {
    state.isPartnershipClientDashboardStatDetailRequestLoading = false;
    state.selectedPartnershipClientDashboardStatDetail = action.payload;
    state.selectedPartnershipClientDashboardStatDetailFailed = {};
  },
  getPartnershipClientDashboardStatByIdRequestFailed: (state, action) => {
    state.isPartnershipClientDashboardStatDetailRequestLoading = false;
    state.selectedPartnershipClientDashboardStatDetail = {};
    state.selectedPartnershipClientDashboardStatDetailFailed = action.payload;
  },

  //save Multiple partnership
  postSaveMultiplePartnershipRequest: (state) => {
    state.saveMultiplePartnershipRequestLoading = true;
    state.saveMultiplePartnershipClientData = {};
    state.saveMultiplePartnershipClientFailed = {};
  },
  postSaveMultiplePartnershipSuccess: (state, action) => {
    state.saveMultiplePartnershipRequestLoading = false;
    state.saveMultiplePartnershipClientData = action.payload;
    state.saveMultiplePartnershipClientFailed = {};
  },
  postSaveMultiplePartnershipFailed: (state, action) => {
    state.saveMultiplePartnershipRequestLoading = false;
    state.saveMultiplePartnershipClientData = {};
    state.saveMultiplePartnershipClientFailed = action.payload;
  },

  //add partnership data
  postPartnershipDataRequest: (state) => {
    state.savePartnershipDataLoading = true;
    state.savePartnershipData = {};
    state.savePartnershipDataFailed = {};
  },
  postPartnershipDataSuccess: (state, action) => {
    state.savePartnershipDataLoading = false;
    state.savePartnershipData = action.payload;
    state.savePartnershipDataFailed = {};
  },
  postPartnershipDataFailed: (state, action) => {
    state.savePartnershipDataLoading = false;
    state.savePartnershipData = {};
    state.savePartnershipDataFailed = action.payload;
  },

  //update partnership data
  updatePartnershipDataRequest: (state) => {
    state.updatePartnershipDataLoading = true;
    state.updatePartnershipData = {};
    state.updatePartnershipDataFailed = {};
  },
  updatePartnershipDataSuccess: (state, action) => {
    state.updatePartnershipDataLoading = false;
    state.updatePartnershipData = action.payload;
    state.updatePartnershipDataFailed = {};
  },
  updatePartnershipDataFailed: (state, action) => {
    state.updatePartnershipDataLoading = false;
    state.updatePartnershipData = {};
    state.updatePartnershipDataFailed = action.payload;
  },

  //delete partnership data
  deletePartnershipDataRequest: (state) => {
    state.deletePartnershipDataLoading = true;
    state.deletePartnershipData = {};
    state.deletePartnershipDataFailed = {};
  },
  deletePartnershipDataSuccess: (state, action) => {
    state.deletePartnershipDataLoading = false;
    state.deletePartnershipData = action.payload;
    state.deletePartnershipDataFailed = {};
  },
  deletePartnershipDataFailed: (state, action) => {
    state.deletePartnershipDataLoading = false;
    state.deletePartnershipData = {};
    state.deletePartnershipDataFailed = action.payload;
  },

  //filter  Partnership
  postFilterPartnershipRequest: (state) => {
    state.filterDataPartnershipLoading = true;
    state.filterDataPartnershipSuccess = [];
    state.filterDataPartnershipFailed = {};
  },
  postFilterPartnershipSuccess: (state, action) => {
    state.filterDataPartnershipLoading = false;
    state.filterDataPartnershipSuccess = action.payload;
    state.filterDataPartnershipFailed = {};
  },
  postFilterPartnershipFailed: (state, action) => {
    state.filterDataPartnershipLoading = false;
    state.filterDataPartnershipSuccess = [];
    state.filterDataPartnershipFailed = action.payload;
  },

  //add linked entities Partnership
  addLinkedEntitiesPartnershipRequest: (state) => {
    state.addLinkedEntitiesPartnershipLoading = true;
    state.addLinkedEntitiesPartnershipSuccess = [];
    state.addLinkedEntitiesPartnershipFailed = {};
  },
  addLinkedEntitiesPartnershipSuccess: (state, action) => {
    state.addLinkedEntitiesPartnershipLoading = false;
    state.addLinkedEntitiesPartnershipSuccess = action.payload;
    state.addLinkedEntitiesPartnershipFailed = {};
  },
  addLinkedEntitiesPartnershipFailed: (state, action) => {
    state.addLinkedEntitiesPartnershipLoading = false;
    state.addLinkedEntitiesPartnershipSuccess = [];
    state.addLinkedEntitiesPartnershipFailed = action.payload;
  },

  //delete linked entities Partnership
  deleteLinkedEntitiesPartnershipRequest: (state) => {
    state.deleteLinkedEntitiesPartnershipLoading = true;
    state.deleteLinkedEntitiesPartnershipSuccess = [];
    state.deleteLinkedEntitiesPartnershipFailed = {};
  },
  deleteLinkedEntitiesPartnershipSuccess: (state, action) => {
    state.deleteLinkedEntitiesPartnershipLoading = false;
    state.deleteLinkedEntitiesPartnershipSuccess = action.payload;
    state.deleteLinkedEntitiesPartnershipFailed = {};
  },
  deleteLinkedEntitiesPartnershipFailed: (state, action) => {
    state.deleteLinkedEntitiesPartnershipLoading = false;
    state.addLinkedEntitiesPartnershipSuccess = [];
    state.deleteLinkedEntitiesPartnershipFailed = action.payload;
  },
});
