import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
// import { backButtonSvg } from 'assets/svg/svgImg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton = () => {
  const navigate = useNavigate();

  // const styles = {
  //   color: '#2E2E2E',
  //   '&:hover': {
  //     color: '#2E2E2E',
  //   },
  // };

  return (
    <Button
      size="small"
      startIcon={<ArrowBackIosIcon />}
      // sx={styles}
      onClick={() => navigate(-1)}
      sx={(theme) => ({
        fontSize: '0.875rem',
        color: theme.palette.blue?.[700],
        fontWeight: theme.typography.fontWeightBold,
      })}
    >
      Back
    </Button>
  );
};

export default BackButton;
