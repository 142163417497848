export const abnPattern = (num) => {
  if (num) {
    return num.replace(/(\d)(?=(\d{3})+$)/g, '$1 ').trim();
  } else {
    return 'N/A';
  }
};

export const tfnPattern = (num) => {
  if (num) {
    return num.replace(/(\d{3})(?=\d)/g, '$1 ').trim();
  } else {
    return 'N/A';
  }
};

export const acnPattern = (num) => {
  if (num) {
    return num.replace(/(\d{3})(?=\d)/g, '$1 ').trim();
  } else {
    return 'N/A';
  }
};
