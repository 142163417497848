import React from 'react';
import { Field } from 'formik';
import MuiTextField from '@mui/material/TextField';
import { Autocomplete } from 'formik-mui';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

const filter = createFilterOptions();

const FormikAutocomplete = (props) => {
  const {
    label,
    name,
    formik,
    options,
    title,
    multiple,
    id,
    isOptionCreatable,
    disabled,
    InputProps,
  } = props;

  const dynamicAutoComProps = {};
  const dynamicTextFieldProps = {};
  if (multiple) {
    dynamicAutoComProps.ChipProps = {
      color: 'primary',
      size: 'medium',
      variant: 'outlined',
      sx: {
        marginLeft: '0.5rem !important',
      },
    };
    dynamicTextFieldProps.onKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
      }
    };
  }

  return (
    <Box display="flex" flex alignItems="flex-end" style={{ width: '100%' }}>
      <Field
        name={name}
        multiple={multiple}
        component={Autocomplete}
        options={options ? options : []}
        getOptionLabel={(option) => option[title] || ''}
        isOptionEqualToValue={(option, value) => option?.[id] === value?.[id]}
        filterOptions={(options, params) => {
          const filtered = filter(options || [], params);
          const { inputValue } = params;
          //suggest creation of new option on creatable Autocomplete
          if (isOptionCreatable) {
            const isExistingOption =
              options?.some && options.some((option) => inputValue === option?.[title]);
            if (inputValue !== '' && options?.some && !isExistingOption) {
              filtered.push({
                inputValue,
                value: inputValue,
                id: inputValue,
                [title]: `${inputValue}`,
              });
            }
          }
          return filtered;
        }}
        {...(dynamicAutoComProps || {})}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo={!!isOptionCreatable || !formik?.values?.[name]}
        groupedOptions={[]}
        style={{ width: '100%' }}
        size={'small'}
        margin="dense"
        disabled={disabled}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label={label}
            name={name}
            error={formik?.touched?.[name] && !!formik?.errors?.[name]}
            helperText={formik?.touched?.[name] && formik?.errors?.[name]}
            disabled={disabled}
            variant="outlined"
            size="small"
            margin="dense"
            fullWidth
            style={{ width: '100%' }}
            sx={{
              '& .MuiInputBase-root': {
                padding: '0.075rem !important',
              },
              '& .MuiFormLabel-root': {
                lineHeight: '2rem !important',
              },
              '& .MuiInputLabel-shrink': {
                lineHeight: '1.75em !important',
              },
              '& .MuiInputBase-input': {
                paddingBlock: '0.7875rem 0.7875rem !important',
              },
            }}
            InputProps={{
              ...(params?.InputProps || {}),
              ...(InputProps || {}),
            }}
            {...(dynamicTextFieldProps || {})}
          />
        )}
      />
    </Box>
  );
};

FormikAutocomplete.defaultProps = {
  id: 'id',
};

export default FormikAutocomplete;
