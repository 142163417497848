import { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  LinearProgress,
  Stack,
  linearProgressClasses,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useCommonStyles } from 'styles/styles';
import { gridSpacing } from 'store/constant';

import CommonModal from 'common/material/CommonModal';
import FormikTextField from 'common/formik/FormikTextField';
import FormikDatePicker from 'common/formik/FormikDatePicker';
import DropDownAutoCom from 'common/material/DropDownAutoCom';
import FormikCheckbox from 'common/formik/FormikCheckbox';
import FormikSwitch from 'common/formik/FormikSwitch';

import { getOptionsData } from 'utils/compositeFormat';
import {
  DynamicAgeGenerator,
  DynamicHoursWorkedGeneratorFormik,
  DynamicOccupationFieldGeneratorFormik,
} from 'views/client/updateClient/individual/components/formikComponents';
import { getAgeFromDOB } from 'utils/date-of-birth';
import AutoClearClientSearchFormik from './components/AutoClearClientSearchFormik';
import EditInitialDataFormik from './components/EditInitialDataFormik';

import { AddCircleOutlineRounded, Edit, SaveOutlined } from '@mui/icons-material';

import { requestClientDetail, requestFilterClientList } from 'redux/Actions/clientsAction';
import { requestSaveCompanyDirector, requestUpdateCompanyDirector } from 'redux/Actions/companyClientsAction';

const ModalContent = (props) => {
  const { handleCloseDialog, companyDirectorEditData, handleRefetchCompanyData, companyData } = props;
  /**
   * Uses common classes
   */
  const {
    classes: { clearBtn, saveBtn, updateBtn, formSubContainer, mainDivider },
  } = useCommonStyles();

  /**
   * Redux integrations
   */
  const dispatch = useDispatch();

  ///composite store
  const compositeRequestLoading = useSelector(
    (state) => state.compositeDataReducer
  )?.compositeRequestLoading;
  //_company store
  const companyClientReducer = useSelector((state) => state?.companyClientReducer);
  const selectedCompanyClientDetail = companyClientReducer?.selectedCompanyClientDetail;
  const isCompanyClientDetailRequestLoading = companyClientReducer?.isCompanyClientDetailRequestLoading;
  //_client data reducer
  const clientReducer = useSelector((state) => state.clientReducer);
  const filterClientsRequestLoading = clientReducer?.filterClientsRequestLoading;
  const filterClientsRequestSuccessData = clientReducer?.filterClientsRequestSuccessData;
  const isClientDetailRequestLoading = clientReducer?.isClientDetailRequestLoading;
  //edit mode
  const isEditMode = !!companyDirectorEditData;

  const [companyDirectorClientOptions, setCompanyDirectorClientOptions] = useState([]);
  const [companyDirectorSearchValue, setCompanyDirectorSearchValue] = useState('');
  const [selectedExistingClientData, setSelectedExistingClientData] = useState(null);
  const [clientSearchPaginationData, setClientSearchPaginationData] = useState({
    data: [],
    prev_cursor: null,
    next_cursor: null,
    pageSize: 100,
  });
  const [isExistingIndividualClient, setIsExistingIndividualClient] = useState(false);
  /**
   * Memoized option data
   */
  const optionsData = useMemo(() => {
    let [
      titleOptions,
      gender,
      maritalStatus,
      occupationStatuses,
      occupations,
      employmentStatuses,
      employmentTypes,
    ] = [
      getOptionsData('title'),
      getOptionsData('gender'),
      getOptionsData('maritalStatus'),
      getOptionsData('occupationStatuses'),
      getOptionsData('occupations'),
      getOptionsData('employmentStatuses'),
      getOptionsData('employmentTypes'),
    ];
    return {
      titleOptions,
      gender,
      maritalStatus,
      occupationStatuses,
      occupations,
      employmentStatuses,
      employmentTypes,
    };
  }, [compositeRequestLoading]);
  //_Initial Values
  const initialValues = {
    isExistingClient: !!companyDirectorEditData?.isExistingClient,
    clientId: companyDirectorEditData?.clientId || null,

    title: companyDirectorEditData?.title || '',
    firstName: companyDirectorEditData?.firstName || '',
    middleName: companyDirectorEditData?.middleName || '',
    surName: companyDirectorEditData?.surName || '',
    preferredName: companyDirectorEditData?.preferredName || '',
    gender: companyDirectorEditData?.gender || '',
    dateOfBirth:
      (companyDirectorEditData?.dateOfBirth &&
        new Date(companyDirectorEditData?.dateOfBirth)) ||
      null,
    age: getAgeFromDOB(
      companyDirectorEditData?.dateOfBirth && new Date(companyDirectorEditData?.dateOfBirth),
      true
    ),
    // lifeExpectancy: companyDirectorEditData?.lifeExpectancy || '',
    maritalStatus: companyDirectorEditData?.maritalStatus || '',
    hasPartner: !!companyDirectorEditData?.hasPartner,
    // idType: companyDirectorEditData?.idType || '',
    // idNumber: companyDirectorEditData?.idNumber || '',
    occupationStatus: companyDirectorEditData?.occupationStatus || '',
    occupationStartedDate: companyDirectorEditData?.occupationStartedDate || null,
    occupation:
      (
        companyDirectorEditData?.occupation &&
        optionsData?.occupations?.find &&
        optionsData.occupations.find(
          (singleOccupation) => singleOccupation?.name === companyDirectorEditData?.occupation
        )
      )?.value || '',
    employerName: companyDirectorEditData?.employerName || '',
    employmentStatus: companyDirectorEditData?.employmentStatus || '',
    employmentType: companyDirectorEditData?.employmentType || '',
    hoursWorked: companyDirectorEditData?.hoursWorked || '',
    yearsInCurrentOccupation: companyDirectorEditData?.yearsInCurrentOccupation || '',
    desiredRetirementAge: companyDirectorEditData?.desiredRetirementAge || '',

    primaryEmailAddress: companyDirectorEditData?.primaryEmailAddress || '',
    homePhoneNumber: companyDirectorEditData?.homePhoneNumber || '',
    primaryMobileNumber: companyDirectorEditData?.primaryMobileNumber || '',
    hasPrivateHealthCover: !!companyDirectorEditData?.hasPrivateHealthCover,
    smoker: !!companyDirectorEditData?.smoker,
    emailWeeklyTaskSummary: !!companyDirectorEditData?.emailWeeklyTaskSummary,
    taxFileNumber: companyDirectorEditData?.taxFileNumber || '',
    //options loading reinitialize
    isOptionLoading: compositeRequestLoading ? '' : '  ',
  };

  /**
  |--------------------------------------------------
  | validate existing client as company director
  |--------------------------------------------------
  */
  Yup.addMethod(Yup.string, 'validateExistingIndividualClient', function (message) {
    return this.test('validateExistingIndividualClient', message, function (value) {
      const { path, createError, parent } = this;
      const existingCompanyDirectors = selectedCompanyClientDetail?.director || [];
      const isClientIdAlreadyExistInDataList =
        Array.isArray(existingCompanyDirectors) &&
        existingCompanyDirectors.find(
          (companyDirectorDetail) => companyDirectorDetail?.clientId === value
        );
      if (isClientIdAlreadyExistInDataList && value && !isEditMode) {
        return createError({
          path: path,
          message: message,
        });
      } else {
        return true;
      }
    });
  });
  //_Validation Schema
  const validationSchema = Yup.object().shape({
    isExistingClient: Yup.boolean().required().nullable(),
    clientId: Yup.string().when('isExistingClient', {
      is: (isExistingClient) => isExistingClient,
      then: Yup.string()
        .required('Please Search and Select a client!')
        .validateExistingIndividualClient('Selected client is already added!')
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
    title: Yup.string().nullable().required('Required !').typeError('Invalid Title!'),
    firstName: Yup.string().nullable().required('Required !').typeError('Invalid First Name!'),
    surName: Yup.string().nullable().required('Required !').typeError('Invalid Surname!'),
    preferredName: Yup.string()
      .nullable()
      .required('Required !')
      .typeError('Invalid Preferred Name!'),
    gender: Yup.string().nullable().required('Required !').typeError('Invalid Gender!'),
    dateOfBirth: Yup.date().required('Required !').nullable(),
    primaryEmailAddress: Yup.string()
      .email('Invalid Email Address!')
      .required('Required')
      .nullable()
      .typeError('Invalid Email Address!'),
    maritalStatus: Yup.string()
      .nullable()
      .required('Required !')
      .typeError('Invalid Marital Status!'),
    middleName: Yup.string().nullable().typeError('Invalid Middle Name!'),
    age: Yup.string().nullable().typeError('Invalid Age!'),

    primaryMobileNumber: Yup.string().nullable().typeError('Invalid Mobile Number!'),
    hasPrivateHealthCover: Yup.boolean().typeError('Invalid!').nullable(),
    smoker: Yup.boolean().typeError('Invalid!').nullable(),
    taxFileNumber: Yup.string().nullable().typeError('Invalid Tax File Number!'),
  });
  /**
   *
   * @param {*} event
   * @param {*} fieldValue
   * @param {*} reason
   * @returns
   */
  const handleClientSearchChange = (event, fieldValue, reason) => {
    setCompanyDirectorSearchValue(fieldValue);
    if ((!fieldValue || fieldValue?.length >= 3) && reason !== 'reset') {
      handleFilterExistingClientsByName(fieldValue);
    }
  };
  /**
   * Handle Save and add another company director
   * @param {*} event
   * @param {*} formik
   */
  const handleSaveAndAddAnotherCompanyDirector = (event, formik) => {
    formik
      .validateForm(formik.values)
      .then((errorObj) => {
        let tempErrorObj = { ...(errorObj || {}) };
        let tempTouched = { ...(formik.touched || {}) };
        Object.keys(tempErrorObj).forEach((singleKey) => {
          tempTouched[singleKey] = !!tempErrorObj[singleKey];
        });
        let isError = typeof tempErrorObj === 'object' && !!Object.keys(tempErrorObj)?.length;
        if (isError) {
          formik.setTouched(tempTouched, true);
        } else {
          onSubmit(formik?.values, formik, false);
        }
      })
      .catch((error) => {})
      .finally((value) => {});
  };
  /**
   * Handle formik submission
   * @param {*} values
   * @param {*} submitProps
   */
  const onSubmit = (values, submitProps, isAutoClose = true) => {
    submitProps?.setSubmitting && submitProps.setSubmitting(true);
    if (!isEditMode) {
      const companyDirectorSaveData = {
        requestBody: {
          isExistingClient: values?.isExistingClient,
          clientId: values?.clientId || undefined,
          title: values?.title || undefined,
          firstName: values?.firstName || undefined,
          middleName: values?.middleName || undefined,
          surName: values?.surName || undefined,
          preferredName: values?.preferredName || undefined,
          occupationStatus: values?.occupationStatus || undefined,
          dateOfBirth: values?.dateOfBirth || undefined,
          gender: values?.gender || undefined,
          maritalStatus: values?.maritalStatus || undefined,
          primaryMobileNumber: values?.primaryMobileNumber || undefined,
          primaryEmailAddress: values?.primaryEmailAddress || undefined,
          occupationStartedDate: values?.occupationStartedDate || undefined,
          smoker: values?.smoker || undefined,
          hasPrivateHealthCover: values?.hasPrivateHealthCover || undefined,
          occupation: values?.occupation || undefined,
          employerName: values?.employerName || undefined,
          employmentStatus: values?.employmentStatus || undefined,
          employmentType: values?.employmentType || undefined,
          hoursWorked: values?.hoursWorked || undefined,
          yearsInCurrentOccupation: values?.yearsInCurrentOccupation || undefined,
          taxFileNumber: values?.taxFileNumber || undefined,
        },
        companyId: companyData?._id,
      };
      const handleSaveSuccessCallback = (response) => {
        submitProps?.setSubmitting && submitProps.setSubmitting(false);
        handleRefetchCompanyData && handleRefetchCompanyData();
        if (isAutoClose) {
          handleCloseDialog && handleCloseDialog();
        }
        setIsExistingIndividualClient(false);
        setSelectedExistingClientData(null);
        submitProps?.resetForm && submitProps.resetForm();
      };
      const handleSaveFailedCallback = (error) => {
        submitProps?.setSubmitting && submitProps.setSubmitting(false);
      };
      dispatch(
        requestSaveCompanyDirector(
          companyDirectorSaveData,
          handleSaveSuccessCallback,
          handleSaveFailedCallback
        )
      );
    } else {
      const companyDirectorUpdateData = {
        requestBody: {
          isExistingClient: values?.isExistingClient,
          clientId: values?.clientId || undefined,
          title: values?.title || undefined,
          firstName: values?.firstName || undefined,
          middleName: values?.middleName || undefined,
          surName: values?.surName || undefined,
          preferredName: values?.preferredName || undefined,
          occupationStatus: values?.occupationStatus || undefined,
          dateOfBirth: values?.dateOfBirth || undefined,
          gender: values?.gender || undefined,
          maritalStatus: values?.maritalStatus || undefined,
          primaryMobileNumber: values?.primaryMobileNumber || undefined,
          primaryEmailAddress: values?.primaryEmailAddress || undefined,
          occupationStartedDate: values?.occupationStartedDate || undefined,
          smoker: values?.smoker || undefined,
          hasPrivateHealthCover: values?.hasPrivateHealthCover || undefined,
          occupation: values?.occupation || undefined,
          employerName: values?.employerName || undefined,
          employmentStatus: values?.employmentStatus || undefined,
          employmentType: values?.employmentType || undefined,
          hoursWorked: values?.hoursWorked || undefined,
          yearsInCurrentOccupation: values?.yearsInCurrentOccupation || undefined,
          taxFileNumber: values?.taxFileNumber || undefined,
        },
        companyId: companyData?._id,
        directorId: companyDirectorEditData?._id,
      };
      const handleUpdateRequestSuccessCallback = (response) => {
        submitProps?.setSubmitting && submitProps.setSubmitting(false);
        handleRefetchCompanyData && handleRefetchCompanyData();
        if (isAutoClose) {
          handleCloseDialog && handleCloseDialog();
        }
        setIsExistingIndividualClient(false);
        setSelectedExistingClientData(null);
        submitProps?.resetForm && submitProps.resetForm();
      };
      const handleUpdateRequestFailedCallback = (error) => {
        submitProps?.setSubmitting && submitProps.setSubmitting(false);
      };

      dispatch(
        requestUpdateCompanyDirector(
          companyDirectorUpdateData,
          handleUpdateRequestSuccessCallback,
          handleUpdateRequestFailedCallback
        )
      );
    }
  };
  /**
   * Handle search clients by filter
   * @param {string} searchText
   */
  const handleFilterExistingClientsByName = (searchText) => {
    const filterData = {
      name: searchText || undefined,
      next_cursor: clientSearchPaginationData?.next_cursor,
      prev_cursor: clientSearchPaginationData?.prev_cursor,
      pageSize: clientSearchPaginationData?.pageSize,
    };
    const handleFilterSuccessCallback = (response) => {
      setClientSearchPaginationData((prev) => ({
        ...prev,
        data: response?.data || [],
        next_cursor: response?.next_cursor,
        prev_cursor: response?.prev_cursor,
      }));
      const clientData = response?.data;
      const clientOptions = (clientData || []).map((clientDetail) => ({
        id: clientDetail?._id,
        value: clientDetail?._id,
        name:
          (clientDetail?.title &&
            clientDetail.title +
              ' ' +
              ((clientDetail?.surName && clientDetail?.firstName + ' ' + clientDetail.surName) ||
                clientDetail.firstName)) ||
          (clientDetail?.surName && clientDetail?.firstName + ' ' + clientDetail?.surName) ||
          clientDetail?.firstName,
        label:
          (clientDetail?.title &&
            clientDetail.title +
              ' ' +
              ((clientDetail?.surName && clientDetail?.firstName + ' ' + clientDetail.surName) ||
                clientDetail.firstName)) ||
          (clientDetail?.surName && clientDetail?.firstName + ' ' + clientDetail?.surName) ||
          clientDetail?.firstName,
      }));
      setCompanyDirectorClientOptions(clientOptions);
    };
    dispatch(requestFilterClientList(filterData, handleFilterSuccessCallback));
  };

  const handleSetClientDropdownValue = (formik, value) => {
    formik.setFieldValue('clientId', value);
    const foundClientData =
      Array.isArray(filterClientsRequestSuccessData?.data) &&
      filterClientsRequestSuccessData.data.find((clientData) => clientData?._id === value);
    if (foundClientData?._id) {
      handleFindClientDetails(foundClientData?._id);
    }
  };
  /**
   * Find selected client's details
   * @param {*} clientId
   */
  const handleFindClientDetails = (clientId) => {
    const requestData = {
      clientId,
    };
    const handleClientFindSuccessCallback = (response) => {
      if (response?.data?._id) {
        setSelectedExistingClientData(response?.data);
      }
    };
    dispatch(requestClientDetail(requestData, handleClientFindSuccessCallback));
  };

  return (
    <Grid container spacing={gridSpacing} sx={{ position: 'relative' }}>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form noValidate>
              <Box className={formSubContainer}>
                <EditInitialDataFormik
                  editData={companyDirectorEditData}
                  setClientOptions={setCompanyDirectorClientOptions}
                  handleFindClientDetails={handleFindClientDetails}
                  isExistingClient={isExistingIndividualClient}
                  setIsExistingClient={setIsExistingIndividualClient}
                  selectedExistingClientData={selectedExistingClientData}
                />
                <Grid container spacing={gridSpacing} alignItems="center">
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormikCheckbox
                      name={'isExistingClient'}
                      label={'Existing Client'}
                      checked={!!formik?.values?.isExistingClient}
                      onChange={(event) => {
                        setIsExistingIndividualClient(event?.target?.checked);
                        formik.setFieldValue('isExistingClient', !!event?.target?.checked);
                      }}
                      disabled={formik?.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8} lg={9}>
                    <Stack direction="row" spacing={gridSpacing} alignItems="center">
                      <AutoClearClientSearchFormik
                        setSelectedExistingClientData={setSelectedExistingClientData}
                      />
                      <DropDownAutoCom
                        fieldName="clientId"
                        label={'Client'}
                        optionData={companyDirectorClientOptions || []}
                        dropDownValue={formik.values?.clientId}
                        placeholder="Please Select !"
                        error={formik.touched?.clientId && formik.errors?.clientId}
                        helperText={formik.touched?.clientId && formik.errors?.clientId}
                        setDropDownValue={(value) => {
                          handleSetClientDropdownValue(formik, value);
                        }}
                        onFieldBlur={formik.handleBlur}
                        disabled={formik.isSubmitting || !formik.values?.isExistingClient}
                        loading={!!filterClientsRequestLoading}
                        onInputChange={handleClientSearchChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Divider className={mainDivider} />
                <Fade in={isClientDetailRequestLoading}>
                  <LinearProgress
                    color="primary"
                    sx={(theme) => ({
                      width: '100%',
                      height: '0.25rem',
                      borderRadius: theme.shape.globalBorderRadius,
                      [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor:
                          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                      },
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: 5,
                        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                      },
                    })}
                  />
                </Fade>
              </Box>
              <Box className={formSubContainer}>
                <Grid container spacing={gridSpacing}>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <DropDownAutoCom
                      fieldName="title"
                      label={'Title*'}
                      optionData={optionsData?.titleOptions || []}
                      dropDownValue={formik.values?.title}
                      placeholder="Please Select !"
                      error={formik.touched?.title && formik.errors?.title}
                      helperText={formik.touched?.title && formik.errors?.title}
                      setDropDownValue={(value) => formik.setFieldValue('title', value)}
                      onFieldBlur={formik.handleBlur}
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                      required
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <FormikTextField
                      name={'firstName'}
                      type="text"
                      label={'First Name *'}
                      required
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <FormikTextField
                      name={'middleName'}
                      type="text"
                      label={'Middle Name'}
                      required
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <FormikTextField
                      name={'surName'}
                      type="text"
                      label={'Surname *'}
                      required
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <FormikTextField
                      name={'preferredName'}
                      type="text"
                      label={'Preferred Name *'}
                      required
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <DropDownAutoCom
                      fieldName="gender"
                      label={'Gender *'}
                      optionData={optionsData?.gender || []}
                      dropDownValue={formik.values?.gender || null}
                      placeholder="Please Select !"
                      error={formik.touched?.gender && formik.errors?.gender}
                      helperText={formik.touched?.gender && formik.errors?.gender}
                      setDropDownValue={(value) => formik.setFieldValue('gender', value)}
                      onFieldBlur={formik.handleBlur}
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <Box style={{ width: '100%' }}>
                      <FormikDatePicker
                        name={'dateOfBirth'}
                        label={'Date of Birth *'}
                        value={formik.values.dateOfBirth}
                        setFieldValue={formik.setFieldValue}
                        error={formik?.errors?.dateOfBirth}
                        views={['year', 'month', 'day']}
                        inputFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        InputProps={{
                          onBlur: (event) =>
                            formik?.setFieldTouched &&
                            formik.setFieldTouched('dateOfBirth', true, true),
                        }}
                        disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                      />
                    </Box>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <FormikTextField
                      name={'age'}
                      type="text"
                      label={'Age'}
                      disabled
                      required
                      shrink
                    />
                    <DynamicAgeGenerator type="CLIENT" />
                  </Grid>
                  {/* <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <FormikTextField
                      name={'lifeExpectancy'}
                      type="number"
                      label={'Life expectancy'}
                      required
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid> */}
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <DropDownAutoCom
                      fieldName="maritalStatus"
                      label={'Marital Status *'}
                      optionData={optionsData?.maritalStatus || []}
                      dropDownValue={formik.values?.maritalStatus}
                      placeholder="Please Select !"
                      error={formik.touched?.maritalStatus && formik.errors?.maritalStatus}
                      helperText={
                        (formik.touched?.maritalStatus && formik.errors?.maritalStatus) || ''
                      }
                      setDropDownValue={(value) => formik.setFieldValue('maritalStatus', value)}
                      onFieldBlur={formik.handleBlur}
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  {/* <Grid container item xs={12} sm={12} md={4} lg={3} alignItems={'center'}>
                      <FormikCheckbox
                        name={'hasPartner'}
                        label={'Partner Present'}
                        disabled={!['married', 'de facto'].includes(formik.values?.maritalStatus)}
                        error={formik.touched?.hasPartner && !!formik.errors?.hasPartner}
                        isErrorMessage
                      />
                      <DynamicPartnerTab
                        handleChangeIsPartnerPresent={handleChangeIsPartnerPresent}
                        clientDetail={clientDetail}
                        setPersonalDetailsTabValue={setPersonalDetailsTabValue}
                        setConfirmationData={setConfirmationData}
                      />
                    </Grid> */}
                  {/* <Grid container item xs={12} sm={12} md={6} lg={6}>
                    <Grid container>
                      <Grid item xs="auto" className={combinedFieldLeftSelect}>
                        <DropDownAutoCom
                          fieldName="idType"
                          label={'Type'}
                          optionData={[
                            { value: 'NIC', name: 'Identification' },
                            { value: 'Passport', name: 'Passport' },
                          ]}
                          dropDownValue={formik.values?.idType}
                          placeholder="Please Select !"
                          error={formik.touched?.idType && formik.errors?.idType}
                          helperText={formik.touched?.idType && formik.errors?.idType}
                          setDropDownValue={(value) => formik.setFieldValue('idType', value)}
                          onFieldBlur={formik.handleBlur}
                          disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                          style={{ minWidth: '25ch' }}
                        />
                      </Grid>
                      <Grid item xs className={combinedFieldRightInput}>
                        <FormikTextField
                          name={'idNumber'}
                          type="text"
                          label={'Identification Number'}
                          required
                          disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12} md={4} lg={3}>
                    <FormikTextField
                      name={'primaryEmailAddress'}
                      type="text"
                      label={'Primary Email Address* '}
                      required
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormikTextField
                      name={'primaryMobileNumber'}
                      type="text"
                      label={'Primary Mobile Number'}
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Divider className={mainDivider} />
              </Box>
              <Box className={formSubContainer}>
                <DynamicOccupationFieldGeneratorFormik
                  occupationOptions={optionsData?.occupations || []}
                />
                <Grid container spacing={gridSpacing}>
                  <Grid container item xs={12} sm={12} md={4} lg={3}>
                    <DropDownAutoCom
                      fieldName="occupationStatus"
                      label={'Occupation status'}
                      optionData={optionsData.occupationStatuses || []}
                      dropDownValue={formik.values?.occupationStatus}
                      placeholder="Please Select !"
                      error={formik.touched?.occupationStatus && formik.errors?.occupationStatus}
                      helperText={
                        (formik.touched?.occupationStatus && formik.errors?.occupationStatus) || ''
                      }
                      setDropDownValue={(value) => formik.setFieldValue('occupationStatus', value)}
                      onFieldBlur={formik.handleBlur}
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  <Fade
                    unmountOnExit
                    in={
                      !formik?.values?.occupationStatus ||
                      formik?.values?.occupationStatus !== 'Unspecified'
                    }
                  >
                    <Grid container item xs={12} sm={12} md={4} lg={3}>
                      <DropDownAutoCom
                        fieldName="occupation"
                        label={'Occupations'}
                        optionData={optionsData.occupations || []}
                        dropDownValue={formik.values?.occupation || ''}
                        placeholder="Please Select !"
                        error={formik.touched?.occupation && formik.errors?.occupation}
                        helperText={(formik.touched?.occupation && formik.errors?.occupation) || ''}
                        setDropDownValue={(value) => {
                          formik.setFieldValue('occupation', value);
                        }}
                        onFieldBlur={formik.handleBlur}
                        disabled={
                          formik.isSubmitting ||
                          formik?.values?.occupationStatus === 'Home Duties' ||
                          formik?.values?.occupationStatus === 'Retired' ||
                          formik?.values?.isExistingClient
                        }
                      />
                    </Grid>
                  </Fade>
                  <Fade
                    unmountOnExit
                    in={
                      !formik?.values?.occupationStatus ||
                      formik?.values?.occupationStatus === 'Working'
                    }
                  >
                    <Grid container item xs={12} sm={12} md={4} lg={3}>
                      <Box style={{ width: '100%' }}>
                        <FormikDatePicker
                          name={'occupationStartedDate'}
                          label={'Occupation start date'}
                          value={formik.values.occupationStartedDate}
                          setFieldValue={formik.setFieldValue}
                          error={formik?.errors?.occupationStartedDate}
                          InputProps={{
                            onBlur: (event) =>
                              formik?.setFieldTouched &&
                              formik.setFieldTouched('occupationStartedDate', true, true),
                          }}
                          placeholder="Enter Here.."
                          disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                        />
                      </Box>
                    </Grid>
                  </Fade>

                  <Fade
                    unmountOnExit
                    in={
                      !formik?.values?.occupationStatus ||
                      formik?.values?.occupationStatus === 'Working'
                    }
                  >
                    <Grid container item xs={12} sm={12} md={4} lg={3}>
                      <FormikTextField
                        name={'employerName'}
                        type="text"
                        label={'Employer name'}
                        required
                        placeholder="Enter Here.."
                        disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                      />
                    </Grid>
                  </Fade>

                  <Fade
                    unmountOnExit
                    in={
                      !formik?.values?.occupationStatus ||
                      formik?.values?.occupationStatus === 'Working'
                    }
                  >
                    <Grid container item xs={12} sm={12} md={4} lg={3}>
                      <DropDownAutoCom
                        fieldName="occupation"
                        label={'Employment status'}
                        optionData={optionsData.employmentStatuses || []}
                        dropDownValue={formik.values?.employmentStatus}
                        placeholder="Please Select !"
                        error={formik.touched?.employmentStatus && formik.errors?.employmentStatus}
                        helperText={
                          (formik.touched?.employmentStatus && formik.errors?.employmentStatus) ||
                          ''
                        }
                        setDropDownValue={(value) =>
                          formik.setFieldValue('employmentStatus', value)
                        }
                        onFieldBlur={formik.handleBlur}
                        disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                      />
                    </Grid>
                  </Fade>

                  <Fade
                    unmountOnExit
                    in={
                      !formik?.values?.occupationStatus ||
                      formik?.values?.occupationStatus === 'Working'
                    }
                  >
                    <Grid container item xs={12} sm={12} md={4} lg={3}>
                      <DropDownAutoCom
                        fieldName="employmentType"
                        label={'Employment type'}
                        optionData={optionsData.employmentTypes || []}
                        dropDownValue={formik.values?.employmentType}
                        placeholder="Please Select !"
                        error={formik.touched?.employmentType && formik.errors?.employmentType}
                        helperText={
                          (formik.touched?.employmentType && formik.errors?.employmentType) || ''
                        }
                        setDropDownValue={(value) => formik.setFieldValue('employmentType', value)}
                        onFieldBlur={formik.handleBlur}
                        disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                      />
                    </Grid>
                  </Fade>
                  <Fade
                    unmountOnExit
                    in={
                      !formik?.values?.occupationStatus ||
                      formik?.values?.occupationStatus === 'Working'
                    }
                  >
                    <Grid container item xs={12} sm={12} md={4} lg={3}>
                      <FormikTextField
                        name={'hoursWorked'}
                        type="number"
                        label={'Hours worked *'}
                        required
                        placeholder="Enter Here.."
                        disabled={
                          !!(formik?.values?.employmentType === 'Full-Time') ||
                          formik?.isSubmitting ||
                          formik?.values?.isExistingClient
                        }
                      />
                      <DynamicHoursWorkedGeneratorFormik type="CLIENT" />
                    </Grid>
                  </Fade>
                  <Fade
                    unmountOnExit
                    in={
                      !formik?.values?.occupationStatus ||
                      formik?.values?.occupationStatus === 'Working'
                    }
                  >
                    <Grid container item xs={12} sm={12} md={4} lg={3}>
                      <FormikTextField
                        name={'yearsInCurrentOccupation'}
                        type="number"
                        label={'Years in current occupation'}
                        required
                        placeholder="Enter Here.."
                        disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                      />
                    </Grid>
                  </Fade>
                  {/* <Fade
                    unmountOnExit
                    in={
                      !formik?.values?.occupationStatus ||
                      formik?.values?.occupationStatus === 'Working' ||
                      formik?.values?.occupationStatus === 'Retired'
                    }
                  >
                    <Grid container item xs={12} sm={12} md={4} lg={3}>
                      <FormikTextField
                        name={'desiredRetirementAge'}
                        type="text"
                        label={
                          formik?.values?.occupationStatus === 'Retired'
                            ? 'Retirement Age'
                            : 'Desired retirement age'
                        }
                        required
                        placeholder="Enter Here.."
                        disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                      />
                    </Grid>
                  </Fade> */}
                </Grid>
              </Box>
              <Box>
                <Divider className={mainDivider} />
              </Box>
              <Box className={formSubContainer}>
                <Grid container spacing={gridSpacing}>
                  <Grid container item xs={12} sm={12} md={4} lg={3} alignItems={'center'}>
                    <FormikCheckbox
                      name={'hasPrivateHealthCover'}
                      label={'Private Health Care'}
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={3} alignItems={'center'}>
                    <FormikSwitch
                      name={'smoker'}
                      label={'Smoker'}
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                  {/* <Grid container item xs={12} sm={12} md={4} lg={3} alignItems={'center'}>
                    <FormikSwitch
                      name={'emailWeeklyTaskSummary'}
                      label={'Email me a weekly task summary'}
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid> */}
                  <Grid container item xs={12} sm={12} md={true}>
                    <FormikTextField
                      name={'taxFileNumber'}
                      type="text"
                      label={'Tax File Number'}
                      required
                      disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className={formSubContainer}>
                <Grid container item xs={12} justifyContent={'flex-end'}>
                  <Stack direction="row" spacing={gridSpacing}>
                    <Button
                      id="formResetBtn"
                      onClick={formik.handleReset}
                      variant="outlined"
                      className={clearBtn}
                    >
                      Reset
                    </Button>
                    <Grid item xs="auto">
                      <LoadingButton
                        className={!isEditMode ? saveBtn : updateBtn}
                        id={'calculate-btn'}
                        endIcon={!isEditMode ? <SaveOutlined /> : <Edit />}
                        loading={
                          formik.isSubmitting ||
                          compositeRequestLoading ||
                          isClientDetailRequestLoading ||
                          isCompanyClientDetailRequestLoading
                        }
                        loadingPosition="end"
                        variant="contained"
                        type="submit"
                      >
                        {!isEditMode ? 'Save' : 'Update'}
                      </LoadingButton>
                    </Grid>
                    {!isEditMode && (
                      <Grid item xs="auto">
                        <LoadingButton
                          className={saveBtn}
                          id={'calculate-btn'}
                          endIcon={<AddCircleOutlineRounded />}
                          loading={
                            formik.isSubmitting ||
                            compositeRequestLoading ||
                            isClientDetailRequestLoading ||
                            isCompanyClientDetailRequestLoading
                          }
                          loadingPosition="end"
                          variant="contained"
                          onClick={(event) =>
                            handleSaveAndAddAnotherCompanyDirector(event, formik)
                          }
                        >
                          Save and Add Another
                        </LoadingButton>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

const ManageCompanyDirectorModal = (props) => {
  const { isOpened, handleClose, companyDirectorEditData, handleRefetchCompanyData, companyData } =
    props;

  const isEditMode = !!companyDirectorEditData;

  return (
    <CommonModal
      open={isOpened}
      handleDialogOpened={handleClose}
      dialogTitle={!isEditMode ? 'Add Director' : 'Update Director'}
      dialogContent={
        <ModalContent
          handleCloseDialog={handleClose}
          companyDirectorEditData={companyDirectorEditData}
          handleRefetchCompanyData={handleRefetchCompanyData}
          companyData={companyData}
        />
      }
      dialogContentProps={{
        sx: {
          padding: '0 !important',
        },
      }}
      maxWidth="md"
    />
  );
};

ManageCompanyDirectorModal.propTypes = {
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default memo(ManageCompanyDirectorModal);
