import { createReducer } from '@reduxjs/toolkit';

const leadQuestionnairesInitialState = {

    //get Lead Questionnaires
    isGetLeadQuestionnairesLoading: false,
    leadQuestionnairesSuccessData: {},
    leadQuestionnairesFailedData: {},

    //update lead questionnaires
    isUpdateLeadQuestionnaireLoading: false,
    updateLeadQuestionnaireSuccessData: {},
    updateLeadQuestionnaireFailedData: {},

    isDeleteQuestionnaireRequestLoading: false,
    deleteQuestionnaireRequestSuccessData: {},
    deleteQuestionnaireRequestFailedData: {},
};

//lead questionnaires reducer
export const leadQuestionnairesReducer = createReducer(leadQuestionnairesInitialState, {
    //get lead questionnaires
    getLeadQuestionnaireRequest: (state) => {
        state.isGetLeadQuestionnairesLoading = true;
        state.leadQuestionnairesSuccessData = {};
        state.leadQuestionnairesFailedData = {};
    },
    getLeadQuestionnaireRequestSuccess: (state, action) => {
        state.isGetLeadQuestionnairesLoading = false;
        state.leadQuestionnairesSuccessData = action.payload;
        state.leadQuestionnairesFailedData = {};
    },
    getLeadQuestionnaireRequestFailed: (state, action) => {
        state.isGetLeadQuestionnairesLoading = false;
        state.leadQuestionnairesSuccessData = {};
        state.leadQuestionnairesFailedData = action.payload;
    },

    //update lead questionnaires
    updateLeadQuestionnaireRequest: (state) => {
        state.isUpdateLeadQuestionnaireLoading = true;
        state.updateLeadQuestionnaireSuccessData = {};
        state.updateLeadQuestionnaireFailedData = {};
    },
    updateLeadQuestionnaireRequestSuccess: (state, action) => {
        state.isUpdateLeadQuestionnaireLoading = false;
        state.updateLeadQuestionnaireSuccessData = action.payload;
        state.updateLeadQuestionnaireFailedData = {};
    },
    updateLeadQuestionnaireRequestFailed: (state, action) => {
        state.isUpdateLeadQuestionnaireLoading = false;
        state.updateLeadQuestionnaireSuccessData = {};
        state.updateLeadQuestionnaireFailedData = action.payload;
    },

    deleteQuestionnaireRequest: (state) => {
        state.isDeleteQuestionnaireRequestLoading = true;
        state.deleteQuestionnaireRequestSuccessData = {}
        state.deleteQuestionnaireRequestFailedData = {}
    },
    deleteQuestionnaireRequestSuccess: (state, action) => {
        state.isDeleteQuestionnaireRequestLoading = false;
        state.deleteQuestionnaireRequestSuccessData = action.payload
        state.deleteQuestionnaireRequestFailedData = {}
    },
    deleteQuestionnaireRequestFailed: (state, action) => {
        state.isDeleteQuestionnaireRequestLoading = false;
        state.deleteQuestionnaireRequestSuccessData = {}
        state.deleteQuestionnaireRequestFailedData = action.payload
    },
});
