import * as Yup from 'yup';

import CommonModal from 'common/material/CommonModal';
import { useCallback, useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { gridSpacing } from 'store/constant';
import { Button, Grid, Stack, Typography } from '@mui/material';
import QuestionAnswerConfigureListItem from './components/QuestionAnswerConfigureListItem';
import { useCommonStyles } from 'styles/styles';
import { Save, SaveOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const QuestionAnswerConfigureForm = (props) => {
  const { questionData, actionDataIndex, handleDialogOpened, modalData, isOpened } = props;

  const {
    classes: { profileName1, cancelBtn, saveBtn },
  } = useCommonStyles();

  const formikContext = useFormikContext();
  const [questionnaireFieldInitialValues, setQuestionnaireFieldInitialValues] = useState({
    answers: [
      {
        label: '',
        type: 'Text',
      },
    ],
  });
  const [isMounted, setIsMounted] = useState(false);

  const questionnaireFieldValidationSchema = Yup.object({
    answers: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Required!').nullable(),
        type: Yup.mixed().required('Required!').nullable(),
        value: Yup.mixed().nullable(),
      })
    ),
  });
  /**
   * Handle Generate Nested Touched value for Error Obj
   * @param {*} mainErrorKeyOrObject
   * @param {*} prevTouchedData
   * @returns
   */
  const handleNestedTouchEvents = (mainErrorKeyOrObject, prevTouchedData = {}) => {
    let touchedData = { ...prevTouchedData };
    Object.keys(mainErrorKeyOrObject).forEach((singleErrorKey) => {
      if (typeof mainErrorKeyOrObject[singleErrorKey] === 'object') {
        touchedData[singleErrorKey] = {};
        touchedData[singleErrorKey] = handleNestedTouchEvents(
          mainErrorKeyOrObject[singleErrorKey],
          touchedData[singleErrorKey]
        );
      } else if (typeof mainErrorKeyOrObject[singleErrorKey] === 'string') {
        touchedData[singleErrorKey] = !!mainErrorKeyOrObject[singleErrorKey];
      }
    });

    return touchedData;
  };
  /**
   * Handle add new answer type
   */
  const handleOnAddNewAnswer = useCallback((fieldIndex, formik) => {
    formik
      .validateForm(formik.values)
      .then((errorObj) => {
        let tempErrorObj = { ...(errorObj || {}) };
        let tempTouched = { ...(formik.touched || {}) };
        Object.keys(tempErrorObj).forEach((singleKey) => {
          if (typeof tempErrorObj[singleKey] === 'object') {
            try {
              tempTouched[singleKey] = handleNestedTouchEvents(tempErrorObj[singleKey]);
            } catch (err) {}
          } else {
            tempTouched[singleKey] = !!tempErrorObj[singleKey];
          }
        });
        let isError = typeof tempErrorObj === 'object' && !!Object.keys(tempErrorObj)?.length;
        if (isError) {
          formik.setTouched(tempTouched, true);
        } else {
          const updatedAnswers = [...(formik?.values?.answers || [])];
          updatedAnswers.splice(fieldIndex + 1, 0, {
            label: '',
            type: 'Text',
            value: '',
          });
          formik?.setFieldValue?.(`answers`, updatedAnswers);
        }
      })
      .catch((error) => {})
      .finally((value) => {});
  }, []);
  /**
   * Handle remove answer type
   */
  const handleOnRemoveAnswer = useCallback((fieldIndex, formik) => {
    const updatedAnswers = [...(formik?.values?.answers || [])];
    updatedAnswers.splice(fieldIndex, 1);
    formik?.setFieldValue?.(`answers`, updatedAnswers);
  }, []);
  /**
   * Handle on form submission
   * @param {*} values
   * @param {*} submitProps
   */
  const onSubmit = (values, submitProps) => {
    submitProps?.setSubmitting?.(true);

    //questionnaireData, actionDataIndex, handleDialogOpened
    const answersData = (values?.answers || []).map((answerDetail) => ({
      ...answerDetail,
    }));

    let questionnaires = [...(formikContext.values?.questionnaires || [])];

    if (!modalData?.isPartner) {
      questionnaires[actionDataIndex] = {
        ...questionnaires[actionDataIndex],
        answerFieldKeys: ['multipleFormatAnswers'],
        answer: {
          ...(questionnaires?.[actionDataIndex]?.answer || {}),
          client: {
            ...(questionnaires?.[actionDataIndex]?.answer?.client || {}),
            multipleFormatAnswers: answersData,
          },
        },
      };
    } else {
      questionnaires[actionDataIndex] = {
        ...questionnaires[actionDataIndex],
        answerFieldKeys: ['multipleFormatAnswers'],
        answer: {
          ...(questionnaires?.[actionDataIndex]?.answer || {}),
          partner: {
            ...(questionnaires?.[actionDataIndex]?.answer?.client || {}),
            multipleFormatAnswers: answersData,
          },
        },
      };
    }
    formikContext.setFieldValue(`questionnaires`, questionnaires);
    submitProps?.setSubmitting?.(false);
    handleDialogOpened(false);
    submitProps?.setSubmitting?.(false);
  };
  useEffect(() => {
    if (isMounted && isOpened) {
      let initialAnswers =
        questionData?.answer?.[modalData?.isPartner ? 'partner' : 'client']
          ?.multipleFormatAnswers || [];
      if (initialAnswers?.[0]?.type && Array.isArray(initialAnswers)) {
        setQuestionnaireFieldInitialValues((prev) => ({
          ...prev,
          answers: initialAnswers,
        }));
      }
    }
  }, [isMounted, isOpened]);
  /**
   * Effect on mounted
   */
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <Formik
      validationSchema={questionnaireFieldValidationSchema}
      initialValues={questionnaireFieldInitialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <section>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Typography className={profileName1} sx={{ textTransform: 'none' }} fontSize="1rem">
                Configure this question's multiple answers
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={gridSpacing}>
                {Array.isArray(formik?.values?.answers) &&
                  formik.values.answers.map((answerDetail, answerIndex) => (
                    <Grid item xs={12} key={`${answerIndex}-answerConfigure-list-wrapper`}>
                      <QuestionAnswerConfigureListItem
                        answerDetail={answerDetail}
                        answerIndex={answerIndex}
                        modalData={modalData}
                        handleOnAddNewAnswer={(addOnIndex) =>
                          handleOnAddNewAnswer(addOnIndex, formik)
                        }
                        handleOnRemoveAnswer={(removeOnIndex) =>
                          handleOnRemoveAnswer(removeOnIndex, formik)
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={gridSpacing}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  id={'rest-btn'}
                  type="reset"
                  variant={'outlined'}
                  className={cancelBtn}
                  onClick={(event) => formik?.resetForm && formik.resetForm(event)}
                >
                  Reset
                </Button>
                <LoadingButton
                  className={saveBtn}
                  id={'save-btn'}
                  endIcon={<SaveOutlined />}
                  loading={!!formik?.isSubmitting}
                  disabled={!!formik?.isSubmitting}
                  loadingPosition="end"
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  {'Save'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </section>
      )}
    </Formik>
  );
};

const ConfigureMultipleAnswerFormatModal = (props) => {
  const { isOpened, questionData, actionDataIndex, modalData, handleDialogOpened, isEditMode } =
    props;

  return (
    <CommonModal
      open={isOpened}
      dialogTitle="Edit Question"
      dialogContent={
        <QuestionAnswerConfigureForm
          questionData={questionData}
          actionDataIndex={actionDataIndex}
          handleDialogOpened={handleDialogOpened}
          isEditMode={isEditMode}
          modalData={modalData}
          isOpened={isOpened}
        />
      }
      handleDialogOpened={handleDialogOpened}
      fullWidth
    />
  );
};

export default ConfigureMultipleAnswerFormatModal;
