import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // get all questionnaire template tags
  isGetAllQuestionnaireTemplateTagsLoading: false,
  questionnaireTemplateTagsData: {},
  questionnaireTemplateTagsFailedData: {},

  // search questionnaire template tag
  isSearchQuestionnaireTemplateTagLoading: false,
  questionnaireTemplateTagSearchSuccessData: {},
  questionnaireTemplateTagSearchFailedData: {},

  // save questionnaire template tag
  isSaveQuestionnaireTemplateTagLoading: false,
  questionnaireTemplateTagSaveSuccessData: {},
  questionnaireTemplateTagSaveFailedData: {},

  //delete questionnaire template tag
  isDeleteQuestionnaireTemplateTagLoading: false,
  questionnaireTemplateTagDeleteSuccessData: {},
  questionnaireTemplateTagDeleteFailedData: {},

  //update questionnaire template tag
  isUpdateQuestionnaireTemplateRequestLoading: false,
  questionnaireTemplateTagUpdateSuccessData: {},
  questionnaireTemplateTagUpdateFailedData: {},
};

/**
 * Questionnaire template tag reducer
 */
export const questionnaireTemplateTagReducer = createReducer(initialState, {
  // get all questionnaire templates
  getAllQuestionnaireTemplateTagsRequest: (state) => {
    state.isGetAllQuestionnaireTemplateTagsLoading = true;
    state.questionnaireTemplateTagsData = {};
    state.questionnaireTemplateTagsFailedData = {};
  },
  getAllQuestionnaireTemplateTagsRequestSuccess: (state, action) => {
    state.isGetAllQuestionnaireTemplateTagsLoading = false;
    state.questionnaireTemplateTagsData = action.payload;
    state.questionnaireTemplateTagsFailedData = {};
  },
  getAllQuestionnaireTemplateTagsRequestFailed: (state, action) => {
    state.isGetAllQuestionnaireTemplateTagsLoading = false;
    state.questionnaireTemplateTagsData = {};
    state.questionnaireTemplateTagsFailedData = action.payload;
  },

  // search questionnaire template tag
  postSearchQuestionnaireTemplateTagRequest: (state) => {
    state.isSearchQuestionnaireTemplateLoading = true;
    state.questionnaireTemplateTagSearchSuccessData = {};
    state.questionnaireTemplateTagSearchFailedData = {};
  },
  postSearchQuestionnaireTemplateTagRequestSuccess: (state, action) => {
    state.isSearchQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateTagSearchSuccessData = action.payload;
    state.questionnaireTemplateTagSearchFailedData = {};
  },
  postSearchQuestionnaireTemplateTagRequestFailed: (state, action) => {
    state.isSearchQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateTagSearchSuccessData = {};
    state.questionnaireTemplateTagSearchFailedData = action.payload;
  },

  // save questionnaire template tag
  postSaveQuestionnaireTemplateRequest: (state) => {
    state.isSaveQuestionnaireTemplateLoading = true;
    state.questionnaireTemplateTagSaveSuccessData = {};
    state.questionnaireTemplateTagSaveFailedData = {};
  },
  postSaveQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isSaveQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateTagSaveSuccessData = action.payload;
    state.questionnaireTemplateTagSaveFailedData = {};
  },
  postSaveQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isSaveQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateTagSaveSuccessData = {};
    state.questionnaireTemplateTagSaveFailedData = action.payload;
  },

  // delete questionnaire template tag
  deleteQuestionnaireTemplateRequest: (state) => {
    state.isDeleteQuestionnaireTemplateLoading = true;
    state.questionnaireTemplateTagDeleteSuccessData = {};
    state.questionnaireTemplateTagDeleteFailedData = {};
  },
  deleteQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isDeleteQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateTagDeleteSuccessData = action.payload;
    state.questionnaireTemplateTagDeleteFailedData = {};
  },
  deleteQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isDeleteQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateTagDeleteSuccessData = {};
    state.questionnaireTemplateTagDeleteFailedData = action.payload;
  },

  // delete questionnaire template tag
  putUpdateQuestionnaireTemplateRequest: (state) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = true;
    state.questionnaireTemplateTagDeleteSuccessData = {};
    state.questionnaireTemplateTagDeleteFailedData = {};
  },
  putUpdateQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = false;
    state.questionnaireTemplateTagDeleteSuccessData = action.payload;
    state.questionnaireTemplateTagDeleteFailedData = {};
  },
  putUpdateQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = false;
    state.questionnaireTemplateTagDeleteSuccessData = {};
    state.questionnaireTemplateTagDeleteFailedData = action.payload;
  },

  // update template tag
  updateQuestionnaireTemplateRequest: (state) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = true;
    state.questionnaireTemplateTagUpdateSuccessData = {};
    state.questionnaireTemplateTagUpdateFailedData = {};
  },
  updateQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = false;
    state.questionnaireTemplateTagUpdateSuccessData = action.payload;
    state.questionnaireTemplateTagUpdateFailedData = {};
  },
  updateQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = false;
    state.questionnaireTemplateTagUpdateSuccessData = {};
    state.questionnaireTemplateTagUpdateFailedData = action.payload;
  },
});
