import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Chip, Grid, SvgIcon, Tooltip } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import { gridSpacing } from 'store/constant';
import ShareholderDetail from './ShareholderDetail';

import { deleteSvg, editSvg } from 'assets/svg/svgImg';

const CompanyShareholderList = (props) => {
  const { setManageShareholdersModalData, handleOpenConfirmationModal } = props;

  //_trust store
  const companyClientReducer = useSelector((state) => state?.companyClientReducer);
  const isCompanyClientDetailRequestLoading =
    companyClientReducer?.isCompanyClientDetailRequestLoading;
  const selectedCompanyClientDetail = companyClientReducer?.selectedCompanyClientDetail;
  //_states
  const [paginationData, setPaginationData] = useState({
    data: [],
    pageSize: 5,
    totalElements: 0,
  });
  /**
   * Get data grid rows
   */
  const getDataGridRows = () => {
    const rowData = paginationData?.data;
    return rowData;
  };
  /**
   * Get data grid column definition
   */
  const getDataGridColumns = () => {
    const shareholderColumns = [
      {
        field: 'fullName',
        headerName: 'Shareholder Name',
        flex: 1,
      },
      {
        field: 'shareholderType',
        headerName: 'Shareholder Type',
        align: 'center',
        renderCell: (params) =>
          params?.value ? (
            <Tooltip title={<ShareholderDetail shareholderData={params?.row} />} arrow>
              <Chip size="small" variant="outlined" color="primary" label={params?.value} />
            </Tooltip>
          ) : null,
        flex: 1,
      },
      {
        field: 'percentage',
        headerName: 'Percentage',
        align: 'right',
        valueGetter: (params) => `${(params.value?.toFixed && params.value.toFixed(2)) || 0.0}%`,
        flex: 1,
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 1,
        type: 'actions',
        getActions: (params) => [
          <GridActionsCellItem
            key={1}
            id="edit"
            icon={<SvgIcon viewBox="0 0 25 25">{editSvg}</SvgIcon>}
            onClick={(event) => handleActionClick(event, 'EDIT', params)}
            label="Edit Shareholder"
          />,
          <GridActionsCellItem
            key={2}
            id="delete"
            icon={<SvgIcon viewBox="0 0 25 25">{deleteSvg}</SvgIcon>}
            onClick={(event) => handleActionClick(event, 'DELETE', params)}
            label="Delete"
          />,
        ],
      },
    ];

    return shareholderColumns;
  };
  /**
   * Handle datagrid action click
   * @param {*} event
   * @param {*} actionType
   * @param {*} gridParams
   */
  const handleActionClick = (event, actionType, gridParams) => {
    if (actionType === 'EDIT') {
      const editingShareholderData = gridParams?.row;
      if (editingShareholderData) {
        setManageShareholdersModalData &&
          setManageShareholdersModalData((prev) => ({
            ...prev,
            isOpened: true,
            editData: editingShareholderData || null,
          }));
      }
    } else if (actionType === 'DELETE') {
      const deletingShareholderData = gridParams?.row;
      handleOpenConfirmationModal({
        isConfirmationOpen: true,
        confirmationModalHeader: 'Delete Shareholder!',
        confirmationModalContent: 'Do you want to remove this shareholder?',
        confirmationData: deletingShareholderData,
        actionType: 'DELETE_SHAREHOLDER',
      });
    }
  };
  /**
   * Data grid unique key
   * @param {*} row
   * @returns {string} key
   */
  const getRowId = (row) => row?._id;

  /**
   * Get Company Name
   */
  const getCompanyName = (shareholderData) => {
    const shareholderDetailKey =
      shareholderData?.shareholderType === 'Company'
        ? 'companyId'
        : shareholderData?.shareholderType === 'SMSF'
        ? 'smsfId'
        : shareholderData?.shareholderType === 'Trust' && 'trustId';
    return shareholderData?.[shareholderDetailKey]?.name || 'N/A';
  };
  /**
   * Effect on trust details
   */
  useEffect(() => {
    if (
      Array.isArray(selectedCompanyClientDetail?.shareholder) &&
      !isCompanyClientDetailRequestLoading
    ) {
      const shareholdersData = (selectedCompanyClientDetail?.shareholder || []).map(
        (shareholderDetail) => {
          const shareholderType = shareholderDetail?.directorId
            ? 'Director'
            : (shareholderDetail?.clientId || !shareholderDetail?.isExistingClient
                ? 'Individual'
                : shareholderDetail?.smsfId
                ? 'SMSF'
                : shareholderDetail?.companyId
                ? 'Company'
                : shareholderDetail?.trustId && 'Trust') || 'N/A';
          return {
            ...shareholderDetail,
            fullName:
              shareholderDetail?.clientId ||
              !shareholderDetail?.isExistingClient ||
              shareholderType === 'Director'
                ? (shareholderDetail?.title &&
                    shareholderDetail.title +
                      ' ' +
                      ((shareholderDetail?.surName &&
                        shareholderDetail?.firstName + ' ' + shareholderDetail.surName) ||
                        shareholderDetail.firstName)) ||
                  (shareholderDetail?.surName &&
                    shareholderDetail?.firstName + ' ' + shareholderDetail?.surName) ||
                  shareholderDetail?.firstName
                : getCompanyName({ ...(shareholderDetail || {}), shareholderType }),
            shareholderType,
          };
        }
      );
      setPaginationData((prev) => ({
        ...prev,
        data: shareholdersData,
        totalElements: shareholdersData?.length,
      }));
    }
  }, [selectedCompanyClientDetail?.shareholder?.length, isCompanyClientDetailRequestLoading]);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <DataGrid
          rows={getDataGridRows()}
          columns={getDataGridColumns()}
          getRowId={getRowId}
          rowsPerPageOptions={[5, 10]}
          // checkboxSelection
          sx={{ height: '60vh' }}
          loading={isCompanyClientDetailRequestLoading}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyShareholderList;
