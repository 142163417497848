import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Save Trust Client Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestSaveTrustClient =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveTrustClientRequest' });

    const requestData = {
      url: API_URL.trust.trustProfile.POST_SAVE_TRUST_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveTrustClientSuccess', payload: response.data });
      const message = `Trust is Saved Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveTrustClientFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete Trust Client Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestDeleteTrustClient =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteTrustClientRequest' });

    const { trustId, trustFinancialPositionId } = requestBody;

    const requestData = {
      url: API_URL.trust.trustProfile.DELETE_TRUST_CLIENT.replace('{trustId}', trustId).replace(
        '{trustFinancialPositionId}',
        trustFinancialPositionId
      ),
      method: 'DELETE',
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteTrustClientSuccess', payload: response.data });
      const message = `Trust is deleted Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteTrustClientFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Request Trust Client List
 * @param {Function} handleClientListSuccessCallback
 * @param {Function} handleClientListFailedCallback
 * @returns
 */
export const requestTrustClientList =
  (handleClientListSuccessCallback, handleClientListFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllTrustClientsRequest' });

    const requestData = {
      url: API_URL.trust.trustClientList.GET_ALL_TRUST_CLIENTS,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllTrustClientsSuccess', payload: response.data });

      handleClientListSuccessCallback && handleClientListSuccessCallback(response.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllTrustClientsFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleClientListFailedCallback && handleClientListFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle Request Find Trust Detail by Id
 * @param {object} requestBody
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns void
 */
export const requestTrustClientDetail =
  (requestBody, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getTrustClientByIdRequest' });

    const requestData = {
      url: API_URL.trust.trustProfile.GET_FIND_TRUST_CLIENT_BY_ID.replace(
        '{trustId}',
        requestBody?.trustId
      ),
      method: 'GET',
    };
    const successCallback = (response) => {
      handleRequestSuccessCallback && handleRequestSuccessCallback(response);
      dispatch({ type: 'getTrustClientByIdRequestSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      handleRequestFailedCallback && handleRequestFailedCallback(error);
      dispatch({ type: 'getTrustClientByIdRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Update Trust Basic Profile Detail
 * @param {object} clientUpdaterRequestData {requestBody, clientDetail}
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestUpdateTrustClient =
  (
    clientUpdaterRequestData,
    handleRequestSuccessCallback = null,
    handleRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateTrustClientRequest' });
    let { requestBody, trustDetail, customSuccessMessage } = clientUpdaterRequestData;
    let trustId = trustDetail?._id;
    const requestData = {
      url: API_URL.trust.trustProfile.PATCH_UPDATE_TRUST_CLIENT_BY_ID.replace('{trustId}', trustId),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateTrustClientRequestSuccess',
        payload: response.data,
      });
      const message = customSuccessMessage
        ? customSuccessMessage
        : `Trust is Updated Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleRequestSuccessCallback && handleRequestSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateTrustClientRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleRequestFailedCallback && handleRequestFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save trust beneficiary
 * @param {*} requestDetails
 * @param {*} handleSaveBeneficiarySuccessCallback
 * @param {*} handleSaveBeneficiaryFailedCallback
 * @returns
 */
export const requestSaveTrustBeneficiary =
  (
    requestDetails,
    handleSaveBeneficiarySuccessCallback = null,
    handleSaveBeneficiaryFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveTrustBeneficiary' });

    const { trustId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.POST_CREATE_TRUST_BENEFICIARY.replace('{trustId}', trustId),
      method: 'POST',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'postSaveTrustBeneficiarySuccess',
        payload: response.data,
      });
      const message = `Beneficiary is saved successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveBeneficiarySuccessCallback && handleSaveBeneficiarySuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'postSaveTrustBeneficiaryFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveBeneficiaryFailedCallback && handleSaveBeneficiaryFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Update trust beneficiary
 * @param {*} requestDetails
 * @param {*} handleUpdateBeneficiarySuccessCallback
 * @param {*} handleUpdateBeneficiaryFailedCallback
 * @returns
 */
export const requestUpdateTrustBeneficiary =
  (
    requestDetails,
    handleUpdateBeneficiarySuccessCallback = null,
    handleUpdateBeneficiaryFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateTrustBeneficiary' });

    const { trustId, beneficiaryId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.PATCH_UPDATE_TRUST_BENEFICIARY_BY_ID.replace(
        '{trustId}',
        trustId
      ).replace('{beneficiaryId}', beneficiaryId),
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateTrustBeneficiarySuccess',
        payload: response.data,
      });
      const message = `Beneficiary is updated successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleUpdateBeneficiarySuccessCallback &&
        handleUpdateBeneficiarySuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateTrustBeneficiaryFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateBeneficiaryFailedCallback && handleUpdateBeneficiaryFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete trust beneficiary
 * @param {*} requestDetails
 * @param {*} deleteRequestSuccessCallback
 * @param {*} handleSaveBeneficiaryFailedCallback
 * @returns
 */
export const requestDeleteTrustBeneficiary =
  (
    requestDetails,
    handleDeleteRequestSuccessCallback = null,
    handleDeleteRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteTrustBeneficiary' });

    const { trustId, beneficiaryId } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.DELETE_TRUST_BENEFICIARY.replace(
        '{trustId}',
        trustId
      ).replace('{beneficiaryId}', beneficiaryId),
      method: 'DELETE',
    };

    const successCallback = (response) => {
      dispatch({
        type: 'deleteTrustBeneficiarySuccess',
        payload: response.data,
      });
      const message = `Beneficiary is deleted successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleDeleteRequestSuccessCallback && handleDeleteRequestSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'deleteTrustBeneficiaryFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteRequestFailedCallback && handleDeleteRequestFailedCallback(error);
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save individual trustee
 * @param {*} requestDetails
 * @param {*} handleSaveIndividualSuccessCallback
 * @param {*} handleSaveIndividualFailedCallback
 * @returns
 */
export const requestSaveIndividualTrustee =
  (
    requestDetails,
    handleSaveIndividualSuccessCallback = null,
    handleSaveIndividualFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveIndividualTrusteeRequest' });

    const { trustId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.POST_CREATE_TRUST_INDIVIDUAL.replace('{trustId}', trustId),
      method: 'POST',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'postSaveIndividualTrusteeRequestSuccess',
        payload: response.data,
      });
      const message = `Individual Trustee is saved successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveIndividualSuccessCallback && handleSaveIndividualSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'postSaveIndividualTrusteeRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveIndividualFailedCallback && handleSaveIndividualFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Update individual trustee
 * @param {*} requestDetails
 * @param {*} handleUpdateIndividualSuccessCallback
 * @param {*} handleUpdateIndividualFailedCallback
 * @returns
 */
export const requestUpdateIndividualTrustee =
  (
    requestDetails,
    handleUpdateIndividualSuccessCallback = null,
    handleUpdateIndividualFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateIndividualTrusteeRequest' });

    const { trustId, individualId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.PATCH_UPDATE_TRUST_INDIVIDUAL_BY_ID.replace(
        '{trustId}',
        trustId
      ).replace('{individualId}', individualId),
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateIndividualTrusteeRequestSuccess',
        payload: response.data,
      });
      const message = `Individual trustee updated successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleUpdateIndividualSuccessCallback &&
        handleUpdateIndividualSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateIndividualTrusteeRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateIndividualFailedCallback && handleUpdateIndividualFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete individual trustee
 * @param {*} requestDetails
 * @param {*} handleDeleteRequestSuccessCallback
 * @param {*} handleSaveBeneficiaryFailedCallback
 * @returns
 */
export const requestDeleteIndividualTrustee =
  (
    requestDetails,
    handleDeleteRequestSuccessCallback = null,
    handleDeleteRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteIndividualTrusteeRequest' });

    const { trustId, individualId } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.DELETE_TRUST_INDIVIDUAL.replace('{trustId}', trustId).replace(
        '{individualId}',
        individualId
      ),
      method: 'DELETE',
    };

    const successCallback = (response) => {
      dispatch({
        type: 'deleteIndividualTrusteeRequestSuccess',
        payload: response.data,
      });
      const message = `Individual trustee is deleted successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleDeleteRequestSuccessCallback && handleDeleteRequestSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'deleteIndividualTrusteeRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteRequestFailedCallback && handleDeleteRequestFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save corporate trustee director
 * @param {*} requestDetails
 * @param {*} handleSaveDirectorSuccessCallback
 * @param {*} handleSaveDirectorFailedCallback
 * @returns
 */
export const requestSaveCorporateTrusteeDirector =
  (
    requestDetails,
    handleSaveDirectorSuccessCallback = null,
    handleSaveDirectorFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveCorporateTrusteeDirectorRequest' });

    const { trustId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.POST_CREATE_CORPORATE_TRUST_DIRECTOR.replace(
        '{trustId}',
        trustId
      ),
      method: 'POST',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'postSaveCorporateTrusteeDirectorRequestSuccess',
        payload: response.data,
      });
      const message = `Director is saved successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveDirectorSuccessCallback && handleSaveDirectorSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'postSaveCorporateTrusteeDirectorRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveDirectorFailedCallback && handleSaveDirectorFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Update corporate director
 * @param {*} requestDetails
 * @param {*} handleUpdateDirectorSuccessCallback
 * @param {*} handleUpdateDirectorFailedCallback
 * @returns
 */
export const requestUpdateCorporateTrusteeDirector =
  (
    requestDetails,
    handleUpdateDirectorSuccessCallback = null,
    handleUpdateDirectorFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateCorporateTrusteeDirectorRequest' });

    const { trustId, directorId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.PATCH_UPDATE_CORPORATE_TRUST_DIRECTOR.replace(
        '{trustId}',
        trustId
      ).replace('{directorId}', directorId),
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateCorporateTrusteeDirectorRequestSuccess',
        payload: response.data,
      });
      const message = `Director updated successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleUpdateDirectorSuccessCallback && handleUpdateDirectorSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateCorporateTrusteeDirectorRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateDirectorFailedCallback && handleUpdateDirectorFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete corporate trustee director
 * @param {*} requestDetails
 * @param {*} handleDeleteRequestSuccessCallback
 * @param {*} handleDeleteRequestFailedCallback
 * @returns
 */
export const requestDeleteCorporateTrusteeDirector =
  (
    requestDetails,
    handleDeleteRequestSuccessCallback = null,
    handleDeleteRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteCorporateTrusteeDirectorRequest' });

    const { trustId, directorId } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.DELETE_CORPORATE_TRUST_DIRECTOR.replace(
        '{trustId}',
        trustId
      ).replace('{directorId}', directorId),
      method: 'DELETE',
    };

    const successCallback = (response) => {
      dispatch({
        type: 'deleteCorporateTrusteeDirectorRequestSuccess',
        payload: response.data,
      });
      const message = `Director is deleted successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleDeleteRequestSuccessCallback && handleDeleteRequestSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'deleteCorporateTrusteeDirectorRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteRequestFailedCallback && handleDeleteRequestFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 *  Save Multiple Trust Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */

export const requestSaveMultipleTrust =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveMultipleTrustRequest' });

    const requestData = {
      url: API_URL.trust.trustProfile.POST_SAVE_MULTIPLE_TRUST_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveMultipleTrustSuccess', payload: response.data });
      const successMessage = `Clients have been saved successfully.`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveMultipleTrustFailed', payload: error });
      let message = error?.response?.data?.name || 'Clients&apos; Save has Failed';
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save corporate trustee shareholder
 * @param {*} requestDetails
 * @param {*} handleSaveShareholderSuccessCallback
 * @param {*} handleSaveShareholderFailedCallback
 * @returns
 */
export const requestSaveCorporateTrusteeShareholder =
  (
    requestDetails,
    handleSaveShareholderSuccessCallback = null,
    handleSaveShareholderFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveCorporateTrusteeShareholderRequest' });

    const { trustId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.POST_CREATE_CORPORATE_TRUST_SHAREHOLDER.replace(
        '{trustId}',
        trustId
      ),
      method: 'POST',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'postSaveCorporateTrusteeShareholderRequestSuccess',
        payload: response.data,
      });
      const message = `Shareholder is saved successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveShareholderSuccessCallback && handleSaveShareholderSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'postSaveCorporateTrusteeShareholderRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveShareholderFailedCallback && handleSaveShareholderFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Update corporate director
 * @param {*} requestDetails
 * @param {*} handleUpdateShareholderSuccessCallback
 * @param {*} handleUpdateShareholderFailedCallback
 * @returns
 */
export const requestUpdateCorporateTrusteeShareholder =
  (
    requestDetails,
    handleUpdateShareholderSuccessCallback = null,
    handleUpdateShareholderFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateCorporateTrusteeShareholderRequest' });

    const { trustId, directorId, requestBody } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.PATCH_UPDATE_CORPORATE_TRUST_SHAREHOLDER.replace(
        '{trustId}',
        trustId
      ).replace('{shareholderId}', directorId),
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateCorporateTrusteeShareholderRequestSuccess',
        payload: response.data,
      });
      const message = `Shareholder updated successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleUpdateShareholderSuccessCallback &&
        handleUpdateShareholderSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateCorporateTrusteeShareholderRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateShareholderFailedCallback && handleUpdateShareholderFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete corporate trustee shareholder
 * @param {*} requestDetails
 * @param {*} handleDeleteRequestSuccessCallback
 * @param {*} handleDeleteRequestFailedCallback
 * @returns
 */
export const requestDeleteCorporateTrusteeShareholder =
  (
    requestDetails,
    handleDeleteRequestSuccessCallback = null,
    handleDeleteRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteCorporateTrusteeShareholderRequest' });

    const { trustId, shareholderId } = requestDetails;

    const requestData = {
      url: API_URL.trust.trustProfile.DELETE_CORPORATE_TRUST_SHAREHOLDER.replace(
        '{trustId}',
        trustId
      ).replace('{shareholderId}', shareholderId),
      method: 'DELETE',
    };

    const successCallback = (response) => {
      dispatch({
        type: 'deleteCorporateTrusteeShareholderRequestSuccess',
        payload: response.data,
      });
      const message = `Shareholder is deleted successfully!`;
      Store.dispatch(handleNotification(message, 'success'));
      handleDeleteRequestSuccessCallback && handleDeleteRequestSuccessCallback(response?.data);
    };

    const errorCallback = (error) => {
      dispatch({ type: 'deleteCorporateTrusteeShareholderRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteRequestFailedCallback && handleDeleteRequestFailedCallback();
    };

    http_request(requestData, successCallback, errorCallback);
  };

/**
 * filter Trust  Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestFilterTrust =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postFilterTrustRequest' });

    const requestData = {
      url: API_URL.trust.trustClientList.FILTER_TRUST,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postFilterTrustSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postFilterTrustFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * add linked entities trust
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const addLinkedEntitiesTrust =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'addLinkedEntitiesTrustRequest' });

    const requestData = {
      url: API_URL.trust.trustClientList.ADD_LINKED_ENTITIES,
      method: 'PATCH',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({ type: 'addLinkedEntitiesTrustSuccess', payload: response.data });
      const requestDetail = {
        trustId: requestBody.trustId,
      };
      handleSaveClientSuccessCallback();
      !requestBody?.isNotGetting && dispatch(requestTrustClientDetail(requestDetail));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'addLinkedEntitiesTrustFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback?.(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * delete linked entities trust
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const deleteLinkedEntitiesTrust =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteLinkedEntitiesTrustRequest' });

    const requestData = {
      url: API_URL.trust.trustClientList.DELETE_LINKED_ENTITIES,
      method: 'DELETE',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteLinkedEntitiesTrustSuccess', payload: response.data });
      const requestDetail = {
        trustId: requestBody.trustId,
      };
      dispatch(requestTrustClientDetail(requestDetail));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteLinkedEntitiesTrustFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback?.(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
