import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //get document templates
  isGetDocumentTemplatesRequestLoading: false,
  documentTemplatesGetRequestSuccessData: [],
  documentTemplatesGetRequestErrorData: null,

  //create document templates
  isCreateDocumentTemplateRequestLoading: false,
  documentTemplateCreateRequestSuccessData: null,
  documentTemplateCreateRequestErrorData: null,

  //update document templates
  isUpdateDocumentTemplateRequestLoading: false,
  documentTemplateUpdateRequestSuccessData: null,
  documentTemplateUpdateRequestErrorData: null,

  isDeleteDocumentTemplateRequestLoading: false,
  documentTemplateDeleteRequestSuccessData: null,
  documentTemplateDeleteRequestFailedData: null,

  //get document templates
  isGetDocumentCategoriesRequestLoading: false,
  documentCategoriesGetRequestSuccessData: [],
  documentCategoriesGetRequestErrorData: null,
  
  //create document template category
  isCreateDocumentCategoryRequestLoading: false,
  documentCategoryCreateRequestSuccessData: null,
  documentCategoryCreateRequestErrorData: null,

  //update document template category
  isUpdateDocumentCategoryRequestLoading: false,
  documentCategoryUpdateRequestSuccessData: null,
  documentCategoryUpdateRequestErrorData: null,
};

export const documentTemplateReducer = createReducer(initialState, {
  // get document templates
  getDocumentTemplatesRequest: (state) => {
    state.isGetDocumentTemplatesRequestLoading = true;
    state.documentTemplatesGetRequestSuccessData = [];
    state.documentTemplatesGetRequestErrorData = null;
  },
  getDocumentTemplatesRequestSuccess: (state, action) => {
    state.isGetDocumentTemplatesRequestLoading = false;
    state.documentTemplatesGetRequestSuccessData = action.payload;
    state.documentTemplatesGetRequestErrorData = null;
  },
  getDocumentTemplatesRequestFailed: (state, action) => {
    state.isGetDocumentTemplatesRequestLoading = false;
    state.documentTemplatesGetRequestSuccessData = [];
    state.documentTemplatesGetRequestErrorData = action.payload;
  },

  //create document template
  createDocumentTemplateRequest: (state) => {
    state.isCreateDocumentTemplateRequestLoading = true;
    state.documentTemplateCreateRequestSuccessData = null;
    state.documentTemplateCreateRequestErrorData = null;
  },
  createDocumentTemplateRequestSuccess: (state, action) => {
    state.isCreateDocumentTemplateRequestLoading = false;
    state.documentTemplateCreateRequestSuccessData = action.payload;
    state.documentTemplateCreateRequestErrorData = null;
  },
  createDocumentTemplateRequestFailed: (state, action) => {
    state.isCreateDocumentTemplateRequestLoading = false;
    state.documentTemplateCreateRequestSuccessData = null;
    state.documentTemplateCreateRequestErrorData = action.payload;
  },

  //update document template
  updateDocumentTemplateRequest: (state) => {
    state.isUpdateDocumentTemplateRequestLoading = true;
    state.documentTemplateUpdateRequestSuccessData = null;
    state.documentTemplateUpdateRequestErrorData = null;
  },
  updateDocumentTemplateRequestSuccess: (state, action) => {
    state.isUpdateDocumentTemplateRequestLoading = false;
    state.documentTemplateUpdateRequestSuccessData = action.payload;
    state.documentTemplateUpdateRequestErrorData = null;
  },
  updateDocumentTemplateRequestFailed: (state, action) => {
    state.isUpdateDocumentTemplateRequestLoading = false;
    state.documentTemplateUpdateRequestSuccessData = null;
    state.documentTemplateUpdateRequestErrorData = action.payload;
  },

  //delete document template
  deleteDocumentTemplateRequest: (state) => {
    state.isDeleteDocumentTemplateRequestLoading = true;
    state.documentTemplateDeleteRequestSuccessData = null;
    state.documentTemplateDeleteRequestFailedData = null;
  },
  deleteDocumentTemplateRequestSuccess: (state, action) => {
    state.isDeleteDocumentTemplateRequestLoading = false;
    state.documentTemplateDeleteRequestSuccessData = action.payload;
    state.documentTemplateDeleteRequestFailedData = null;
  },
  deleteDocumentTemplateRequestFailed: (state, action) => {
    state.isDeleteDocumentTemplateRequestLoading = false;
    state.documentTemplateDeleteRequestSuccessData = null;
    state.documentTemplateDeleteRequestFailedData = action.payload;
  },

  //get document categories request
  getDocumentCategoriesRequest: (state) => {
    state.isGetDocumentCategoriesRequestLoading = true
    state.documentCategoriesGetRequestSuccessData = []
    state.documentCategoriesGetRequestErrorData = null
  },
  getDocumentCategoriesRequestSuccess: (state, action) => {
    state.isGetDocumentCategoriesRequestLoading = false
    state.documentCategoriesGetRequestSuccessData = action.payload
    state.documentCategoriesGetRequestErrorData = null
  },
  getDocumentCategoriesRequestFailed: (state, action) => {
    state.isGetDocumentCategoriesRequestLoading = false
    state.documentCategoriesGetRequestSuccessData = []
    state.documentCategoriesGetRequestErrorData = action.payload
  },

  //create document category request
  createDocumentCategoryRequest: (state) => {
    state.isCreateDocumentCategoryRequestLoading = true;
    state.documentCategoryCreateRequestSuccessData = null;
    state.documentCategoryCreateRequestErrorData = null;
  },
  createDocumentCategoryRequestSuccess: (state, action) => {
    state.isCreateDocumentCategoryRequestLoading = false;
    state.documentCategoryCreateRequestSuccessData = action.payload;
    state.documentCategoryCreateRequestErrorData = null;
  },
  createDocumentCategoryRequestFailed: (state, action) => {
    state.isCreateDocumentCategoryRequestLoading = false;
    state.documentCategoryCreateRequestSuccessData = null;
    state.documentCategoryCreateRequestErrorData = action.payload;
  },

  //update document category request
  updateDocumentCategoryRequest: (state) => {
    state.isUpdateDocumentCategoryRequestLoading = true;
    state.documentCategoryUpdateRequestSuccessData = null;
    state.documentCategoryUpdateRequestErrorData = null;
  },
  updateDocumentCategoryRequestSuccess: (state, action) => {
    state.isUpdateDocumentCategoryRequestLoading = false;
    state.documentCategoryUpdateRequestSuccessData = action.payload;
    state.documentCategoryUpdateRequestErrorData = null;
  },
  updateDocumentCategoryRequestFailed: (state, action) => {
    state.isUpdateDocumentCategoryRequestLoading = false;
    state.documentCategoryUpdateRequestSuccessData = null;
    state.documentCategoryUpdateRequestErrorData = action.payload;
  },
});
