import React from 'react';
import { Button, Box } from '@mui/material';

const PrimaryButton = (props) => {
  const { label, icon, onClick, disabled, size, sx } = props;

  return (
    <Button
      variant="contained"
      color={'secondary'}
      size={size || 'small'}
      startIcon={
        <Box display={'flex'} alignItems={'center'}>
          {icon}
        </Box>
      }
      sx={sx ? sx : null}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default PrimaryButton;
