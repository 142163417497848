import React, { useCallback } from 'react';

import { Grid, Typography, Paper, Stack, Tooltip, Button, Chip } from '@mui/material';

import { useCommonStyles } from 'styles/styles';
import { gridSpacing } from 'store/constant';

const TemplateListItem = (props) => {
  const { templateDetail, handleAssignTemplate } = props;

  const { classes: commonClasses } = useCommonStyles();

  /**
   * Format statistic
   * @param {*} value
   * @returns
   */
  const formatStatistic = (value) => {
    return (value.toString && value.toString().padStart(2, '0')) || '00';
  };

  const handleOnClickAssignTemplate = useCallback(
    (event) => {
      handleAssignTemplate && handleAssignTemplate(event, templateDetail);
    },
    [templateDetail]
  );

  return (
    <Paper sx={{ padding: '1rem' }} variant="outlined">
      <Grid container item xs={12} spacing={gridSpacing}>
        <Grid item xs={'auto'} container>
          <Stack
            direction="column"
            alignItems={'flex-start'}
            justifyContent="center"
            spacing={gridSpacing}
          >
            <Grid container direction="row" alignItems="center">
              <Typography className={commonClasses.profileName1}>
                {templateDetail?.templateTitle}
              </Typography>
            </Grid>
            {Array.isArray(templateDetail?.tags) && !!templateDetail?.tags?.length && (
              <Stack spacing={1} direction="row">
                {templateDetail.tags.map((tagDetail) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    color="default"
                    label={tagDetail?.tagName || ''}
                    sx={{
                      fontSize: 10,
                      height: 18,
                      '& .MuChip-label': {
                        paddingLeft: '0.25rem !important',
                        paddingRight: '0.25rem !important',
                      },
                    }}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={true} />
        <Grid container item xs={'auto'} alignItems={'center'} justifyContent="center">
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography
              variant="caption"
              style={{ fontSize: '0.775rem', lineHeight: '0.875rem', textAlign: 'center' }}
            >
              {templateDetail?.countOfUses
                ? `Used ${formatStatistic(templateDetail.countOfUses)} times`
                : ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={'auto'} alignItems={'center'} justifyContent={'flex-end'}>
          <Stack direction="row" spacing={2}>
            <Tooltip title="Edit">
              <Button
                id={`assign-btn-${templateDetail?._id}`}
                variant="contained"
                color="primary"
                onClick={handleOnClickAssignTemplate}
              >
                Assign
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TemplateListItem;
