import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format, isDate } from 'date-fns';

import {
  Avatar,
  CardActionArea,
  Chip,
  Fade,
  Grid,
  Icon,
  Skeleton,
  SvgIcon,
  Typography,
  Stack,
} from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

import { gridSpacing } from 'store/constant';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ReactComponent as DateOfBirthIcon } from 'assets/images/icons/date-of-birth.svg';

import { abnPattern, tfnPattern, acnPattern } from 'utils/numberPattern';

const BasicDetailsCard = () => {
  /**
   * Router Navigation
   */
  const navigate = useNavigate();
  /**
   * Trust Client Detail store
   */
  const companyClientReducer = useSelector((state) => state?.companyClientReducer);
  const selectedCompanyClientDetail = companyClientReducer?.selectedCompanyClientDetail;
  const isCompanyClientDetailRequestLoading =
    companyClientReducer?.isCompanyClientDetailRequestLoading;

  /**
   * Handle edit trust details   * @param {*} event
   */
  const handleEditTrustDetails = (event) => {
    navigate('/main/create-company', {
      state: { isEditMode: true, editingData: selectedCompanyClientDetail },
    });
  };
  /**
   * Get Company Address
   * @returns companyAddress
   */
  const getCompanyAddress = (addressData) => {
    let clientAddress = 'N/A';
    if (addressData?.address) {
      let addresses = [
        addressData?.address,
        addressData?.suburb,
        addressData?.state,
        addressData?.postcode,
      ];
      addresses = addresses.filter((addressDetail) => !!addressDetail);
      clientAddress = addresses.join(', ');
    }
    return clientAddress;
  };

  /**
   *
   * handle show tfn
   */
  const [isShowTFN, setIsShowTFN] = React.useState(false);

  const handleShowTFN = () => {
    setIsShowTFN((pre) => !pre);
  };

  return (
    <MainCard
      contentSX={{
        padding: '0 !important',
      }}
    >
      <CardActionArea
        disabled={!selectedCompanyClientDetail?._id}
        sx={{ padding: '1.5rem', position: 'relative' }}
      >
        <Avatar
          sx={{
            width: '3.125rem',
            height: '3.125rem',
            position: 'absolute',
            right: '0.75rem',
            top: '0.75rem',
            background: (theme) => theme.palette?.background?.paper,
          }}
          src={require('assets/images/dashboard/companyImg.png')}
        />
        <Fade in={selectedCompanyClientDetail?.isSpecialPurposeCompany}>
          <Chip
            size="small"
            label="Special Purpose Company"
            variant="outlined"
            color="primary"
            sx={{ fontSize: '0.75rem', position: 'absolute', right: '4rem', top: '1.5rem' }}
          />
        </Fade>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={gridSpacing}>
              <Grid item xs={12} onClick={handleEditTrustDetails}>
                <Typography
                  sx={(theme) => ({
                    fontSize: '1rem',
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.text.primary,
                    cursor: 'pointer',
                  })}
                >
                  Company Details
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} onClick={handleEditTrustDetails}>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.grey?.[800],
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: '0.875rem',
                  })}
                >
                  Company Name:{' '}
                  {isCompanyClientDetailRequestLoading ? (
                    <Skeleton
                      variant="text"
                      component="span"
                      sx={{ display: 'inline-block', width: '22ch' }}
                    />
                  ) : (
                    selectedCompanyClientDetail?.name || '  '
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} onClick={handleEditTrustDetails}>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.grey?.[800],
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: '0.875rem',
                  })}
                >
                  Address:{' '}
                  {isCompanyClientDetailRequestLoading ? (
                    <Skeleton
                      variant="text"
                      component="span"
                      sx={{ display: 'inline-block', width: '22ch' }}
                    />
                  ) : (
                    getCompanyAddress(selectedCompanyClientDetail?.address || {})
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} onClick={handleEditTrustDetails}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs="auto">
                    <SvgIcon viewBox="0 0 20 20" component={DateOfBirthIcon} />
                  </Grid>
                  <Grid item xs={true}>
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey?.[800],
                        fontWeight: theme.typography.fontWeightBold,
                        fontSize: '0.875rem',
                        overflow: 'hidden',
                        whiteSpace: 'pre',
                        textOverflow: 'ellipsis',
                      })}
                    >
                      Established:{' '}
                      {isCompanyClientDetailRequestLoading ? (
                        <Skeleton
                          variant="text"
                          component="span"
                          sx={{ display: 'inline-block', width: '13ch' }}
                        />
                      ) : (
                        (selectedCompanyClientDetail?.established &&
                          isDate(new Date(selectedCompanyClientDetail?.established)) &&
                          format(
                            new Date(selectedCompanyClientDetail?.established),
                            'yyyy-MM-dd'
                          )) ||
                        'N/A'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs="auto">
                    <Icon
                      sx={{
                        border: 1,
                        borderRadius: '0.25rem',
                      }}
                    >
                      <SvgIcon
                        viewBox="0 0 26 24"
                        sx={{ background: (theme) => theme.palette?.blue?.[600] }}
                      >
                        <svg
                          viewBox="0 0 26 24"
                          xmlns="http://www.w3.org/2000/svg"
                          background="currentColor"
                        >
                          <text x="4" y="15" fontSize="0.5rem" color="white">
                            TFN
                          </text>
                        </svg>
                      </SvgIcon>
                    </Icon>
                  </Grid>
                  <Grid item xs={true}>
                    <Stack direction="row" alignItems={'center'} spacing={2}>
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.grey?.[800],
                          fontWeight: theme.typography.fontWeightBold,
                          fontSize: '0.875rem',
                          overflow: 'hidden',
                          whiteSpace: 'pre',
                          textOverflow: 'ellipsis',
                        })}
                      >
                        {isCompanyClientDetailRequestLoading ? (
                          <Skeleton
                            variant="text"
                            component="span"
                            sx={{ display: 'inline-block', width: '12ch' }}
                          />
                        ) : selectedCompanyClientDetail?.isSpecialPurposeCompany ? (
                          'Special Purpose Company'
                        ) : isShowTFN ? (
                          tfnPattern(selectedCompanyClientDetail?.tfn)
                        ) : (
                          '*** *** ***'
                        )}
                      </Typography>

                      {isShowTFN ? (
                        <VisibilityOff
                          onClick={handleShowTFN}
                          sx={{
                            cursor: 'pointer',
                          }}
                        />
                      ) : (
                        <Visibility
                          onClick={handleShowTFN}
                          sx={{
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} onClick={handleEditTrustDetails}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs="auto">
                    <Icon
                      sx={{
                        border: 1,
                        borderRadius: '0.25rem',
                        background: (theme) => theme.palette?.blue?.[600],
                      }}
                    >
                      <SvgIcon
                        viewBox="0 0 26 24"
                        sx={{ background: (theme) => theme.palette?.blue?.[600] }}
                      >
                        <svg
                          viewBox="0 0 26 24"
                          xmlns="http://www.w3.org/2000/svg"
                          background="currentColor"
                        >
                          <text x="4" y="15" fontSize="0.5rem" color="white">
                            ABN
                          </text>
                        </svg>
                      </SvgIcon>
                    </Icon>
                  </Grid>
                  <Grid item xs={true}>
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey?.[800],
                        fontWeight: theme.typography.fontWeightBold,
                        fontSize: '0.875rem',
                        overflow: 'hidden',
                        whiteSpace: 'pre',
                        textOverflow: 'ellipsis',
                        textTransform: 'capitalize',
                      })}
                    >
                      {isCompanyClientDetailRequestLoading ? (
                        <Skeleton
                          variant="text"
                          component="span"
                          sx={{ display: 'inline-block', width: '8ch' }}
                        />
                      ) : selectedCompanyClientDetail?.isSpecialPurposeCompany ? (
                        'Special Purpose Company'
                      ) : (
                        abnPattern(selectedCompanyClientDetail?.abn)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} onClick={handleEditTrustDetails}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs="auto">
                    <Icon
                      sx={{
                        border: 1,
                        borderRadius: '0.25rem',
                      }}
                    >
                      <SvgIcon
                        viewBox="0 0 26 24"
                        sx={{ background: (theme) => theme.palette?.blue?.[600] }}
                      >
                        <svg
                          viewBox="0 0 26 24"
                          xmlns="http://www.w3.org/2000/svg"
                          background="currentColor"
                        >
                          <text x="4" y="15" fontSize="0.5rem" color="white">
                            ACN
                          </text>
                        </svg>
                      </SvgIcon>
                    </Icon>
                  </Grid>
                  <Grid item xs={true}>
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey?.[800],
                        fontWeight: theme.typography.fontWeightBold,
                        fontSize: '0.875rem',
                        overflow: 'hidden',
                        whiteSpace: 'pre',
                        textOverflow: 'ellipsis',
                      })}
                    >
                      {isCompanyClientDetailRequestLoading ? (
                        <Skeleton
                          variant="text"
                          component="span"
                          sx={{ display: 'inline-block', width: '11ch' }}
                        />
                      ) : (
                        acnPattern(selectedCompanyClientDetail?.acn)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </MainCard>
  );
};

export default BasicDetailsCard;
