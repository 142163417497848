import React, { memo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  IconButton,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import IndividualSearch from './components/IndividualSearch';
import CompanySearch from './components/CompanySearch';
import SmsfSearch from './components/SmsfSearch';
import TrustSearch from './components/TrustSearch';
import PartnershipSearch from './components/PartnershipSearch';

import { useCommonStyles } from 'styles/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GlobalSearch = (props) => {
  const { openDialog, handleCloseDialog } = props;

  /**
   * Uses common classes
   */
  const { classes: commonClasses } = useCommonStyles();

  const [value, setValue] = React.useState('Individual/Couple');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="new-goal-dialog-"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle className={commonClasses.modalTitleBox}>
        <Typography className={commonClasses.modalTitle}>What are you looking for?</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          height: '25rem',
        }}
        className={commonClasses.modalContent}
      >
        <Grid container spacing={2}>
          <Grid container item sx={12}>
            <FormControl
              sx={{
                marginTop: '2rem',
              }}
            >
              <FormLabel id="demo-controlled-radio-buttons-group">Types</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Individual/Couple"
                  control={<Radio />}
                  label="Individual/Couple"
                />
                <FormControlLabel value="SMSF" control={<Radio />} label="SMSF" />
                <FormControlLabel value="Company" control={<Radio />} label="Company" />
                <FormControlLabel value="Trust" control={<Radio />} label="Trust" />
                <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {value === 'Individual/Couple' && (
            <Grid container item sx={12}>
              <IndividualSearch handleCloseDialog={handleCloseDialog} />
            </Grid>
          )}
          {value === 'SMSF' && (
            <Grid container item sx={12}>
              <SmsfSearch handleCloseDialog={handleCloseDialog} />
            </Grid>
          )}
          {value === 'Company' && (
            <Grid container item sx={12}>
              <CompanySearch handleCloseDialog={handleCloseDialog} />
            </Grid>
          )}
          {value === 'Trust' && (
            <Grid container item sx={12}>
              <TrustSearch handleCloseDialog={handleCloseDialog} />
            </Grid>
          )}
          {value === 'Partnership' && (
            <Grid container item sx={12}>
              <PartnershipSearch handleCloseDialog={handleCloseDialog} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(GlobalSearch);
