import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    //Person Identities save
    personIdentitiesSaveProcessing: false,
    personIdentitiesSavedData: {},
    personIdentitiesSaveFailedData: {},

    //person identification us citizenship status update
    personUSCitizenshipUpdateProcessing: false,
    personUSCitizenshipUpdatedData: {},
    personUSCitizenshipUpdateFailedData: {},

    //get all identification documents
    isGetAllIdentificationDocumentRequestLoading: false,
    identificationDocumentsGetAllRequestSuccessData: {},
    identificationDocumentsGetAllRequestFailedData: {},
};

export const personIdentitiesReducer = createReducer(initialState, {
    //Person Identities save
    postSavePersonIdentitiesRequest: (state) => {
        state.personIdentitiesSaveProcessing = true;
        state.personIdentitiesSavedData = {};
        state.personIdentitiesSaveFailedData = {};
    },
    postSavePersonIdentitiesRequestSuccess: (state, action) => {
        state.personIdentitiesSaveProcessing = false;
        state.personIdentitiesSavedData = action.payload;
        state.personIdentitiesSaveFailedData = {};
    },
    postSavePersonIdentitiesRequestFailed: (state, action) => {
        state.personIdentitiesSaveProcessing = false;
        state.personIdentitiesSavedData = {};
        state.personIdentitiesSaveFailedData = action.payload;
    },

    //person identification or us citizenship status update
    putUpdatePersonUSCitizenshipRequest: (state) => {
        state.personUSCitizenshipUpdateProcessing = true;
        state.personUSCitizenshipUpdatedData = {};
        state.personUSCitizenshipUpdateFailedData = {};
    },
    putUpdatePersonUSCitizenshipRequestSuccess: (state, action) => {
        state.personUSCitizenshipUpdateProcessing = false;
        state.personUSCitizenshipUpdatedData = action.payload;
        state.personUSCitizenshipUpdateFailedData = {};
    },
    putUpdatePersonUSCitizenshipRequestFailed: (state, action) => {
        state.personUSCitizenshipUpdateProcessing = false;
        state.personUSCitizenshipUpdatedData = {};
        state.personUSCitizenshipUpdateFailedData = action.payload;
    },

    //get all documents
    getAllIdentificationDocumentsRequest: (state) => {
        state.isGetAllIdentificationDocumentRequestLoading = true;
        state.identificationDocumentsGetAllRequestSuccessData = {};
        state.identificationDocumentsGetAllRequestFailedData = {};
    },
    getAllIdentificationDocumentsRequestSuccess: (state, action) => {
        state.isGetAllIdentificationDocumentRequestLoading = false;
        state.identificationDocumentsGetAllRequestSuccessData = action.payload;
        state.identificationDocumentsGetAllRequestFailedData = {};
    },
    getAllIdentificationDocumentsRequestFailed: (state, action) => {
        state.isGetAllIdentificationDocumentRequestLoading = false;
        state.identificationDocumentsGetAllRequestSuccessData = {};
        state.identificationDocumentsGetAllRequestFailedData = action.payload;
    }
});
