import CommonModal from 'common/material/CommonModal';
import { useSelector } from 'react-redux';
import ReAuthenticationForm from './ReAuthenticationForm';

const ReAuthenticateModal = () => {
  const { isReAuthenticateModal, reAuthenticationData } = useSelector(
    (state) => state.authenticationReducer
  );

  return (
    <CommonModal
      open={isReAuthenticateModal}
      dialogTitle="Please Confirm Login Detail"
      dialogContent={<ReAuthenticationForm />}
      fullWidth
    />
  );
};

export default ReAuthenticateModal;
