import { useFormikContext } from 'formik';
import { isEmpty, isNil } from 'ramda';

import ConfirmationModal from 'common/ConfirmationModal';

import * as deleteAnimationData from 'assets/animation/delete.json';

const RemoveTemplateQuestionConfirmationModal = (props) => {
  const {
    isOpened,
    questionnaireData,
    actionDataIndex,
    handleDialogOpened,
    handleOnRemoveQuestionSuccess,
  } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  
  /**
   * Handle remove question
   */
  const handleRemoveQuestion = () => {
    let questionnaireData = [...(formikContext?.values?.questionnaires || [])];
    if (
      !isNil(questionnaireData) &&
      !isEmpty(questionnaireData) &&
      Array.isArray(questionnaireData) &&
      !isNil(questionnaireData?.[actionDataIndex]) &&
      !isEmpty(questionnaireData?.[actionDataIndex])
    ) {
      questionnaireData.splice(actionDataIndex, 1);
      questionnaireData = questionnaireData.filter((questionData) => !!questionData?.component);

      formikContext?.setFieldValue &&
        formikContext.setFieldValue('questionnaires', questionnaireData);
      formikContext?.setFieldTouched && formikContext.setFieldTouched('questionnaires', true);

      setTimeout(() => {
        handleOnRemoveQuestionSuccess && handleOnRemoveQuestionSuccess();
        handleDialogOpened && handleDialogOpened(false);
      }, 0);
    }
  };

  return (
    <ConfirmationModal
      isConfirmationModal={isOpened}
      closeConfirmationAction={() => handleDialogOpened(false)}
      modalConfirmAction={handleRemoveQuestion}
      confirmationModalHeader={'Remove Question'}
      confirmationModalContent={'Do you want to remove this Question ?'}
      img={deleteAnimationData}
      loading={questionnaireData?.loading}
    />
  );
};

export default RemoveTemplateQuestionConfirmationModal;
