import React from 'react';
import { Button, Box } from '@mui/material';

const SecondaryButton = (props) => {
  const { label, icon, onClick, size } = props;
  // #06aed4
  return (
    <Button
      variant="outlined"
      color={'secondary'}
      size={size || 'small'}
      startIcon={
        <Box display={'flex'} alignItems={'center'}>
          {icon}
        </Box>
      }
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default SecondaryButton;
