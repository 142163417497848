import * as React from 'react';
import { Field } from 'formik';
import { Box, Stack } from '@mui/material';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RadioGroup } from 'formik-mui';

const FormikRadioGroup = (props) => {
  const { name, direction, dataSet, controlMargin, spacing } = props;

  return (
    <Box>
      <Field component={RadioGroup} name={name} row={true}>
        <Stack direction={direction} spacing={spacing || 2}>
          {dataSet &&
            dataSet.map((data, index) => (
              <FormControlLabel
                key={index}
                value={data.value}
                control={<Radio disabled={data.disabled} />}
                label={data.label}
                disabled={data.disabled}
                sx={{ margin: controlMargin }}
              />
            ))}
        </Stack>
      </Field>
    </Box>
  );
};

export default FormikRadioGroup;
