import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //client invitation template
  clientInvitationTemplateRequestLoading: false,
  clientInvitationTemplateRequestSuccess: {},
  clientInvitationTemplateRequestFailed: {},

  //client invitation
  clientInvitationRequestLoading: false,
  clientInvitationRequestSuccess: {},
  clientInvitationRequestFailed: {},
};

/**
 * Client Invitation reducer
 */
export const clientInvitationReducer = createReducer(initialState, {
  //invitation template
  getClientInvitationTemplateRequest: (state) => {
    state.clientInvitationTemplateRequestLoading = true;
    state.clientInvitationTemplateRequestSuccess = {};
    state.clientInvitationTemplateRequestFailed = {};
  },
  getClientInvitationTemplateRequestSuccess: (state, action) => {
    state.clientInvitationTemplateRequestLoading = false;
    state.clientInvitationTemplateRequestSuccess = action.payload;
    state.clientInvitationTemplateRequestFailed = {};
  },
  getClientInvitationTemplateRequestFailed: (state, action) => {
    state.clientInvitationTemplateRequestLoading = false;
    state.clientInvitationTemplateRequestSuccess = {};
    state.clientInvitationTemplateRequestFailed = action.payload;
  },
  //client invitation request
  postInviteClientRequest: (state) => {
    state.clientInvitationRequestLoading = true;
    state.clientInvitationRequestSuccess = {};
    state.clientInvitationRequestFailed = {};
  },
  postInviteClientRequestSuccess: (state, action) => {
    state.clientInvitationRequestLoading = false;
    state.clientInvitationRequestSuccess = action.payload;
    state.clientInvitationRequestFailed = {};
  },
  postInviteClientRequestFailed: (state, action) => {
    state.clientInvitationRequestLoading = false;
    state.clientInvitationRequestSuccess = {};
    state.clientInvitationRequestFailed = action.payload;
  },
});
