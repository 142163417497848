import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // create
  saveRequestLoading: false,
  saveData: {},
  saveFailed: {},

  // get all
  getRequestLoading: false,
  getListData: null,
  getListDataFailed: null,
};

export const smsfCashflowReducer = createReducer(initialState, {
  //save
  postSmsfCashflowRequest: (state) => {
    state.saveRequestLoading = true;
    state.saveData = {};
    state.saveFailed = {};
  },
  postSmsfCashflowSuccess: (state, action) => {
    state.saveRequestLoading = false;
    state.saveData = action.payload;
    state.saveFailed = {};
  },
  postSmsfCashflowFailed: (state, action) => {
    state.saveRequestLoading = false;
    state.saveData = {};
    state.saveFailed = action.payload;
  },

  //get all
  getSmsfCashflowRequest: (state) => {
    state.getRequestLoading = true;
    state.getListData = null;
    state.getListDataFailed = {};
  },
  getSmsfCashflowSuccess: (state, action) => {
    state.getRequestLoading = false;
    state.getListData = action.payload;
    state.getListDataFailed = {};
  },
  getSmsfCashflowFailed: (state, action) => {
    state.getRequestLoading = false;
    state.getListData = null;
    state.getListDataFailed = action.payload;
  },
});
