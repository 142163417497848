import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const isTokenAvailable = useSelector((state) => !!state.authenticationReducer?.loginData?.token);

  return (
    <>
      {isTokenAvailable && <Navigate to="main/newDashboard" replace={true} />}
      <Outlet />
      {/* <Customization /> */}
    </>
  );
};

export default MinimalLayout;
