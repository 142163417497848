import { Button, ButtonGroup, Fade, Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import { gridSpacing } from 'store/constant';

const QuestionBuilderFieldPopoverContent = (props) => {
  const {
    popperData,
    questionnaireRef,
    leadDetails,
    hasPartner,
    partnerDetails,
    handlePopoverClose,
    isSmallScreens,
    clientType,
  } = props;
  /**
   * Uses formik context
   */
  const { values: fieldValues, setFieldValue } = useFormikContext();
  /**
   * Handle field type change
   * @param {*} fieldType
   */
  const handleClickOnFieldType = (fieldType) => {
    let questionnaireArray = [...(fieldValues?.questionnaires || [])];
    let questionData = questionnaireArray?.[popperData?.dataIndex];
    if (questionData && typeof questionData === 'object') {
      questionnaireArray[popperData.dataIndex] = {
        ...(questionData || {}),
        fieldType,
        ...(fieldType === 'multipleFormat' ? { isRequired: false } : {}),
      };
      questionnaireArray = questionnaireArray.filter((questionData) => !!questionData?.component);

      setFieldValue('questionnaires', questionnaireArray);
      if (questionnaireRef?.current) {
        questionnaireRef.current = { ...{} };
      }
    }
    handlePopoverClose && handlePopoverClose();
  };
  /**
   * Handle person type change
   * @param {*} personType
   */
  const handleClickOnPersonType = (personType) => {
    let questionnaireArray = [...(fieldValues?.questionnaires || [])];
    let questionData = questionnaireArray?.[popperData?.dataIndex];
    if (questionData && typeof questionData === 'object') {
      questionnaireArray[popperData.dataIndex] = {
        ...(questionData || {}),
        personType,
      };
      questionnaireArray = questionnaireArray.filter((questionData) => !!questionData?.component);

      setFieldValue('questionnaires', questionnaireArray);
      if (questionnaireRef?.current) {
        questionnaireRef.current = { ...{} };
      }
    }
    handlePopoverClose && handlePopoverClose();
  };
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Fade in={popperData?.action === 'EDIT_FIELD_TYPE'} unmountOnExit>
          <ButtonGroup
            variant="contained"
            aria-label="button group"
            orientation={isSmallScreens ? 'vertical' : 'horizontal'}
          >
            <Button
              size="small"
              sx={{ fontSize: 'small' }}
              onClick={(event) => handleClickOnFieldType('boolean')}
            >
              Yes/No answer
            </Button>
            <Button
              size="small"
              sx={{ fontSize: 'small' }}
              onClick={(event) => handleClickOnFieldType('booleanAndText')}
            >
              Yes/No with Text answer
            </Button>
            <Button
              size="small"
              sx={{ fontSize: 'small' }}
              onClick={(event) => handleClickOnFieldType('text')}
            >
              Text answer
            </Button>
            <Button
              size="small"
              sx={{ fontSize: 'small' }}
              onClick={(event) => handleClickOnFieldType('multipleTabular')}
            >
              Table
            </Button>
            <Button
              size="small"
              sx={{ fontSize: 'small' }}
              onClick={(event) => handleClickOnFieldType('multipleFormat')}
            >
              Multiple Answers
            </Button>
            <Button
              size="small"
              sx={{ fontSize: 'small' }}
              onClick={(event) => handleClickOnFieldType('signatureAgreement')}
            >
              Agreement/Signature
            </Button>
          </ButtonGroup>
        </Fade>
        <Fade in={popperData?.action === 'EDIT_PERSON_TYPE'} unmountOnExit>
          <ButtonGroup
            variant="contained"
            aria-label="button group"
            orientation={isSmallScreens ? 'vertical' : 'horizontal'}
          >
            <Button
              size="small"
              sx={{ fontSize: 'small' }}
              onClick={(event) => handleClickOnPersonType('CLIENT')}
            >
              {leadDetails?.firstName} ({clientType === 'LEAD' ? 'Lead' : 'Client'})
            </Button>
            {hasPartner ? (
              <>
                <Button
                  size="small"
                  sx={{ fontSize: 'small' }}
                  onClick={(event) => handleClickOnPersonType('PARTNER')}
                >
                  {partnerDetails?.firstName} (Partner)
                </Button>
                <Button
                  size="small"
                  sx={{ fontSize: 'small' }}
                  onClick={(event) => handleClickOnPersonType('BOTH')}
                >
                  Both
                </Button>
              </>
            ) : null}
          </ButtonGroup>
        </Fade>
      </Grid>
    </Grid>
  );
};

export default QuestionBuilderFieldPopoverContent;
