import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Field } from 'formik';
import { Box, FormControlLabel } from '@mui/material';
import { Switch } from 'formik-mui';

const LabeledToggleSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" size="large" {...props} />
))(({ theme }) => ({
  width: '8.875ch',
  height: '6ch',

  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    color: theme.palette?.background?.paper,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.675rem',
    backgroundColor: theme.palette?.blue?.[300],
    opacity: 1,
  },

  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(2.825ch)',
    color: theme.palette?.blue?.[25],
  },

  '& .MuiSwitch-track': {
    borderRadius: 42 / 2,
    color: theme.palette?.background?.paper,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.675rem',
    backgroundColor: theme.palette?.grey?.[800],
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-45%)',
    },
    width: '8ch',
    height: '4ch',
    '&:before': {
      content: '"Yes"',
      left: 16.5,
    },
    '&:after': {
      content: '"No"',
      right: 18.5,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 20,
    height: 20,
    marginBlock: 5,
    marginInline: 5,
  },
}));

const FormikLabeledToggleSwitch = (props) => {
  const { name, label, formControlLabelSX, ...otherProps } = props;
  return (
    <Box>
      <FormControlLabel
        control={<Field component={LabeledToggleSwitch} type="checkbox" name={name} />}
        label={<Box marginLeft={1}>{label}</Box>}
        sx={formControlLabelSX}
        {...otherProps}
      />
    </Box>
  );
};

FormikLabeledToggleSwitch.defaultProps = {
  formControlLabelSX: { marginLeft: '0 !important' },
};

export default FormikLabeledToggleSwitch;
