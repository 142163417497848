import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //search company by abn
  isSearchCompanyByABNRequestLoading: false,
  searchCompanyByABNRequestSuccessData: [],
  searchCompanyByABNRequestFailedData: null,

  //search company by name
  isSearchCompanyByNameRequestLoading: false,
  searchCompanyByNameRequestSuccessData: [],
  searchCompanyByNameRequestFailedData: null,

  //Create New Client [Company]
  saveCompanyClientRequestLoading: false,
  saveCompanyClientData: {},
  saveCompanyClientFailed: {},

  //Request Client List
  isCompanyClientListRequestLoading: false,
  companyClientListData: {},
  companyClientListFailed: {},

  //selected client detail
  isCompanyClientDetailRequestLoading: false,
  selectedCompanyClientDetail: {},
  selectedCompanyClientDetailFailed: {},

  //update Company Client detail
  isUpdateCompanyClientRequestLoading: false,
  updateCompanyClientData: {},
  updateCompanyClientFailed: {},

  // get company detail for company dashboard
  isCompanyClientDashboardStatDetailRequestLoading: false,
  selectedCompanyClientDashboardStatDetail: {},
  selectedCompanyClientDashboardStatDetailFailed: {},

  //save company director
  isSaveCompanyDirectorRequestLoading: false,
  saveCompanyDirectorRequestSuccessData: null,
  saveCompanyDirectorRequestFailedData: null,

  //update company director
  isUpdateCompanyDirectorRequestLoading: false,
  updateCompanyDirectorRequestSuccessData: null,
  updateCompanyDirectorRequestFailedData: null,

  //delete director
  isDeleteCompanyDirectorLoading: false,
  deleteCompanyDirectorSuccessData: null,
  deleteCompanyDirectorFailedData: null,

  //save company shareholder
  isSaveCompanyShareholderRequestLoading: false,
  saveCompanyShareholderRequestSuccessData: null,
  saveCompanyShareholderRequestFailedData: null,

  //update company shareholder
  isUpdateCompanyShareholderRequestLoading: false,
  updateCompanyShareholderRequestSuccessData: null,
  updateCompanyShareholderRequestFailedData: null,

  //delete shareholder
  isDeleteCompanyShareholderLoading: false,
  deleteCompanyShareholderSuccessData: null,
  deleteCompanyShareholderFailedData: null,

  //Create  Multiple Company
  saveMultipleCompanyClientRequestLoading: false,
  saveMultipleCompanyClientData: {},
  saveMultipleCompanyClientFailed: {},

  //filter Company
  filterDataCompanyLoading: false,
  filterDataCompanySuccess: [],
  filterDataCompanyFailed: {},

  //add Company Entities
  addLinkedEntitiesCompanyLoading: false,
  addLinkedEntitiesCompanySuccess: null,
  addLinkedEntitiesCompanyFailed: {},

  //delete Company Entities
  deleteLinkedEntitiesCompanyLoading: false,
  deleteLinkedEntitiesCompanySuccess: null,
  deleteLinkedEntitiesCompanyFailed: {},
};

export const companyClientReducer = createReducer(initialState, {
  getSearchCompanyByABNRequest: (state) => {
    state.isSearchCompanyByABNRequestLoading = true;
    state.searchCompanyByABNRequestSuccessData = [];
    state.searchCompanyByABNRequestFailedData = null;
  },
  getSearchCompanyByABNRequestSuccess: (state, action) => {
    state.isSearchCompanyByABNRequestLoading = false;
    state.searchCompanyByABNRequestSuccessData = action?.payload || [];
    state.searchCompanyByABNRequestFailedData = null;
  },
  getSearchCompanyByABNRequestFailed: (state, action) => {
    state.isSearchCompanyByABNRequestLoading = false;
    state.searchCompanyByABNRequestSuccessData = [];
    state.searchCompanyByABNRequestFailedData = action?.payload;
  },

  //search company by name
  getSearchCompanyByNameRequest: (state) => {
    state.isSearchCompanyByNameRequestLoading = true;
    state.searchCompanyByNameRequestSuccessData = [];
    state.searchCompanyByNameRequestFailedData = null;
  },
  getSearchCompanyByNameRequestSuccess: (state, action) => {
    state.isSearchCompanyByNameRequestLoading = false;
    state.searchCompanyByNameRequestSuccessData = action?.payload || [];
    state.searchCompanyByNameRequestFailedData = null;
  },
  getSearchCompanyByNameRequestFailed: (state, action) => {
    state.isSearchCompanyByNameRequestLoading = false;
    state.searchCompanyByNameRequestSuccessData = [];
    state.searchCompanyByNameRequestFailedData = action?.payload;
  },

  //save client
  postSaveCompanyClientRequest: (state) => {
    state.saveCompanyClientRequestLoading = true;
    state.saveCompanyClientData = {};
    state.saveCompanyClientFailed = {};
  },
  postSaveCompanyClientSuccess: (state, action) => {
    state.saveCompanyClientRequestLoading = false;
    state.saveCompanyClientData = action.payload;
    state.saveCompanyClientFailed = {};
  },
  postSaveCompanyClientFailed: (state, action) => {
    state.saveCompanyClientRequestLoading = false;
    state.saveCompanyClientData = {};
    state.saveCompanyClientFailed = action.payload;
  },

  //client List
  getAllCompanyClientsRequest: (state) => {
    state.isCompanyClientListRequestLoading = true;
    state.companyClientListData = {};
    state.companyClientListFailed = {};
  },
  getAllCompanyClientsSuccess: (state, action) => {
    state.isCompanyClientListRequestLoading = false;
    state.companyClientListData = action.payload;
    state.companyClientListFailed = {};
  },
  getAllCompanyClientsFailed: (state, action) => {
    state.isCompanyClientListRequestLoading = false;
    state.companyClientListData = {};
    state.companyClientListFailed = action.payload;
  },

  //find client detail
  getCompanyClientByIdRequest: (state) => {
    state.isCompanyClientDetailRequestLoading = true;
    state.selectedCompanyClientDetail = {};
    state.selectedCompanyClientDetailFailed = {};
  },
  getCompanyClientByIdRequestSuccess: (state, action) => {
    state.isCompanyClientDetailRequestLoading = false;
    state.selectedCompanyClientDetail = action.payload;
    state.selectedCompanyClientDetailFailed = {};
  },
  getCompanyClientByIdRequestFailed: (state, action) => {
    state.isCompanyClientDetailRequestLoading = false;
    state.selectedCompanyClientDetail = {};
    state.selectedCompanyClientDetailFailed = action.payload;
  },

  //update client
  patchUpdateCompanyClientRequest: (state) => {
    state.isUpdateCompanyClientRequestLoading = true;
    state.updateCompanyClientData = {};
    state.updateCompanyClientFailed = {};
  },
  patchUpdateCompanyClientRequestSuccess: (state, action) => {
    state.isUpdateCompanyClientRequestLoading = false;
    state.updateCompanyClientData = action.payload;
    state.updateCompanyClientFailed = {};
  },
  patchUpdateCompanyClientRequestFailed: (state, action) => {
    state.isUpdateCompanyClientRequestLoading = false;
    state.updateCompanyClientData = {};
    state.updateCompanyClientFailed = action.payload;
  },

  //find client detail
  getCompanyClientDashboardStatByIdRequest: (state) => {
    state.isCompanyClientDashboardStatDetailRequestLoading = true;
    state.selectedCompanyClientDashboardStatDetail = {};
    state.selectedCompanyClientDashboardStatDetailFailed = {};
  },
  getCompanyClientDashboardStatByIdRequestSuccess: (state, action) => {
    state.isCompanyClientDashboardStatDetailRequestLoading = false;
    state.selectedCompanyClientDashboardStatDetail = action.payload;
    state.selectedCompanyClientDashboardStatDetailFailed = {};
  },
  getCompanyClientDashboardStatByIdRequestFailed: (state, action) => {
    state.isCompanyClientDashboardStatDetailRequestLoading = false;
    state.selectedCompanyClientDashboardStatDetail = {};
    state.selectedCompanyClientDashboardStatDetailFailed = action.payload;
  },

  //save director
  postSaveCompanyDirector: (state) => {
    state.isSaveCompanyDirectorRequestLoading = true;
    state.saveCompanyDirectorRequestSuccessData = null;
    state.saveCompanyDirectorRequestFailedData = null;
  },
  postSaveCompanyDirectorSuccess: (state, action) => {
    state.isSaveCompanyDirectorRequestLoading = false;
    state.saveCompanyDirectorRequestSuccessData = action.payload;
    state.saveCompanyDirectorRequestFailedData = null;
  },
  postSaveCompanyDirectorFailed: (state, action) => {
    state.isSaveCompanyDirectorRequestLoading = false;
    state.saveCompanyDirectorRequestSuccessData = null;
    state.saveCompanyDirectorRequestFailedData = action.payload;
  },

  //update director
  patchUpdateCompanyDirector: (state) => {
    state.isUpdateCompanyDirectorRequestLoading = true;
    state.updateCompanyDirectorRequestSuccessData = null;
    state.updateCompanyDirectorRequestFailedData = null;
  },
  patchUpdateCompanyDirectorSuccess: (state, action) => {
    state.isUpdateCompanyDirectorRequestLoading = false;
    state.updateCompanyDirectorRequestSuccessData = action.payload;
    state.updateCompanyDirectorRequestFailedData = null;
  },
  patchUpdateCompanyDirectorFailed: (state, action) => {
    state.isUpdateCompanyDirectorRequestLoading = false;
    state.updateCompanyDirectorRequestSuccessData = null;
    state.updateCompanyDirectorRequestFailedData = action.payload;
  },

  //delete director
  deleteCompanyDirector: (state) => {
    state.isDeleteCompanyDirectorLoading = true;
    state.deleteCompanyDirectorSuccessData = null;
    state.deleteCompanyDirectorFailedData = null;
  },
  deleteCompanyDirectorSuccess: (state, action) => {
    state.isDeleteCompanyDirectorLoading = false;
    state.deleteCompanyDirectorSuccessData = action?.payload;
    state.deleteCompanyDirectorFailedData = null;
  },
  deleteCompanyDirectorFailed: (state, action) => {
    state.isDeleteCompanyDirectorLoading = false;
    state.deleteCompanyDirectorSuccessData = null;
    state.deleteCompanyDirectorFailedData = action?.payload;
  },

  //save shareholder
  postSaveCompanyShareholder: (state) => {
    state.isSaveCompanyShareholderRequestLoading = true;
    state.saveCompanyShareholderRequestSuccessData = null;
    state.saveCompanyShareholderRequestFailedData = null;
  },
  postSaveCompanyShareholderSuccess: (state, action) => {
    state.isSaveCompanyShareholderRequestLoading = false;
    state.saveCompanyShareholderRequestSuccessData = action.payload;
    state.saveCompanyShareholderRequestFailedData = null;
  },
  postSaveCompanyShareholderFailed: (state, action) => {
    state.isSaveCompanyShareholderRequestLoading = false;
    state.saveCompanyShareholderRequestSuccessData = null;
    state.saveCompanyShareholderRequestFailedData = action.payload;
  },

  //update shareholder
  patchUpdateCompanyShareholder: (state) => {
    state.isUpdateCompanyShareholderRequestLoading = true;
    state.updateCompanyShareholderRequestSuccessData = null;
    state.updateCompanyShareholderRequestFailedData = null;
  },
  patchUpdateCompanyShareholderSuccess: (state, action) => {
    state.isUpdateCompanyShareholderRequestLoading = false;
    state.updateCompanyShareholderRequestSuccessData = action.payload;
    state.updateCompanyShareholderRequestFailedData = null;
  },
  patchUpdateCompanyShareholderFailed: (state, action) => {
    state.isUpdateCompanyShareholderRequestLoading = false;
    state.updateCompanyShareholderRequestSuccessData = null;
    state.updateCompanyShareholderRequestFailedData = action.payload;
  },

  //delete shareholder
  deleteCompanyShareholder: (state) => {
    state.isDeleteCompanyShareholderLoading = true;
    state.deleteCompanyShareholderSuccessData = null;
    state.deleteCompanyShareholderFailedData = null;
  },
  deleteCompanyShareholderSuccess: (state, action) => {
    state.isDeleteCompanyShareholderLoading = false;
    state.deleteCompanyShareholderSuccessData = action?.payload;
    state.deleteCompanyShareholderFailedData = null;
  },
  deleteCompanyShareholderFailed: (state, action) => {
    state.isDeleteCompanyShareholderLoading = false;
    state.deleteCompanyShareholderSuccessData = null;
    state.deleteCompanyShareholderFailedData = action?.payload;
  },

  //save Multiple client
  postSaveMultipleCompanyRequest: (state) => {
    state.saveCompanyClientRequestLoading = true;
    state.saveCompanyClientData = {};
    state.saveCompanyClientFailed = {};
  },
  postSaveMultipleCompanySuccess: (state, action) => {
    state.saveCompanyClientRequestLoading = false;
    state.saveCompanyClientData = action.payload;
    state.saveCompanyClientFailed = {};
  },
  postSaveMultipleCompanyFailed: (state, action) => {
    state.saveCompanyClientRequestLoading = false;
    state.saveCompanyClientData = {};
    state.saveCompanyClientFailed = action.payload;
  },

  //filter  Company
  postFilterCompanyRequest: (state) => {
    state.filterDataCompanyLoading = true;
    state.filterDataCompanySuccess = [];
    state.filterDataCompanyFailed = {};
  },
  postFilterCompanySuccess: (state, action) => {
    state.filterDataCompanyLoading = false;
    state.filterDataCompanySuccess = action.payload;
    state.filterDataCompanyFailed = {};
  },
  postFilterCompanyFailed: (state, action) => {
    state.filterDataCompanyLoading = false;
    state.filterDataCompanySuccess = [];
    state.filterDataCompanyFailed = action.payload;
  },

  //add linked entities Company
  addLinkedEntitiesCompanyRequest: (state) => {
    state.addLinkedEntitiesCompanyLoading = true;
    state.addLinkedEntitiesCompanySuccess = [];
    state.addLinkedEntitiesCompanyFailed = {};
  },
  addLinkedEntitiesCompanySuccess: (state, action) => {
    state.addLinkedEntitiesCompanyLoading = false;
    state.addLinkedEntitiesCompanySuccess = action.payload;
    state.addLinkedEntitiesCompanyFailed = {};
  },
  addLinkedEntitiesCompanyFailed: (state, action) => {
    state.addLinkedEntitiesCompanyLoading = false;
    state.addLinkedEntitiesCompanySuccess = [];
    state.addLinkedEntitiesCompanyFailed = action.payload;
  },

  //delete linked entities Company
  deleteLinkedEntitiesCompanyRequest: (state) => {
    state.deleteLinkedEntitiesCompanyLoading = true;
    state.deleteLinkedEntitiesCompanySuccess = [];
    state.deleteLinkedEntitiesCompanyFailed = {};
  },
  deleteLinkedEntitiesCompanySuccess: (state, action) => {
    state.deleteLinkedEntitiesCompanyLoading = false;
    state.deleteLinkedEntitiesCompanySuccess = action.payload;
    state.deleteLinkedEntitiesCompanyFailed = {};
  },
  deleteLinkedEntitiesCompanyFailed: (state, action) => {
    state.deleteLinkedEntitiesCompanyLoading = false;
    state.addLinkedEntitiesCompanySuccess = [];
    state.deleteLinkedEntitiesCompanyFailed = action.payload;
  },
});
