import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //signup
  signUpLoading: false,
  signUpData: {},
  signUpFailed: {},

  //login
  loginLoading: false,
  loginData: {},
  loginFailed: {},

  //signOut
  isLogout: false,

  //re-authenticate modal
  isReAuthenticateModal: false,
  reAuthenticationData: {},

  //forgot password
  isForgotPasswordRequestLoading: false,
  forgotPasswordRequestSuccessData: null,
  forgotPasswordRequestFailedData: null,

  //reset password
  isResetPasswordRequestLoading: false,
  resetPasswordSuccessData: null,
  resetPasswordFailedData: null,

  //verify user email address
  isVerifyUserEmailRequestLoading: false,
  verifyUserEmailRequestSuccessData: null,
  verifyUserEmailRequestFailedData: null
};

export const authenticationReducer = createReducer(initialState, {
  //signup
  postSignUpRequest: (state) => {
    state.signUpLoading = true;
    state.isLogout = false;
    state.signUpData = {};
    state.signUpFailed = {};
  },
  postSignUpSuccess: (state, action) => {
    state.signUpLoading = false;
    state.signUpData = action.payload;
    state.signUpFailed = {};
  },
  postSignUpFailed: (state, action) => {
    state.signUpLoading = false;
    state.signUpData = {};
    state.signUpFailed = action.payload;
  },

  //login
  postLoginRequest: (state) => {
    state.loginLoading = true;
    state.isLogout = false;
    state.loginData = {};
    state.loginFailed = {};
  },
  postLoginSuccess: (state, action) => {
    state.loginLoading = false;
    state.loginData = action.payload;
    state.loginFailed = {};
  },
  postLoginFailed: (state, action) => {
    state.loginLoading = false;
    state.loginData = {};
    state.loginFailed = action.payload;
  },

  //sign out
  handleUserSignOut: (state, action) => {
    state.signUpLoading = initialState.signUpLoading;
    state.signUpData = initialState.signUpData;
    state.signUpFailed = initialState.signUpFailed;
    state.loginLoading = initialState.loginLoading;
    state.loginData = initialState.loginData;
    state.loginFailed = initialState.loginFailed;
    state.isReAuthenticateModal = initialState.isReAuthenticateModal;
    state.reAuthenticationData = initialState.reAuthenticationData;
    state.isLogout = true;
  },

  //reauthenticate
  openReAuthenticateModal: (state, action) => {
    state.isReAuthenticateModal = true;
    state.reAuthenticationData = action.payload;
  },
  closeReAuthenticateModal: (state, action) => {
    state.isReAuthenticateModal = false;
    state.reAuthenticationData = {};
  },

  //forgot password
  forgotPasswordRequest: (state) => {
    state.isForgotPasswordRequestLoading = true;
    state.forgotPasswordRequestSuccessData = null;
    state.forgotPasswordRequestFailedData = null;
  },
  forgotPasswordRequestSuccess: (state, action) => {
    state.isForgotPasswordRequestLoading = false;
    state.forgotPasswordRequestSuccessData = action.payload;
    state.forgotPasswordRequestFailedData = null;
  },
  forgotPasswordRequestFailed: (state, action) => {
    state.isForgotPasswordRequestLoading = false;
    state.forgotPasswordRequestSuccessData = null;
    state.forgotPasswordRequestFailedData = action.payload;
  },

  //reset password
  resetPasswordRequest: (state) => {
    state.isResetPasswordRequestLoading = true;
    state.resetPasswordSuccessData = null;
    state.resetPasswordFailedData = null;
  },
  resetPasswordRequestSuccess: (state, action) => {
    state.isResetPasswordRequestLoading = false;
    state.resetPasswordSuccessData = action.payload;
    state.resetPasswordFailedData = null;
  },
  resetPasswordRequestFailed: (state, action) => {
    state.isResetPasswordRequestLoading = false;
    state.resetPasswordSuccessData = null;
    state.resetPasswordFailedData = action.payload;
  },

  verifyUserEmailRequest: (state) => {
    state.isVerifyUserEmailRequestLoading = true;
    state.verifyUserEmailRequestSuccessData = null;
    state.verifyUserEmailRequestFailedData = null;
  },
  verifyUserEmailRequestSuccess: (state, action) => {
    state.isVerifyUserEmailRequestLoading = false;
    state.verifyUserEmailRequestSuccessData = action?.payload;
    state.verifyUserEmailRequestFailedData = null;
  },
  verifyUserEmailRequestFailed: (state, action) => {
    state.isVerifyUserEmailRequestLoading = false;
    state.verifyUserEmailRequestSuccessData = null;
    state.verifyUserEmailRequestFailedData = action?.payload;
  },


});
