import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Request save email template
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestPostSaveEmailTemplate =
  (
    requestBody,
    handleSaveEmailTemplateSuccessCallback = null,
    handleSaveEmailTemplateFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveEmailTemplateRequest' });

    const requestData = {
      url: API_URL.emailTemplates.POST_CREATE_EMAIL_TEMPLATE,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveEmailTemplateRequestSuccess', payload: response.data });
      Store.dispatch(handleNotification(`Email Template is Saved Successfully!`, 'success'));
      handleSaveEmailTemplateSuccessCallback &&
        handleSaveEmailTemplateSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveEmailTemplateRequestFailed', payload: error });
      let message = 'Email Template Save is Failed!';
      if (error?.response?.status === 500) {
        message = error?.response?.data?.message || 'Please Check Details !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveEmailTemplateFailedCallback && handleSaveEmailTemplateFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle get all email templates
 * @param {*} requestSuccessCallback
 * @param {*} requestFailedCallback
 * @returns
 */
export const requestGetAllEmailTemplates =
  (requestSuccessCallback = null, requestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllEmailTemplatesRequest' });

    const requestData = {
      url: API_URL.emailTemplates.GET_ALL_EMAIL_TEMPLATES,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllEmailTemplatesRequestSuccess', payload: response.data });
      requestSuccessCallback && requestSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllEmailTemplatesRequestFailed', payload: error });
      requestFailedCallback && requestFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle request delete email template
 * @param {*} requestSuccessCallback
 * @param {*} requestFailedCallback
 * @returns
 */
export const requestDeleteEmailTemplate =
  (requestDetails, requestSuccessCallback = null, requestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteEmailTemplateRequest' });

    const { emailTemplateId } = requestDetails;
    const requestData = {
      url: API_URL.emailTemplates.DELETE_EMAIL_TEMPLATE.replace(
        '{emailTemplateId}',
        emailTemplateId
      ),
      method: 'DELETE',
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteEmailTemplateRequestSuccess', payload: response.data });
      requestSuccessCallback && requestSuccessCallback(response?.data);
      Store.dispatch(handleNotification(`Email Template is successfully deleted!`, 'success'));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteEmailTemplateRequestFailed', payload: error });
      requestFailedCallback && requestFailedCallback(error);
      Store.dispatch(handleNotification(`Email Template Delete is Failed!`, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Search Email Templates by filters
 * @param {*} searchRequestData
 * @param {*} handleSearchEmailTemplatesSuccessCallback
 * @param {*} handleSearchEmailTemplatesFailedCallback
 * @returns
 */
export const requestSearchEmailTemplates =
  (
    searchRequestData,
    handleSearchEmailTemplatesSuccessCallback = null,
    handleSearchEmailTemplatesFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSearchEmailTemplateRequest' });
    // filters example : {
    //   No current filters defined
    // }
    const { filterRequestBody } = searchRequestData;

    const requestData = {
      url: API_URL.emailTemplates.POST_SEARCH_EMAIL_TEMPLATES,
      method: 'POST',
      data: filterRequestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSearchEmailTemplateRequestSuccess', payload: response.data });
      handleSearchEmailTemplatesSuccessCallback &&
        handleSearchEmailTemplatesSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSearchEmailTemplateRequestFailed', payload: error });
      handleSearchEmailTemplatesFailedCallback && handleSearchEmailTemplatesFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request update email template
 * @param {object} requestDetails
 * @returns {async function}
 */
export const requestUpdateEmailTemplate =
  (
    requestDetails,
    handleUpdateEmailTemplateSuccessCallback = null,
    handleUpdateEmailTemplateFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'putUpdateEmailTemplateRequest' });

    const { requestBody, emailTemplateId } = requestDetails;

    const requestData = {
      url: API_URL.emailTemplates.PUT_UPDATE_EMAIL_TEMPLATE.replace(
        '{emailTemplateId}',
        emailTemplateId
      ),
      method: 'PUT',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'putUpdateEmailTemplateRequestSuccess', payload: response.data });
      Store.dispatch(handleNotification(`Email Template is Updated Successfully!`, 'success'));
      handleUpdateEmailTemplateSuccessCallback &&
        handleUpdateEmailTemplateSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'putUpdateEmailTemplateRequestFailed', payload: error });
      let message = 'Email Template Update is Failed!';
      if (error?.response?.status === 500) {
        message = error?.response?.data?.message || 'Please Check Details !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateEmailTemplateFailedCallback && handleUpdateEmailTemplateFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle get all admin email templates
 * @param {*} requestSuccessCallback
 * @param {*} requestFailedCallback
 * @returns
 */
export const requestGetAllAdminEmailTemplates =
  (requestSuccessCallback = null, requestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllAdminEmailTemplatesRequest' });

    const requestData = {
      url: API_URL.emailTemplates.GET_ALL_ADMIN_EMAIL_TEMPLATES,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllAdminEmailTemplatesRequestSuccess', payload: response.data });
      requestSuccessCallback && requestSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllAdminEmailTemplatesRequestFailed', payload: error });
      requestFailedCallback && requestFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
