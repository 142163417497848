import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
//actions
import { handleNotification } from './handleNotificationAction';
/**
 * Save Advisor Profile Setting Action Request
 * @param {*} saveRequestData
 * @param {*} handleSaveAdvisorProfileSettingSuccessCallback
 * @param {*} handleSaveAdvisorProfileSettingFailedCallback
 * @returns
 */
export const requestSaveAdvisorProfileSetting =
  (
    saveRequestData,
    handleSaveAdvisorProfileSettingSuccessCallback,
    handleSaveAdvisorProfileSettingFailedCallback
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: 'postSaveAdvisorProfileSettingRequest' });
      const { loginUser, requestBody } = saveRequestData;
      const advisorProfileSaveRequestURL = API_URL.advisorProfiles.POST_CREATE_PROFILE;

      const requestData = {
        url: advisorProfileSaveRequestURL,
        method: 'POST',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({ type: 'postSaveAdvisorProfileSettingRequestSuccess', payload: response.data });
        const successMessage = `Advisor Profile Setting is Saved Successfully !`;
        Store.dispatch(handleNotification(successMessage, 'success'));
        handleSaveAdvisorProfileSettingSuccessCallback &&
          handleSaveAdvisorProfileSettingSuccessCallback(response);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'postSaveAdvisorProfileSettingRequestFailed', payload: error });
        let message = 'Advisor Profile Setting Save is Failed !';
        
        Store.dispatch(handleNotification(message, 'error'));
        handleSaveAdvisorProfileSettingFailedCallback &&
          handleSaveAdvisorProfileSettingFailedCallback(error);
      };
      http_request(requestData, successCallback, errorCallback);
    };

/**
 * Get Advisor Profile Setting Action Request
 * @param {*} handleGetAdvisorProfileSettingSuccessCallback
 * @param {*} handleGetAdvisorProfileSettingFailedCallback
 * @returns
 */
export const requestGetAdvisorProfileSetting =
  (handleGetAdvisorProfileSettingSuccessCallback, handleGetAdvisorProfileSettingFailedCallback) =>
    async (dispatch, getState) => {
      dispatch({ type: 'getAdvisorProfileSettingRequest' });
      const advisorProfileGetRequestURL = API_URL.advisorProfiles.GET_ADVISOR_PROFILE_DATA;
      const requestData = {
        url: advisorProfileGetRequestURL,
        method: 'GET',
      };
      const successCallback = (response) => {
        dispatch({ type: 'getAdvisorProfileSettingRequestSuccess', payload: response.data });
        handleGetAdvisorProfileSettingSuccessCallback &&
          handleGetAdvisorProfileSettingSuccessCallback(response);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'getAdvisorProfileSettingRequestFailed', payload: error });
        handleGetAdvisorProfileSettingFailedCallback &&
          handleGetAdvisorProfileSettingFailedCallback(error);
      };
      http_request(requestData, successCallback, errorCallback);
    };

/**
 * Update Advisor Profile Setting Action Request
 * @param {*} updateRequestData
 * @param {*} handleUpdateAdvisorProfileSettingSuccessCallback
 * @param {*} handleUpdateAdvisorProfileSettingFailedCallback
 * @returns
 */
export const requestUpdateAdvisorProfileSetting =
  (
    updateRequestData,
    handleUpdateAdvisorProfileSettingSuccessCallback,
    handleUpdateAdvisorProfileSettingFailedCallback
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: 'updateAdvisorProfileSettingRequest' });
      const { loginUser, requestBody } = updateRequestData;
      const advisorProfileUpdateRequestURL = API_URL.advisorProfiles.PUT_UPDATE_PROFILE;
      const requestData = {
        url: advisorProfileUpdateRequestURL,
        method: 'PUT',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({ type: 'updateAdvisorProfileSettingRequestSuccess', payload: response.data });
        const successMessage = `Advisor Profile Setting is Updated Successfully !`;
        Store.dispatch(handleNotification(successMessage, 'success'));
        handleUpdateAdvisorProfileSettingSuccessCallback &&
          handleUpdateAdvisorProfileSettingSuccessCallback(response);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'updateAdvisorProfileSettingRequestFailed', payload: error });
        let message = 'Advisor Profile Setting Update is Failed !';
        
        Store.dispatch(handleNotification(message, 'error'));
        handleUpdateAdvisorProfileSettingFailedCallback &&
          handleUpdateAdvisorProfileSettingFailedCallback(error);
      };
      http_request(requestData, successCallback, errorCallback);
    };

/**
 * Update Advisor Profile Setting Action Request
 * @param {*} profilePicSignedURLRequestData
 * @param {*} handleUploadMyProfilePicSignedURLSuccessCallback
 * @param {*} handleUploadMyProfilePicSignedURLFailedCallback
 * @returns
 */
export const requestUploadMyProfilePictureSignedURL =
  (
    profilePicSignedURLRequestData,
    handleUploadMyProfilePicSignedURLAndUploadSuccessCallback,
    handleUploadMyProfilePicSignedURLAndUploadFailedCallback
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: 'uploadProfilePictureSignedURLRequest' });
      const { loginUser, signedUrlRequestData: requestBody } = profilePicSignedURLRequestData;
      const myProfilePictureSignedURLRequestUrl =
        API_URL.files.POST_GENERATE_SIGNED_URL;
      const requestData = {
        url: myProfilePictureSignedURLRequestUrl,
        method: 'POST',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({ type: 'uploadProfilePictureSignedURLRequestSuccess', payload: response.data });

        const uploadRequestData = {
          ...profilePicSignedURLRequestData,
          ...(response?.data || {}),
        };
        Store.dispatch(
          requestUploadMyProfilePicture(
            uploadRequestData,
            handleUploadMyProfilePicSignedURLAndUploadSuccessCallback,
            handleUploadMyProfilePicSignedURLAndUploadFailedCallback
          )
        );
      };
      const errorCallback = (error) => {
        dispatch({ type: 'uploadProfilePictureSignedURLRequestFailed', payload: error });
        let message = 'Upload File Request Failed !';
        
        Store.dispatch(handleNotification(message, 'error'));
        handleUploadMyProfilePicSignedURLAndUploadFailedCallback &&
          handleUploadMyProfilePicSignedURLAndUploadFailedCallback(error);
      };
      http_request(requestData, successCallback, errorCallback);
    };

/**
 * Update Advisor Profile Setting Action Request
 * @param {*} uploadProfilePictureRequestData
 * @param {*} handleUploadMyProfilePictureSuccessCallback
 * @param {*} handleUploadMyProfilePictureFailedCallback
 * @returns
 */
export const requestUploadMyProfilePicture =
  (
    uploadProfilePictureRequestData,
    handleUploadMyProfilePictureSuccessCallback,
    handleUploadMyProfilePictureFailedCallback
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: 'uploadUserProfilePictureSettingRequest' });
      const {
        loginUser,
        imageUploadRequestData: { fileData: requestBody },
        signedUrl,
        resourceUrl,
      } = uploadProfilePictureRequestData;
      const myProfilePictureUploadUrl = signedUrl;

      const requestData = {
        url: myProfilePictureUploadUrl,
        method: 'PUT',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({ type: 'uploadUserProfilePictureSettingRequestSuccess', payload: response.data });
        let message = 'Profile Picture Uploaded Successfully !';
        Store.dispatch(handleNotification(message, 'success'));
        const uploadSuccessData = {
          ...(response?.data || {}),
          signedUrl,
          resourceUrl,
        };
        handleUploadMyProfilePictureSuccessCallback &&
          handleUploadMyProfilePictureSuccessCallback(uploadSuccessData);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'uploadUserProfilePictureSettingRequestFailed', payload: error });
        let message = 'Upload File Request Failed !';
        
        Store.dispatch(handleNotification(message, 'error'));
        handleUploadMyProfilePictureFailedCallback &&
          handleUploadMyProfilePictureFailedCallback(error);
      };

      http_request(requestData, successCallback, errorCallback, {
        headers: { 'Content-Type': requestBody.type },
        noToken: true,
      });
    };

/**
 * Request Change my profile password
 * @param {*} passwordChangeRequestData
 * @param {*} handlePasswordChangeRequestSuccessCallback
 * @param {*} handlePasswordChangeRequestFailedCallback
 * @returns async function
 */
export const requestMyProfilePasswordChange =
  (
    passwordChangeRequestData,
    handlePasswordChangeRequestSuccessCallback,
    handlePasswordChangeRequestFailedCallback
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: 'myProfilePasswordChangeRequest' });

      const requestData = {
        url: API_URL.auth.advisorProfiles.PUT_CHANGE_MY_PROFILE_PASSWORD,
        method: 'PUT',
        data: passwordChangeRequestData?.requestBody,
      };
      const successCallback = (response) => {
        dispatch({ type: 'myProfilePasswordChangeRequestSuccess', payload: response.data });
        const successMessage = `Password is Updated Successfully !`;
        Store.dispatch(handleNotification(successMessage, 'success'));
        handlePasswordChangeRequestSuccessCallback &&
          handlePasswordChangeRequestSuccessCallback(response);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'myProfilePasswordChangeRequestFailed', payload: error });
        let message = 'Password Change Request Failed !';
        
        Store.dispatch(handleNotification(message, 'error'));
        handlePasswordChangeRequestFailedCallback && handlePasswordChangeRequestFailedCallback(error);
      };

      http_request(requestData, successCallback, errorCallback);
    };
