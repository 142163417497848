import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

const AutoClearClientSearchFormik = (props) => {
  const {
    setSelectedExistingClientData,
    isEditMode,
    isShareholderClear,
    setCompanyShareholderClientOptions,
  } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();

  const isExistingClient = formikContext?.values?.isExistingClient;
  const shareholderExistingType = formikContext?.values?.shareholderExistingType;

  const [isCompletedInitialMount, setIsCompletedInitialMount] = useState(false);
  /**
   * Effect on existing client check change
   */
  useEffect(() => {
    if(isCompletedInitialMount){
      formikContext?.setFieldValue && formikContext.setFieldValue('clientId', null);
      formikContext?.setFieldTouched && formikContext.setFieldTouched('clientId', true);
  
      setSelectedExistingClientData && setSelectedExistingClientData(null);
  
      if (isShareholderClear) {
        formikContext?.setFieldValue && formikContext.setFieldValue('directorId', null);
        formikContext?.setFieldTouched && formikContext.setFieldTouched('directorId', true, true);
  
        formikContext?.setFieldValue && formikContext.setFieldValue('smsfId', null);
        formikContext?.setFieldTouched && formikContext.setFieldTouched('smsfId', true, true);
  
        formikContext?.setFieldValue && formikContext.setFieldValue('companyId', null);
        formikContext?.setFieldTouched && formikContext.setFieldTouched('companyId', true, true);
  
        formikContext?.setFieldValue && formikContext.setFieldValue('trustId', null);
        formikContext?.setFieldTouched && formikContext.setFieldTouched('trustId', true, true);
  
        formikContext?.setFieldValue &&
          formikContext.setFieldValue(
            'shareholderExistingType',
            shareholderExistingType ? shareholderExistingType : 'Individual'
          );
        formikContext?.setFieldTouched &&
          formikContext.setFieldTouched('shareholderExistingType', true, true);
  
        if (!isEditMode || (isEditMode && isCompletedInitialMount)){
          setCompanyShareholderClientOptions([]);
        }
      }
    }
  }, [isExistingClient, isShareholderClear, shareholderExistingType]);
  /**
   * Completed initial mount
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCompletedInitialMount(true);
    }, 350);
    return () => clearTimeout(timer);
  }, []);
  return null;
};

export default AutoClearClientSearchFormik;
