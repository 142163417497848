import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //tax setting data request
  isGetAllTaxSettingRequestLoading: false,
  taxSettingData: [],
  taxSettingFailedData: {},

  //find tax setting
  isFindTaxSettingRequestLoading: false,
  promoCodeCreateRequestSuccessData: {},
  promoCodeCreateRequestFailedData: {},

  //search tax settings
  isSearchTaxSettingsRequestLoading: false,
  searchTaxSettingsRequestSuccessData: {},
  searchTaxSettingsRequestFailedData: {},

};

export const taxSettingReducer = createReducer(initialState, {
  //get tax settings
  getAllTaxSettingsRequest: (state) => {
    state.isGetAllTaxSettingRequestLoading = true;
    state.taxSettingData = [];
    state.taxSettingFailedData = {};
  },
  getAllTaxSettingsSuccess: (state, action) => {
    state.isGetAllTaxSettingRequestLoading = false;
    state.taxSettingData = action.payload;
    state.taxSettingFailedData = {};
  },
  getAllTaxSettingsFailed: (state, action) => {
    state.isGetAllTaxSettingRequestLoading = false;
    state.taxSettingData = [];
    state.taxSettingFailedData = action.payload;
  },

  // post save tax settings
  findTaxSettingRequest: (state) => {
    state.isFindTaxSettingRequestLoading = true;
    state.promoCodeCreateRequestSuccessData = true;
    state.promoCodeCreateRequestFailedData = true;
  },
  findTaxSettingRequestSuccess: (state, action) => {
    state.isFindTaxSettingRequestLoading = false;
    state.promoCodeCreateRequestSuccessData = action.payload;
    state.promoCodeCreateRequestFailedData = {};
  },
  findTaxSettingRequestFailed: (state, action) => {
    state.isFindTaxSettingRequestLoading = false;
    state.promoCodeCreateRequestSuccessData = {};
    state.promoCodeCreateRequestFailedData = action.payload;
  },

  // search tax settings
  searchTaxSettingsRequest: (state) => {
    state.isSearchTaxSettingsRequestLoading = true;
    state.searchTaxSettingsRequestSuccessData = {};
    state.searchTaxSettingsRequestFailedData = {};
  },
  searchTaxSettingsRequestSuccess: (state, action) => {
    state.isSearchTaxSettingsRequestLoading = false;
    state.searchTaxSettingsRequestSuccessData = action.payload;
    state.searchTaxSettingsRequestFailedData = {};
  },
  searchTaxSettingsRequestFailed: (state, action) => {
    state.isSearchTaxSettingsRequestLoading = false;
    state.searchTaxSettingsRequestSuccessData = {};
    state.searchTaxSettingsRequestFailedData = action.payload;
  },

});
