import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // create
  saveRequestLoading: false,
  saveData: {},
  saveFailed: {},

  // get all
  getRequestLoading: false,
  getListData: null,
  getListDataFailed: null,
};

export const smsfExpenseReducer = createReducer(initialState, {
  //save
  postSmsfExpenseRequest: (state) => {
    state.saveRequestLoading = true;
    state.saveData = {};
    state.saveFailed = {};
  },
  postSmsfExpenseSuccess: (state, action) => {
    state.saveRequestLoading = false;
    state.saveData = action.payload;
    state.saveFailed = {};
  },
  postSmsfExpenseFailed: (state, action) => {
    state.saveRequestLoading = false;
    state.saveData = {};
    state.saveFailed = action.payload;
  },

  //get all
  getSmsfExpenseRequest: (state) => {
    state.getRequestLoading = true;
    state.getListData = null;
    state.getListDataFailed = {};
  },
  getSmsfExpenseSuccess: (state, action) => {
    state.getRequestLoading = false;
    state.getListData = action.payload;
    state.getListDataFailed = {};
  },
  getSmsfExpenseFailed: (state, action) => {
    state.getRequestLoading = false;
    state.getListData = null;
    state.getListDataFailed = action.payload;
  },
});
