import { Link } from 'react-router-dom';

import { ButtonBase } from '@mui/material';
// project imports
import config from 'config';
import Logo from 'ui-component/LogoNew';

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <Logo />
  </ButtonBase>
);

export default LogoSection;
