import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //search company by abn
  isSearchCompanyByABNRequestLoading: false,
  searchCompanyByABNRequestSuccessData: [],
  searchCompanyByABNRequestFailedData: null,

  //search company by name
  isSearchCompanyByNameRequestLoading: false,
  searchCompanyByNameRequestSuccessData: [],
  searchCompanyByNameRequestFailedData: null,
};

export const superFundCompanyReducer = createReducer(initialState, {
  getSearchCompanyByABNRequest: (state) => {
    state.isSearchCompanyByABNRequestLoading = true;
    state.searchCompanyByABNRequestSuccessData = [];
    state.searchCompanyByABNRequestFailedData = null;
  },
  getSearchCompanyByABNRequestSuccess: (state, action) => {
    state.isSearchCompanyByABNRequestLoading = false;
    state.searchCompanyByABNRequestSuccessData = action?.payload || [];
    state.searchCompanyByABNRequestFailedData = null;
  },
  getSearchCompanyByABNRequestFailed: (state, action) => {
    state.isSearchCompanyByABNRequestLoading = false;
    state.searchCompanyByABNRequestSuccessData = [];
    state.searchCompanyByABNRequestFailedData = action?.payload;
  },

  //search company by name
  getSearchCompanyByNameRequest: (state) => {
    state.isSearchCompanyByNameRequestLoading = true;
    state.searchCompanyByNameRequestSuccessData = [];
    state.searchCompanyByNameRequestFailedData = null;
  },
  getSearchCompanyByNameRequestSuccess: (state, action) => {
    state.isSearchCompanyByNameRequestLoading = false;
    state.searchCompanyByNameRequestSuccessData = action?.payload || [];
    state.searchCompanyByNameRequestFailedData = null;
  },
  getSearchCompanyByNameRequestFailed: (state, action) => {
    state.isSearchCompanyByNameRequestLoading = false;
    state.searchCompanyByNameRequestSuccessData = [];
    state.searchCompanyByNameRequestFailedData = action?.payload;
  },
});
