import { AddCircleOutline, AddCircleOutlineRounded } from '@mui/icons-material';
import { Fade, Grid, IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';

import { useFormikContext } from 'formik';

import FormikTextField from 'common/formik/FormikTextField';
import DropDownAutoCom from 'common/material/DropDownAutoCom';

import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

import { ReactComponent as QuestionRemoveIcon } from 'assets/images/icons/question-delete.svg';
const QuestionAnswerConfigureListItem = (props) => {
  const { answerIndex, handleOnAddNewAnswer, handleOnRemoveAnswer } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  return (
    <MainCard>
      <Grid container spacing={gridSpacing} alignItems="flex-start">
        <Grid item xs>
          <Grid container spacing={gridSpacing} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <FormikTextField
                name={`answers[${answerIndex}].label`}
                type="text"
                label={'Label*'}
                required
                disabled={formikContext?.isSubmitting}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropDownAutoCom
                fieldName={`answers[${answerIndex}].type`}
                label={'Answer Type*'}
                optionData={[
                  {
                    name: 'Text',
                    value: 'Text',
                    id: 'Text',
                  },
                  {
                    name: 'Decimal',
                    value: 'Decimal',
                    id: 'Decimal',
                  },
                  {
                    name: 'Percentage',
                    value: 'Percentage',
                    id: 'Percentage',
                  },
                  {
                    name: 'Amount(currency)',
                    value: 'Amount',
                    id: 'Amount',
                  },
                  {
                    name: 'Date',
                    value: 'Date',
                    id: 'Date',
                  },
                  {
                    name: 'Yes/No',
                    value: 'Boolean',
                    id: 'Boolean',
                  },
                ]}
                dropDownValue={formikContext.values?.answers?.[answerIndex]?.type}
                placeholder="Please Select !"
                error={
                  formikContext.touched?.answers?.[answerIndex]?.type &&
                  formikContext.errors?.answers?.[answerIndex]?.type
                }
                helperText={
                  formikContext.touched?.answers?.[answerIndex]?.type &&
                  formikContext.errors?.answers?.[answerIndex]?.type
                }
                setDropDownValue={(value) =>
                  formikContext.setFieldValue(`answers[${answerIndex}].type`, value)
                }
                onFieldBlur={formikContext.handleBlur}
                disabled={formikContext?.isSubmitting}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Stack direction="auto" spacing={gridSpacing} sx={{marginTop: '0.25rem'}}>
            <Tooltip title="Add another">
              <IconButton
                aria-label="add-another-answer"
                onClick={(e) => handleOnAddNewAnswer?.(answerIndex)}
                sx={{ margin: '0 !important', marginTop: '0.5rem !important' }}
              >
                <AddCircleOutlineRounded />
              </IconButton>
            </Tooltip>
            {answerIndex > 0 && (
              <Tooltip title="Remove">
                <IconButton
                  aria-label="remove-answer"
                  onClick={(e) => handleOnRemoveAnswer?.(answerIndex)}
                  sx={{ margin: '0 !important', marginTop: '0.5rem !important' }}
                >
                  <SvgIcon
                    viewBox='0 0 24 24'
                    component={QuestionRemoveIcon}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default QuestionAnswerConfigureListItem;
