//export to reducer/index.js
// import { CONSTANTS } from "../../actions";

import { createReducer } from '@reduxjs/toolkit';
/**
 * Initial data
 *  _Template>> [{title, id, cards[{id, text, name, textCard},...]},...]
 */
const initialState = [
  
];

export const digitalDocReducer = createReducer(initialState, {
  DRAG_HAPPENED: (state, action) => {
    const {
      // this coming from listAction
      droppableIdStart,
      droppableIdEnd,
      droppableIndexStart,
      droppableIndexEnd,
      draggableId,
      type,
    } = action.payload;
    const newState = [...state];

    //dragin list around
    if (type === 'list') {
      const list = newState.splice(droppableIndexStart, 1);
      newState.splice(droppableIndexEnd, 0, ...list);

      return newState;
    }

    //in the same list
    if (droppableIdStart === droppableIdEnd) {
      const list = state.find((list) => droppableIdStart === list.id);
      const card = list.cards.splice(droppableIndexStart, 1);
      list.cards.splice(droppableIndexEnd, 0, ...card);
    }

    //in the other list
    if (droppableIdStart !== droppableIdEnd) {
      //find the list where drag happen
      const listStart = state.find((list) => droppableIdStart === list.id);

      //pull out the card from this list

      const card = listStart.cards.splice(droppableIndexStart, 1);

      // find the list where drag ended
      const listEnd = state.find((list) => droppableIdEnd === list.id);

      // put the card in the new list
      listEnd.cards.splice(droppableIndexEnd, 0, ...card);
    }

    return state;
  },
  SET_DIGITAL_DOCUMENTS: (state, action) => {
    const documentsData = action.payload;
    if(Array.isArray(documentsData)){
      const newState = [...documentsData];
      return newState
    }
    return state;
  },
});
