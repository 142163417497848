import { useCallback, useMemo, useState } from 'react';

import { Collapse, Fade, Grid, IconButton, Stack, SvgIcon } from '@mui/material';
import { AddCircleOutlineRounded } from '@mui/icons-material';

import { useFormikContext } from 'formik';

import FormikTextField from 'common/formik/FormikTextField';
import DropDownAutoCom from 'common/material/DropDownAutoCom';
import FormikAutocomplete from 'common/formik/FormikAutocomplete';

import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

import { ReactComponent as QuestionRemoveIcon } from 'assets/images/icons/question-delete.svg';

const ColumnConfigureListItem = (props) => {
  const { fieldIndex, columnDetail, handleOnAddNewConfigureTableHeader, handleOnRemoveHeader } =
    props;

  const formikContext = useFormikContext();
  // const fieldType = columnDetail?.type;
  const selectOptions = useMemo(() => columnDetail?.valueOptions || [], [columnDetail]);
  //_states
  const [fieldTypeOptions, setFieldTypeOptions] = useState([
    {
      id: 1,
      name: 'Text',
      value: 'string',
    },
    {
      id: 2,
      name: 'Number',
      value: 'number',
    },
    {
      id: 3,
      name: 'Checkbox(Yes/No)',
      value: 'boolean',
    },
    {
      id: 4,
      name: 'Date',
      value: 'date',
    },
    {
      id: 5,
      name: 'Date and Time',
      value: 'dateTime',
    },
    {
      id: 6,
      name: 'Select',
      value: 'singleSelect',
    },
    {
      id: 7,
      name: 'Multi-Select',
      value: 'multiSelect',
    },
  ]);
  /**
   * Handle set column types
   */
  const handleSetColumnType = useCallback(
    (value) => {
      if (
        (value !== 'singleSelect' || value !== 'multiSelect') &&
        typeof selectOptions !== 'undefined'
      ) {
        formikContext.setFieldValue(`columnData[${fieldIndex}].valueOptions`, undefined);
        formikContext.setFieldTouched(`columnData[${fieldIndex}].valueOptions`, undefined);
      } else {
        formikContext.setFieldValue(`columnData[${fieldIndex}].valueOptions`, []);
        formikContext.setFieldTouched(`columnData[${fieldIndex}].valueOptions`, []);
      }
      setTimeout(() => {
        formikContext.setFieldValue(`columnData[${fieldIndex}].type`, value);
        formikContext.setFieldTouched(`columnData[${fieldIndex}].type`, value);
      }, 10);
    },
    [formikContext, selectOptions, fieldIndex]
  );

  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={true}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs>
              <FormikTextField
                name={`columnData[${fieldIndex}].headerName`}
                type="text"
                label={'Header Name'}
                required
              />
            </Grid>
            <Grid item xs>
              <DropDownAutoCom
                fieldName={`columnData[${fieldIndex}].type`}
                label={'Column Type'}
                optionData={fieldTypeOptions}
                dropDownValue={formikContext.values?.columnData?.[fieldIndex]?.type || ''}
                placeholder="Please Select !"
                error={
                  formikContext.touched?.columnData?.[fieldIndex]?.type &&
                  formikContext.errors?.columnData?.[fieldIndex]?.type
                }
                helperText={
                  formikContext.touched?.columnData?.[fieldIndex]?.type &&
                  formikContext.errors?.columnData?.[fieldIndex]?.type
                }
                setDropDownValue={(value) => {
                  handleSetColumnType(value);
                }}
                onFieldBlur={formikContext.handleBlur}
                disabled={formikContext.isSubmitting}
                // loading={!!filterClientsRequestLoading}
                // onInputChange={handleClientSearchChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse
                in={columnDetail?.type === 'singleSelect' || columnDetail?.type === 'multiSelect'}
                timeout={300}
              >
                <Fade
                  in={columnDetail?.type === 'singleSelect' || columnDetail?.type === 'multiSelect'}
                  timeout={100}
                >
                  <Grid container item xs={12} spacing={gridSpacing} alignItems="center">
                    <Grid item xs>
                      {(columnDetail?.type === 'singleSelect' ||
                        columnDetail?.type === 'multiSelect') && (
                        <FormikAutocomplete
                          name={`columnData[${fieldIndex}].valueOptions`}
                          formikContext={formikContext}
                          options={[]}
                          label={'Add Answer Option'}
                          title={'title'}
                          multiple
                          isOptionCreatable
                        />
                      )}
                    </Grid>
                  </Grid>
                </Fade>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Stack direction="auto" spacing={gridSpacing} sx={{ marginTop: '0.25rem' }}>
            <Grid item xs="auto">
              <Fade in>
                <IconButton
                  aria-label="add-another-column-header"
                  onClick={() => handleOnAddNewConfigureTableHeader(fieldIndex)}
                  sx={{ margin: '0 !important', marginTop: '0.5rem !important' }}
                >
                  <AddCircleOutlineRounded />
                </IconButton>
              </Fade>
            </Grid>
            <Grid item xs="auto">
              <Fade in={!!(fieldIndex > 0)} unmountOnExit>
                <IconButton
                  aria-label="remove-column-header"
                  onClick={(event) => handleOnRemoveHeader(fieldIndex)}
                  sx={{ margin: '0 !important', marginTop: '0.5rem !important' }}
                >
                  <SvgIcon viewBox="0 0 24 24" component={QuestionRemoveIcon} />
                </IconButton>
              </Fade>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ColumnConfigureListItem;
