import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New Lead
  saveLeadRequestLoading: false,
  saveLeadData: {},
  saveLeadFailed: {},

  //leads list data
  isLeadListLoading: false,
  leadListData: {},
  leadListFailedData: {},

  //find lead request
  isFindLeadRequestLoading: false,
  findLeadRequestSuccessData: {},
  findLeadRequestFailedData: {},

  //update lead request
  isUpdateLeadRequestLoading: false,
  updateLeadRequestSuccessData: {},
  updateLeadRequestFailedData: {},

  //delete lead request
  isDeleteLeadRequestLoading: false,
  deleteLeadRequestSuccessData: {},
  deleteLeadRequestFailedData: {},

  //search and filter leads
  isPostFilterLeadsRequestLoading: false,
  postFilterLeadsRequestSuccessData: [],
  postFilterLeadsRequestFailedData: null,
};

export const leadsReducer = createReducer(initialState, {
  //lead save
  postSaveLeadRequest: (state) => {
    state.saveLeadRequestLoading = true;
    state.saveLeadData = {};
    state.saveLeadFailed = {};
  },
  postSaveLeadSuccess: (state, action) => {
    state.saveLeadRequestLoading = false;
    state.saveLeadData = action.payload;
    state.saveLeadFailed = {};
  },
  postSaveLeadFailed: (state, action) => {
    state.saveLeadRequestLoading = false;
    state.saveLeadData = {};
    state.saveLeadFailed = action.payload;
  },

  //lead list
  getAllLeadsRequest: (state) => {
    state.isLeadListLoading = true;
    state.leadListData = {};
    state.leadListFailedData = {};
  },
  getAllLeadsSuccess: (state, action) => {
    state.isLeadListLoading = false;
    state.leadListData = action.payload;
    state.leadListFailedData = {};
  },
  getAllLeadsFailed: (state, action) => {
    state.isLeadListLoading = false;
    state.leadListData = {};
    state.leadListFailedData = action.payload;
  },

  //find lead
  getFindLeadByIdRequest: (state) => {
    state.isFindLeadRequestLoading = true;
    state.findLeadRequestSuccessData = {};
    state.findLeadRequestFailedData = {};
  },
  getFindLoadByIdSuccess: (state, action) => {
    state.isFindLeadRequestLoading = false;
    state.findLeadRequestSuccessData = action.payload;
    state.findLeadRequestFailedData = {};
  },
  getFindLeadByIdFailed: (state, action) => {
    state.isFindLeadRequestLoading = false;
    state.findLeadRequestSuccessData = {};
    state.findLeadRequestFailedData = action.payload;
  },

  //update lead
  putUpdateLeadRequest: (state) => {
    state.isUpdateLeadRequestLoading = true;
    state.updateLeadRequestSuccessData = {};
    state.updateLeadRequestFailedData = {};
  },
  putUpdateLeadRequestSuccess: (state, action) => {
    state.isUpdateLeadRequestLoading = false;
    state.updateLeadRequestSuccessData = action.payload;
    state.updateLeadRequestFailedData = {};
  },
  putUpdateLeadRequestFailed: (state, action) => {
    state.isUpdateLeadRequestLoading = false;
    state.updateLeadRequestSuccessData = {};
    state.updateLeadRequestFailedData = {};
  },

  //delete lead
  deleteLeadRequest: (state) => {
    state.isDeleteLeadRequestLoading = true;
    state.deleteLeadRequestSuccessData = {};
    state.deleteLeadRequestFailedData = {};
  },
  deleteLeadRequestSuccess: (state, action) => {
    state.isDeleteLeadRequestLoading = false;
    state.deleteLeadRequestSuccessData = action.payload;
    state.deleteLeadRequestFailedData = {};
  },
  deleteLeadRequestFailed: (state, action) => {
    state.isDeleteLeadRequestLoading = false;
    state.deleteLeadRequestSuccessData = {};
    state.deleteLeadRequestFailedData = action.payload;
  },

  //search leads
  postFilterLeadsRequest: (state) => {
    state.isPostFilterLeadsRequestLoading = true;
    state.postFilterLeadsRequestSuccessData = [];
    state.postFilterLeadsRequestFailedData = null;
  },
  postFilterLeadsRequestSuccess: (state, action) => {
    state.isPostFilterLeadsRequestLoading = false;
    state.postFilterLeadsRequestSuccessData = action.payload;
    state.postFilterLeadsRequestFailedData = null;
  },
  postFilterLeadsRequestFailed: (state, action) => {
    state.isPostFilterLeadsRequestLoading = false;
    state.postFilterLeadsRequestSuccessData = [];
    state.postFilterLeadsRequestFailedData = action.payload;
  },
});
