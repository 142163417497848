import { Box, Button, Fade, Grid, Stack, Tooltip, Typography } from '@mui/material';

import { useFormikContext } from 'formik';
import Lottie from 'lottie-react';

import CommonModal from 'common/material/CommonModal';
import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';

import * as deleteAnimationData from 'assets/animation/delete.json';

const RemoveQuestionnaireSectionConfirmContent = (props) => {
  const { handleDialogOpened, questionnaireSections, removeSectionModalData } = props;
  /**
   * Uses common classes
   */
  const {
    classes: { conformationContentText, cancelBtn, saveBtn },
  } = useCommonStyles();

  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  //questionnaires
  const questionnairesData = formikContext?.values?.questionnaires;

  const aniamtionImageStyle = {
    height: 100,
  };

  const isSectionQuestionnairesMerge =
    (Array.isArray(questionnaireSections) && questionnaireSections?.length > 1) || false;
  /**
   * Handle section and its questions
   * @param {*} event
   */
  const handleRemoveSection = (event) => {
    const removingSectionIndex = removeSectionModalData?.questionnaireHeaderKey;
    if (
      typeof removingSectionIndex === 'number' &&
      removingSectionIndex >= 0 &&
      Array.isArray(questionnairesData)
    ) {
      const questionnaireRemoveStart = removingSectionIndex;
      const sectionQuestions = removeSectionModalData?.sectionQuestions;
      // const questionnaireRemoveEnd = (sectionQuestions && Array.isArray(sectionQuestions) && sectionQuestions?.[sectionQuestions?.length - 1]?.questionnaireKey) || questionnaireRemoveStart;
      const questionCount = sectionQuestions?.length || 0;
      const deleteElementCount = questionCount + 1;
      let questionnaireArray = [...questionnairesData];
      questionnaireArray.splice(questionnaireRemoveStart, deleteElementCount);
      questionnaireArray = questionnaireArray.filter((questionData) => !!questionData?.component);

      formikContext.setFieldValue('questionnaires', questionnaireArray);
      //complete section and its answers removed
      handleDialogOpened && handleDialogOpened(false);
    }
  };
  /**
   * Handle remove section and merge its questions to available nearest section
   * @param {*} event
   */
  const handleRemoveSectionOnly = (event) => {
    const removingSectionIndex = removeSectionModalData?.questionnaireHeaderKey;
    const preSectionIndex =
      Array.isArray(questionnairesData) &&
      questionnairesData.findIndex(
        (questionnaireData, questionnaireIndex) =>
          questionnaireIndex < removingSectionIndex &&
          questionnaireData?.component === 'header' &&
          questionnaireData?.headerType === 'section'
      );
    const postSectionIndex =
      Array.isArray(questionnairesData) &&
      questionnairesData.findIndex(
        (questionnaireData, questionnaireIndex) =>
          questionnaireIndex > removingSectionIndex &&
          questionnaireData?.component === 'header' &&
          questionnaireData?.headerType === 'section'
      );
    let questionnaireArray = [...questionnairesData];

    if (preSectionIndex >= 0) {
      //only section header removed when merging with previous section
      questionnaireArray.splice(removingSectionIndex, 1);
    } else if (postSectionIndex >= 0) {
      //only section header removed when merging with next section
      const removingQuestions = questionnaireArray.slice(
        removingSectionIndex + 1,
        postSectionIndex
      );
      const startArray =
        removingSectionIndex === 0 ? [] : questionnaireArray.slice(0, removingSectionIndex);
      const postSectionQuestionnaireArray = questionnaireArray.slice(
        postSectionIndex,
        questionnaireSections?.[(removeSectionModalData?.sectionIndexKey || 0) + 1]
          ?.questionnaireHeaderNextKey || undefined
      );
      const postSectionAfterQuestionnaireArray =
        questionnaireSections?.[(removeSectionModalData?.sectionIndexKey || 0) + 1]
          ?.questionnaireHeaderNextKey > 0
          ? questionnaireArray.slice(
              questionnaireSections?.[(removeSectionModalData?.sectionIndexKey || 0) + 1]
                ?.questionnaireHeaderNextKey
            )
          : [];
      questionnaireArray = [
        ...startArray,
        ...postSectionQuestionnaireArray,
        ...removingQuestions,
        ...postSectionAfterQuestionnaireArray,
      ];
    }
    formikContext?.setFieldValue &&
      formikContext.setFieldValue('questionnaires', questionnaireArray);
      //only section header removed and its questions merged with existing nearest section
    handleDialogOpened && handleDialogOpened(false);
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12} sm={12} md={3} lg={3} alignItems={'center'}>
        <Box style={aniamtionImageStyle} alignItems={'center'}>
          <Lottie animationData={deleteAnimationData} style={aniamtionImageStyle} />
        </Box>
      </Grid>
      <Grid container item xs={12} sm={12} md={9} lg={9} alignItems={'center'}>
        <Typography className={conformationContentText} id="conformation-dialog-text">
          Do you want to delete selected section/header?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={gridSpacing} justifyContent="flex-end" alignItems="center">
          <Button
            className={cancelBtn}
            variant="outlined"
            onClick={(event) => handleDialogOpened && handleDialogOpened(false)}
          >
            Cancel
          </Button>
          <Tooltip title="Delete Section and its Questionnaires!">
            <Button variant="contained" className={saveBtn} onClick={handleRemoveSection}>
              Delete Section
            </Button>
          </Tooltip>
          <Fade in={isSectionQuestionnairesMerge} unmountOnExit>
            <Tooltip title="Remove section only and merge its questions with available section!">
              <Button variant="contained" className={saveBtn} onClick={handleRemoveSectionOnly}>
                Remove Section Only
              </Button>
            </Tooltip>
          </Fade>
        </Stack>
      </Grid>
    </Grid>
  );
};

const RemoveTemplateQuestionSectionConfirmationModal = (props) => {
  const {
    isOpened,
    isLoading,
    handleDialogOpened,
    questionnaireSections,
    removeSectionModalData,
  } = props;

  return (
    <CommonModal
      open={isOpened}
      handleDialogOpened={() => handleDialogOpened(false)}
      // modalConfirmAction={handleRemoveQuestion}
      dialogTitle={'Remove Header Section'}
      dialogContent={
        <RemoveQuestionnaireSectionConfirmContent
          loading={isLoading}
          handleDialogOpened={handleDialogOpened}
          questionnaireSections={questionnaireSections}
          removeSectionModalData={removeSectionModalData}
        />
      }
      // img={deleteAnimationData}
      // loading={questionnaireData?.loading}
    />
  );
};

export default RemoveTemplateQuestionSectionConfirmationModal;
