import * as React from 'react';
import { ErrorMessage, Field } from 'formik';
import { Box } from '@mui/material';
import { CheckboxWithLabel } from 'formik-mui';

import TextError from './TextError';

const FormikCheckbox = (props) => {
  const { name, label, disabled, isErrorMessage, ...otherProps } = props;

  return (
    <Box>
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        name={name}
        Label={{ label: label }}
        disabled={disabled}
        {...(otherProps || {})}
      />
      {isErrorMessage && <ErrorMessage component={TextError} name={name} />}
    </Box>
  );
};

export default FormikCheckbox;
