import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create new financial position
  saveFinancialPositionRequestLoading: false,
  saveFinancialPositionData: {},
  saveFinancialPositionFailed: {},

  isFinancialPositionsListLoading: false,
  financialPositionsListData: null,
  financialPositionsListFailed: null,

  isFinancialPositionUpdateRequestLoading: false,
  financialPositionUpdateRequestSuccessData: {},
  financialPositionUpdateRequestFailedData: {},

  isFinancialPositionDeleteRequestLoading: false,
  financialPositionDeleteRequestSuccessData: {},
  financialPositionDeleteRequestFailedData: {},

  isFinancialActivePositionUpdateRequestLoading: false,
  financialActivePositionUpdateRequestSuccessData: {},
  financialActivePositionUpdateRequestFailedData: {},

  isFinancialPositionGetRequestLoading: false,
  financialPositionGetRequestSuccessData: {},
  financialPositionGetRequestFailedData: {},

  isFinancialPositionGetSummaryRequestLoading: false,
  financialPositionGetSummaryRequestSuccessData: {},
  financialPositionGetSummaryRequestFailedData: {},
};

export const financialPositionsReducer = createReducer(initialState, {
  //save financial Positions
  postSaveFinancialPositionRequest: (state) => {
    state.saveFinancialPositionRequestLoading = true;
    state.saveFinancialPositionData = {};
    state.saveFinancialPositionFailed = {};
  },
  postSaveFinancialPositionSuccess: (state, action) => {
    state.saveFinancialPositionRequestLoading = false;
    state.saveFinancialPositionData = action.payload;
    state.saveFinancialPositionFailed = {};
  },
  postSaveFinancialPositionFailed: (state, action) => {
    state.saveFinancialPositionRequestLoading = false;
    state.saveFinancialPositionData = {};
    state.saveFinancialPositionFailed = action.payload;
  },

  //get all financial Positions
  postGetFinancialPositionsRequest: (state) => {
    state.isFinancialPositionsListLoading = true;
    state.financialPositionsListData = null;
    state.financialPositionsListFailed = {};
  },
  postGetFinancialPositionsSuccess: (state, action) => {
    state.isFinancialPositionsListLoading = false;
    state.financialPositionsListData = action.payload;
    state.financialPositionsListFailed = {};
  },
  postGetFinancialPositionsFailed: (state, action) => {
    state.isFinancialPositionsListLoading = false;
    state.financialPositionsListData = null;
    state.financialPositionsListFailed = action.payload;
  },

  //update a financial Position
  patchUpdateFinancialPositionRequest: (state) => {
    state.isFinancialPositionUpdateRequestLoading = true;
    state.financialPositionUpdateRequestSuccessData = [];
    state.financialPositionUpdateRequestFailedData = {};
  },
  patchUpdateFinancialPositionRequestSuccess: (state, action) => {
    state.isFinancialPositionUpdateRequestLoading = false;
    state.financialPositionUpdateRequestSuccessData = action.payload;
    state.financialPositionUpdateRequestFailedData = {};
  },
  patchUpdateFinancialPositionRequestFailed: (state, action) => {
    state.isFinancialPositionUpdateRequestLoading = false;
    state.financialPositionUpdateRequestSuccessData = [];
    state.financialPositionUpdateRequestFailedData = action.payload;
  },

  //delete a financial Position

  deleteFinancialPositionRequest: (state) => {
    state.isFinancialPositionDeleteRequestLoading = true;
    state.financialPositionDeleteRequestSuccessData = [];
    state.financialPositionDeleteRequestFailedData = {};
  },
  deleteFinancialPositionRequestSuccess: (state, action) => {
    state.isFinancialPositionDeleteRequestLoading = false;
    state.financialPositionDeleteRequestSuccessData = action.payload;
    state.financialPositionDeleteRequestFailedData = {};
  },
  deleteFinancialPositionRequestFailed: (state, action) => {
    state.isFinancialPositionDeleteRequestLoading = false;
    state.financialPositionDeleteRequestSuccessData = [];
    state.financialPositionDeleteRequestFailedData = action.payload;
  },

  //change active Position
  patchUpdateActiveFinancialPositionRequest: (state) => {
    state.isFinancialActivePositionUpdateRequestLoading = true;
    state.financialActivePositionUpdateRequestSuccessData = {};
    state.financialActivePositionUpdateRequestFailedData = {};
  },
  patchUpdateActiveFinancialPositionRequestSuccess: (state, action) => {
    state.isFinancialActivePositionUpdateRequestLoading = false;
    state.financialActivePositionUpdateRequestSuccessData = action.payload;
    state.financialActivePositionUpdateRequestFailedData = {};
  },
  patchUpdateActiveFinancialPositionRequestFailed: (state, action) => {
    state.isFinancialActivePositionUpdateRequestLoading = false;
    state.financialActivePositionUpdateRequestSuccessData = {};
    state.financialActivePositionUpdateRequestFailedData = action.payload;
  },

  //get financial position
  getFinancialPositionRequest: (state) => {
    state.isFinancialPositionGetRequestLoading = true;
    state.financialPositionGetRequestSuccessData = {};
    state.financialPositionGetRequestFailedData = {};
  },
  getPositionRequestSuccess: (state, action) => {
    state.isFinancialPositionGetRequestLoading = false;
    state.financialPositionGetRequestSuccessData = action.payload;
    state.financialPositionGetRequestFailedData = {};
  },
  getPositionRequestFailed: (state, action) => {
    state.isFinancialPositionGetRequestLoading = false;
    state.financialPositionGetRequestSuccessData = {};
    state.financialPositionGetRequestFailedData = action.payload;
  },

  //get financial position summary
  getFinancialPositionSummaryRequest: (state) => {
    state.isFinancialPositionGetSummaryRequestLoading = true;
    state.financialPositionGetSummaryRequestSuccessData = {};
    state.financialPositionGetSummaryRequestFailedData = {};
  },
  getFinancialPositionSummarySuccess: (state, action) => {
    state.isFinancialPositionGetSummaryRequestLoading = false;
    state.financialPositionGetSummaryRequestSuccessData = action.payload;
    state.financialPositionGetSummaryRequestFailedData = {};
  },
  getFinancialPositionSummaryFailed: (state, action) => {
    state.isFinancialPositionGetSummaryRequestLoading = false;
    state.financialPositionGetSummaryRequestSuccessData = {};
    state.financialPositionGetSummaryRequestFailedData = action.payload;
  },
});
