import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isDocumentRequestLoading: false,
  documentRequestSuccessData: [],
  documentRequestFailedData: null,

  isDocumentCreateRequestLoading: false,
  documentCreateRequestSuccessData: null,
  documentCreateRequestFailedData: null,

  isDocumentUpdateRequestLoading: false,
  documentUpdateRequestSuccessData: null,
  documentUpdateRequestFailedData: null,

  isDocumentDeleteRequestLoading: false,
  documentDeleteRequestSuccessData: null,
  documentDeleteRequestFailedData: null
};

export const clientDocumentsReducer = createReducer(initialState, {
  //_get documents
  clientDocumentsGetRequest: (state) => {
    state.isDocumentRequestLoading = true;
    state.documentRequestSuccessData = [];
    state.documentRequestFailedData = null
  },
  clientDocumentsGetRequestSuccess: (state, action) => {
    state.isDocumentRequestLoading = false;
    state.documentRequestSuccessData = action.payload;
    state.documentRequestFailedData = null
  },
  clientDocumentsGetRequestFailed: (state, action) => {
    state.isDocumentRequestLoading = false;
    state.documentRequestSuccessData = [];
    state.documentRequestFailedData = action.payload
  },

  //_save document
  clientDocumentCreateRequest: (state) => {
    state.isDocumentCreateRequestLoading = true;
    state.documentCreateRequestSuccessData = null;
    state.documentCreateRequestFailedData = null;
  },
  clientDocumentCreateRequestSuccess: (state, action) => {
    state.isDocumentCreateRequestLoading = false;
    state.documentCreateRequestSuccessData = null;
    state.documentCreateRequestFailedData = null;
  },
  clientDocumentCreateRequestFailed: (state, action) => {
    state.isDocumentCreateRequestLoading = false;
    state.documentCreateRequestSuccessData = null;
    state.documentCreateRequestFailedData = null;
  },

  //_update document
  clientDocumentUpdateRequest: (state) => {
    state.isDocumentUpdateRequestLoading = true;
    state.documentUpdateRequestSuccessData = null;
    state.documentUpdateRequestFailedData = null;
  },
  clientDocumentUpdateRequestSuccess: (state, action) => {
    state.isDocumentUpdateRequestLoading = false;
    state.documentUpdateRequestSuccessData = null;
    state.documentUpdateRequestFailedData = null;
  },
  clientDocumentUpdateRequestFailed: (state, action) => {
    state.isDocumentUpdateRequestLoading = false;
    state.documentUpdateRequestSuccessData = null;
    state.documentUpdateRequestFailedData = null;
  },
  
  //_delete document
  clientDocumentDeleteRequest: (state) => {
    state.isDocumentDeleteRequestLoading = true;
    state.documentDeleteRequestSuccessData = null;
    state.documentDeleteRequestFailedData = null;
  },
  clientDocumentDeleteRequestSuccess: (state, action) => {
    state.isDocumentDeleteRequestLoading = false;
    state.documentDeleteRequestSuccessData = null;
    state.documentDeleteRequestFailedData = null;
  },
  clientDocumentDeleteRequestFailed: (state, action) => {
    state.isDocumentDeleteRequestLoading = false;
    state.documentDeleteRequestSuccessData = null;
    state.documentDeleteRequestFailedData = null;
  },
  
});
