import {
  AddRounded,
  ArrowDownward,
  ArrowUpward,
  BorderColorOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  PeopleOutlineRounded,
  TableChartOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Fade,
  Grid,
  Grow,
  Icon,
  IconButton,
  Paper,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import FormikLabeledToggleSwitch from 'common/formik/FormikLabeledToggleSwitch';
import { gridSpacing } from 'store/constant';

import { ReactComponent as RequiredQuestionIcon } from 'assets/images/icons/required-question.svg';
import { ReactComponent as QuestionRemoveIcon } from 'assets/images/icons/question-delete.svg';
import { useFormikContext } from 'formik';
import { DataGrid } from '@mui/x-data-grid';
import ConfigureTableAnswerFormatModal from 'modal/questionnaire/ConfigureTableAnswerFormatModal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { format, isDate, isValid } from 'date-fns';
import MultiSelectCellView from './MultiSelectCellView';

const MultipleTableAnswerQuestion = (props) => {
  const {
    questionData,
    questionDataIndex,
    isSelectedQuestionnaire,
    isSmallScreens,
    isQuestionEdit,
    editData,
    setEditData,
    handleSaveQuestion,
    isPopoverOpen,
    popperData,
    isSmallerScreens,
    fieldTypeBtnRef,
    handleOnFieldTypeBtnClick,
    handleGeFieldTypeData,
    fieldPersonTypeRef,
    handleOnPersonTypeBtnClick,
    partnerDetails,
    selectedClientDetail,
    isQuestionCaptionEdit,
    isShowClientAnswer,
    isShowPartnerAnswer,
    handleAddNewQuestionnaire,
    handleRemoveQuestion,
    handleMoveQuestion,
    handleSaveQuestionCaption,
    clientType,
    isTemplateBuilder,
  } = props;

  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  const formikValues = formikContext?.values;

  //_answers
  const leadOrClientTableAnswer =
    formikValues.questionnaires?.[questionDataIndex]?.answer?.client?.multipleTabularAnswers;
  const partnerTableAnswer =
    formikValues?.questionnaires?.[questionDataIndex]?.answer?.partner?.multipleTabularAnswers;

  const [configureTableModalData, setConfigureTableModalData] = useState({
    isOpened: false,
    modalData: null,
  });

  //_memo field type button label
  const fieldTypeSelectorBtnLabel = useMemo(() => {
    if (isTemplateBuilder) {
      return questionData?.personType === 'CLIENT'
        ? clientType === 'LEAD'
          ? 'Lead'
          : 'Client'
        : questionData?.personType === 'PARTNER'
        ? 'Partner'
        : questionData?.personType === 'BOTH' && 'Both';
    } else {
      return (
        (questionData?.personType === 'CLIENT'
          ? `${selectedClientDetail?.firstName || ''} (${
              clientType === 'LEAD' ? 'Lead' : 'Client'
            })`
          : questionData?.personType === 'PARTNER'
          ? `${partnerDetails?.firstName || ''} (Partner)`
          : questionData?.personType === 'BOTH' && 'Both') ||
        `${selectedClientDetail?.firstName || ''} (${clientType === 'LEAD' ? 'Lead' : 'Client'})`
      );
    }
  }, [questionData, partnerDetails, selectedClientDetail, isTemplateBuilder, clientType]);

  /**
   * Handle render multi select cell view
   * @param {*} params
   * @returns
   */
  const renderMultiSelectCellView = (params) => {
    return <MultiSelectCellView {...params} />;
  };

  /**
   * handler render data grid component
   */
  const handleGenerateDataGrid = useCallback(
    (answerType) => {
      const answers = formikValues?.questionnaires?.[questionDataIndex]?.answer || [];
      const answerRows =
        answerType === 'clientAnswer'
          ? answers?.client?.multipleTabularAnswers?.[1] || []
          : answerType === 'partnerAnswer'
          ? answers?.partner?.multipleTabularAnswers?.[1] || []
          : [];
      const tableColumns = (
        answerType === 'clientAnswer'
          ? answers?.client?.multipleTabularAnswers?.[0] || []
          : answerType === 'partnerAnswer'
          ? answers?.partner?.multipleTabularAnswers?.[0] || []
          : []
      )
        .filter((columnDetail) => columnDetail?.field !== 'actions')
        .map((column) => ({
          ...column,
          editable: false,
          ...(column?.type === 'singleSelect'
            ? {
                valueOptions: column?.valueOptions?.map((option) => ({
                  ...option,
                  label: option?.title,
                  value: option?.value,
                })),
              }
            : column?.type === 'date'
            ? {
                valueFormatter: (params) => {
                  if (
                    !params?.value ||
                    !isValid(new Date(params?.value)) ||
                    !isDate(new Date(params?.value))
                  ) {
                    return '';
                  }

                  const valueFormatted = format(new Date(params.value), 'yyyy-MM-dd');
                  return valueFormatted;
                },
              }
            : column?.type === 'dateTime'
            ? {
                valueFormatter: (params) => {
                  if (
                    !params?.value ||
                    !isValid(new Date(params?.value)) ||
                    !isDate(new Date(params?.value))
                  ) {
                    return '';
                  }

                  const valueFormatted = format(new Date(params?.value), 'yyyy-MM-dd hh:mm aa');
                  return valueFormatted;
                },
              }
            : column?.type === 'multiSelect'
            ? {
                type: undefined,
                renderCell: renderMultiSelectCellView,
                valueOptions: column?.valueOptions?.map((option) => ({
                  ...option,
                  label: option?.title,
                  value: option?.value,
                })),
              }
            : {}),
        }));
      return (
        <Grid item xs={12}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <DataGrid
              autoHeight={true}
              rows={answerRows}
              columns={tableColumns}
              getRowId={(row) => row?._id || row.id}
              checkboxSelection={false}
              sx={(theme) => ({
                backgroundColor: theme.palette.white,
              })}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </Box>
        </Grid>
      );
    },
    [formikValues?.questionnaires]
  );
  /**
   * Handle open/close configure dialog opened
   */
  const handleConfigureDialogOpened = useCallback((isOpened = false, modalData = null) => {
    setConfigureTableModalData((prev) => ({
      ...prev,
      isOpened,
      modalData,
    }));
  }, []);

  return (
    <Grid container spacing={gridSpacing} wrap="nowrap">
      <ConfigureTableAnswerFormatModal
        isOpened={configureTableModalData?.isOpened}
        tableAnswerModalData={configureTableModalData?.modalData}
        questionData={questionData}
        actionDataIndex={questionDataIndex}
        handleDialogOpened={handleConfigureDialogOpened}
      />
      <Tooltip
        title={isSelectedQuestionnaire ? '' : 'Click to select this question'}
        arrow
        placement="top"
      >
        <Grid
          item
          xs={true}
          sx={{
            cursor: 'pointer',
            border: (theme) =>
              '1px solid ' + theme.palette?.grey?.[isSelectedQuestionnaire ? 325 : 300],
            borderRadius: '0.5rem',
            WebkitTransition: 'border-radius 0.5s, box-shadow 0.75s, border 0.5s',
            boxShadow: (theme) =>
              isSelectedQuestionnaire
                ? `5px 0px 8px 0px ${
                    theme.palette?.grey?.[isSelectedQuestionnaire ? 325 : 300]
                  }, -4px 0px 0px 0px ${theme.palette.blue?.[350]}`
                : `0.25px 0px 8px 2px  ${
                    theme.palette?.grey?.[isSelectedQuestionnaire ? 325 : 300]
                  }`,
            paddingTop: '0 !important',
            paddingLeft: '0 !important',
            minHeight: '5rem',
            backgroundColor: (theme) => theme.palette.background?.paper,
            maxHeight: 'fit-content',
          }}
        >
          <Grid
            container
            columnSpacing={gridSpacing}
            alignItems="flex-start"
            flexWrap={isSmallScreens ? 'wrap-reverse' : 'nowrap'}
            sx={{ paddingLeft: '1rem !important' }}
          >
            <Grid item xs={12} md={true} sx={{ paddingTop: '1rem !important' }}>
              <Collapse in={!isQuestionEdit || !isSelectedQuestionnaire} timeout={300}>
                <Fade in={!isQuestionEdit || !isSelectedQuestionnaire} timeout={100}>
                  <Stack direction="row" flexWrap="nowrap" spacing={1} alignItems="center">
                    {!!questionData?.isRequired && (
                      <Tooltip title="Answer is required" arrow placement="top">
                        <Icon viewBox="0 0 36 36">
                          <SvgIcon
                            viewBox="0 0 36 36"
                            component={RequiredQuestionIcon}
                            sx={{ fontSize: '0.75rem', borderRadius: '0.2rem' }}
                          />
                        </Icon>
                      </Tooltip>
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        paddingRight: '1rem !important',
                        color: (theme) => theme.palette.text?.primary,
                      }}
                    >
                      {questionData?.question}
                    </Typography>
                  </Stack>
                </Fade>
              </Collapse>
              <Collapse in={!!isQuestionEdit && isSelectedQuestionnaire} timeout={300}>
                <Fade in={!!isQuestionEdit && isSelectedQuestionnaire} timeout={100}>
                  <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                    <TextField
                      fullWidth
                      sx={{
                        paddingRight: '1.25rem',
                        '& .MuiInputBase-root': {
                          '& .MuiInputBase-input': {
                            paddingBlock: '0.25rem !important',
                          },
                        },
                      }}
                      label="Question"
                      value={editData?.question || ''}
                      onChange={(event) =>
                        setEditData((prev) => ({
                          ...prev,
                          question: event?.target?.value || '',
                        }))
                      }
                      variant="standard"
                      error={!editData?.question}
                      helperText={!editData?.question ? 'Question is required!' : ' '}
                      onBlur={(event) => handleSaveQuestion(event)}
                    />
                  </Stack>
                </Fade>
              </Collapse>
            </Grid>
            <Fade in={isSelectedQuestionnaire} unmountOnExit>
              <Grid item xs={12} md={'auto'} sx={{ paddingTop: '0 !important' }}>
                <Paper
                  sx={(theme) => ({
                    border: '1px solid ' + theme.palette.blue?.[350],
                    borderTop: 'none',
                    borderRadius: `0 0 ${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px !important`,
                    WebkitTransition: 'border-radius 0.5s, box-shadow 0.75s',
                    boxShadow: isSelectedQuestionnaire
                      ? `0px 1px 0px 0px ${theme.palette.blue?.[350]}`
                      : undefined,
                    height: '100%',
                    mx: '0.5rem',
                    scale: '0.75',
                    marginTop: isSmallScreens ? '-1.125rem !important' : '-0.375rem !important',
                    width: isSmallScreens ? '125%' : '100%',
                    marginLeft: isSmallScreens && '-15%',
                  })}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{
                      position: 'relative',
                      flexDirection: isSmallerScreens ? 'column' : 'row',
                    }}
                  >
                    <Tooltip
                      title="Toggle this to mark this question as required"
                      arrow
                      placement="top"
                    >
                      <Box>
                        <FormikLabeledToggleSwitch
                          name={`questionnaires[${questionDataIndex}].isRequired`}
                          label={'Required'}
                          labelPlacement="start"
                        />
                      </Box>
                    </Tooltip>
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={(theme) => ({ backgroundColor: theme.palette?.blue?.[350] })}
                    />
                    <Tooltip
                      title="Click here to change the type of this question."
                      arrow
                      placement="top"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="grey"
                        startIcon={<BorderColorOutlined fontSize="0.875rem" />}
                        endIcon={
                          isPopoverOpen && popperData?.action === 'EDIT_FIELD_TYPE' ? (
                            <KeyboardArrowUpRounded />
                          ) : (
                            <KeyboardArrowDownRounded />
                          )
                        }
                        sx={(theme) => ({
                          background: theme.palette.grey?.[50],
                          borderRadius: 0,
                          marginLeft: '0 !important',
                          minHeight: '3rem',
                          boxShadow: 'none',
                          minWidth: 'fit-content',
                          maxWidth: isSmallerScreens ? '12ch' : undefined,
                          whiteSpace: 'pre-wrap',
                        })}
                        ref={fieldTypeBtnRef}
                        onClick={handleOnFieldTypeBtnClick}
                      >
                        {handleGeFieldTypeData('fieldTypeBtnLabel')}
                      </Button>
                    </Tooltip>
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={(theme) => ({
                        marginLeft: '0 !important',
                        backgroundColor: theme.palette?.blue?.[350],
                      })}
                    />
                    <Tooltip
                      title="Click here to change the person who will answer the question."
                      arrow
                      placement="top"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<PeopleOutlineRounded fontSize="0.875rem" />}
                        endIcon={
                          isPopoverOpen && popperData?.action === 'EDIT_PERSON_TYPE' ? (
                            <KeyboardArrowUpRounded />
                          ) : (
                            <KeyboardArrowDownRounded />
                          )
                        }
                        color="grey"
                        sx={(theme) => ({
                          background: theme.palette.grey?.[50],
                          borderRadius: isSmallerScreens
                            ? `0 0 ${theme?.shape.globalBorderRadius}px ${theme?.shape.globalBorderRadius}px`
                            : `0 0 ${theme?.shape.globalBorderRadius}px 0`,
                          marginLeft: '0 !important',
                          minHeight: '3rem',
                          boxShadow: 'none',
                          minWidth: 'fit-content',
                          maxWidth: isSmallerScreens ? '12ch' : undefined,
                          whiteSpace: 'pre-wrap',
                        })}
                        ref={fieldPersonTypeRef}
                        onClick={handleOnPersonTypeBtnClick}
                      >
                        {fieldTypeSelectorBtnLabel}
                      </Button>
                    </Tooltip>
                  </Stack>
                </Paper>
              </Grid>
            </Fade>
          </Grid>
          <Grid container spacing={gridSpacing} alignItems="center">
            <Grid item xs={12} sx={{ paddingLeft: '2rem !important' }}>
              <Collapse
                in={
                  (!isQuestionCaptionEdit || !isSelectedQuestionnaire) &&
                  !!questionData?.questionCaption
                }
                timeout={300}
              >
                <Fade
                  in={
                    (!isQuestionCaptionEdit || !isSelectedQuestionnaire) &&
                    !!questionData?.questionCaption
                  }
                  timeout={100}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    flexWrap="nowrap"
                    alignItems="center"
                    paddingTop="0.875rem"
                  >
                    <Typography
                      variant="caption"
                      component={
                        questionData?.questionCaption?.startsWith &&
                        (questionData.questionCaption.startsWith('https://') ||
                          questionData.questionCaption.startsWith('http://'))
                          ? 'a'
                          : 'span'
                      }
                      sx={{ paddingRight: '1rem' }}
                      href={
                        questionData?.questionCaption?.startsWith &&
                        (questionData.questionCaption.startsWith('https://') ||
                          questionData.questionCaption.startsWith('http://'))
                          ? questionData.questionCaption
                          : ''
                      }
                    >
                      {questionData?.questionCaption}
                    </Typography>
                  </Stack>
                </Fade>
              </Collapse>
              <Collapse
                in={
                  (!!isQuestionCaptionEdit || !questionData?.questionCaption) &&
                  isSelectedQuestionnaire
                }
                timeout={300}
              >
                <Fade
                  in={
                    (!!isQuestionCaptionEdit || !questionData?.questionCaption) &&
                    isSelectedQuestionnaire
                  }
                  timeout={100}
                >
                  <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                    <TextField
                      fullWidth
                      sx={{
                        paddingRight: '1.25rem',
                        '& .MuiInputBase-root': {
                          '& .MuiInputBase-input': {
                            paddingBlock: '0.25rem !important',
                          },
                        },
                      }}
                      label="Question Caption"
                      value={editData?.questionCaption || ''}
                      onChange={(event) =>
                        setEditData((prev) => ({
                          ...prev,
                          questionCaption: event?.target?.value || '',
                        }))
                      }
                      placeholder="Enter caption for this question here.."
                      variant="standard"
                      onBlur={(event) => handleSaveQuestionCaption(event)}
                    />
                  </Stack>
                </Fade>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={isShowPartnerAnswer || isShowClientAnswer} unmountOnExit sx={{}}>
                <Grow
                  in={isShowPartnerAnswer || isShowClientAnswer}
                  style={{ transformOrigin: '0 0 0' }}
                >
                  <Fade in={isShowPartnerAnswer || isShowClientAnswer}>
                    <Grid
                      container
                      spacing={gridSpacing}
                      flexDirection={isSmallerScreens ? 'column' : 'row'}
                    >
                      <Grid item xs={12}>
                        <Divider
                          flexItem
                          orientation="horizontal"
                          variant="fullWidth"
                          sx={{ paddingTop: isSelectedQuestionnaire ? '2rem' : '1rem' }}
                        />
                      </Grid>
                      <Collapse
                        in={isShowClientAnswer}
                        unmountOnExit
                        sx={{ display: 'flex', flex: 1, paddingTop: '0.875rem' }}
                      >
                        <Grow in={isShowClientAnswer}>
                          <Fade in={isShowClientAnswer}>
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="center"
                              alignItems="center"
                              spacing={gridSpacing}
                              sx={(theme) => ({
                                paddingInline: '2rem 1rem',
                                paddingBottom: '0.875rem',
                                '& div': {
                                  '& .MuiFormGroup-root': {
                                    '& .MuiStack-root': {
                                      '& .MuiFormControlLabel-root': {
                                        [isSmallerScreens && 'marginLeft']: isSmallerScreens
                                          ? '0 !important'
                                          : undefined,
                                        '& .Mui-checked': {
                                          color: theme.palette.primary.dark + ' !important',
                                        },
                                        '& .Mui-checked + .MuiFormControlLabel-label': {
                                          color: theme.palette.primary.dark + ' !important',
                                        },
                                      },
                                    },
                                  },
                                },
                              })}
                            >
                              <Grid item xs>
                                <Grid container alignItems="center" justifyContent="center">
                                  <Grid item xs="auto">
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        userSelect: 'none',
                                        display: 'flex',
                                        whiteSpace: 'pre-line',
                                        maxWidth: isSmallerScreens ? '10ch' : undefined,
                                        fontSize: Array.isArray(leadOrClientTableAnswer)
                                          ? '0.875rem'
                                          : '0.75rem',
                                        color: (theme) =>
                                          Array.isArray(leadOrClientTableAnswer)
                                            ? theme.palette.primary.dark
                                            : theme.palette.text.disabled,
                                      }}
                                    >
                                      Answers of{' '}
                                      {(!isTemplateBuilder && selectedClientDetail?.firstName) ||
                                        ''}{' '}
                                      ({clientType === 'LEAD' ? 'Lead' : 'Client'})
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs="auto">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  startIcon={<TableChartOutlined />}
                                  onClick={() =>
                                    handleConfigureDialogOpened(true, {
                                      questionData,
                                      isPartner: false,
                                    })
                                  }
                                >
                                  Edit Table
                                </Button>
                              </Grid>
                              {handleGenerateDataGrid('clientAnswer')}
                            </Grid>
                          </Fade>
                        </Grow>
                      </Collapse>
                      <Divider flexItem orientation="vertical" variant="fullWidth" />
                      <Collapse
                        in={isShowPartnerAnswer}
                        unmountOnExit
                        sx={{ display: 'flex', flex: 1, paddingTop: '0.875rem' }}
                      >
                        <Grow in={isShowPartnerAnswer}>
                          <Fade in={isShowPartnerAnswer}>
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="center"
                              alignItems="center"
                              sx={(theme) => ({
                                paddingBottom: '0.875rem',
                                flexDirection: 'column',
                                '& div': {
                                  '& .MuiFormGroup-root': {
                                    '& .MuiStack-root': {
                                      '& .MuiFormControlLabel-root': {
                                        [isSmallerScreens && 'marginLeft']: isSmallerScreens
                                          ? '0 !important'
                                          : undefined,
                                        '& .Mui-checked': {
                                          color: theme.palette.primary.dark + ' !important',
                                        },
                                        '& .Mui-checked + .MuiFormControlLabel-label': {
                                          color: theme.palette.primary.dark + ' !important',
                                        },
                                      },
                                    },
                                  },
                                },
                              })}
                            >
                              <Grid item xs>
                                <Grid container alignItems="center" justifyContent="center">
                                  <Grid item xs={'auto'}>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        userSelect: 'none',
                                        display: 'flex',
                                        whiteSpace: 'pre-line',
                                        maxWidth: isSmallerScreens ? '10ch' : undefined,
                                        fontSize: Array.isArray(partnerTableAnswer)
                                          ? '0.875rem'
                                          : '0.75rem',
                                        color: (theme) =>
                                          Array.isArray(partnerTableAnswer)
                                            ? theme.palette.primary.dark
                                            : theme.palette.text.disabled,
                                      }}
                                    >
                                      Answers of{' '}
                                      {(!isTemplateBuilder && partnerDetails?.firstName) || ''}{' '}
                                      (Partner)
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={'auto'}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  startIcon={<TableChartOutlined />}
                                  onClick={() =>
                                    handleConfigureDialogOpened(true, {
                                      questionData,
                                      isPartner: true,
                                    })
                                  }
                                >
                                  Edit Table
                                </Button>
                              </Grid>
                              {handleGenerateDataGrid('partnerAnswer')}
                            </Grid>
                          </Fade>
                        </Grow>
                      </Collapse>
                    </Grid>
                  </Fade>
                </Grow>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
      <Fade in={isSelectedQuestionnaire}>
        <Grid item xs={'auto'} sx={{ zIndex: 150, overflow: 'visible' }}>
          <Paper
            sx={{
              marginTop: '-16px',
              backgroundColor: (theme) => theme.palette.background.paper,
              border: (theme) =>
                '1px solid ' + theme.palette?.grey?.[isSelectedQuestionnaire ? 325 : 300],
              borderRadius: '0.5rem',
              WebkitTransition: 'border-radius 0.5s, box-shadow 0.75s, border 0.5s',
              boxShadow: (theme) =>
                `0.25px 0px 8px 2px ${theme.palette?.grey?.[isSelectedQuestionnaire ? 325 : 300]}`,
            }}
          >
            <Grid container direction="column" alignItems="center" justifyContent={'center'}>
              <Grid item xs="auto">
                <Tooltip title="Add new question" arrow placement="left">
                  <IconButton
                    color="primary"
                    id={`addFieldBtn-${questionDataIndex}`}
                    onClick={(event) =>
                      handleAddNewQuestionnaire &&
                      handleAddNewQuestionnaire(event, questionDataIndex)
                    }
                  >
                    <AddRounded />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs="auto">
                <Tooltip title="Remove this question" arrow placement="left">
                  <IconButton
                    id={`removeFieldBtn-${questionDataIndex}`}
                    onClick={(event) =>
                      handleRemoveQuestion &&
                      handleRemoveQuestion(
                        true,
                        { actionDataIndex: questionDataIndex, ...(questionData || {}) },
                        questionDataIndex
                      )
                    }
                  >
                    <SvgIcon viewBox="0 0 24 24" component={QuestionRemoveIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              {questionDataIndex > 1 && (
                <Grid item xs="auto">
                  <Tooltip title="Move this question Up" arrow placement="left">
                    <IconButton
                      id={`removeFieldBtn-${questionDataIndex}`}
                      onClick={(event) => handleMoveQuestion('UP', questionDataIndex, event)}
                    >
                      <ArrowUpward />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {questionDataIndex < formikValues?.questionnaires?.length - 1 && (
                <Grid item xs="auto">
                  <Tooltip title="Move this question Down" arrow placement="left">
                    <IconButton
                      id={`removeFieldBtn-${questionDataIndex}`}
                      onClick={(event) => handleMoveQuestion('DOWN', questionDataIndex, event)}
                    >
                      <ArrowDownward />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default MultipleTableAnswerQuestion;
