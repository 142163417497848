import React, { memo } from 'react';
import { Grid, Typography, Stack, IconButton, TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';

import { gridSpacing } from 'store/constant';

import PrimaryButton from 'ui-component/buttons/PrimaryButton';
import SecondaryButton from 'ui-component/buttons/SecondaryButton';
import BackButton from 'ui-component/buttons/BackButton';
import { useCommonStyles } from 'styles/styles';

const TitleContainer = (props) => {
  const {
    title,
    value,
    onChange,
    secondaryButtonTitle,
    clickSecondaryButton,
    primaryButtonTitle,
    clickPrimaryButton,
    isPrimaryButton,
    isSecondaryButton,
    isSearchBox,
    isSearchBoxDisabled,
    searchBoxLabel,
    secondarySvgIcon,
    primarySvgIcon,
    isTabButtons,
    CustomizedTabComponent,
    wrapperSx,
    isIconComponent,
    iconComponent,
  } = props;

  const { classes: commonClasses } = useCommonStyles();

  TitleContainer.defaultProps = {
    isPrimaryButton: true,
    isSecondaryButton: true,
    isSearchBox: true,
  };

  return (
    <Grid container spacing={gridSpacing} sx={wrapperSx}>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'space-between'} spacing={0}>
          <Typography className={commonClasses.mainTitle}>{title}</Typography>
          {isIconComponent && iconComponent}
        </Stack>

        <Stack direction={'row'} spacing={0}>
          <BackButton />
        </Stack>
      </Grid>
      {isTabButtons && CustomizedTabComponent && (
        <Grid container item xs={12} justifyContent="flex-end">
          {CustomizedTabComponent}
        </Grid>
      )}
      {!!(isSearchBox || isSecondaryButton || isPrimaryButton) && (
        <Grid container item xs={12}>
          <Grid container item xs={12} sm={12} md={6} lg={6}>
            {isSearchBox && (
              <TextField
                id="search-by-client-name"
                label={searchBoxLabel || 'Search by Client Name '}
                variant="outlined"
                fullWidth
                value={value}
                onChange={onChange}
                disabled={isSearchBoxDisabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>
          <Grid container justifyContent={'flex-end'} item xs={12} sm={12} md={6} lg={6}>
            <Stack direction="row" spacing={2}>
              {isSecondaryButton && (
                <SecondaryButton
                  label={secondaryButtonTitle}
                  icon={secondarySvgIcon}
                  onClick={clickSecondaryButton}
                />
              )}
              {isPrimaryButton && (
                <PrimaryButton
                  label={primaryButtonTitle}
                  icon={primarySvgIcon}
                  onClick={clickPrimaryButton}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

TitleContainer.defaultProps = {
  value: '',
};

TitleContainer.propTypes = {
  CustomizedTabComponent: PropTypes.elementType,
};

export default memo(TitleContainer);
