import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { clientDocumentsReducer } from './Reducers/clientDocumentsReducer';

import { customization } from './Reducers/LayoutReducer';

import { notificationReducer } from './Reducers/handleNotificationReducer';

import { modalReducer } from './Reducers/modalReducer';

import { digitalDocReducer } from './Reducers/digitalDocReducer';
// login, signup
import { authenticationReducer } from './Reducers/authenticationReducer';
//module
import { leadsReducer } from './Reducers/leadsReducer';
import { clientReducer } from './Reducers/clientReducer';
import { compositeDataReducer } from './Reducers/compositeDataReducer';
import { filesUploadReducer } from './Reducers/filesUploadReducer';
import { tasksReducer } from './Reducers/tasksReducer';
import { goalsReducer } from './Reducers/goalsReducer';
import { teamMembersReducer } from './Reducers/teamMembersReducer';
import { clientInvitationReducer } from './Reducers/clientInvitationReducer';
import { emailPostsReducer } from './Reducers/emailReducer';
import { superannuationReducer } from './Reducers/superannuationReducer';
import { investorProfileSettingsReducer } from './Reducers/investorProfileSettingsReducer';
import { superannuationSettingsReducer } from './Reducers/superannuationSettingsReducer';
import { emailNotificationSettingsReducer } from './Reducers/emailNotificationsSettingsReducer';
import { insuranceModellingSettingsReducer } from './Reducers/insuranceModellingSettingsReducer';
import { organizationSettingsReducer } from './Reducers/organizationSettingsReducer';
import { economicVariableSettingsReducer } from './Reducers/calculationSettingsReducer';
import { clientAppSettingsReducer } from './Reducers/clientAppSettingsReducer';
import { webFormSettingsReducer } from './Reducers/webFormsSettingsReducer';
import { advisorProfileSettingsReducer } from './Reducers/advisorProfileSettingsReducer';
import { fileNotesReducer } from './Reducers/fileNotesReducer';
import { clientAssetReducer } from './Reducers/clientAssetReducer';
import { clientExpenseReducer } from './Reducers/clientExpenseReducer';
import { clientIncomeReducer } from './Reducers/clientIncomeReducer';
import { clientLiabilityReducer } from './Reducers/clientLiabilityReducer';
import { basicInvestmentProjectionReducer } from './Reducers/basicInvestmentProjectionReducer';
import { personIdentitiesReducer } from './Reducers/personIdentitiesReducer';
import { financialPositionsReducer } from './Reducers/financialPositionsReducer';
import { familyAndLifestyleProtectionsReducer } from './Reducers/familyAndLifestyleProtectionsReducer';
import { smsfClientReducer } from './Reducers/smsfClientReducer';

import { smsfAssetReducer } from './Reducers/smsfAssetReducer';
import { smsfIncomeReducer } from './Reducers/smsfIncomeReducer';
import { smsfLiabilityReducer } from './Reducers/smsfLiabilityReducer';
import { smsfExpenseReducer } from './Reducers/smsfExpenseReducer';
import { smsfSMSFFinancialPositionsReducer } from './Reducers/smsfFinancialPositionsReducer';
import { smsfContributionReducer } from './Reducers/smsfContributionReducer';
import { smsfCashflowReducer } from './Reducers/smsfCashflowReducer';
import { paymentsReducer } from './Reducers/paymentsReducer';
import { leadQuestionnairesReducer } from './Reducers/leadQuestionnairesReducer';
import { clientQuestionnairesReducer } from './Reducers/clientQuestionnairesReducer';

import { kanbanBoardReducer } from './Reducers/kanbanBoardReducer';

import { emailTemplateReducer } from './Reducers/emailTemplateReducer';
import { taxSettingReducer } from './Reducers/taxSettingReducer';
import { documentTemplateReducer } from './Reducers/documentTemplateReducer';
import { trustClientReducer } from './Reducers/trustClientReducer';
import { companyClientReducer } from './Reducers/companyClientReducer';
import { superFundCompanyReducer } from './Reducers/superFundCompanyReducer';
import { partnershipClientReducer } from './Reducers/partnershipClientReducer';

import { questionnaireTemplateReducer } from './Reducers/questionnaireTemplateReducer';
import { questionnaireTemplateTagReducer } from './Reducers/questionnaireTemplateTagReducer';
import { assignableMembersReducer } from './Reducers/assignableMembersReducer';

const authenticationPersistConfig = {
  key: 'authenticationReducer',
  storage: storage,
};
const rootReducerPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['authenticationReducer'],
};
//app reducer
const appReducer = combineReducers({
  authenticationReducer: persistReducer(authenticationPersistConfig, authenticationReducer),
  clientDocumentsReducer: clientDocumentsReducer,
  customization: customization,
  notificationReducer: notificationReducer,
  modalReducer: modalReducer,
  digitalDocReducer: digitalDocReducer,
  leadsReducer: leadsReducer,
  clientReducer: clientReducer,
  compositeDataReducer: compositeDataReducer,
  filesUploadReducer: filesUploadReducer,
  tasksReducer: tasksReducer,
  goalsReducer: goalsReducer,
  teamMembersReducer: teamMembersReducer,
  clientInvitationReducer: clientInvitationReducer,
  emailPostsReducer: emailPostsReducer,
  superannuationReducer: superannuationReducer,
  investorProfileSettingsReducer: investorProfileSettingsReducer,
  superannuationSettingsReducer: superannuationSettingsReducer,
  emailNotificationSettingsReducer: emailNotificationSettingsReducer,
  insuranceModellingSettingsReducer: insuranceModellingSettingsReducer,
  organizationSettingsReducer: organizationSettingsReducer,
  economicVariableSettingsReducer: economicVariableSettingsReducer,
  clientAppSettingsReducer: clientAppSettingsReducer,
  webFormSettingsReducer: webFormSettingsReducer,
  advisorProfileSettingsReducer: advisorProfileSettingsReducer,
  fileNotesReducer: fileNotesReducer,
  clientAssetReducer: clientAssetReducer,
  clientExpenseReducer: clientExpenseReducer,
  clientIncomeReducer: clientIncomeReducer,
  clientLiabilityReducer: clientLiabilityReducer,
  basicInvestmentProjectionReducer: basicInvestmentProjectionReducer,
  personIdentitiesReducer: personIdentitiesReducer,
  financialPositionsReducer: financialPositionsReducer,
  familyAndLifestyleProtectionsReducer: familyAndLifestyleProtectionsReducer,
  smsfClientReducer: smsfClientReducer,
  smsfAssetReducer: smsfAssetReducer,
  smsfIncomeReducer: smsfIncomeReducer,
  smsfLiabilityReducer: smsfLiabilityReducer,
  smsfExpenseReducer: smsfExpenseReducer,
  smsfSMSFFinancialPositionsReducer: smsfSMSFFinancialPositionsReducer,
  smsfContributionReducer: smsfContributionReducer,
  smsfCashflowReducer: smsfCashflowReducer,
  paymentsReducer: paymentsReducer,
  leadQuestionnairesReducer: leadQuestionnairesReducer,
  clientQuestionnairesReducer: clientQuestionnairesReducer,
  kanbanBoardReducer: kanbanBoardReducer,
  emailTemplateReducer: emailTemplateReducer,
  taxSettingReducer: taxSettingReducer,
  documentTemplateReducer: documentTemplateReducer,
  trustClientReducer: trustClientReducer,
  companyClientReducer: companyClientReducer,
  superFundCompanyReducer: superFundCompanyReducer,
  partnershipClientReducer: partnershipClientReducer,
  questionnaireTemplateReducer: questionnaireTemplateReducer,
  questionnaireTemplateTagReducer: questionnaireTemplateTagReducer,
  assignableMembersReducer: assignableMembersReducer,
});
//root reducer
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') state = undefined;

  return appReducer(state, action);
};

//configured store
const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
export default Store;
