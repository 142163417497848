import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New Client [SMSF]
  saveSMSFClientRequestLoading: false,
  saveSMSFClientData: {},
  saveSMSFClientFailed: {},

  //Request Client List
  isSMSFClientListRequestLoading: false,
  smsfClientListData: {},
  smsfClientListFailed: {},

  //selected client detail
  isSMSFClientDetailRequestLoading: false,
  selectedSMSFClientDetail: {},
  selectedSMSFClientDetailFailed: {},

  //update SMSF Client detail
  isUpdateSMSFClientRequestLoading: false,
  updateSMSFClientData: {},
  updateSMSFClientFailed: {},

  //update member detail
  isPatchUpdateSMSFMemberRequestLoading: false,
  patchUpdateMemberSuccessData: {},
  patchUpdateMemberFailedData: {},

  //save new smsf member
  isPostSaveNewSMSFMemberRequestLoading: false,
  postSaveSMSFMemberSuccessData: {},
  postSaveSMSFMemberFailedData: {},

  //delete smsf member
  isDeleteSMSFMemberRequestLoading: false,
  deleteSMSFMemberRequestSuccessData: {},
  deleteSMSFMemberRequestFailedData: {},

  // get smsf detail for smsf dashboard
  isSMSFClientDashboardStatDetailRequestLoading: false,
  selectedSMSFClientDashboardStatDetail: {},
  selectedSMSFClientDashboardStatDetailFailed: {},

  //Create Multiple Client [SMSF]
  saveMultipleSMSFClientRequestLoading: false,
  saveMultipleSMSFClientData: {},
  saveMultipleSMSFClientFailed: {},

  //filter smsf
  filterDataSMSFLoading: false,
  filterDataSMSFSuccess: [],
  filterDataSMSFFailed: {},

  //add smsf Entities
  addLinkedEntitiesSMSFLoading: false,
  addLinkedEntitiesSMSFSuccess: null,
  addLinkedEntitiesSMSFFailed: {},

  //delete smsf Entities
  deleteLinkedEntitiesSMSFLoading: false,
  deleteLinkedEntitiesSMSFSuccess: null,
  deleteLinkedEntitiesSMSFFailed: {},
};

export const smsfClientReducer = createReducer(initialState, {
  //save client
  postSaveSMSFClientRequest: (state) => {
    state.saveSMSFClientRequestLoading = true;
    state.saveSMSFClientData = {};
    state.saveSMSFClientFailed = {};
  },
  postSaveSMSFClientSuccess: (state, action) => {
    state.saveSMSFClientRequestLoading = false;
    state.saveSMSFClientData = action.payload;
    state.saveSMSFClientFailed = {};
  },
  postSaveSMSFClientFailed: (state, action) => {
    state.saveSMSFClientRequestLoading = false;
    state.saveSMSFClientData = {};
    state.saveSMSFClientFailed = action.payload;
  },

  //client List
  getAllSMSFClientsRequest: (state) => {
    state.isSMSFClientListRequestLoading = true;
    state.smsfClientListData = {};
    state.smsfClientListFailed = {};
  },
  getAllSMSFClientsSuccess: (state, action) => {
    state.isSMSFClientListRequestLoading = false;
    state.smsfClientListData = action.payload;
    state.smsfClientListFailed = {};
  },
  getAllSMSFClientsFailed: (state, action) => {
    state.isSMSFClientListRequestLoading = false;
    state.smsfClientListData = {};
    state.smsfClientListFailed = action.payload;
  },

  //find client detail
  getSMSFClientByIdRequest: (state) => {
    state.isSMSFClientDetailRequestLoading = true;
    state.selectedSMSFClientDetail = {};
    state.selectedSMSFClientDetailFailed = {};
  },
  getSMSFClientByIdRequestSuccess: (state, action) => {
    state.isSMSFClientDetailRequestLoading = false;
    state.selectedSMSFClientDetail = action.payload;
    state.selectedSMSFClientDetailFailed = {};
  },
  getSMSFClientByIdRequestFailed: (state, action) => {
    state.isSMSFClientDetailRequestLoading = false;
    state.selectedSMSFClientDetail = {};
    state.selectedSMSFClientDetailFailed = action.payload;
  },

  //update client
  patchUpdateSMSFClientRequest: (state) => {
    state.isUpdateSMSFClientRequestLoading = true;
    state.updateSMSFClientData = {};
    state.updateSMSFClientFailed = {};
  },
  patchUpdateSMSFClientRequestSuccess: (state, action) => {
    state.isUpdateSMSFClientRequestLoading = false;
    state.updateSMSFClientData = action.payload;
    state.updateSMSFClientFailed = {};
  },
  patchUpdateSMSFClientRequestFailed: (state, action) => {
    state.isUpdateSMSFClientRequestLoading = false;
    state.updateSMSFClientData = {};
    state.updateSMSFClientFailed = action.payload;
  },

  //update smsf member
  patchUpdateSMSFMemberRequest: (state) => {
    state.isPatchUpdateSMSFMemberRequestLoading = true;
    state.patchUpdateMemberSuccessData = {};
    state.patchUpdateMemberFailedData = {};
  },
  patchUpdateSMSFMemberRequestSuccess: (state, action) => {
    state.isPatchUpdateSMSFMemberRequestLoading = false;
    state.patchUpdateMemberSuccessData = action.payload;
    state.patchUpdateMemberFailedData = {};
  },
  patchUpdateSMSFMemberRequestFailed: (state, action) => {
    state.isPatchUpdateSMSFMemberRequestLoading = false;
    state.patchUpdateMemberSuccessData = {};
    state.patchUpdateMemberFailedData = action.payload;
  },

  //add new smsf member to smsf
  postSaveNewSMSFMemberRequest: (state) => {
    state.isPostSaveNewSMSFMemberRequestLoading = true;
    state.postSaveSMSFMemberSuccessData = {};
    state.postSaveSMSFMemberFailedData = {};
  },
  postSaveNewSMSFMemberRequestSuccess: (state, action) => {
    state.isPostSaveNewSMSFMemberRequestLoading = false;
    state.postSaveSMSFMemberSuccessData = action.payload;
    state.postSaveSMSFMemberFailedData = {};
  },
  postSaveNewSMSFMemberRequestFailed: (state, action) => {
    state.isPostSaveNewSMSFMemberRequestLoading = false;
    state.postSaveSMSFMemberSuccessData = {};
    state.postSaveSMSFMemberFailedData = action.payload;
  },

  //delete smsf member
  deleteSMSFMemberRequest: (state) => {
    state.isDeleteSMSFMemberRequestLoading = true;
    state.deleteSMSFMemberRequestSuccessData = {};
    state.deleteSMSFMemberRequestFailedData = {};
  },
  deleteSMSFMemberRequestSuccess: (state, action) => {
    state.isDeleteSMSFMemberRequestLoading = false;
    state.deleteSMSFMemberRequestSuccessData = action.payload;
    state.deleteSMSFMemberRequestFailedData = {};
  },
  deleteSMSFMemberRequestFailed: (state, action) => {
    state.isDeleteSMSFMemberRequestLoading = false;
    state.deleteSMSFMemberRequestSuccessData = {};
    state.deleteSMSFMemberRequestFailedData = action.payload;
  },

  //find client detail
  getSMSFClientDashboardStatByIdRequest: (state) => {
    state.isSMSFClientDashboardStatDetailRequestLoading = true;
    state.selectedSMSFClientDashboardStatDetail = {};
    state.selectedSMSFClientDashboardStatDetailFailed = {};
  },
  getSMSFClientDashboardStatByIdRequestSuccess: (state, action) => {
    state.isSMSFClientDashboardStatDetailRequestLoading = false;
    state.selectedSMSFClientDashboardStatDetail = action.payload;
    state.selectedSMSFClientDashboardStatDetailFailed = {};
  },
  getSMSFClientDashboardStatByIdRequestFailed: (state, action) => {
    state.isSMSFClientDashboardStatDetailRequestLoading = false;
    state.selectedSMSFClientDashboardStatDetail = {};
    state.selectedSMSFClientDashboardStatDetailFailed = action.payload;
  },

  //save Multiple SMSFS
  postSaveMultipleSMSFClientRequest: (state) => {
    state.saveMultipleSMSFClientRequestLoading = true;
    state.saveMultipleSMSFClientData = {};
    state.saveMultipleSMSFClientFailed = {};
  },
  postSaveMultipleSMSFClientSuccess: (state, action) => {
    state.saveMultipleSMSFClientRequestLoading = false;
    state.saveMultipleSMSFClientData = action.payload;
    state.saveMultipleSMSFClientFailed = {};
  },
  postSaveMultipleSMSFClientFailed: (state, action) => {
    state.saveMultipleSMSFClientRequestLoading = false;
    state.saveMultipleSMSFClientData = {};
    state.saveMultipleSMSFClientFailed = action.payload;
  },

  //filter  SMSF
  postFilterSMSFRequest: (state) => {
    state.filterDataSMSFLoading = true;
    state.filterDataSMSFSuccess = [];
    state.filterDataSMSFFailed = {};
  },
  postFilterSMSFSuccess: (state, action) => {
    state.filterDataSMSFLoading = false;
    state.filterDataSMSFSuccess = action.payload;
    state.filterDataSMSFFailed = {};
  },
  postFilterSMSFFailed: (state, action) => {
    state.filterDataSMSFLoading = false;
    state.filterDataSMSFSuccess = [];
    state.filterDataSMSFFailed = action.payload;
  },

  //add linked entities smsf
  addLinkedEntitiesSMSFRequest: (state) => {
    state.addLinkedEntitiesSMSFLoading = true;
    state.addLinkedEntitiesSMSFSuccess = [];
    state.addLinkedEntitiesSMSFFailed = {};
  },
  addLinkedEntitiesSMSFSuccess: (state, action) => {
    state.addLinkedEntitiesSMSFLoading = false;
    state.addLinkedEntitiesSMSFSuccess = action.payload;
    state.addLinkedEntitiesSMSFFailed = {};
  },
  addLinkedEntitiesSMSFFailed: (state, action) => {
    state.addLinkedEntitiesSMSFLoading = false;
    state.addLinkedEntitiesSMSFSuccess = [];
    state.addLinkedEntitiesSMSFFailed = action.payload;
  },

  //delete linked entities smsf
  deleteLinkedEntitiesSMSFRequest: (state) => {
    state.deleteLinkedEntitiesSMSFLoading = true;
    state.deleteLinkedEntitiesSMSFSuccess = [];
    state.deleteLinkedEntitiesSMSFFailed = {};
  },
  deleteLinkedEntitiesSMSFSuccess: (state, action) => {
    state.deleteLinkedEntitiesSMSFLoading = false;
    state.deleteLinkedEntitiesSMSFSuccess = action.payload;
    state.deleteLinkedEntitiesSMSFFailed = {};
  },
  deleteLinkedEntitiesSMSFFailed: (state, action) => {
    state.deleteLinkedEntitiesSMSFLoading = false;
    state.addLinkedEntitiesSMSFSuccess = [];
    state.deleteLinkedEntitiesSMSFFailed = action.payload;
  },
});
