import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // get all questionnaire templates
  isGetAllQuestionnaireTemplatesLoading: false,
  questionnaireTemplatesData: {},
  questionnaireTemplatesFailedData: {},

  // search questionnaire template
  isSearchQuestionnaireTemplateLoading: false,
  questionnaireTemplateSearchSuccessData: {},
  questionnaireTemplateSearchFailedData: {},

  // save questionnaire template
  isSaveQuestionnaireTemplateLoading: false,
  questionnaireTemplateSaveSuccessData: {},
  questionnaireTemplateSaveFailedData: {},

  //delete questionnaire template
  isDeleteQuestionnaireTemplateLoading: false,
  questionnaireTemplateDeleteSuccessData: {},
  questionnaireTemplateDeleteFailedData: {},
  
  //update questionnaire template
  isUpdateQuestionnaireTemplateRequestLoading: false,
  questionnaireTemplateUpdateSuccessData: {},
  questionnaireTemplateUpdateFailedData: {},

  //post assign template to clients
  isQuestionnaireTemplateAssignLoading: false,
  questionnaireTemplateAssignRequestSuccessData: {},
  questionnaireTemplateAssignRequestFailedData: {},

  //get questionnaire template assigned clients
  isTemplateAssignedClientsLoading: false,
  templateAssignedClientsRequestSuccessData: {},
  templateAssignedClientsFailedSuccessData: {},
};

/**
 * Questionnaire template reducer
 */
export const questionnaireTemplateReducer = createReducer(initialState, {
  // get all questionnaire templates
  getAllQuestionnaireTemplatesRequest: (state) => {
    state.isGetAllQuestionnaireTemplatesLoading = true;
    state.questionnaireTemplatesData = {};
    state.questionnaireTemplatesFailedData = {};
  },
  getAllQuestionnaireTemplatesRequestSuccess: (state, action) => {
    state.isGetAllQuestionnaireTemplatesLoading = false;
    state.questionnaireTemplatesData = action.payload;
    state.questionnaireTemplatesFailedData = {};
  },
  getAllQuestionnaireTemplatesRequestFailed: (state, action) => {
    state.isGetAllQuestionnaireTemplatesLoading = false;
    state.questionnaireTemplatesData = {};
    state.questionnaireTemplatesFailedData = action.payload;
  },

  // search questionnaire template
  postSearchQuestionnaireTemplateRequest: (state) => {
    state.isSearchQuestionnaireTemplateLoading = true;
    state.questionnaireTemplateSearchSuccessData = {};
    state.questionnaireTemplateSearchFailedData = {};
  },
  postSearchQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isSearchQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateSearchSuccessData = action.payload;
    state.questionnaireTemplateSearchFailedData = {};
  },
  postSearchQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isSearchQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateSearchSuccessData = {};
    state.questionnaireTemplateSearchFailedData = action.payload;
  },

  // save questionnaire template
  postSaveQuestionnaireTemplateRequest: (state) => {
    state.isSaveQuestionnaireTemplateLoading = true;
    state.questionnaireTemplateSaveSuccessData = {};
    state.questionnaireTemplateSaveFailedData = {};
  },
  postSaveQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isSaveQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateSaveSuccessData = action.payload;
    state.questionnaireTemplateSaveFailedData = {};
  },
  postSaveQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isSaveQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateSaveSuccessData = {};
    state.questionnaireTemplateSaveFailedData = action.payload;
  },

  // delete questionnaire template
  deleteQuestionnaireTemplateRequest: (state) => {
    state.isDeleteQuestionnaireTemplateLoading = true;
    state.questionnaireTemplateDeleteSuccessData = {};
    state.questionnaireTemplateDeleteFailedData = {};
  },
  deleteQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isDeleteQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateDeleteSuccessData = action.payload;
    state.questionnaireTemplateDeleteFailedData = {};
  },
  deleteQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isDeleteQuestionnaireTemplateLoading = false;
    state.questionnaireTemplateDeleteSuccessData = {};
    state.questionnaireTemplateDeleteFailedData = action.payload;
  },

  // update questionnaire template
  patchUpdateQuestionnaireTemplateRequest: (state) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = true;
    state.questionnaireTemplateDeleteSuccessData = {};
    state.questionnaireTemplateDeleteFailedData = {};
  },
  patchUpdateQuestionnaireTemplateRequestSuccess: (state, action) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = false;
    state.questionnaireTemplateDeleteSuccessData = action.payload;
    state.questionnaireTemplateDeleteFailedData = {};
  },
  patchUpdateQuestionnaireTemplateRequestFailed: (state, action) => {
    state.isUpdateQuestionnaireTemplateRequestLoading = false;
    state.questionnaireTemplateDeleteSuccessData = {};
    state.questionnaireTemplateDeleteFailedData = action.payload;
  },

  //assign template to clients
  postAssignTemplateToClientsRequest: (state) => {
    state.isQuestionnaireTemplateAssignLoading = true;
    state.questionnaireTemplateAssignRequestSuccessData = {};
    state.questionnaireTemplateAssignRequestFailedData = {};
  },
  postAssignTemplateToClientsRequestSuccess: (state, action) => {
    state.isQuestionnaireTemplateAssignLoading = false;
    state.questionnaireTemplateAssignRequestSuccessData = action.payload;
    state.questionnaireTemplateAssignRequestFailedData = {};
  },
  postAssignTemplateToClientsRequestFailed: (state, action) => {
    state.isQuestionnaireTemplateAssignLoading = false;
    state.questionnaireTemplateAssignRequestSuccessData = {};
    state.questionnaireTemplateAssignRequestFailedData = action.payload;
  },

  //get template assigned clients
  getAssignedClientsOfTemplateRequest: (state) => {
    state.isTemplateAssignedClientsLoading = true;
    state.templateAssignedClientsRequestSuccessData = {};
    state.templateAssignedClientsFailedSuccessData = {};
  },
  getAssignedClientsOfTemplateRequestSuccess: (state, action) => {
    state.isTemplateAssignedClientsLoading = false;
    state.templateAssignedClientsRequestSuccessData = action.payload;
    state.templateAssignedClientsFailedSuccessData = {};
  },
  getAssignedClientsOfTemplateRequestFailed: (state, action) => {
    state.isTemplateAssignedClientsLoading = false;
    state.templateAssignedClientsRequestSuccessData = {};
    state.templateAssignedClientsFailedSuccessData = action.payload;
  },
});
