import { width } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export const useCommonStyles = makeStyles()((theme) => ({
  mainContainerWrapper: {
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 5,
    padding: '5px 5px 1px 15px',
  },
  mainTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h3.fontSize,
  },
  profileName1: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h4.fontSize,
    textTransform: 'capitalize',
  },
  profileName2: {
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.h4.fontSize,
    // textTransform: 'capitalize',
  },
  labelBlue: {
    color: theme.palette.primary[800],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
  },
  labelGreen: {
    color: theme.palette.green[600],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
  },
  labelYellow: {
    color: theme.palette.yellow.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
  },
  labelOrange: {
    color: theme.palette.orange.dark,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
  },
  labelRed: {
    color: theme.palette.red.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
  },

  lightBlueChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: theme.palette.primary.main,
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary[200]}`,
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: theme.typography.fontWeightBold,
  },
  greenChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: theme.palette?.green?.[400],
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette?.green?.[400]}`,
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: theme.typography.fontWeightBold,
  },
  orangeChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: theme.palette?.orange?.main,
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette?.orange?.main}`,
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: theme.typography.fontWeightBold,
  },
  yellowChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: theme.palette?.yellow?.[700],
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette?.yellow?.[700]}`,
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: theme.typography.fontWeightBold,
  },
  containerBlueChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: theme.palette.primary.light,
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: theme.typography.fontWeightBold,
  },
  containerLightBlueChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: theme.palette.primary.light,
    lineHeight: 1.5,
    backgroundColor: theme.palette.success.dark,
    border: `2px solid ${theme.palette.success.dark}`,
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: theme.typography.fontWeightBold,
  },
  mainDivider: {
    width: '100%',
    height: '1px',
    background: theme.palette.text.secondary,
    borderStyle: ' none',
  },
  formTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h4.fontSize,
  },
  formSubContainer: {
    padding: 20,
  },
  formLabel: {
    color: theme.palette.primary.dark,
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  requiredAsterisk: {
    position: 'absolute',
    right: '0.4rem',
    top: '1.8rem',
    color: theme.palette.error.main,
    zIndex: 1,
  },
  errorMessage: {
    backgroundColor: theme.palette.primary.tooLiter,
    color: theme.palette.error.main,
  },
  datePickerClass: {
    width: '100% !important',
    backgroundColor: 'red',
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textTransform: 'capitalize',
    outline: 'none',
    textDecoration: 'none',
    '&:hover': {
      // backgroundColor: theme.palette.main.dark,
    },
  },
  cancelBtn: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    outline: 'none',
    textDecoration: 'none',
    backgroundColor: theme.palette?.background?.paper,
  },
  clearBtn: {
    textTransform: 'capitalize',
    outline: 'none',
    textDecoration: 'none',
    color: theme.palette.grey?.[400],
    borderColor: theme.palette.grey?.[400],
    '&:hover': {
      color: theme.palette.grey?.[400],
      borderColor: theme.palette.grey?.[400],
    },
  },
  updateBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textTransform: 'capitalize',
    outline: 'none',
    textDecoration: 'none',
    '&:hover': {
      // backgroundColor: theme.palette.main.dark,
    },
  },
  cameraIcon: {
    height: 22,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: -12,
    marginLeft: 24,
  },
  addIcon: {
    fontSize: '1.5rem',
    // color: theme.palette.primary.dark,
    borderRadius: '0.5rem',
    marginLeft: '1rem',
    marginTop: '0.5rem',
    cursor: 'pointer',
  },
  deleteIcon: {
    fontSize: '1.5rem',
    // color: theme.palette.reds.dark,
    borderRadius: '0.5rem',
    marginLeft: '1rem',
    marginTop: '0.5rem',
    cursor: 'pointer',
  },
  modalTitleBox: {
    // background: theme.palette.secondary.light,
    // background: '#fff',
    // color: '#fff',
  },
  modalTitle: {
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h3.fontSize,
    textTransform: 'capitalize',
  },
  modalActionBox: {
    marginTop: 30,
  },
  modalContent: {
    background: theme.palette.secondary.light,
    border: `1px solid ${theme.palette.primary.dark}`,
    width: '100%',
  },
  confirmationModalHeader: {
    // background: theme.palette.brandPrimary.dark,
    fontSize: '20px',
    color: '#575555',
    // borderBottom: "2px solid #000",
    '&:hover': {
      color: '#575555',
    },
  },
  dialogCloseBtn: {
    float: 'right',
    marginTop: '0.1rem',
    cursor: 'pointer',
  },
  conformationContentText: {
    color: '#000',
    fontSize: 16,
  },

  confirmationModalContent: {
    // backgroundColor: '#F5FFFC',
    // border: '1px solid #2EBA88',
    width: '100%',
    padding: 20,
  },
  greyText: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
  },
  greyTextBolt: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  mainContainer: {
    width: '100%',
  },
  mainContainer_Wrap: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    borderRadius: 5,
    padding: 2,
  },
  //combined fields
  combinedFieldLeftSelect: {
    borderRadius: '0.75rem 0 0 0.75rem !important',
    '& fieldset': {
      borderRadius: '0.75rem 0 0 0.75rem',
    },
    '& .MuiSelect-select': {
      borderRadius: '0.75rem 0 0 0.75rem !important',
    },
  },
  combinedFieldRightInput: {
    '& fieldset': {
      borderRadius: '0 0.75rem 0.75rem 0',
    },
    '& .MuiFormControl-root': {
      '& .MuiInputBase-root': {
        borderRadius: '0 0.75rem 0.75rem 0 !important',
      },
    },
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.light,
    },
  },
  inactiveButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
  },
  listContainer: {
    width: '100%',
    padding: 8,
    margin: '5px 0px',
    cursor: 'pointer',
  },
  cardItemTextType_1: {
    color: theme.palette.text.dark,
  },
  cardItemTextType_2: {
    color: theme.palette.text.dark,
    fontWeight: theme.typography.fontWeightBold,
  },
  formText_1: {
    color: theme.palette.text.dark,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h3.fontSize,
  },
  formText_2: {
    color: theme.palette.text.dark,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h3.fontSize,
  },
  redBtnSmall: {
    backgroundColor: theme.palette.red[700],
    '&:hover': {
      backgroundColor: theme.palette.red[700],
    },
  },
  reportBtn: {
    backgroundColor: theme.palette.red[700],
    '&:hover': {
      backgroundColor: theme.palette.red[700],
    },
  },

  btnActive: {
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.primary.light,
    textTransform: `capitalize !important`,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.primary[800],
    },
  },
  btn: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary[800],
    textTransform: `capitalize !important`,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  btnRedirect: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.primary[800],
    },
  },
  detailsCardTextMain: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  detailsCardTextSecondary: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  chartTitle: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 5,
  },
  typoLargeText: {
    fontSize: '36px',
    color: theme.palette.text.secondary,
  },
  tableList: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h4.fontSize,
    // margin: "0.5rem 0rem",
  },
  assetsGraphTitle: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  chartAmountTitle: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.secondary,
  },
  chartAmount: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
  },
  summarySquare: {
    height: '8px',
    width: '18px',
    borderRadius: '5px 0px 5px 0px',
    marginRight: '1rem',
    marginTop: '0.2rem',
  },
  priceTitle: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  //toggle switch
  blueToggleSwitch: {
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: theme.palette.primary.contrastText,
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.blue[700] : theme.palette.blue[400],
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.primary.main,
        border: `6px solid ${theme.palette.primary.contrastText}`,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  greenToggleSwitch: {
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: theme.palette.primary.contrastText,
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.green[600] : theme.palette.green[400],
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.primary.main,
        border: `6px solid ${theme.palette.primary.contrastText}`,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  btnInProgress: {
    backgroundColor: '#4c79a2',
    color: '#FFFFFF',
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '0.7rem',
    '&:hover': {
      backgroundColor: '#4c79a2',
    },
  },
  btnDone: {
    backgroundColor: '#70ace2',
    color: '#FFFFFF',
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '0.7rem',
    '&:hover': {
      backgroundColor: '#70ace2',
    },
  },
  btnOpen: {
    backgroundColor: '#c1ee0b',
    color: '#FFFFFF',
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '0.7rem',
    '&:hover': {
      backgroundColor: '#c1ee0b',
    },
  },
  btnHigh: {
    backgroundColor: '#d65057',
    color: '#FFFFFF',
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '0.7rem',
    minWidth: '14ch',
    '&:hover': {
      backgroundColor: '#d65057',
    },
  },
  btnMedium: {
    backgroundColor: theme.palette.yellow.main,
    color: '#FFFFFF',
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '0.7rem',
    minWidth: '14ch',
    '&:hover': {
      backgroundColor: theme.palette.yellow.main,
    },
  },
  btnLow: {
    backgroundColor: '#56ba80',
    color: '#FFFFFF',
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '0.7rem',
    minWidth: '14ch',
    '&:hover': {
      backgroundColor: '#56ba80',
    },
  },
  clientGradeButton: {
    background: 'linear-gradient(to right, #dce35b, #45b649)',
    color: '#FFFFFF',
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '1rem',
    '&:hover': {
      background: 'linear-gradient(to right, #dce35b, #45b649)',
      backgroundColor: '#4c79a2',
    },
  },
  clientGradeButtonHorizontalListSuccess: {
    borderRadius: '0.5rem',
    background: 'linear-gradient(to right, #8FE293, #67cf6c)',
    color: '#FFFFFF',
    textTransform: 'none',
    margin: '0rem 0.2rem',

    fontSize: '1rem',
    '&:hover': {
      background: 'linear-gradient(to right, #8FE293, #67cf6c)',
      backgroundColor: '#4c79a2',
    },
  },

  clientGradeButtonHorizontalListWarning: {
    borderRadius: '0.5rem',
    background: 'linear-gradient(to right, #fb8602, #c06700)',
    color: '#FFFFFF',
    textTransform: 'none',
    margin: '0rem 0.2rem',

    fontSize: '1rem',
    '&:hover': {
      background: 'linear-gradient(to right, #fb8602, #c06700)',
      backgroundColor: '#4c79a2',
    },
  },
  clientGradeButtonHorizontalListDanger: {
    borderRadius: '0.5rem',
    background: 'linear-gradient(to right, #d65059, #9e3e42)',
    color: '#FFFFFF',
    textTransform: 'none',
    margin: '0rem 0.2rem',

    fontSize: '1rem',
    '&:hover': {
      background: 'linear-gradient(to right, #d65059, #9e3e42)',
      backgroundColor: '#4c79a2',
    },
  },
  clientGradeButtonHorizontalListInfo: {
    borderRadius: '0.5rem',
    background: 'linear-gradient(to right,  #D6F16C, #c1f001)',
    color: '#FFFFFF',
    textTransform: 'none',
    margin: '0rem 0.2rem',

    fontSize: '1rem',
    '&:hover': {
      background: 'linear-gradient(to right,  #D6F16C, #c1f001)',
      backgroundColor: '#4c79a2',
    },
  },
  calendarPickerClass: {
    width: '100%',
    height: '100%',
  },

  // syncfusion editor classes
  richTextEditorDefaultClass: {
    borderRadius: `${theme.shape.globalBorderRadius}px`,
    '& .e-rte-content': {
      borderRadius: `0 0 ${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px`,
      '& .label': {
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& .label-info': {
        color: theme.palette.green?.[400],
      },
    },
    '& .e-toolbar': {
      borderRadius: `${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px 0 0 !important`,
    },
    '& .e-toolbar .e-toolbar-items': {
      borderRadius: `${theme.shape.globalBorderRadius}px 0 0 0`,
    },
    '& .e-rte-toolbar .e-hor-nav.e-expended-nav': {
      borderRadius: `0 ${theme.shape.globalBorderRadius}px 0 0`,
    },
    '& .e-toolbar-wrapper': {
      borderRadius: `${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px 0 0`,
    },
  },
  richTextEditorDisabledClass: {
    borderRadius: `${theme.shape.globalBorderRadius}px`,
    '& .e-rte-content': {
      borderRadius: `0 0 ${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px`,
      background: theme.palette?.grey?.[300] + ' !important',
      '& .label': {
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& .label-info': {
        color: theme.palette.green?.[400],
      },
    },
    '& .e-toolbar': {
      borderRadius: `${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px 0 0 !important`,
    },
    '& .e-toolbar .e-toolbar-items': {
      borderRadius: `${theme.shape.globalBorderRadius}px 0 0 0`,
    },
    '& .e-rte-toolbar .e-hor-nav.e-expended-nav': {
      borderRadius: `0 ${theme.shape.globalBorderRadius}px 0 0`,
    },
    '& .e-toolbar-wrapper': {
      borderRadius: `${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px 0 0`,
    },
  },
  richTextEditorErrorClass: {
    borderColor: theme.palette.error.main + ' !important',
    '& .e-rte-content': {
      borderBottomColor: theme.palette.error.main + ' !important',
    },
  },

  errorButtonHighlight: {
    boxShadow: '0 0 3px 1px ' + theme.palette.error.main,
    color: theme.palette.error.main + ' !important',
  },

  //popper arrow
  popperCustomCurrentColorArrow: {
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },

  //tab btn classes
  defaultTabBtn: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
  },
  selectedTabBtn: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.light} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.light} !important`,
    },
  },
  greyActionPopperIcon: {
    '& path': {
      fill: theme.palette.blue?.[700] + ' !important',
    },
  },
  kanbanBoardPrimaryBtn: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[600],
  },
  kanbanBoardSecondaryBtn: {
    color: theme.palette.grey[600],
  },
  kanbanCardTitle: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium,
  },
  labelTitle: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
  },
  summaryCardTitle: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.dark.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  summaryCardValue: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.dark.main,
    fontWeight: theme.typography.fontWeightMedium,
  },

  cardTitle: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  cardValue: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.primary[800],
    fontWeight: theme.typography.fontWeightMedium,
  },
  smsfPropertyCalculatorCard: {
    backgroundColor: '#fff',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));
