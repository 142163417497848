import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Request save questionnaire template
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestPOSTSaveQuestionnaireTemplate =
  (
    requestBody,
    handleSaveQuestionnaireTemplateSuccessCallback = null,
    handleSaveQuestionnaireTemplateFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveQuestionnaireTemplateRequest' });

    const requestData = {
      url: API_URL.questionnaireTemplate.template.POST_SAVE_QUESTIONNAIRE_TEMPLATE,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveQuestionnaireTemplateRequestSuccess', payload: response.data });
      Store.dispatch(
        handleNotification(`Questionnaire Template is Saved Successfully!`, 'success')
      );
      handleSaveQuestionnaireTemplateSuccessCallback &&
        handleSaveQuestionnaireTemplateSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveQuestionnaireTemplateRequestFailed', payload: error });
      let message = 'Questionnaire Template is failed to save!';
      if (error?.response?.status === 500) {
        message = error?.response?.data?.message || 'Please Check Details !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveQuestionnaireTemplateFailedCallback &&
        handleSaveQuestionnaireTemplateFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request filter questionnaire template
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestPOSTFilterQuestionnaireTemplates =
  (
    requestBody,
    handleFilterQuestionnaireTemplateSuccessCallback = null,
    handleFilterQuestionnaireTemplateFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSearchQuestionnaireTemplateRequest' });

    const requestData = {
      url: API_URL.questionnaireTemplate.template.POST_FILTER_QUESTIONNAIRE_TEMPLATES,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSearchQuestionnaireTemplateRequestSuccess', payload: response.data });
      handleFilterQuestionnaireTemplateSuccessCallback &&
        handleFilterQuestionnaireTemplateSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSearchQuestionnaireTemplateRequestFailed', payload: error });
      handleFilterQuestionnaireTemplateFailedCallback &&
        handleFilterQuestionnaireTemplateFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request assign template to clients
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestPOSTAssignTemplateToClients =
  (
    requestBody,
    handleAssignTemplateRequestSuccessCallback = null,
    handleAssignTemplateRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postAssignTemplateToClientsRequest' });

    const requestData = {
      url: API_URL.questionnaireTemplate.template.POST_ASSIGN_TEMPLATE_CLIENT_LEAD,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postAssignTemplateToClientsRequestSuccess', payload: response.data });
      handleAssignTemplateRequestSuccessCallback &&
        handleAssignTemplateRequestSuccessCallback(response?.data);
      let message = 'Questionnaire Template assigned to Client(s) !';
      Store.dispatch(handleNotification(message, 'success'));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postAssignTemplateToClientsRequestFailed', payload: error });
      handleAssignTemplateRequestFailedCallback && handleAssignTemplateRequestFailedCallback(error);
            let message = 'Questionnaire Template failed to assign!';
      if (error?.response?.status === 500) {
        message = error?.response?.data?.message || 'Please Check Details !';
      }
      Store.dispatch(handleNotification(message, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request Template assigned members
 * @param {object} requestData
 * @param {Function} handleGetTemplateAssignedMembersSuccessCallback
 * @param {Function} handleGetTemplateAssignedMembersFailedCallback
 * @returns
 */
export const requestGetTemplateAssignedMembers =
  (
    requestDetails,
    handleGetTemplateAssignedMembersSuccessCallback,
    handleGetTemplateAssignedMembersFailedCallback
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAssignedClientsOfTemplateRequest' });
    const { templateId } = requestDetails;
    const requestData = {
      url: API_URL.questionnaireTemplate.template.GET_FILTER_ASSIGNED_MEMBERS.replace(
        '{templateId}',
        templateId
      ),
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAssignedClientsOfTemplateRequestSuccess', payload: response.data });

      handleGetTemplateAssignedMembersSuccessCallback &&
        handleGetTemplateAssignedMembersSuccessCallback(response.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAssignedClientsOfTemplateRequestFailed', payload: error });

      handleGetTemplateAssignedMembersFailedCallback &&
        handleGetTemplateAssignedMembersFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle update questionnaire template
 * @param {*} requestBody
 * @param {*} handleUpdateQuestionnaireTemplateSuccessCallback
 * @param {*} handleUpdateQuestionnaireTemplateFailedCallback
 * @returns
 */
export const requestPATCHUpdateQuestionnaireTemplate =
  (
    requestBody,
    handleUpdateQuestionnaireTemplateSuccessCallback = null,
    handleUpdateQuestionnaireTemplateFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateQuestionnaireTemplateRequest' });

    const requestData = {
      url: API_URL.questionnaireTemplate.template.PATCH_UPDATE_QUESTIONNAIRE_TEMPLATE,
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'patchUpdateQuestionnaireTemplateRequestSuccess', payload: response.data });
      Store.dispatch(
        handleNotification(`Questionnaire Template is Updated Successfully!`, 'success')
      );
      handleUpdateQuestionnaireTemplateSuccessCallback &&
        handleUpdateQuestionnaireTemplateSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateQuestionnaireTemplateRequestFailed', payload: error });
      let message = 'Questionnaire Template is failed to Update!';
      if (error?.response?.status === 500) {
        message = error?.response?.data?.message || 'Please Check Details !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateQuestionnaireTemplateFailedCallback &&
        handleUpdateQuestionnaireTemplateFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request remove template
 * @param {*} requestDetails
 * @param {*} handleDeleteQuestionnaireTemplateSuccessCallback
 * @param {*} handleDeleteQuestionnaireTemplateFailedCallback
 * @returns
 */
export const requestRemoveQuestionnaireTemplate =
  (
    requestDetails,
    handleDeleteQuestionnaireTemplateSuccessCallback = null,
    handleDeleteQuestionnaireTemplateFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteQuestionnaireTemplateRequest' });

    const { templateId, templateFilters } = requestDetails;
    const requestData = {
      url: API_URL.questionnaireTemplate.template.DELETE_QUESTIONNAIRE_TEMPLATES.replace(
        '{templateId}',
        templateId
      ),
      method: 'DELETE',
      data: {
        templateFilters,
      },
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteQuestionnaireTemplateRequestSuccess', payload: response.data });
      Store.dispatch(handleNotification(`Questionnaire Template is deleted!`, 'success'));
      handleDeleteQuestionnaireTemplateSuccessCallback &&
        handleDeleteQuestionnaireTemplateSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteQuestionnaireTemplateRequestFailed', payload: error });
      let message = 'Questionnaire Template delete failed!';
      if (error?.response?.status === 500) {
        message = error?.response?.data?.message || 'Please Check Details !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteQuestionnaireTemplateFailedCallback &&
        handleDeleteQuestionnaireTemplateFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
