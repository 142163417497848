import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Avatar, Box, ButtonBase, Autocomplete, TextField } from '@mui/material';
import { shouldForwardProp } from '@mui/system';

import { styled } from '@mui/material/styles';

import { IconSearch } from '@tabler/icons';

import GlobalSearch from 'modal/search/GlobalSearch';

import { handleModals } from 'redux/Actions/handleModalAction';

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  '&:hover': {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
  },
}));

const SearchSection = () => {
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();

  //modal reducer

  const modalReducer = useSelector((state) => state.modalReducer);

  const closeModal = React.useCallback(() => {
    dispatch(handleModals(false, 'GLOBAL_SEARCH_MODAL'));
  }, []);

  const openModal = () => {
    dispatch(handleModals(true, 'GLOBAL_SEARCH_MODAL'));
  };

  return (
    <>
      {modalReducer.GLOBAL_SEARCH_MODAL.modalStatus && (
        <GlobalSearch
          openDialog={modalReducer.GLOBAL_SEARCH_MODAL.modalStatus}
          handleCloseDialog={closeModal}
        />
      )}
      <Box onClick={openModal} sx={{ display: { xs: 'block', md: 'none' }, marginLeft: 1 }}>
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <HeaderAvatarStyle variant="rounded">
            <IconSearch stroke={1.5} size="1.2rem" />
          </HeaderAvatarStyle>
        </ButtonBase>
      </Box>
      <Box onClick={openModal} sx={{ display: { xs: 'none', md: 'block', marginLeft: 12 } }}>
        <Autocomplete
          options={[]}
          id="auto-complete-search-by-name"
          getOptionLabel={(option) => option.firstName}
          renderInput={(params) => <TextField {...params} label="Name" />}
          sx={(theme) => ({
            width: '100%',
            minWidth: '40ch',
            '& .MuiAutocomplete-input': {
              padding: '0.325rem 0 !important',
            },
          })}
        />
      </Box>
    </>
  );
};

export default SearchSection;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// import PropTypes from 'prop-types';
// import { useSelector, useDispatch } from 'react-redux';

// // material-ui
// import { useTheme, styled } from '@mui/material/styles';
// import {
//   Avatar,
//   Box,
//   ButtonBase,
//   Card,
//   Grid,
//   InputAdornment,
//   OutlinedInput,
//   Popper,
//   Autocomplete,
//   TextField,
// } from '@mui/material';

// // third-party
// import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
// import CircularProgress from '@mui/material/CircularProgress';
// // project imports
// import Transitions from 'ui-component/extended/Transitions';

// // assets
// import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons';
// import { shouldForwardProp } from '@mui/system';

// import { requestFilterClientList } from 'redux/Actions/clientsAction';

// // styles
// const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
//   zIndex: 1100,
//   width: '99%',
//   top: '-55px !important',
//   padding: '0 12px',
//   [theme.breakpoints.down('sm')]: {
//     padding: '0 10px',
//   },
// }));

// const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
//   width: 434,
//   marginLeft: 16,
//   paddingLeft: 16,
//   paddingRight: 16,
//   '& input': {
//     background: 'transparent !important',
//     paddingLeft: '4px !important',
//   },
//   [theme.breakpoints.down('lg')]: {
//     width: 250,
//   },
//   [theme.breakpoints.down('md')]: {
//     width: '100%',
//     marginLeft: 4,
//     background: '#fff',
//   },
// }));

// const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
//   ...theme.typography.commonAvatar,
//   ...theme.typography.mediumAvatar,
//   background: theme.palette.secondary.light,
//   color: theme.palette.secondary.dark,
//   '&:hover': {
//     background: theme.palette.secondary.dark,
//     color: theme.palette.secondary.light,
//   },
// }));

// // ==============================|| SEARCH INPUT - MOBILE||============================== //

// const MobileSearch = ({ value, setValue, popupState }) => {
//   const theme = useTheme();

//   return (
//     <OutlineInputStyle
//       id="input-search-header"
//       value={value}
//       onChange={(e) => setValue(e.target.value)}
//       placeholder="Search"
//       startAdornment={
//         <InputAdornment position="start">
//           <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
//         </InputAdornment>
//       }
//       endAdornment={
//         <InputAdornment position="end">
//           <ButtonBase sx={{ borderRadius: '12px' }}>
//             <HeaderAvatarStyle variant="rounded">
//               <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
//             </HeaderAvatarStyle>
//           </ButtonBase>
//           <Box sx={{ ml: 2 }}>
//             <ButtonBase sx={{ borderRadius: '12px' }}>
//               <Avatar
//                 variant="rounded"
//                 sx={{
//                   ...theme.typography.commonAvatar,
//                   ...theme.typography.mediumAvatar,
//                   background: theme.palette.orange.light,
//                   color: theme.palette.orange.dark,
//                   '&:hover': {
//                     background: theme.palette.orange.dark,
//                     color: theme.palette.orange.light,
//                   },
//                 }}
//                 {...bindToggle(popupState)}
//               >
//                 <IconX stroke={1.5} size="1.3rem" />
//               </Avatar>
//             </ButtonBase>
//           </Box>
//         </InputAdornment>
//       }
//       aria-describedby="search-helper-text"
//       inputProps={{ 'aria-label': 'weight' }}
//     />
//   );
// };

// MobileSearch.propTypes = {
//   value: PropTypes.string,
//   setValue: PropTypes.func,
//   popupState: PopupState,
// };

// // ==============================|| SEARCH INPUT ||============================== //

// const SearchSection = () => {
//   const theme = useTheme();

//   const navigate = useNavigate();

//   /**
//    * Redux integrations
//    */
//   const dispatch = useDispatch();

//   //client data reducer
//   const clientReducer = useSelector((state) => state.clientReducer);
//   const filterClientsRequestLoading = clientReducer?.filterClientsRequestLoading;
//   const filterClientsRequestSuccessData = clientReducer?.filterClientsRequestSuccessData;

//   const [selectedClient, setSelectedClient] = useState(null);

//   const [clientInputValue, setClientInputValue] = useState('');

//   /**
//    * Request for client list data
//    */

//   const handleGetClientList = (newInputValue) => {
//     const handleSuccessCallback = (response) => {};

//     const handleFailedCallback = (error) => {
//       console.log(error);
//     };

//     newInputValue &&
//       newInputValue.length > 2 &&
//       dispatch(
//         requestFilterClientList(
//           { name: newInputValue },
//           handleSuccessCallback,
//           handleFailedCallback
//         )
//       );
//   };

//   //open client profile
//   const openClientProfile = (clientData) => {
//     navigate(`/main/clientDashboard`, { state: { clientData } });
//   };

//   const AutocompleteClientSearch = ({ popupState }) => {
//     return (
//       <Autocomplete
//         disableClearable={false}
//         disableCloseOnSelect={false}
//         value={selectedClient}
//         onChange={(event, newValue) => {
//           setSelectedClient(newValue);
//         }}
//         inputValue={clientInputValue}
//         onInputChange={(event, newInputValue) => {
//           setClientInputValue(newInputValue);
//           // dispatch(requestFilterClientList({ name: newInputValue }));
//           handleGetClientList(newInputValue);
//         }}
//         id="auto-complete-search-by-name"
//         options={
//           filterClientsRequestSuccessData && Array.isArray(filterClientsRequestSuccessData.data)
//             ? filterClientsRequestSuccessData.data
//             : []
//         }
//         getOptionLabel={(option) => option.firstName}
//         renderOption={(props, option, { selected }) => (
//           <li
//             {...props}
//             style={{ textTransform: 'capitalize' }}
//             onClick={() => openClientProfile(option)}
//           >
//             {option.hasPartner
//               ? `${option?.surName}, ${option?.firstName} and ${option?.firstName}`
//               : `${option?.surName}, ${option?.firstName}`}
//           </li>
//         )}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             label="Client Name"
//             InputProps={{
//               ...params.InputProps,
//               endAdornment: (
//                 <React.Fragment>
//                   {filterClientsRequestLoading ? (
//                     <CircularProgress color="inherit" size={20} />
//                   ) : null}
//                   {popupState && (
//                     <Box sx={{ ml: 2 }}>
//                       <ButtonBase sx={{ borderRadius: '12px' }}>
//                         <Avatar
//                           variant="rounded"
//                           sx={{
//                             ...theme.typography.commonAvatar,
//                             ...theme.typography.mediumAvatar,
//                             background: theme.palette.orange.light,
//                             color: theme.palette.orange.dark,
//                             '&:hover': {
//                               background: theme.palette.orange.dark,
//                               color: theme.palette.orange.light,
//                             },
//                           }}
//                           {...bindToggle(popupState)}
//                         >
//                           <IconX stroke={1.5} size="1.3rem" />
//                         </Avatar>
//                       </ButtonBase>
//                     </Box>
//                   )}
//                 </React.Fragment>
//               ),
//             }}
//           />
//         )}
//         sx={(theme) => ({
//           width: '100%',
//           minWidth: '40ch',
//           '& .MuiAutocomplete-input': {
//             padding: '0.325rem 0 !important',
//           },
//         })}
//       />
//     );
//   };

//   return (
//     <>
//       <Box sx={{ display: { xs: 'block', md: 'none' } }}>
//         <PopupState variant="popper" popupId="demo-popup-popper">
//           {(popupState) => (
//             <>
//               <Box sx={{ ml: 2 }}>
//                 <ButtonBase sx={{ borderRadius: '12px' }}>
//                   <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
//                     <IconSearch stroke={1.5} size="1.2rem" />
//                   </HeaderAvatarStyle>
//                 </ButtonBase>
//               </Box>
//               <PopperStyle {...bindPopper(popupState)} transition>
//                 {({ TransitionProps }) => (
//                   <>
//                     <Transitions
//                       type="zoom"
//                       {...TransitionProps}
//                       sx={{ transformOrigin: 'center left' }}
//                     >
//                       <Card
//                         sx={{
//                           background: '#fff',
//                           [theme.breakpoints.down('sm')]: {
//                             border: 0,
//                             boxShadow: 'none',
//                           },
//                         }}
//                       >
//                         <Box sx={{ p: 2 }}>
//                           <Grid container alignItems="center" justifyContent="space-between">
//                             <Grid item xs>
//                               {/* <MobileSearch
//                                 value={value}
//                                 setValue={setValue}
//                                 popupState={popupState}
//                               /> */}
//                               <AutocompleteClientSearch popupState={popupState} />
//                             </Grid>
//                           </Grid>
//                         </Box>
//                       </Card>
//                     </Transitions>
//                   </>
//                 )}
//               </PopperStyle>
//             </>
//           )}
//         </PopupState>
//       </Box>
//       <Box sx={{ display: { xs: 'none', md: 'block', marginLeft: 12 } }}>
//         {/* <AutocompleteClientSearch /> */}
//         <Autocomplete
//           // disableClearable={true}
//           // disableCloseOnSelect={true}
//           value={selectedClient}
//           onChange={(event, newValue) => {
//             // setSelectedClient(newValue);
//             openClientProfile(newValue);
//           }}
//           inputValue={clientInputValue}
//           onInputChange={(event, newInputValue) => {
//             setClientInputValue(newInputValue);
//             // dispatch(requestFilterClientList({ name: newInputValue }));
//             handleGetClientList(newInputValue);
//           }}
//           id="auto-complete-search-by-name"
//           options={
//             filterClientsRequestSuccessData && Array.isArray(filterClientsRequestSuccessData.data)
//               ? filterClientsRequestSuccessData.data
//               : []
//           }
//           getOptionLabel={(option) => option.firstName}
//           renderOption={(props, option, { selected }) => (
//             <li
//               {...props}
//               style={{ textTransform: 'capitalize' }}
//               // onClick={() => openClientProfile(option)}
//             >
//               {option.hasPartner
//                 ? `${option?.surName}, ${option?.firstName} and ${option?.firstName}`
//                 : `${option?.surName}, ${option?.firstName}`}
//             </li>
//           )}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               label="Client Name"
//               // InputProps={{
//               //   ...params.InputProps,
//               //   endAdornment: (
//               //     <React.Fragment>
//               //       {filterClientsRequestLoading ? (
//               //         <CircularProgress color="inherit" size={20} />
//               //       ) : null}
//               //     </React.Fragment>
//               //   ),
//               // }}
//             />
//           )}
//           sx={(theme) => ({
//             width: '100%',
//             minWidth: '40ch',
//             '& .MuiAutocomplete-input': {
//               padding: '0.325rem 0 !important',
//             },
//           })}
//         />
//       </Box>
//     </>
//   );
// };

// export default SearchSection;
