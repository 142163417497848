import { createReducer } from '@reduxjs/toolkit';

export const initialState = {
  GOAL_MODAL: {
    modalName: 'GOAL_MODAL',
    modalStatus: false,
  },
  ADD_CLIENT_FILE_MODAL: {
    modalName: 'ADD_CLIENT_FILE_MODAL',
    modalStatus: false,
  },
  ADD_CLIENT_TASK_MODAL: {
    modalName: 'ADD_CLIENT_TASK_MODAL',
    modalStatus: false,
  },
  SEND_CLIENT_EMAIL_MODAL: {
    modalName: 'SEND_CLIENT_EMAIL_MODAL',
    modalStatus: false,
  },
  ADD_TASK_RECURRENCE_RULE_MODAL: {
    modalName: 'ADD_TASK_RECURRENCE_RULE_MODAL',
    modalStatus: false,
  },
  VIDEO_NAME_EDIT_MODAL: {
    modalName: 'VIDEO_NAME_EDIT_MODAL',
    modalStatus: false,
  },
  VIDEO_UPLOAD_MODAL: {
    modalName: 'VIDEO_UPLOAD_MODAL',
    modalStatus: false,
  },
  FILE_UPLOAD_MODAL: {
    modalName: 'FILE_UPLOAD_MODAL',
    modalStatus: false,
  },
  DOCUMENT_PUBLISH_MODAL: {
    modalName: 'DOCUMENT_PUBLISH_MODAL',
    modalStatus: false,
    modalData: null,
  },
  DOCUMENT_PREVIEW_MODAL: {
    modalName: 'DOCUMENT_PREVIEW_MODAL',
    modalStatus: false,
    modalData: null,
  },
  ADD_KANBAN_MODAL: {
    modalName: 'ADD_KANBAN_MODAL',
    modalStatus: false,
  },
  QUESTIONNAIRE_TAGS_MODAL: {
    modalName: 'QUESTIONNAIRE_TAGS_MODAL',
    modalStatus: false,
    modalData: null,
  },
  QUESTIONNAIRE_TEMPLATE_ASSIGNEE_MODAL: {
    modalName: 'QUESTIONNAIRE_TEMPLATE_ASSIGNEE_MODAL',
    modalStatus: false,
    modalData: null,
  },
  CLIENT_TEMPLATE_ASSIGN_MODAL: {
    modalName: 'CLIENT_TEMPLATE_ASSIGN_MODAL',
    modalStatus: false,
    modalData: null,
  },
  PROJECTION_FILTER_MODAL: {
    modalName: 'PROJECTION_FILTER_MODAL',
    modalStatus: false,
  },
  SMSF_ADD_ENTITIES_MODAL: {
    modalName: 'SMSF_ADD_ENTITIES_MODAL',
    modalStatus: false,
  },
  COMPANY_ADD_ENTITIES_MODAL: {
    modalName: 'COMPANY_ADD_ENTITIES_MODAL',
    modalStatus: false,
  },
  TRUST_ADD_ENTITIES_MODAL: {
    modalName: 'TRUST_ADD_ENTITIES_MODAL',
    modalStatus: false,
  },
  PARTNERSHIP_ADD_ENTITIES_MODAL: {
    modalName: 'PARTNERSHIP_ADD_ENTITIES_MODAL',
    modalStatus: false,
  },
  GLOBAL_SEARCH_MODAL: {
    modalName: 'GLOBAL_SEARCH_MODAL',
    modalStatus: false,
  },
};

export const modalReducer = createReducer(initialState, {
  HANDLE_MODAL: (state, action) => {
    if (action.payload.modalName === 'GOAL_MODAL') {
      state = {
        ...state,
        GOAL_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'ADD_CLIENT_FILE_MODAL') {
      state = {
        ...state,
        ADD_CLIENT_FILE_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'ADD_CLIENT_TASK_MODAL') {
      state = {
        ...state,
        ADD_CLIENT_TASK_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'SEND_CLIENT_EMAIL_MODAL') {
      state = {
        ...state,
        SEND_CLIENT_EMAIL_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'ADD_TASK_REMINDER_MODAL') {
      state = {
        ...state,
        ADD_TASK_REMINDER_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'VIDEO_NAME_EDIT_MODAL') {
      state = {
        ...state,
        VIDEO_NAME_EDIT_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'VIDEO_UPLOAD_MODAL') {
      state = {
        ...state,
        VIDEO_UPLOAD_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'FILE_UPLOAD_MODAL') {
      state = {
        ...state,
        FILE_UPLOAD_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'DOCUMENT_PUBLISH_MODAL') {
      state = {
        ...state,
        DOCUMENT_PUBLISH_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'DOCUMENT_PREVIEW_MODAL') {
      state = {
        ...state,
        DOCUMENT_PREVIEW_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'ADD_KANBAN_MODAL') {
      state = {
        ...state,
        ADD_KANBAN_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'QUESTIONNAIRE_TAGS_MODAL') {
      state = {
        ...state,
        QUESTIONNAIRE_TAGS_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'QUESTIONNAIRE_TEMPLATE_ASSIGNEE_MODAL') {
      state = {
        ...state,
        QUESTIONNAIRE_TEMPLATE_ASSIGNEE_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'CLIENT_TEMPLATE_ASSIGN_MODAL') {
      state = {
        ...state,
        CLIENT_TEMPLATE_ASSIGN_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'PROJECTION_FILTER_MODAL') {
      state = {
        ...state,
        PROJECTION_FILTER_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'SMSF_ADD_ENTITIES_MODAL') {
      state = {
        ...state,
        SMSF_ADD_ENTITIES_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'COMPANY_ADD_ENTITIES_MODAL') {
      state = {
        ...state,
        COMPANY_ADD_ENTITIES_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'TRUST_ADD_ENTITIES_MODAL') {
      state = {
        ...state,
        TRUST_ADD_ENTITIES_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'PARTNERSHIP_ADD_ENTITIES_MODAL') {
      state = {
        ...state,
        PARTNERSHIP_ADD_ENTITIES_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'GLOBAL_SEARCH_MODAL') {
      state = {
        ...state,
        GLOBAL_SEARCH_MODAL: action.payload,
      };
    }
    return state;
  },
});
