import { createReducer } from '@reduxjs/toolkit';

const organizationSettingsInitialState = {
  //Create New Organization Setting
  isSaveOrganizationSettingLoading: false,
  saveOrganizationSettingSuccessData: {},
  saveOrganizationSettingFailedData: {},

  //get Organization Settings
  isGetOrganizationSettingsLoading: false,
  organizationSettingsSuccessData: {},
  organizationSettingsFailedData: {},

  //update Organization Setting
  isUpdateOrganizationSettingLoading: false,
  updateOrganizationSettingSuccessData: {},
  updateOrganizationSettingFailedData: {},
};
/**
 * Organization Setting Reducer
 */
export const organizationSettingsReducer = createReducer(organizationSettingsInitialState, {
  // save Organization Setting
  postSaveOrganizationSettingRequest: (state) => {
    state.isSaveOrganizationSettingLoading = true;
    state.saveOrganizationSettingSuccessData = {};
    state.saveOrganizationSettingFailedData = {};
  },
  postSaveOrganizationSettingRequestSuccess: (state, action) => {
    state.isSaveOrganizationSettingLoading = false;
    state.saveOrganizationSettingSuccessData = action?.payload;
    state.saveOrganizationSettingFailedData = {};
  },
  postSaveOrganizationSettingRequestFailed: (state, action) => {
    state.isSaveOrganizationSettingLoading = false;
    state.saveOrganizationSettingSuccessData = {};
    state.saveOrganizationSettingFailedData = action?.payload;
  },

  //get Organization Settings
  getOrganizationSettingRequest: (state) => {
    state.isGetOrganizationSettingsLoading = true;
    state.organizationSettingsSuccessData = {};
    state.organizationSettingsFailedData = {};
  },
  getOrganizationSettingRequestSuccess: (state, action) => {
    state.isGetOrganizationSettingsLoading = false;
    state.organizationSettingsSuccessData = action.payload;
    state.organizationSettingsFailedData = {};
  },
  getOrganizationSettingRequestFailed: (state, action) => {
    state.isGetOrganizationSettingsLoading = false;
    state.organizationSettingsSuccessData = {};
    state.organizationSettingsFailedData = action.payload;
  },

  //update Organization Setting
  updateOrganizationSettingRequest: (state) => {
    state.isUpdateOrganizationSettingLoading = true;
    state.updateOrganizationSettingSuccessData = {};
    state.updateOrganizationSettingFailedData = {};
  },
  updateOrganizationSettingRequestSuccess: (state, action) => {
    state.isUpdateOrganizationSettingLoading = false;
    state.updateOrganizationSettingSuccessData = action.payload;
    state.updateOrganizationSettingFailedData = {};
  },
  updateOrganizationSettingRequestFailed: (state, action) => {
    state.isUpdateOrganizationSettingLoading = false;
    state.updateOrganizationSettingSuccessData = {};
    state.updateOrganizationSettingFailedData = action.payload;
  },
});
