import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Autocomplete, TextField, CircularProgress } from '@mui/material';

import { requestFilterCompany } from 'redux/Actions/companyClientsAction';

const CompanySearch = (props) => {
  const { handleCloseDialog } = props;
  /**
   * uses react-router-dom location
   */

  const navigate = useNavigate();

  /**
   * Redux integrations
   */

  const dispatch = useDispatch();

  const companyClientReducer = useSelector((state) => state.companyClientReducer);

  const filterDataCompanySuccess = companyClientReducer?.filterDataCompanySuccess;
  const filterDataCompanyLoading = companyClientReducer?.filterDataCompanyLoading;

  const [clientInputValue, setClientInputValue] = useState('');

  /**
   * Request for client list data
   */

  const handleGetClientList = (newInputValue) => {
    const handleSuccessCallback = (response) => {};

    const handleFailedCallback = (error) => {
      console.log(error);
    };

    newInputValue &&
      newInputValue.length > 2 &&
      dispatch(
        requestFilterCompany({ name: newInputValue }, handleSuccessCallback, handleFailedCallback)
      );
  };

  //open client profile
  const openClientProfile = (clientData) => {
    navigate('/main/company/company-dashboard', {
      state: {
        companyData: clientData,
      },
    });
    handleCloseDialog();
  };

  return (
    <Autocomplete
      value={null}
      onChange={(event, newValue) => {
        openClientProfile(newValue);
      }}
      inputValue={clientInputValue}
      onInputChange={(event, newInputValue) => {
        setClientInputValue(newInputValue);

        handleGetClientList(newInputValue);
      }}
      loading={filterDataCompanyLoading}
      id="auto-complete-search-by-name"
      options={
        filterDataCompanySuccess && Array.isArray(filterDataCompanySuccess)
          ? filterDataCompanySuccess
          : []
      }
      getOptionLabel={(option) => `${option.name}`}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{ textTransform: 'capitalize' }}
          onClick={() => openClientProfile(option)}
        >
          {option?.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Name"
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {true ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            },
          }}
        />
      )}
      sx={(theme) => ({
        width: '100%',
        minWidth: '40ch',
        '& .MuiAutocomplete-input': {
          padding: '0.325rem 0 !important',
        },
      })}
    />
  );
};

export default CompanySearch;
