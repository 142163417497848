import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import CompanyDashboard from 'views/client/dashboard/company/CompanyDashboard';
import CreateQuestionnaireTemplate from 'views/templates/Questionnaires/create/CreateQuestionnaireTemplate';
import ListClientQuestionnaire from 'views/client/clientDashboard/questionnaires/ListClientQuestionnaire';
import { element } from 'prop-types';

const NewDashboard = Loadable(lazy(() => import('views/newDashboard')));

const LeadList = Loadable(lazy(() => import('views/lead/leadList/LeadList')));

const Email = Loadable(lazy(() => import('views/email/Email')));

const BulkEmail = Loadable(lazy(() => import('views/bulkEmail/BulkEmail')));

const Tasks = Loadable(lazy(() => import('views/task/Tasks')));
const CreateTask = Loadable(lazy(() => import('views/task/createTask/CreateTask')));

const Files = Loadable(lazy(() => import('views/files')));

const Video = Loadable(lazy(() => import('views/video/Video')));

const Insights = Loadable(lazy(() => import('views/insights')));

const TimeReport = Loadable(lazy(() => import('views/insights/timeReport/TimeReport')));

const PaymentHistory = Loadable(lazy(() => import('views/insights/paymentHistory/PaymentHistory')));

const Modellings = Loadable(lazy(() => import('views/modellings')));
const BasicInvestmentModeling = Loadable(
  lazy(() => import('views/modellings/basic/BasicInvestmentModeling'))
);
const Superannuation = Loadable(
  lazy(() => import('views/modellings/superannuation/Superannuation'))
);

const Settings = Loadable(lazy(() => import('views/settings/Settings')));

const ClientList = Loadable(lazy(() => import('views/client/clientList/ClientList')));

const ClientDashboard = Loadable(
  lazy(() => import('views/client/clientDashboard/ClientDashboard'))
);

const CreateIndividual = Loadable(
  lazy(() => import('views/client/createClient/individual/CreateIndividual'))
);

const CreateCompany = Loadable(
  lazy(() => import('views/client/createClient/company/CreateCompany'))
);

const CreateCompanyCSV = Loadable(
  lazy(() => import('views/client/createClient/company/CreateCompanyCSV'))
);

const CreateTrust = Loadable(lazy(() => import('views/client/createClient/trust/CreateTrust')));

const CreateTrustCSV = Loadable(
  lazy(() => import('views/client/createClient/trust/CreateTrustCSV'))
);

const UpdateClient = Loadable(
  lazy(() => import('views/client/updateClient/individual/UpdateClient'))
);

const CreateClientCSV = Loadable(
  lazy(() => import('views/client/createClient/individual/CreateClientCSV'))
);

const CreateLead = Loadable(lazy(() => import('views/lead/createLead/CreateLead')));

const CreateLeadCSV = Loadable(lazy(() => import('views/lead/createLead/CreateLeadCSV')));

const UpdateLead = Loadable(lazy(() => import('views/lead/updateLead/UpdateLead')));

const TeamMembersList = Loadable(
  lazy(() => import('views/teamMembers/teamMembersList/TeamMembersList'))
);

const CreateTeamMembers = Loadable(
  lazy(() => import('views/teamMembers/createTeamMembers/CreateTeamMembers'))
);

const MyProfile = Loadable(lazy(() => import('views/myProfile/MyProfile')));

const Organization = Loadable(lazy(() => import('views/organisation/Organisation')));

const Calculation = Loadable(lazy(() => import('views/calculation/Calculation')));

const EmailNotification = Loadable(lazy(() => import('views/emailNotification/EmailNotification')));

const SettingsModeling = Loadable(lazy(() => import('views/settingsModelling/SettingsModeling')));

const WebForms = Loadable(lazy(() => import('views/webForms/WebForms')));

const InvestorProfile = Loadable(lazy(() => import('views/investorProfile/InvestorProfile')));

const ClientApp = Loadable(lazy(() => import('views/clientApp/ClientApp')));

const BasicInvestment = Loadable(
  lazy(() => import('views/settingsModelling/basicInvestment/BasicInvestment'))
);
const InsurenceModelling = Loadable(
  lazy(() => import('views/settingsModelling/insurenceModelling/InsurenceModelling'))
);
const SuperAnnuationModelling = Loadable(
  lazy(() => import('views/settingsModelling/superannuationModelling/SuperAnnuationModelling'))
);

const ModellingSettings = Loadable(
  lazy(() =>
    import('views/settingsModelling/insurenceModelling/modellingSettings/ModellingSettings')
  )
);

// ==============================|| Templates routing||============================== //
const Templates = Loadable(lazy(() => import('views/templates/Templates')));
const DocumentsTemplates = Loadable(
  lazy(() => import('views/templates/Documents/DocumentsTemplates'))
);
// ==============================|| Documents routing||============================== //
const CreateDigital = Loadable(
  lazy(() => import('views/templates/Documents/digitalDocuments/CreateDigital'))
);
// ==============================|| Email Templates routing||============================== //
const EmailTemplates = Loadable(lazy(() => import('views/templates/Email/EmailTemplates')));
const CreateEmailTemplate = Loadable(
  lazy(() => import('views/templates/Email/CreateEmailTemplate'))
);
// ==============================|| Workflow routing||============================== //

const WorkflowTemplates = Loadable(
  lazy(() => import('views/templates/Workflows/WorkflowTemplates'))
);

// ==============================|| Questionnaire Template routing||============================== //

const QuestionnaireTemplate = Loadable(
  lazy(() => import('views/templates/Questionnaires/QuestionnaireTemplate'))
);
// ==============================|| Client Dashboard routing||============================== //

const IncomeDashboard = Loadable(
  lazy(() => import('views/client/clientDashboard/income/dashboard/IncomeDashboard'))
);
const IncomeReport = Loadable(
  lazy(() => import('views/client/clientDashboard/income/report/IncomeReport'))
);

const AssetsDashboard = Loadable(
  lazy(() => import('views/client/clientDashboard/assets/dashboard/AssetsDashboard'))
);
const AssetsReport = Loadable(
  lazy(() => import('views/client/clientDashboard/assets/report/AssetsReport'))
);

const ExpensesDashboard = Loadable(
  lazy(() => import('views/client/clientDashboard/expenses/dashboard/ExpensesDashboard'))
);

const ExpensesReport = Loadable(
  lazy(() => import('views/client/clientDashboard/expenses/report/ExpensesReport'))
);

const SimpleExpensesDashboard = Loadable(
  lazy(() => import('views/client/clientDashboard/expenses/dashboard/SimpleExpensesDashboard'))
);
const GoalsAndObjectiveDashboard = Loadable(
  lazy(() =>
    import('views/client/clientDashboard/goalsAndObjectives/dashboard/GoalsAndObjectiveDashboard')
  )
);
const GoalsAndObjectiveReport = Loadable(
  lazy(() =>
    import('views/client/clientDashboard/goalsAndObjectives/report/GoalsAndObjectiveReport')
  )
);

const CreateGoalsAndObjectives = Loadable(
  lazy(() =>
    import('views/client/clientDashboard/goalsAndObjectives/create/CreateGoalsAndObjectives')
  )
);

const LiabilitiesDashboard = Loadable(
  lazy(() => import('views/client/clientDashboard/liabilities/dashboard/LiabilitiesDashboard'))
);
const LiabilitiesReport = Loadable(
  lazy(() => import('views/client/clientDashboard/liabilities/report/LiabilitiesReport'))
);

const ProtectionDashboard = Loadable(
  lazy(() => import('views/client/clientDashboard/protection/dashboard/ProtectionDashboard'))
);
const ProtectionReport = Loadable(
  lazy(() => import('views/client/clientDashboard/protection/report/ProtectionReport'))
);
const CreateFamilyAndLifestyleProtection = Loadable(
  lazy(() =>
    import('views/client/clientDashboard/protection/create/CreateFamilyAndLifestyleProtection')
  )
);

const Comments = Loadable(lazy(() => import('views/client/clientDashboard/comments/Comments')));

const Documents = Loadable(lazy(() => import('views/client/clientDashboard/documents/Documents')));

const ClientFiles = Loadable(lazy(() => import('views/client/clientDashboard/files/Files')));

const Identification = Loadable(
  lazy(() => import('views/client/clientDashboard/identification/Identification'))
);

const FileNotes = Loadable(lazy(() => import('views/client/clientDashboard/fileNotes/FileNotes')));
const CreateFileNote = Loadable(
  lazy(() => import('views/client/clientDashboard/fileNotes/CreateFileNote'))
);

const AdviseRelationship = Loadable(
  lazy(() => import('views/client/clientDashboard/adviseRelationship/AdviseRelationship'))
);

const ClientQuestionnaires = Loadable(
  lazy(() => import('views/questionnaire/builder/ClientQuestionnaires'))
);

const LinkedEntities = Loadable(
  lazy(() => import('views/client/clientDashboard/linkedEntities/LinkedEntities'))
);

// ==============================|| asx routing||============================== //

const Asx = Loadable(lazy(() => import('views/asx/Asx')));

const Sandp = Loadable(lazy(() => import('views/asx/sAndP/Sandp')));

const Company = Loadable(lazy(() => import('views/asx/company/Company')));

const Crypto = Loadable(lazy(() => import('views/crypto/Crypto')));

const ExchangeRate = Loadable(lazy(() => import('views/exchangeRate/ExchangeRate')));

// ==============================|| Calculators routing||============================== //

const Calculators = Loadable(lazy(() => import('views/calculators/calculators/Calculators')));

const SimpleMortgage = Loadable(
  lazy(() => import('views/calculators/simpleMortgage/SimpleMortgage'))
);

const AnnualizedReturnCalculator = Loadable(
  lazy(() => import('views/calculators/annualizedReturnCalculator/AnnualizedReturnCalculator'))
);

const BreakEvenPointCalculator = Loadable(
  lazy(() => import('views/calculators/breakEvenPointCalculator/BreakEvenPointCalculator'))
);

const CompoundInterestCalculator = Loadable(
  lazy(() => import('views/calculators/compoundInterestCalculator/CompoundInterestCalculator'))
);

const InvestmentTimeCalculator = Loadable(
  lazy(() => import('views/calculators/investmentTimeCalculator/InvestmentTimeCalculator'))
);

const MarkupCalculator = Loadable(
  lazy(() => import('views/calculators/markupCalculator/MarkupCalculator'))
);

const PresentValueCalculator = Loadable(
  lazy(() => import('views/calculators/presentValueCalculator/PresentValueCalculator'))
);

const MortgageWithExtraPayment = Loadable(
  lazy(() => import('views/calculators/mortgageWithExtraPayment/MortgageWithExtraPayment'))
);

const StampDutyCalculator = Loadable(
  lazy(() => import('views/calculators/stampDuty/StampDutyCalculator'))
);

const PropertyCalculator = Loadable(
  lazy(() => import('views/calculators/propertyCalculator/PropertyCalculator'))
);

const CarLoanRepaymentCalculator = Loadable(
  lazy(() => import('views/calculators/carLoanRepaymentCalculator/CarLoanRepaymentCalculator'))
);
const DebtRecyclingCalculator = Loadable(
  lazy(() => import('views/calculators/debtRecycling/DebtRecyclingCalculator'))
);

const IncomeTaxCalculator = Loadable(
  lazy(() => import('views/calculators/incomeTaxCalculator/IncomeTaxCalculator'))
);

const SMSFPropertyPurchaseCalculator = Loadable(
  lazy(() =>
    import('views/calculators/smsfPropertyPurchaseCalculator/SMSFPropertyPurchaseCalculator')
  )
);

const ChatGPT = Loadable(lazy(() => import('views/ai/ChatGPT')));

const VoiceCommand = Loadable(lazy(() => import('views/voice/VoiceCommand')));

const AlanAi = Loadable(lazy(() => import('views/voice/AlanAi')));

// ==============================|| client financial ||============================== //

const CreateExpense = Loadable(
  lazy(() => import('views/client/clientDashboard/expenses/create/CreateExpense'))
);

const CreateLiability = Loadable(
  lazy(() => import('views/client/clientDashboard/liabilities/create/CreateLiability'))
);

const CreateAsset = Loadable(
  lazy(() => import('views/client/clientDashboard/assets/create/CreateAsset'))
);

const CreateIncome = Loadable(
  lazy(() => import('views/client/clientDashboard/income/create/CreateIncome'))
);

// ==============================|| client financial positions||============================== //
const ClientFinancialPositions = Loadable(
  lazy(() => import('views/client/clientDashboard/financialPositions/FinancialPositions'))
);

const FinancialDashboard = Loadable(
  lazy(() => import('views/client/clientDashboard/financialDashboard/FinancialDashboard'))
);

// ==============================|| Lead Dashboard ||============================== //

const LeadDashboard = Loadable(lazy(() => import('views/lead/dashboard/LeadDashboard')));

const LeadAssetDashboard = Loadable(lazy(() => import('views/lead/dashboard/LeadAssetDashboard')));

const LeadExpensesDashboard = Loadable(
  lazy(() => import('views/lead/dashboard/LeadExpensesDashboard'))
);

const LeadGoalsAndObjectiveDashboard = Loadable(
  lazy(() => import('views/lead/dashboard/LeadGoalsAndObjectiveDashboard'))
);

const LeadIncomeDashboard = Loadable(
  lazy(() => import('views/lead/dashboard/LeadIncomeDashboard'))
);

const LeadLiabilityDashboard = Loadable(
  lazy(() => import('views/lead/dashboard/LeadLiabilityDashboard'))
);

const LeadProtectionPoliciesDashboard = Loadable(
  lazy(() => import('views/lead/dashboard/LeadProtectionPoliciesDashboard'))
);

const LeadQuestionnairesDashboard = Loadable(
  lazy(() => import('views/lead/dashboard/LeadQuestionnairesDashboard'))
);

const Messages = Loadable(lazy(() => import('views/messages/Messages')));

const VideoCall = Loadable(lazy(() => import('views/videoCall/VideoCall')));

// ==============================|| SMSF ||============================== //

const UpdateSMSFClient = Loadable(
  lazy(() => import('views/client/updateClient/smsf/UpdateSMSFClient'))
);

const CreateSmsf = Loadable(lazy(() => import('views/client/createClient/smsf/CreateSmsf')));

const CreateSmsfCSV = Loadable(lazy(() => import('views/client/createClient/smsf/CreateSmsfCSV')));

const SmsfDashboard = Loadable(
  lazy(() => import('views/client/dashboard/smsf/smsfDashboard/SmsfDashboard'))
);

const SmsfCreateAsset = Loadable(
  lazy(() => import('views/client/dashboard/smsf/asset/create/SmsfCreateAsset'))
);

const SmsfAssetsDashboard = Loadable(
  lazy(() => import('views/client/dashboard/smsf/asset/dashboard/SmsfAssetsDashboard'))
);

const SmsfCreateCashflow = Loadable(
  lazy(() => import('views/client/dashboard/smsf/cashflow/create/SmsfCreateCashflow'))
);

const SmsfCashflowDashboard = Loadable(
  lazy(() => import('views/client/dashboard/smsf/cashflow/dashboard/SmsfCashflowDashboard'))
);

const SmsfCreateContribution = Loadable(
  lazy(() => import('views/client/dashboard/smsf/contribution/create/SmsfCreateContribution'))
);

const SmsfContributionDashboard = Loadable(
  lazy(() => import('views/client/dashboard/smsf/contribution/dashboard/SmsfContributionDashboard'))
);

const SmsfCreateExpense = Loadable(
  lazy(() => import('views/client/dashboard/smsf/expense/create/SmsfCreateExpense'))
);

const SmsfExpenseDashboard = Loadable(
  lazy(() => import('views/client/dashboard/smsf/expense/dashboard/SmsfExpenseDashboard'))
);

const SmsfCreateIncome = Loadable(
  lazy(() => import('views/client/dashboard/smsf/income/create/SmsfCreateIncome'))
);

const SmsfIncomeDashboard = Loadable(
  lazy(() => import('views/client/dashboard/smsf/income/dashboard/SmsfIncomeDashboard'))
);

const SmsfCreateLiability = Loadable(
  lazy(() => import('views/client/dashboard/smsf/liability/create/SmsfCreateLiability'))
);

const SmsfLiabilityDashboard = Loadable(
  lazy(() => import('views/client/dashboard/smsf/liability/dashboard/SmsfLiabilityDashboard'))
);

const SmsfFinancialPosition = Loadable(
  lazy(() => import('views/client/dashboard/smsf/financialPositions/FinancialPositions'))
);

const Workflow = Loadable(lazy(() => import('views/workflow/Workflow')));

const Board = Loadable(lazy(() => import('views/workflow/board/Board')));

const Payments = Loadable(lazy(() => import('views/payments/Payments')));
const ClientPaymentHistory = Loadable(
  lazy(() => import('views/client/clientDashboard/paymentHistory/ClientPaymentHistory'))
);

const TrustDashboard = Loadable(lazy(() => import('views/client/dashboard/trust/TrustDashboard')));
const CorporateTrusteeDashboard = Loadable(
  lazy(() => import('views/client/dashboard/trust/corporate/dashboard/CorporateTrusteeDashboard'))
);

// ==============================|| Partnership ||============================== //

const CreatePartnership = Loadable(
  lazy(() => import('views/client/createClient/partnership/CreatePartnership'))
);

const CreatePartnershipCSV = Loadable(
  lazy(() => import('views/client/createClient/partnership/CreatePartnershipCSV'))
);

const PartnershipDashboard = Loadable(
  lazy(() => import('views/client/dashboard/partnership/PartnershipDashboard'))
);

// ==============================|| Feedback ||============================== //

const NormalFeedback = Loadable(lazy(() => import('views/feedback/general/NormalFeedback')));

const MainRoutes = {
  path: '/main',
  element: <MainLayout />,
  children: [
    {
      path: 'newDashboard',
      element: <NewDashboard />,
    },
    {
      path: 'clientList',
      element: <ClientList />,
      children: [
        {
          path: 'individual',
        },
        {
          path: 'smsf',
        },
        {
          path: 'company',
        },
        {
          path: 'trust',
        },
        {
          path: 'partnership',
        },
      ],
    },
    {
      path: 'client/update-client',
      element: <UpdateClient />,
    },
    {
      path: 'lead',
      element: <LeadList />,
    },
    {
      path: 'email',
      element: <Email />,
    },
    {
      path: 'bulk-email',
      element: <BulkEmail />,
    },
    {
      path: 'tasks',
      element: <Tasks />,
    },
    {
      path: 'create-tasks',
      element: <CreateTask />,
    },

    {
      path: 'files',
      element: <Files />,
    },
    {
      path: 'videos',
      element: <Video />,
    },
    {
      path: 'insight',
      element: <Insights />,
    },

    {
      path: 'insight/time-report',
      element: <TimeReport />,
    },

    {
      path: 'insight/payment-history',
      element: <PaymentHistory />,
    },

    {
      path: 'modelling',
      element: <Modellings />,
    },
    {
      path: 'modelling/basic',
      element: <BasicInvestmentModeling />,
    },
    {
      path: 'modelling/superannuation',
      element: <Superannuation />,
    },

    {
      path: 'clientDashboard',
      element: <ClientDashboard />,
    },
    {
      path: 'create-individual',
      element: <CreateIndividual />,
    },

    {
      path: 'create-company',
      element: <CreateCompany />,
    },
    {
      path: 'create-company-csv',
      element: <CreateCompanyCSV />,
    },
    {
      path: 'company/company-dashboard',
      element: <CompanyDashboard />,
    },

    {
      path: 'create-trust',
      element: <CreateTrust />,
    },
    {
      path: 'create-trust-csv',
      element: <CreateTrustCSV />,
    },
    {
      path: 'trust/trust-dashboard',
      element: <TrustDashboard />,
    },
    {
      path: 'trust/corporate-dashboard',
      element: <CorporateTrusteeDashboard />,
    },

    // partnership
    {
      path: 'create-partnership',
      element: <CreatePartnership />,
    },
    {
      path: 'create-partnership-csv',
      element: <CreatePartnershipCSV />,
    },
    {
      path: 'partnership/partnership-dashboard',
      element: <PartnershipDashboard />,
    },

    {
      path: 'create-client-csv',
      element: <CreateClientCSV />,
    },
    {
      path: 'createLead',
      element: <CreateLead />,
    },
    {
      path: 'updateLead',
      element: <UpdateLead />,
    },
    {
      path: 'createLeadCSV',
      element: <CreateLeadCSV />,
    },
    {
      path: 'teamMembersList',
      element: <TeamMembersList />,
    },
    {
      path: 'createTeamMember',
      element: <CreateTeamMembers />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },
    {
      path: 'settings/modeling',
      element: <SettingsModeling />,
    },

    {
      path: 'settings/modeling/insurence',
      element: <InsurenceModelling />,
    },
    {
      path: 'settings/modeling/superannuation',
      element: <SuperAnnuationModelling />,
    },
    {
      path: 'settings/modeling/basic',
      element: <BasicInvestment />,
    },
    {
      path: 'settings/modeling/insurence/settings',
      element: <ModellingSettings />,
    },
    {
      path: 'myProfile',
      element: <MyProfile />,
    },
    {
      path: 'organization',
      element: <Organization />,
    },
    {
      path: 'calculation',
      element: <Calculation />,
    },
    {
      path: 'emailNotification',
      element: <EmailNotification />,
    },

    {
      path: 'webForms',
      element: <WebForms />,
    },
    {
      path: 'investorProfile',
      element: <InvestorProfile />,
    },
    {
      path: 'clientApp',
      element: <ClientApp />,
    },
    {
      path: 'templates',
      element: <Templates />,
    },
    {
      path: 'templates/emailTemplates',
      element: <EmailTemplates />,
    },
    {
      path: 'templates/create-emailTemplates',
      element: <CreateEmailTemplate />,
    },
    {
      path: 'templates/documentsTemplates',
      element: <DocumentsTemplates />,
    },
    {
      path: 'templates/create-digital-documents',
      element: <CreateDigital />,
    },
    {
      path: 'templates/workflowTemplates',
      element: <WorkflowTemplates />,
    },
    {
      path: 'templates/questionnaireTemplate',
      element: <QuestionnaireTemplate />,
    },
    {
      path: 'templates/questionnaireTemplate/manage-template',
      element: <CreateQuestionnaireTemplate />,
    },
    {
      path: 'client/income-dashboard',
      element: <IncomeDashboard />,
    },
    {
      path: 'client/income-report',
      element: <IncomeReport />,
    },

    {
      path: 'client/assets-dashboard',
      element: <AssetsDashboard />,
    },
    {
      path: 'client/assets-report',
      element: <AssetsReport />,
    },

    {
      path: 'client/expenses-dashboard',
      element: <ExpensesDashboard />,
    },
    {
      path: 'client/simple-expenses-dashboard',
      element: <SimpleExpensesDashboard />,
    },
    {
      path: 'client/expenses-report',
      element: <ExpensesReport />,
    },

    {
      path: 'client/goalsAndObjectives-dashboard',
      element: <GoalsAndObjectiveDashboard />,
    },
    {
      path: 'client/goalsAndObjectives-report',
      element: <GoalsAndObjectiveReport />,
    },
    {
      path: 'client/createGoalsAndObjectives',
      element: <CreateGoalsAndObjectives />,
    },

    {
      path: 'client/liabilities-dashboard',
      element: <LiabilitiesDashboard />,
    },
    {
      path: 'client/liabilities-report',
      element: <LiabilitiesReport />,
    },

    {
      path: 'client/protection-dashboard',
      element: <ProtectionDashboard />,
    },
    {
      path: 'client/protection-report',
      element: <ProtectionReport />,
    },
    {
      path: 'client/protection-create',
      element: <CreateFamilyAndLifestyleProtection />,
    },

    {
      path: 'client/comments',
      element: <Comments />,
    },
    {
      path: 'client/documents',
      element: <Documents />,
    },
    {
      path: 'client/client-files',
      element: <ClientFiles />,
    },
    {
      path: 'client/identification',
      element: <Identification />,
    },
    {
      path: 'client/file-notes',
      element: <FileNotes />,
    },
    {
      path: 'client/create-notes',
      element: <CreateFileNote />,
    },
    {
      path: 'client/advise-relationship',
      element: <AdviseRelationship />,
    },
    {
      path: 'client/financial-positions',
      element: <ClientFinancialPositions />,
    },
    {
      path: 'client/questionnaire-list',
      element: <ListClientQuestionnaire />,
    },
    {
      path: 'client/questionnaires/:questionnaireId',
      element: <ClientQuestionnaires clientType="CLIENT" />,
    },
    {
      path: 'client/linked-entities',
      element: <LinkedEntities />,
    },

    {
      path: 'client/financial-dashboard/:id',
      element: <FinancialDashboard />,
    },
    {
      path: 'asx',
      element: <Asx />,
    },
    {
      path: 'asx-real-time-indices',
      element: <Sandp />,
    },
    {
      path: 'asx-company',
      element: <Company />,
    },
    {
      path: 'cryptocurrency',
      element: <Crypto />,
    },
    {
      path: 'exchange-rate',
      element: <ExchangeRate />,
    },

    {
      path: 'calculators',
      element: <Calculators />,
    },
    {
      path: 'calculator/simple-mortgage',
      element: <SimpleMortgage />,
    },

    {
      path: 'calculator/annualized-return-calculator',
      element: <AnnualizedReturnCalculator />,
    },

    {
      path: 'calculator/break-even-point-calculator',
      element: <BreakEvenPointCalculator />,
    },

    {
      path: 'calculator/compound-interest-calculator',
      element: <CompoundInterestCalculator />,
    },

    {
      path: 'calculator/investment-time-calculator',
      element: <InvestmentTimeCalculator />,
    },

    {
      path: 'calculator/markup-calculator',
      element: <MarkupCalculator />,
    },

    {
      path: 'calculator/present-value-calculator',
      element: <PresentValueCalculator />,
    },

    {
      path: 'calculator/mortgage-with-extra-payment-calculator',
      element: <MortgageWithExtraPayment />,
    },
    {
      path: 'calculator/stamp-duty-calculator',
      element: <StampDutyCalculator />,
    },
    {
      path: 'calculator/property-calculator',
      element: <PropertyCalculator />,
    },
    {
      path: 'calculator/car-loan-repayment-calculator',
      element: <CarLoanRepaymentCalculator />,
    },
    {
      path: 'calculator/debt-recycling-calculator',
      element: <DebtRecyclingCalculator />,
    },
    {
      path: 'calculator/income-tax-calculator',
      element: <IncomeTaxCalculator />,
    },
    {
      path: 'calculator/smsf-property-purchase-calculator',
      element: <SMSFPropertyPurchaseCalculator />,
    },

    //ai
    {
      path: 'ai/assistant',
      element: <ChatGPT />,
    },
    {
      path: 'ai/voice-command',
      element: <VoiceCommand />,
    },
    {
      path: 'ai/voice-assistance',
      element: <AlanAi />,
    },

    // client Expense
    {
      path: 'client/createExpense',
      element: <CreateExpense />,
    },

    // client Liability
    {
      path: 'client/createLiability',
      element: <CreateLiability />,
    },

    // client assets
    {
      path: 'client/CreateAsset',
      element: <CreateAsset />,
    },

    // client income
    {
      path: 'client/createIncome',
      element: <CreateIncome />,
    },

    //payment history
    {
      path: 'client/payment-history',
      element: <ClientPaymentHistory />,
    },

    {
      path: 'lead/leadDashboard',
      element: <LeadDashboard />,
    },
    {
      path: 'lead/lead-dashboard/asset',
      element: <LeadAssetDashboard />,
    },
    {
      path: 'lead/lead-dashboard/expense',
      element: <LeadExpensesDashboard />,
    },
    {
      path: 'lead/lead-dashboard/goals-and-objective',
      element: <LeadGoalsAndObjectiveDashboard />,
    },
    {
      path: 'lead/lead-dashboard/income',
      element: <LeadIncomeDashboard />,
    },
    {
      path: 'lead/lead-dashboard/liability',
      element: <LeadLiabilityDashboard />,
    },
    {
      path: 'lead/lead-dashboard/protection',
      element: <LeadProtectionPoliciesDashboard />,
    },
    {
      path: 'lead/lead-dashboard/questionnaires',
      element: <LeadQuestionnairesDashboard />,
    },
    {
      path: 'lead/lead-dashboard/questionnaires/:questionnaireId',
      element: <ClientQuestionnaires clientType="LEAD" />,
    },

    {
      path: 'messages',
      element: <Messages />,
    },
    {
      path: 'VideoCall',
      element: <VideoCall />,
    },
    // SMSF
    {
      path: 'smsf/smsf-dashboard',
      element: <SmsfDashboard />,
    },
    {
      path: 'smsf/create-smsf',
      element: <CreateSmsf />,
    },

    {
      path: 'smsf/create-smsf-csv',
      element: <CreateSmsfCSV />,
    },
    {
      path: 'smsf/update-smsf',
      element: <UpdateSMSFClient />,
    },

    {
      path: 'smsf/create-asset',
      element: <SmsfCreateAsset />,
    },
    {
      path: 'smsf/asset-dashboard',
      element: <SmsfAssetsDashboard />,
    },

    {
      path: 'smsf/create-cashflow',
      element: <SmsfCreateCashflow />,
    },
    {
      path: 'smsf/cashflow-dashboard',
      element: <SmsfCashflowDashboard />,
    },

    {
      path: 'smsf/create-contribution',
      element: <SmsfCreateContribution />,
    },
    {
      path: 'smsf/contribution-dashboard',
      element: <SmsfContributionDashboard />,
    },

    {
      path: 'smsf/create-expense',
      element: <SmsfCreateExpense />,
    },
    {
      path: 'smsf/expense-dashboard',
      element: <SmsfExpenseDashboard />,
    },

    {
      path: 'smsf/create-income',
      element: <SmsfCreateIncome />,
    },
    {
      path: 'smsf/income-dashboard',
      element: <SmsfIncomeDashboard />,
    },

    {
      path: 'smsf/create-liability',
      element: <SmsfCreateLiability />,
    },
    {
      path: 'smsf/liability-dashboard',
      element: <SmsfLiabilityDashboard />,
    },
    {
      path: 'smsf/financial-position',
      element: <SmsfFinancialPosition />,
    },

    {
      path: 'payments',
      element: <Payments />,
    },

    {
      path: 'workflow',
      element: <Workflow />,
    },

    {
      path: 'workflow/board',
      element: <Board />,
    },

    {
      path: 'feedback/general',
      element: <NormalFeedback />,
    },
  ],
};

export default MainRoutes;
