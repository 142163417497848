import React from 'react';

import { Grid, Paper, Stack, Skeleton } from '@mui/material';

import { useCommonStyles } from 'styles/styles';
import { gridSpacing } from 'store/constant';

const TemplateListItemSkeleton = (props) => {
  const { classes: commonClasses } = useCommonStyles();
  return (
    <Paper className={commonClasses.listContainer} variant="outlined">
      <Grid container item xs={12} spacing={gridSpacing}>
        <Grid container item xs={6} alignItems={'center'}>
          <Stack direction="column" spacing={2} alignItems={'center'}>
            <Skeleton variant="text" sx={{ height: '1rem', minWidth: '20ch' }} />
            <Skeleton variant="text" sx={{ height: '1rem', minWidth: '20ch' }} />
          </Stack>
        </Grid>
        <Grid item xs={true} md="auto" />
        <Grid container item xs="auto" md={2} alignItems={'center'}>
          <Skeleton variant="text" sx={{ height: '1rem', minWidth: '14ch' }} />
        </Grid>
        <Grid item xs={12} md="auto" />
        <Grid item xs="auto">
          <Skeleton animation="wave" variant="rounded" sx={{ width: 60, height: 24 }} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TemplateListItemSkeleton;
