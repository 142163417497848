import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // create
  saveRequestLoading: false,
  saveData: {},
  saveFailed: {},

  // get all
  getRequestLoading: false,
  getListData: null,
  getListDataFailed: null,
};

export const smsfContributionReducer = createReducer(initialState, {
  //save
  postSmsfContributionRequest: (state) => {
    state.saveRequestLoading = true;
    state.saveData = {};
    state.saveFailed = {};
  },
  postSmsfContributionSuccess: (state, action) => {
    state.saveRequestLoading = false;
    state.saveData = action.payload;
    state.saveFailed = {};
  },
  postSmsfContributionFailed: (state, action) => {
    state.saveRequestLoading = false;
    state.saveData = {};
    state.saveFailed = action.payload;
  },

  //get all
  getSmsfContributionRequest: (state) => {
    state.getRequestLoading = true;
    state.getListData = null;
    state.getListDataFailed = {};
  },
  getSmsfContributionSuccess: (state, action) => {
    state.getRequestLoading = false;
    state.getListData = action.payload;
    state.getListDataFailed = {};
  },
  getSmsfContributionFailed: (state, action) => {
    state.getRequestLoading = false;
    state.getListData = null;
    state.getListDataFailed = action.payload;
  },
});
