import { format, isDate, isValid } from 'date-fns';

import { Fade, Grid, Typography } from '@mui/material';

const ShareholderDetail = (props) => {
  const { shareholderData } = props;
  /**
   * Get Client Name
   */
  const getClientName = () => {
    return (
      <Typography
        sx={(theme) => ({ color: theme.palette?.background?.paper, fontSize: '0.75rem' })}
        component="span"
      >
        {(shareholderData?.title
          ? `${shareholderData?.title} ${
              shareholderData?.surname
                ? `${shareholderData?.firstName} ${shareholderData?.surname}`
                : `${shareholderData?.firstName}`
            } `
          : shareholderData?.surname
          ? `${shareholderData?.firstName} ${shareholderData?.surname}`
          : `${shareholderData?.firstName}`) || shareholderData?.firstName}
      </Typography>
    );
  };
  //shareholder detail key
  const shareholderDetailKey =
    shareholderData?.shareholderType === 'Company'
      ? 'companyId'
      : shareholderData?.shareholderType === 'SMSF'
      ? 'smsfId'
      : shareholderData?.shareholderType === 'Trust' && 'trustId';
  /**
   * Get Company Name
   */
  const getCompanyName = () => {
    return (
      <Typography
        sx={(theme) => ({ color: theme.palette?.background?.paper, fontSize: '0.75rem' })}
        component="span"
      >
        {shareholderData?.[shareholderDetailKey]?.name}
      </Typography>
    );
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="caption" component="span">
          Name:
        </Typography>
        {shareholderData?.shareholderType === 'Individual' ||
        shareholderData?.shareholderType === 'Director'
          ? getClientName()
          : getCompanyName()}
      </Grid>
      <Fade
        unmountOnExit
        in={
          shareholderData?.shareholderType === 'Individual' ||
          shareholderData?.shareholderType === 'Director'
        }
      >
        <Grid item xs={12}>
          <Typography variant="caption" component="span">
            Email Address:
            <Typography
              sx={(theme) => ({ color: theme.palette?.background?.paper, fontSize: '0.75rem' })}
              component="span"
            >
              {shareholderData?.primaryEmailAddress}
            </Typography>
          </Typography>
        </Grid>
      </Fade>
      <Fade
        unmountOnExit
        in={
          shareholderData?.shareholderType === 'Individual' ||
          shareholderData?.shareholderType === 'Director'
        }
      >
        <Grid item xs={12}>
          <Typography variant="caption" component="span">
            Date of Birth:
            <Typography
              sx={(theme) => ({ color: theme.palette?.background?.paper, fontSize: '0.75rem' })}
              component="span"
            >
              {shareholderData?.dateOfBirth &&
              isDate(new Date(shareholderData?.dateOfBirth)) &&
              isValid(new Date(shareholderData?.dateOfBirth))
                ? format(new Date(shareholderData?.dateOfBirth), 'yyyy-MM-dd')
                : 'N/A'}
            </Typography>
          </Typography>
        </Grid>
      </Fade>
      <Fade
        unmountOnExit
        in={
          shareholderData?.shareholderType !== 'Individual' &&
          shareholderData?.shareholderType !== 'Director'
        }
      >
        <Grid item xs={12}>
          <Typography variant="caption" component="span">
            TFN:{' '}
            <Typography
              sx={(theme) => ({ color: theme.palette?.background?.paper, fontSize: '0.75rem' })}
              component="span"
            >
              {shareholderData?.[shareholderDetailKey]?.tfn || 'N/A'}
            </Typography>
          </Typography>
        </Grid>
      </Fade>
      <Fade
        unmountOnExit
        in={
          shareholderData?.shareholderType !== 'Individual' &&
          shareholderData?.shareholderType !== 'Director'
        }
      >
        <Grid item xs={12}>
          <Typography variant="caption" component="span">
            ABN:{' '}
            <Typography
              sx={(theme) => ({ color: theme.palette?.background?.paper, fontSize: '0.75rem' })}
              component="span"
            >
              {shareholderData?.[shareholderDetailKey]?.abn || 'N/A'}
            </Typography>
          </Typography>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default ShareholderDetail;
