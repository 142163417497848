import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, SvgIcon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import { gridSpacing } from 'store/constant';

import { deleteSvg, editSvg } from 'assets/svg/svgImg';
const CompanyDirectorList = (props) => {
  const { setManageDirectorsModalData, handleOpenConfirmationModal } = props;

  //_trust store
  const companyClientReducer = useSelector((state) => state?.companyClientReducer);
  const isCompanyClientDetailRequestLoading =
    companyClientReducer?.isCompanyClientDetailRequestLoading;
  const selectedCompanyClientDetail = companyClientReducer?.selectedCompanyClientDetail;

  const [paginationData, setPaginationData] = useState({
    data: [],
    pageSize: 5,
    totalElements: 0,
  });
  /**
   * Get data grid rows
   */
  const getDataGridRows = () => {
    const rowData = paginationData?.data;
    return rowData;
  };
  /**
   * Get data grid column definition
   */
  const getDataGridColumns = () => {
    const directorsColumns = [
      {
        field: 'fullName',
        headerName: 'Director Name',
        flex: 1,
      },
      {
        field: 'primaryEmailAddress',
        headerName: 'Primary Email Address',
        flex: 1,
      },
      {
        field: 'primaryMobileNumber',
        headerName: 'Primary Mobile Number',
        flex: 1,
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 1,
        align: 'start',
        type: 'actions',
        getActions: (params) => {
          const deletingDirectorId = params?.row?._id;
          const isDirectorWithinShareholder =
            Array.isArray(selectedCompanyClientDetail?.shareholder) &&
            selectedCompanyClientDetail.shareholder.find(
              (shareholderDetail) => shareholderDetail?.directorId?._id === deletingDirectorId
            );
          const isAbleToDelete = !isDirectorWithinShareholder;
          return [
            <GridActionsCellItem
              key={1}
              id="edit"
              icon={<SvgIcon viewBox="0 0 25 25">{editSvg}</SvgIcon>}
              onClick={(event) => handleActionClick(event, 'EDIT', params)}
              label="Edit Director"
            />,
            ...(isAbleToDelete
              ? [
                  <GridActionsCellItem
                    key={2}
                    id="delete"
                    icon={<SvgIcon viewBox="0 0 25 25">{deleteSvg}</SvgIcon>}
                    onClick={(event) => handleActionClick(event, 'DELETE', params)}
                    label="Delete"
                  />,
                ]
              : []),
          ];
        },
      },
    ];

    return directorsColumns;
  };
  /**
   * Handle datagrid action click
   * @param {*} event
   * @param {*} actionType
   * @param {*} gridParams
   */
  const handleActionClick = (event, actionType, gridParams) => {
    if (actionType === 'EDIT') {
      const editingDirectorData = gridParams?.row;
      if (editingDirectorData) {
        setManageDirectorsModalData &&
          setManageDirectorsModalData((prev) => ({
            ...prev,
            isOpened: true,
            editData: editingDirectorData || null,
          }));
      }
    } else if (actionType === 'DELETE') {
      const deletingDirectorData = gridParams?.row;
      handleOpenConfirmationModal({
        isConfirmationOpen: true,
        confirmationModalHeader: 'Delete Director!',
        confirmationModalContent: 'Do you want to remove this director?',
        confirmationData: deletingDirectorData,
        actionType: 'DELETE_DIRECTOR',
      });
    }
  };
  /**
   * Data grid unique key
   * @param {*} row
   * @returns {string} key
   */
  const getRowId = (row) => row?._id;
  /**
   * Effect on trust details
   */
  useEffect(() => {
    if (
      Array.isArray(selectedCompanyClientDetail?.director) &&
      !isCompanyClientDetailRequestLoading
    ) {
      const directorsData = (selectedCompanyClientDetail?.director || []).map((directorDetail) => ({
        ...directorDetail,
        fullName:
          (directorDetail?.title &&
            directorDetail.title +
              ' ' +
              ((directorDetail?.surName &&
                directorDetail?.firstName + ' ' + directorDetail.surName) ||
                directorDetail.firstName)) ||
          (directorDetail?.surName && directorDetail?.firstName + ' ' + directorDetail?.surName) ||
          directorDetail?.firstName,
      }));
      setPaginationData((prev) => ({
        ...prev,
        data: directorsData,
        totalElements: directorsData?.length,
      }));
    }
  }, [selectedCompanyClientDetail?.director?.length, isCompanyClientDetailRequestLoading]);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <DataGrid
          rows={getDataGridRows()}
          columns={getDataGridColumns()}
          getRowId={getRowId}
          rowsPerPageOptions={[5, 10]}
          // checkboxSelection
          sx={{ height: '60vh' }}
          loading={isCompanyClientDetailRequestLoading}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyDirectorList;
