import { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

const CustomizedDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    borderRadius: '1.25rem',
  },
}));

function CustomizedDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

CustomizedDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CommonModal = (props) => {
  const {
    open,
    handleDialogOpened,
    dialogTitle,
    dialogContent,
    dialogActions,
    maxWidth,
    fullWidth,
    dialogProps,
    dialogContentProps,
    dialogActionProps,
  } = props;

  const handleClose = () => {
    handleDialogOpened && handleDialogOpened(false);
  };

  return (
    <CustomizedDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      {...dialogProps}
    >
      <CustomizedDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {typeof dialogTitle === 'string' ? (
          <Typography
            sx={(theme) => ({
              color: theme.palette?.grey?.[750],
              fontWeight: theme.typography.fontWeightBold,
              fontSize: '1.25rem',
            })}
          >
            {dialogTitle}
          </Typography>
        ) : (
          dialogTitle
        )}
      </CustomizedDialogTitle>
      <DialogContent dividers={!!(dialogTitle || dialogActions)} {...dialogContentProps}>
        {dialogContent}
      </DialogContent>
      {dialogActions && <DialogActions {...dialogActionProps}>{dialogActions}</DialogActions>}
    </CustomizedDialog>
  );
};

CommonModal.defaultProps = {
  dialogProps: {},
  dialogContentProps: {},
  dialogActionProps: {},
};

CommonModal.propTypes = {
  open: PropTypes.bool,
  handleDialogOpened: PropTypes.func,
  dialogTitle: PropTypes.string,
  dialogContent: PropTypes.any,
  dialogActions: PropTypes.any,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  dialogProps: PropTypes.object,
  dialogContentProps: PropTypes.object,
  dialogActionProps: PropTypes.object,
};

export default memo(CommonModal);
