import { memo, useCallback, useEffect, useState } from 'react';

import { Button, Fade, Grid, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import DropDownAutoCom from 'common/material/DropDownAutoCom';
import InputField from 'common/material/InputField';
import CommonModal from 'common/material/CommonModal';
import ConfirmationModal from 'common/ConfirmationModal';

import { useCommonStyles } from 'styles/styles';
import { gridSpacing } from 'store/constant';
import LoadingSpinner from 'ui-component/LoadingSpinner';
import TemplateListItem from './components/TemplateListItem';
import TemplateListItemSkeleton from './components/TemplateListItemSkeleton';

import {
  requestPOSTAssignTemplateToClients,
  requestPOSTFilterQuestionnaireTemplates,
} from 'redux/Actions/questionnaireTemplateAction';
import { requestFilterQuestionnaireTemplateTags } from 'redux/Actions/questionnaireTagAction';

import * as personsAnimationData from 'assets/animation/questionnaire-template-animation.json';

const ModalContent = (props) => {
  const { isOpened, handleClose, handleOnAssignSuccessCallback } = props;
  /**
   * Uses react-router-dom navigation
   */
  const navigate = useNavigate();
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  //_common classes
  const {
    classes: { cancelBtn, labelRed },
  } = useCommonStyles();
  //_login user store
  const loginUserData = useSelector((state) => state.authenticationReducer?.loginData?.user);
  //_ assign template modal store
  const assignTemplateModalData = useSelector(
    (state) => state.modalReducer?.CLIENT_TEMPLATE_ASSIGN_MODAL
  );
  const clientDetail = assignTemplateModalData?.modalData?.clientData;
  //_ questionnaire template reducer
  const questionnaireTemplateReducer = useSelector((state) => state.questionnaireTemplateReducer);
  const questionnaireTemplateSearchSuccessData =
    questionnaireTemplateReducer?.questionnaireTemplateSearchSuccessData;
  const isSearchQuestionnaireTemplateLoading =
    questionnaireTemplateReducer?.isSearchQuestionnaireTemplateLoading;
  const isQuestionnaireTemplateAssignLoading =
    questionnaireTemplateReducer?.isQuestionnaireTemplateAssignLoading;

  //_states
  const [templateListData, setTemplateListData] = useState([]);
  const [filterData, setFilterData] = useState({
    searchTitle: '',
    tags: [],
  });
  const [confirmationModalData, setConfirmationModalData] = useState({
    isOpened: false,
    header: 'Do you want to assign this template to the client?',
    content:
      "Confirming this action will create a Advisor's Questionnaire Item from the selected template details.",
    modalData: null,
  });
  const [tagOptionsData, setTagOptionsData] = useState([]);

  const isLoading =
    !!isQuestionnaireTemplateAssignLoading || !!isSearchQuestionnaireTemplateLoading;

  const handleOnTemplateSearchChange = useCallback((event) => {
    const searchTitle = event?.target?.value || '';
    setFilterData((prev) => ({
      ...prev,
      searchTitle,
    }));
  }, []);
  /**
   * Handle close assign confirmation
   */
  const handleCloseConfirmationAction = useCallback(() => {
    setConfirmationModalData((prev) => ({
      ...prev,
      isOpened: false,
      modalData: null,
    }));
  }, []);

  /**
   * Handle assign template to client
   */
  const handleAssignTemplate = useCallback(
    (event, templateDetail) => {
      setConfirmationModalData((prev) => ({
        ...prev,
        isOpened: true,
        modalData: {
          templateDetail,
          clientDetail,
        },
      }));
    },
    [clientDetail]
  );
  /**
   * Handle request templates
   */
  const handleRequestTemplates = useCallback(() => {
    const filterRequestData = {
      rootUser: loginUserData?.rootUser,
    };
    if (typeof filterData?.searchTitle === 'string') {
      filterRequestData.templateTitle = filterData.searchTitle;
    }
    if (Array.isArray(filterData?.tags) && filterData.tags?.length) {
      filterRequestData.tags = filterData.tags.map((tagDetail) => tagDetail?._id);
    }
    dispatch(
      requestPOSTFilterQuestionnaireTemplates({
        filterRequestBody: filterRequestData,
      })
    );
  }, [filterData, loginUserData]);
  /**
   * Handle confirm assign template
   */
  const handleConfirmAssignTemplate = useCallback(() => {
    const templateDetail = confirmationModalData?.modalData?.templateDetail;
    const assignees = [confirmationModalData?.modalData?.clientDetail?._id];
    const assignTemplateRequestData = {
      templateId: templateDetail?._id,
      assignedClients: assignees,
      rootUser: loginUserData?.rootUser,
    };
    const handleAssignTemplateSuccessCallback = (response) => {
      handleRequestTemplates();
      handleOnAssignSuccessCallback && handleOnAssignSuccessCallback();
      handleCloseConfirmationAction();
    };
    dispatch(
      requestPOSTAssignTemplateToClients(
        assignTemplateRequestData,
        handleAssignTemplateSuccessCallback
      )
    );
  }, [confirmationModalData?.modalData]);
  /**
   * Handle navigate to create template
   */
  const handleNavigateCreateTemplate = useCallback(() => {
    navigate('/main/templates/questionnaireTemplate/manage-template');
  }, [navigate]);
  /**
   * Effect on assign modal open
   */
  useEffect(() => {
    if (isOpened) {
      handleRequestTemplates();
    }
  }, [isOpened, filterData]);
  /**
   * Effect on template list success
   */
  useEffect(() => {
    if (Array.isArray(questionnaireTemplateSearchSuccessData?.data)) {
      let templateData = questionnaireTemplateSearchSuccessData.data.map((detail) => ({
        ...detail,
        isAssignable: true,
      }));
      setTemplateListData(templateData);
    }
  }, [questionnaireTemplateSearchSuccessData?.data]);
  /**
   * Request for tags
   */
  useEffect(() => {
    const requestData = {
      rootUser: loginUserData?.rootUser,
    };
    const handleSearchTagsSuccessCallback = (response) => {
      if (Array.isArray(response?.data)) {
        setTagOptionsData(response?.data);
      }
    };
    dispatch(requestFilterQuestionnaireTemplateTags(requestData, handleSearchTagsSuccessCallback));
  }, [loginUserData?.rootUser]);
  return (
    <Grid container alignItems="center" rowGap="1rem">
      {!!isOpened && (
        <ConfirmationModal
          isConfirmationModal={!!confirmationModalData?.isOpened}
          closeConfirmationAction={handleCloseConfirmationAction}
          modalConfirmAction={handleConfirmAssignTemplate}
          confirmationModalHeader={confirmationModalData.header}
          confirmationModalContent={confirmationModalData.content}
          img={personsAnimationData}
          loading={isLoading}
        />
      )}
      <Grid
        container
        sx={(theme) => ({
          padding: '1rem',
          background: theme.palette.secondary.light,
          position: 'relative',
        })}
        rowGap="1rem"
      >
        {!!isLoading && <LoadingSpinner isAbsolutePositioned isLoadingSpinner={!!isLoading} />}
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={gridSpacing}>
            <Grid item xs={6}>
              <InputField
                name="search-templates"
                label="Template Title"
                placeholder="Search here.."
                onChange={handleOnTemplateSearchChange}
              />
            </Grid>
            <Grid item xs={6}>
              <DropDownAutoCom
                label="Tags"
                optionData={tagOptionsData || []}
                setDropDownValue={(value) => {
                  if (Array.isArray(value)) {
                    setFilterData((prev) => ({
                      ...prev,
                      tags: value,
                    }));
                  }
                }}
                dropDownValue={filterData?.tags}
                textFieldMargin="none"
                valueKey="_id"
                labelKey="tagName"
                multiple
                ChipProps={{
                  color: 'primary',
                  size: 'medium',
                  variant: 'outlined',
                  sx: {
                    marginLeft: '0.5rem !important',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ height: '18rem', overflow: 'auto' }}>
          <Fade in={!!isLoading} unmountOnExit>
            <Grid container alignItems="flex-start" spacing={gridSpacing}>
              {[1, 2, 3, 4].map((skeletonKey) => (
                <Grid item xs={12} key={`tag-skeleton-${skeletonKey}`}>
                  <TemplateListItemSkeleton key={`${skeletonKey}-tag-skeletonCom`} />
                </Grid>
              ))}
            </Grid>
          </Fade>
          <Fade in={!!templateListData?.length && !isLoading} unmountOnExit>
            <Grid container alignItems="flex-start" spacing={gridSpacing}>
              {templateListData.map((templateDetail, assigneeIndex) => (
                <Grid item xs={12} key={`tag-${assigneeIndex}`}>
                  <TemplateListItem
                    templateDetail={templateDetail}
                    handleAssignTemplate={handleAssignTemplate}
                  />
                </Grid>
              ))}
            </Grid>
          </Fade>
          <Fade in={!templateListData?.length && !isLoading} unmountOnExit>
            <Grid container item xs={12} justifyContent="center">
              <Grid item xs="auto" textAlign="center">
                <Typography
                  className={labelRed}
                  sx={{
                    minHeight: '12rem',
                  }}
                >
                  No Records
                </Typography>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={gridSpacing}
        sx={{
          paddingBottom: '1rem',
        }}
      >
        <Grid item xs="auto">
          <Button variant="outlined" className={cancelBtn} onClick={handleNavigateCreateTemplate}>
            I need to create a new questionnaire template
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button variant="outlined" className={cancelBtn} onClick={handleClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AssignTemplateModal = (props) => {
  const { isOpened, handleClose, clientDetail, handleOnAssignSuccessCallback } = props;

  return (
    <CommonModal
      open={isOpened}
      handleDialogOpened={handleClose}
      dialogTitle={`(${
        clientDetail?.title ? `${clientDetail.title} ` : ''
      }${clientDetail?.firstName}${clientDetail?.surName ? ` ${clientDetail.surName}` : ''}) - Assign Template`}
      dialogContent={
        <ModalContent
          isOpened={isOpened}
          clientDetail={clientDetail}
          handleClose={handleClose}
          handleOnAssignSuccessCallback={handleOnAssignSuccessCallback}
        />
      }
      dialogContentProps={{
        sx: {
          padding: '0 !important',
        },
      }}
      maxWidth="md"
    />
  );
};

AssignTemplateModal.propTypes = {
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func,
  clientDetail: PropTypes.object,
  handleOnAssignSuccessCallback: PropTypes.func,
};

export default memo(AssignTemplateModal);
