import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useFormikContext } from 'formik';
import { TransitionGroup } from 'react-transition-group';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Fade,
  Grid,
  Grow,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ArrowDownward, ArrowUpward, UndoOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ConfirmationModal from 'common/ConfirmationModal';

import { useCommonStyles } from 'styles/styles';
import { gridSpacing } from 'store/constant';

import * as warningCircleAnimationData from 'assets/animation/warning-circle.json';

import { ReactComponent as SectionAddIcon } from 'assets/images/icons/section-add.svg';
import { ReactComponent as QuestionRemoveIcon } from 'assets/images/icons/question-delete.svg';
import { isNil } from 'ramda';
import RemoveTemplateQuestionSectionConfirmationModal from 'modal/questionnaireTemplate/builderModals/RemoveTemplateQuestionSectionConfirmationModal';
import RemoveTemplateQuestionConfirmationModal from 'modal/questionnaireTemplate/builderModals/RemoveTemplateQuestionConfirmationModal';
import QuestionnaireFieldBuilder from 'views/questionnaire/builder/components/QuestionnaireFieldBuilder';
import CreateQuestionnaireOrSectionModal from 'modal/lead/CreateQuestionnaireOrSectionModal';

const TemplateQuestionnaireBuilder = (props) => {
  /**
   * Uses common classes
   */
  const {
    classes: { cancelBtn },
  } = useCommonStyles();
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  //questionnaires
  const questionnairesData = formikContext?.values?.questionnaires;
  const questionnaireRef = useRef({}); //to track changes and invoke the render effect

  //_media queries
  const isSmallerScreens = useMediaQuery('(max-width:500px)');

  //_states
  const [questionnaireSections, setQuestionnaireSections] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [clearFormData, setClearFormData] = useState({
    isFormCleared: false,
    clearedData: [],
  });
  const [questionnaireOrSectionAddModalData, setQuestionnaireOrSectionAddModalData] = useState({
    isOpened: false,
    modalData: null,
    component: 'header',
    actionDataIndex: 0,
  });
  const [sectionRemoveModalData, setSectionRemoveModalData] = useState({
    isOpened: false,
    isLoading: false,
    actionDataIndex: 0,
    isEditMode: false,
    modalData: null,
  });
  const [confirmationModalData, setConfirmationModalData] = useState({
    isConfirmationOpen: false,
    confirmationTitle: '',
    confirmationContent: '',
    confirmationImage: '',
    loading: false,
    confirmData: null,
    actionType: 'CLEAR_FORM',
  });
  const [removeQuestionConfirmationModalData, setRemoveQuestionConfirmationModalData] = useState({
    isOpened: false,
    actionDataIndex: null,
    modalData: null,
  });

  /**
   * Handle confirm actions
   */
  const handleConfirmConfirmationAction = () => {
    if (confirmationModalData?.actionType === 'CLEAR_FORM') {
      setClearFormData({
        isFormCleared: true,
        clearedData: questionnairesData,
      });
      formikContext.setFieldValue('questionnaires', [], true);
      if (questionnaireRef?.current) {
        questionnaireRef.current = { ...{} };
      }
      closeConfirmationAction();
    }
  };
  /**
   * Handle undo clear form
   * @param {*} event
   */
  const handleUndoClearForm = (event) => {
    formikContext.setFieldValue('questionnaires', clearFormData.clearedData, true);
    if (questionnaireRef?.current) {
      questionnaireRef.current = { ...{} };
    }

    setClearFormData({
      isFormCleared: false,
      clearedData: [],
    });
  };
  /**
   * Handle clear questionnaire form
   * @param {*} event
   */
  const handleClearForm = (event) => {
    setConfirmationModalData((prev) => ({
      ...prev,
      isConfirmationOpen: true,
      confirmationTitle: 'Do you want to clear this form?',
      confirmationContent:
        'By confirming this existing form will be cleared. You have to build a new form for the lead questionnaires!',
      confirmationImage: warningCircleAnimationData,
      actionType: 'CLEAR_FORM',
      confirmData: questionnairesData,
    }));
  };

  /**
   * Add a new question
   * @param {*} event
   * @param {number} addAfterQuestionnaireIndex
   */
  const handleAddNewQuestionnaire = (event, addAfterQuestionnaireIndex, selectedItem = null) => {
    handleQuestionnaireOrSectionAddModalOpened(true, {
      actionDataIndex: addAfterQuestionnaireIndex,
      isEditMode: false,
      isOpened: true,
      component: 'input',
      modalData: null,
    });
  };
  /**
   * Add a new question section at end
   * @param {*} event
   */
  const handleAddNewQuestionnaireSection = (event, actionIndex = null) => {
    handleQuestionnaireOrSectionAddModalOpened(true, {
      actionDataIndex:
        typeof actionIndex === 'number' ? actionIndex : questionnairesData?.length - 1,
      isEditMode: false,
      isOpened: true,
      component: 'header',
      modalData: null,
    });
  };

  const closeConfirmationAction = () => {
    setConfirmationModalData((prev) => ({
      ...prev,
      isConfirmationOpen: false,
      actionType: '',
      confirmData: null,
      loading: false,
    }));
  };
  /**
   * Handle Questionnaire section add modal opened
   * @param {*} isOpened
   * @param {*} modalDetails
   */
  const handleQuestionnaireOrSectionAddModalOpened = (isOpened = false, modalDetails = {}) => {
    setQuestionnaireOrSectionAddModalData((prev) => ({
      ...prev,
      isOpened,
      ...(modalDetails || {}),
    }));
  };
  /**
   * Handle remove section modal opened
   * @param {*} isOpened
   * @param {*} modalData
   */
  const handleRemoveSectionModalOpened = (isOpened = false, modalDetails = {}, event = null) => {
    event?.stopPropagation && event.stopPropagation();
    setSectionRemoveModalData((prev) => ({
      ...prev,
      isOpened,
      modalData: null,
      ...(modalDetails || {}),
    }));
  };
  /**
   * Open or close  remove questionnaire modal
   * @param {*} isOpened
   * @param {*} confirmationData
   */
  const handleRemoveQuestionConfirmationOpened = (isOpened, modalData = null, dataIndex = null) => {
    setRemoveQuestionConfirmationModalData((prev) => ({
      ...prev,
      isOpened,
      actionDataIndex: !isNil(dataIndex) ? dataIndex : null,
      modalData: {
        ...(prev?.modalData || {}),
        ...(modalData || {}),
      },
    }));
  };
  /**
   * Handle on remove question successful
   */
  const handleOnRemoveQuestionSuccess = useCallback(() => {
    setSelectedItem(null);
  }, []);
  /**
   * Handle move section
   * @param {*} actionType
   * @param {*} headerIndex
   * @param {*} sectionData
   * @param {*} event
   */
  const handleMoveSection = (actionType, headerIndex, sectionData, event = null) => {
    if (typeof sectionData == 'object' && Array.isArray(questionnairesData)) {
      let currentQuestionnaireData = [...questionnairesData];
      const movingSectionData = { ...sectionData };
      if (actionType === 'UP') {
        if (movingSectionData?.questionnaireHeaderNextKey) {
          const movingQuestionnaireArraySection = currentQuestionnaireData.slice(
            movingSectionData.questionnaireHeaderKey,
            movingSectionData.questionnaireHeaderNextKey
          );

          const moveToHeaderSectionKey = headerIndex - 1;
          const moveBeforeQuestionnaireHeaderSectionKey =
            questionnaireSections?.[moveToHeaderSectionKey]?.questionnaireHeaderKey;
          const firstQuestionnairesArraySection =
            moveBeforeQuestionnaireHeaderSectionKey === 0
              ? []
              : currentQuestionnaireData.slice(0, moveBeforeQuestionnaireHeaderSectionKey);
          const switchingQuestionnaireSectionEndKey =
            questionnaireSections?.[moveToHeaderSectionKey]?.questionnaireHeaderNextKey;
          const switchingQuestionnaireArraySection = currentQuestionnaireData.slice(
            moveBeforeQuestionnaireHeaderSectionKey,
            switchingQuestionnaireSectionEndKey
          );
          const lastSectionArrayStartKey = movingSectionData?.questionnaireHeaderNextKey;

          const lastQuestionnaireArraySection =
            lastSectionArrayStartKey === questionnairesData?.length - 1
              ? []
              : currentQuestionnaireData.slice(lastSectionArrayStartKey);

          let newQuestionnaireArrayData = [
            ...firstQuestionnairesArraySection,
            ...movingQuestionnaireArraySection,
            ...switchingQuestionnaireArraySection,
            ...lastQuestionnaireArraySection,
          ];
          newQuestionnaireArrayData = newQuestionnaireArrayData.filter(
            (questionData) => !!questionData?.component
          );
          formikContext?.setFieldValue &&
            formikContext.setFieldValue('questionnaires', newQuestionnaireArrayData);
          if (questionnaireRef?.current) {
            questionnaireRef.current = { ...{} };
          }
        }
      } else if (actionType === 'DOWN') {
        if (movingSectionData?.questionnaireHeaderNextKey) {
          const movingQuestionnaireArraySection = currentQuestionnaireData.slice(
            movingSectionData.questionnaireHeaderKey,
            movingSectionData.questionnaireHeaderNextKey
          );

          const moveToHeaderSectionKey = headerIndex + 1;
          const moveAfterQuestionnaireHeaderSectionKey =
            questionnaireSections?.[moveToHeaderSectionKey]?.questionnaireHeaderKey;
          const firstQuestionnairesArraySection =
            movingSectionData.questionnaireHeaderKey === 0
              ? []
              : currentQuestionnaireData.slice(0, movingSectionData.questionnaireHeaderKey);
          const switchingQuestionnaireSectionEndKey =
            questionnaireSections?.[moveToHeaderSectionKey]?.questionnaireHeaderNextKey;
          const switchingQuestionnaireArraySection = currentQuestionnaireData.slice(
            moveAfterQuestionnaireHeaderSectionKey,
            switchingQuestionnaireSectionEndKey
          );
          const lastSectionArrayStartKey =
            questionnaireSections?.[moveToHeaderSectionKey]?.questionnaireHeaderNextKey;

          const lastQuestionnaireArraySection =
            lastSectionArrayStartKey === questionnairesData?.length - 1
              ? []
              : currentQuestionnaireData.slice(lastSectionArrayStartKey);

          let newQuestionnaireArrayData = [
            ...firstQuestionnairesArraySection,
            ...switchingQuestionnaireArraySection,
            ...movingQuestionnaireArraySection,
            ...lastQuestionnaireArraySection,
          ];
          newQuestionnaireArrayData = newQuestionnaireArrayData.filter(
            (questionData) => !!questionData?.component
          );
          formikContext?.setFieldValue &&
            formikContext.setFieldValue('questionnaires', newQuestionnaireArrayData);
          if (questionnaireRef?.current) {
            questionnaireRef.current = { ...{} };
          }
          if (questionnaireRef?.current) {
            questionnaireRef.current = { ...{} };
          }
        }
      }
    }
  };
  /**
   * Get Questionnaire Sections
   * todo
   */
  const handleGetQuestionnaireSections = useCallback(() => {
    let questionnaireCurrentSectionIndex = 0;
    let questionnaireSectionEndIndex = 0;

    let quesSectionData = [];
    if (Array.isArray(questionnairesData) && questionnaireRef) {
      questionnairesData.forEach((questionnaire, questionnaireIndex) => {
        const questionnaireSection = {
          sectionQuestions: [],
          sectionHeader: questionnaire?.headerTitle,
          personType: questionnaire?.personType,
          questionnaireHeaderKey: 0,
          questionnaireHeaderNextKey: 0,
          component: questionnaire?.component,
          headerType: questionnaire?.headerType,
        };
        if (questionnaire?.component === 'header' && questionnaire?.headerType === 'section') {
          questionnaireCurrentSectionIndex = questionnaireIndex;
          const nextFoundQuestionnaireSectionHeaderIndex = questionnairesData
            .slice(questionnaireCurrentSectionIndex + 1, questionnairesData?.length - 1)
            .findIndex(
              (questionnaireAfterSection) =>
                questionnaireAfterSection?.component === 'header' &&
                questionnaireAfterSection?.headerType === 'section'
            );
          if (nextFoundQuestionnaireSectionHeaderIndex > 0) {
            questionnaireSectionEndIndex =
              nextFoundQuestionnaireSectionHeaderIndex + questionnaireCurrentSectionIndex;
          } else if (
            questionnairesData?.[questionnaireCurrentSectionIndex + 1]?.component === 'header'
          ) {
            questionnaireSectionEndIndex = questionnaireCurrentSectionIndex;
          } else {
            questionnaireSectionEndIndex = questionnairesData?.length - 1;
          }
          questionnaireSection.questionnaireHeaderKey = questionnaireIndex;
          questionnaireSection.questionnaireHeaderNextKey = questionnaireSectionEndIndex + 1;
          const sectionStart = questionnaireCurrentSectionIndex;
          const sectionEnd = questionnaireSectionEndIndex;
          questionnaireSection.sectionQuestions = questionnairesData
            .slice(sectionStart + 1, sectionEnd + 1)
            .map((questionnaire, questionIndex) => ({
              ...questionnaire,
              questionnaireKey: sectionStart + 1 + questionIndex,
            }));
          questionnaireSection.sectionIndexKey = quesSectionData?.length || 0;
          quesSectionData = [...quesSectionData, questionnaireSection];
        }
      });
    }
    return quesSectionData;
  }, [questionnairesData, questionnaireRef]);
  /**
   * Effect on mount
   */
  useEffect(() => {
    const quesSectionData = handleGetQuestionnaireSections();
    setQuestionnaireSections(quesSectionData);
  }, [questionnairesData, handleGetQuestionnaireSections, questionnaireRef]);
  return (
    <Box px={2}>
      <CreateQuestionnaireOrSectionModal
        isOpened={questionnaireOrSectionAddModalData?.isOpened}
        questionnaireEditData={questionnaireOrSectionAddModalData?.modalData}
        actionDataIndex={questionnaireOrSectionAddModalData?.actionDataIndex}
        component={questionnaireOrSectionAddModalData?.component}
        handleDialogOpened={handleQuestionnaireOrSectionAddModalOpened}
        isTemplateBuilder
      />
      <RemoveTemplateQuestionSectionConfirmationModal
        isOpened={sectionRemoveModalData?.isOpened}
        isLoading={sectionRemoveModalData?.isLoading}
        actionDataIndex={sectionRemoveModalData?.actionDataIndex}
        handleDialogOpened={handleRemoveSectionModalOpened}
        isEditMode={sectionRemoveModalData?.isEditMode}
        questionnaireSections={questionnaireSections}
        removeSectionModalData={sectionRemoveModalData?.modalData}
      />
      <ConfirmationModal
        isConfirmationModal={confirmationModalData?.isConfirmationOpen}
        closeConfirmationAction={closeConfirmationAction}
        modalConfirmAction={handleConfirmConfirmationAction}
        confirmationModalHeader={confirmationModalData?.confirmationTitle}
        confirmationModalContent={confirmationModalData?.confirmationContent}
        img={confirmationModalData?.confirmationImage}
        loading={confirmationModalData.loading}
      />
      <RemoveTemplateQuestionConfirmationModal
        isOpened={removeQuestionConfirmationModalData?.isOpened}
        questionnaireData={null}
        actionDataIndex={removeQuestionConfirmationModalData?.actionDataIndex}
        handleDialogOpened={handleRemoveQuestionConfirmationOpened}
        handleOnRemoveQuestionSuccess={handleOnRemoveQuestionSuccess}
      />
      <Grid container spacing={gridSpacing} mb={2} alignItems="center" justifyContent="flex-end">
        <Fade in={clearFormData?.isFormCleared}>
          <Grid item xs="auto">
            <Tooltip title="Undo(Undo clear action)" arrow placement="top">
              <IconButton color="primary" onClick={handleUndoClearForm}>
                <UndoOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Fade>
        <Grid item xs="auto">
          <Tooltip title="Clear Questionnaire Form" arrow placement="top">
            <Button variant="outlined" className={cancelBtn} onClick={handleClearForm}>
              Clear Form
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <TransitionGroup>
        {Array.isArray(questionnaireSections) &&
          questionnaireSections.map((headerSection, headerIndex) => {
            const isSelectedSection =
              selectedItem &&
              selectedItem?.headerType === 'section' &&
              selectedItem?.questionnaireHeaderKey === headerSection?.questionnaireHeaderKey;
            return (
              <Collapse timeout={875} key={headerIndex + '-headerCollapse'}>
                <Box
                  variant="text"
                  onClick={(event) => {
                    // event?.stopPropagation && event.stopPropagation();
                    // event.preventDefault();
                    setSelectedItem(headerSection);
                  }}
                >
                  <Accordion
                    key={`questionnaire-${headerSection?.headerType}-${headerIndex}-key`}
                    id={`questionnaire-${headerSection?.headerType}-${headerIndex}-section`}
                    TransitionProps={{ unmountOnExit: true }}
                    sx={(theme) => ({
                      margin: headerIndex ? '1rem 0' : 0,
                      '&:before': {
                        display: 'none',
                      },
                      '&.Mui-expanded': {
                        margin: headerIndex ? '1rem 0 !important' : 0,
                      },
                      borderRadius: `${theme.shape.globalBorderRadius}px !important`,

                      WebkitTransition: 'border-radius 0.5s, box-shadow 0.75s',
                      boxShadow: isSelectedSection
                        ? `-3px 0px 0px 0px ${theme.palette.blue?.[350]}`
                        : undefined,
                    })}
                    disableGutters
                    defaultExpanded
                  >
                    <AccordionSummary
                      sx={(theme) => ({
                        backgroundColor: theme.palette.blue?.[150],
                        '&.Mui-expanded': {
                          borderRadius: `${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px 0 0`,
                        },
                        borderRadius: `${theme.shape.globalBorderRadius}px`,
                        WebkitTransition: 'border-radius 0.5s',
                      })}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Paper
                        sx={(theme) => ({
                          width: '100%',
                          backgroundColor: theme.palette.blue?.[150],
                        })}
                      >
                        <Grid container rowSpacing={gridSpacing} alignItems="center">
                          <Grid item xs={8}>
                            <Typography
                              variant="body2"
                              sx={(theme) => ({
                                fontWeight: theme.typography.fontWeightMedium,
                                fontSize: '1.125rem',
                              })}
                            >
                              {headerSection?.sectionHeader}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={(theme) => ({
                        border: '1px solid ' + theme.palette?.blue?.[150],
                        borderRadius: `0 0 ${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px`,
                        backgroundColor: theme.palette?.grey?.[100],
                      })}
                    >
                      <Box
                      // sx={{
                      //   transition: 'all 0.75s ease',
                      //   WebkitTransition: 'all 0.75s ease',
                      // }}
                      >
                        <TransitionGroup>
                          {Array.isArray(headerSection.sectionQuestions) &&
                            headerSection.sectionQuestions.map(
                              (questionnaire, questionnaireIndex) => (
                                <Collapse timeout={875} key={questionnaireIndex}>
                                  <QuestionnaireFieldBuilder
                                    handleAddNewQuestionnaire={handleAddNewQuestionnaire}
                                    handleRemoveQuestion={handleRemoveQuestionConfirmationOpened}
                                    data={questionnaire}
                                    dataIndex={questionnaire?.questionnaireKey}
                                    key={questionnaire?.questionnaireKey + '-builderField'}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    questionnaireRef={questionnaireRef}
                                    isTemplateBuilder
                                  />
                                </Collapse>
                              )
                            )}
                        </TransitionGroup>
                        <Tooltip title="Add a new question">
                          <Button
                            variant="text"
                            onClick={(event) =>
                              handleAddNewQuestionnaire(
                                event,
                                headerSection.sectionQuestions?.[
                                  headerSection.sectionQuestions?.length - 1
                                ]?.questionnaireKey || headerSection?.questionnaireHeaderKey
                              )
                            }
                            fullWidth
                            sx={(theme) => ({
                              border: '3px dashed ' + theme.palette.grey?.[325],
                              minHeight: '4rem',
                              fontSize: '1.35rem',
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            Add a new question
                          </Button>
                        </Tooltip>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Grow
                    style={{ transformOrigin: '1 1 0 0' }}
                    timeout={750}
                    in={isSelectedSection}
                    unmountOnExit
                  >
                    <Grid
                      container
                      spacing={gridSpacing}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: headerIndex && '0 !important' }}
                    >
                      <Grid item xs={5} md={3} sx={{ paddingTop: headerIndex && '0 !important' }}>
                        <Paper
                          sx={(theme) => ({
                            border: '1px solid ' + theme.palette.blue?.[350],
                            borderTop: 'none',
                            borderRadius: `0 0 ${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px !important`,

                            WebkitTransition: 'border-radius 0.5s, box-shadow 0.75s',
                            boxShadow: isSelectedSection
                              ? `-3px 0px 0px 0px ${theme.palette.blue?.[350]}`
                              : undefined,
                          })}
                        >
                          <Stack
                            direction="row"
                            rowGap={gridSpacing}
                            columnGap={gridSpacing}
                            justifyContent="space-evenly"
                            flexWrap={isSmallerScreens ? 'wrap' : 'nowrap'}
                          >
                            <Tooltip title="Add Section" arrow placement="top">
                              <IconButton
                                color="primary"
                                onClick={(event) =>
                                  handleAddNewQuestionnaireSection &&
                                  handleAddNewQuestionnaireSection(
                                    event,
                                    headerSection.questionnaireHeaderNextKey - 1
                                  )
                                }
                              >
                                <SvgIcon viewBox="0 0 24 24" component={SectionAddIcon} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove this section" arrow placement="top">
                              <IconButton
                                onClick={(event) =>
                                  handleRemoveSectionModalOpened &&
                                  handleRemoveSectionModalOpened(
                                    true,
                                    { modalData: headerSection },
                                    event
                                  )
                                }
                              >
                                <SvgIcon viewBox="0 0 24 24" component={QuestionRemoveIcon} />
                              </IconButton>
                            </Tooltip>

                            {!!questionnaireSections?.length && headerIndex !== 0 && (
                              <IconButton
                                color="primary"
                                onClick={(event) =>
                                  handleMoveSection('UP', headerIndex, headerSection, event)
                                }
                              >
                                <ArrowUpward />
                              </IconButton>
                            )}

                            {!!questionnaireSections?.length &&
                              headerIndex < +questionnaireSections?.length - 1 && (
                                <IconButton
                                  color="primary"
                                  onClick={(event) =>
                                    handleMoveSection('DOWN', headerIndex, headerSection, event)
                                  }
                                >
                                  <ArrowDownward />
                                </IconButton>
                              )}
                          </Stack>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grow>
                </Box>
              </Collapse>
            );
          })}
      </TransitionGroup>
      <Tooltip title="Add a new question header/section" arrow placeholder="bottom">
        <Button
          variant="text"
          fullWidth
          sx={(theme) => ({
            border: '3px dashed ' + theme.palette.grey?.[325],
            minHeight: '4rem',
            fontSize: '1.35rem',
            fontWeight: theme.typography.fontWeightMedium,
            marginTop: questionnairesData?.length && '1rem',
          })}
          onClick={handleAddNewQuestionnaireSection}
        >
          Add a new section
        </Button>
      </Tooltip>
    </Box>
  );
};

export default memo(TemplateQuestionnaireBuilder);
