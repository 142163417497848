import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Request filter questionnaire tags
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestFilterQuestionnaireTemplateTags =
  (
    requestBody,
    handleFilterQuestionnaireTemplateSuccessCallback = null,
    handleFilterQuestionnaireTemplateFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllQuestionnaireTemplateTagsRequest' });

    const requestData = {
      url: API_URL.questionnaireTemplate.tag.POST_FILTER_QUESTIONNAIRE_TEMPLATES_TAGS,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllQuestionnaireTemplateTagsRequestSuccess', payload: response.data });
      handleFilterQuestionnaireTemplateSuccessCallback &&
        handleFilterQuestionnaireTemplateSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllQuestionnaireTemplateTagsRequestFailed', payload: error });
      handleFilterQuestionnaireTemplateFailedCallback &&
        handleFilterQuestionnaireTemplateFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request save questionnaire template tag
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestPOSTSaveQuestionnaireTemplateTag =
  (
    requestBody,
    handleSaveQuestionnaireTemplateTagSuccessCallback = null,
    handleSaveQuestionnaireTemplateTagFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveQuestionnaireTemplateTagRequest' });

    const requestData = {
      url: API_URL.questionnaireTemplate.tag.POST_SAVE_QUESTIONNAIRE_TEMPLATE_TAGS,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveQuestionnaireTemplateTagRequestSuccess', payload: response.data });
      Store.dispatch(handleNotification(`Tag is Saved Successfully!`, 'success'));
      handleSaveQuestionnaireTemplateTagSuccessCallback &&
        handleSaveQuestionnaireTemplateTagSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveQuestionnaireTemplateTagRequestFailed', payload: error });
      let message = 'Tag is failed to save!';
      if (error?.response?.status === 500) {
        message = error?.response?.data?.message || 'Please Check Details !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveQuestionnaireTemplateTagFailedCallback &&
        handleSaveQuestionnaireTemplateTagFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle request delete questionnaire template tag
 * @param {*} requestSuccessCallback
 * @param {*} requestFailedCallback
 * @returns
 */
export const requestDeleteQuestionnaireTag =
  (requestDetails, requestSuccessCallback = null, requestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteQuestionnaireTemplateRequest' });

    const requestData = {
      url: API_URL.questionnaireTemplate.tag.DELETE_QUESTIONNAIRE_TEMPLATES_TAGS,
      method: 'DELETE',
      data: requestDetails,
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteQuestionnaireTemplateRequestSuccess', payload: response.data });
      requestSuccessCallback && requestSuccessCallback(response?.data);
      Store.dispatch(handleNotification(`Tag is successfully deleted!`, 'success'));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteQuestionnaireTemplateRequestFailed', payload: error });
      requestFailedCallback && requestFailedCallback(error);
      Store.dispatch(handleNotification(`Tag is failed to delete!`, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle request update questionnaire template tag
 * @param {*} requestSuccessCallback
 * @param {*} requestFailedCallback
 * @returns
 */
export const requestUpdateQuestionnaireTag =
  (requestDetails, requestSuccessCallback = null, requestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'updateQuestionnaireTemplateRequest' });

    const requestData = {
      url: API_URL.questionnaireTemplate.tag.PATCH_UPDATE_QUESTIONNAIRE_TEMPLATE_TAGS,
      method: 'PATCH',
      data: requestDetails,
    };
    const successCallback = (response) => {
      dispatch({ type: 'updateQuestionnaireTemplateRequestSuccess', payload: response.data });
      requestSuccessCallback && requestSuccessCallback(response?.data);
      Store.dispatch(handleNotification(`Tag is successfully updated!`, 'success'));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'updateQuestionnaireTemplateRequestFailed', payload: error });
      requestFailedCallback && requestFailedCallback(error);
      Store.dispatch(handleNotification(`Tag is failed to update!`, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };
