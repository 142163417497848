import { createReducer } from '@reduxjs/toolkit';

const investorProfileInitialState = {
  //Create New Investor Profile
  saveInvestorProfileLoading: false,
  saveInvestorProfileSuccessData: {},
  saveInvestorProfileFailedData: {},

  //get investor profiles
  getInvestorProfilesLoading: false,
  getInvestorProfilesSuccessData: {},
  getInvestorProfilesFailedData: {},

  //update investor profile
  updateInvestorProfileLoading: false,
  updateInvestorProfileSuccessData: {},
  updateInvestorProfileFailedData: {},

  //delete investor profile
  deleteInvestorProfileLoading: false,
  deleteInvestorProfileSuccessData: {},
  deleteInvestorProfileFailedData: {},
};

//investor profile reducer
export const investorProfileSettingsReducer = createReducer(investorProfileInitialState, {
  // save investor profile
  postSaveInvestorProfileRequest: (state) => {
    state.saveInvestorProfileLoading = true;
    state.saveInvestorProfileSuccessData = {};
    state.saveInvestorProfileFailedData = {};
  },
  postSaveInvestorProfileRequestSuccess: (state, action) => {
    state.saveInvestorProfileLoading = false;
    state.saveInvestorProfileSuccessData = action?.payload;
    state.saveInvestorProfileFailedData = {};
  },
  postSaveInvestorProfileRequestFailed: (state, action) => {
    state.saveInvestorProfileLoading = false;
    state.saveInvestorProfileSuccessData = {};
    state.saveInvestorProfileFailedData = action?.payload;
  },

  //get investor profiles
  getInvestorProfileRequest: (state) => {
    state.getInvestorProfilesLoading = true;
    state.getInvestorProfilesSuccessData = {};
    state.getInvestorProfilesFailedData = {};
  },
  getInvestorProfileRequestSuccess: (state, action) => {
    state.getInvestorProfilesLoading = false;
    state.getInvestorProfilesSuccessData = action.payload;
    state.getInvestorProfilesFailedData = {};
  },
  getInvestorProfileRequestFailed: (state, action) => {
    state.getInvestorProfilesLoading = false;
    state.getInvestorProfilesSuccessData = {};
    state.getInvestorProfilesFailedData = action.payload;
  },

  //update investor profile
  updateInvestorProfileRequest: (state) => {
    state.updateInvestorProfileLoading = true;
    state.updateInvestorProfileSuccessData = {};
    state.updateInvestorProfileFailedData = {};
  },
  updateInvestorProfileRequestSuccess: (state, action) => {
    state.updateInvestorProfileLoading = false;
    state.updateInvestorProfileSuccessData = action.payload;
    state.updateInvestorProfileFailedData = {};
  },
  updateInvestorProfileRequestFailed: (state, action) => {
    state.updateInvestorProfileLoading = false;
    state.updateInvestorProfileSuccessData = {};
    state.updateInvestorProfileFailedData = action.payload;
  },

  //delete investor profile
  deleteInvestorProfileRequest: (state) => {
    state.deleteInvestorProfileLoading = true;
    state.deleteInvestorProfileSuccessData = {};
    state.deleteInvestorProfileFailedData = {};
  },
  deleteInvestorProfileRequestSuccess: (state, action) => {
    state.deleteInvestorProfileLoading = false;
    state.deleteInvestorProfileSuccessData = action.payload;
    state.deleteInvestorProfileFailedData = {};
  },
  deleteInvestorProfileRequestFailed: (state, action) => {
    state.deleteInvestorProfileLoading = false;
    state.deleteInvestorProfileSuccessData = {};
    state.deleteInvestorProfileFailedData = action.payload;
  },
});
