import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';

/**
 * Request Composites
 * @returns {async function}
 */
export const requestCompositeData = () => async (dispatch, getState) => {
  dispatch({ type: 'getCompositeRequestRequest' });

  const requestData = {
    url: API_URL.composites.GET_ALL_ENUMS,
    method: 'GET',
  };
  const successCallback = (response) => {
    dispatch({ type: 'getCompositeRequestSuccess', payload: response.data });
  };
  const errorCallback = (error) => {
    dispatch({ type: 'getCompositeRequestFailed', payload: error });
  };
  http_request(requestData, successCallback, errorCallback);
};
