import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { getAgeFromDOB } from 'utils/date-of-birth';

import * as warningAnimationData from 'assets/animation/warning-circle.json';
/**
 * Unsaved Changes identifier
 * @param {*} props
 * @returns null
 */
export const UnsavedFormChangesFormik = (props) => {
  const { setScreenData, showScreen, tabSection } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  /**
   * Effect on Formik form dirty
   */
  useEffect(() => {
    if (tabSection && showScreen) {
      setScreenData &&
        setScreenData((prev) => ({
          ...prev,
          [showScreen]: {
            ...prev[showScreen],
            [tabSection]: {
              ...prev[showScreen][tabSection],
              hasUnsavedChanges: formikContext?.dirty,
            },
          },
        }));
    } else if (!tabSection && showScreen) {
      setScreenData &&
        setScreenData((prev) => ({
          ...prev,
          [showScreen]: {
            ...prev[showScreen],
            hasUnsavedChanges: formikContext?.dirty,
          },
        }));
    }
  }, [formikContext?.dirty]);

  return null;
};
/**
 * Dynamic Age generator from Date of Birth
 * @param {*} props
 * @returns null
 */
export const DynamicAgeGenerator = (props) => {
  const { type } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  //date of birth value
  const dateOfBirthValue = formikContext?.values?.dateOfBirth;

  /**
   * Dynamic Age based on the Date of Birth value
   */
  useEffect(() => {
    if (type === 'PARTNER') {
      let partnerDOB = null;
      try {
        partnerDOB =
          formikContext?.values?.dateOfBirth && new Date(formikContext.values.dateOfBirth);
      } catch (err) {}
      if (partnerDOB) {
        let partnerAge = getAgeFromDOB(partnerDOB, true);
        if (partnerAge) {
          if (
            (formikContext?.values?.age && partnerAge !== formikContext?.values?.age) ||
            (!formikContext?.values?.age && partnerAge)
          )
            formikContext?.setFieldValue && formikContext.setFieldValue('age', partnerAge);
          formikContext?.setFieldTouched && formikContext.setFieldTouched('age', true);
        }
      }
    } else {
      let clientDOB = null;
      try {
        clientDOB =
          formikContext?.values?.dateOfBirth && new Date(formikContext.values.dateOfBirth);
      } catch (err) {}
      if (clientDOB) {
        let clientAge = getAgeFromDOB(clientDOB, true);
        if (clientAge) {
          if (
            (formikContext?.values?.age && clientAge !== formikContext?.values?.age) ||
            (!formikContext?.values?.age && clientAge)
          )
            formikContext?.setFieldValue && formikContext.setFieldValue('age', clientAge);
          formikContext?.setFieldTouched && formikContext.setFieldTouched('age', !!clientAge);
        }
      }
    }
  }, [dateOfBirthValue]);

  return null;
};
/**
 * Dynamic Partner tab visibility
 * @param {*} props
 * @returns null
 */
export const DynamicPartnerTab = (props) => {
  const {
    handleChangeIsPartnerPresent,
    clientDetail,
    setPersonalDetailsTabValue,
    setConfirmationData,
  } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  // CLIENT STORE
  const clientReducer = useSelector((state) => state?.clientReducer);
  const isClientDetailRequestLoading = clientReducer?.isClientDetailRequestLoading;

  /**
   * Get whether page has unsaved changes
   * @param {*} initialValues
   * @param {*} formValues
   * @returns {boolean} hasUnsavedChanges
   */
  const handleGetClientPageHasUnsavedChanges = (initialValues, formValues) => {
    let hasUnsavedChanges = false;
    if (typeof initialValues === 'object' && typeof formValues === 'object') {
      let initialData = { ...initialValues };
      if (typeof initialData?.hasPartner !== 'undefined') {
        delete initialData.hasPartner;
      }
      if (typeof initialData?.partner !== 'undefined') {
        delete initialData.partner;
      }
      let currentData = { ...formValues };
      if (typeof currentData?.hasPartner !== 'undefined') {
        delete currentData.hasPartner;
      }
      if (typeof currentData?.partner !== 'undefined') {
        delete currentData.partner;
      }

      Object.keys(initialData).forEach((singleInitialKey) => {
        if (initialData?.[singleInitialKey] !== currentData?.[singleInitialKey]) {
          hasUnsavedChanges = true;
          return hasUnsavedChanges;
        }
      });
    }
    return hasUnsavedChanges;
  };
  /**
   * Effect on has partner change
   */
  useEffect(() => {
    if (
      (clientDetail?._id && clientDetail?.partner?._id) ||
      (!clientDetail?.hasPartner && clientDetail?._id)
    ) {
      let isPartnerTabError = false;
      let isPartnerAvailabilityChanged =
        formikContext?.values?.firstName &&
        !!formikContext?.values?.hasPartner !== !!clientDetail?.hasPartner;
      const clientPageHasUnsavedChanges = handleGetClientPageHasUnsavedChanges(
        formikContext?.initialValues,
        formikContext?.values
      );
      if (
        clientPageHasUnsavedChanges &&
        formikContext?.values?.hasPartner &&
        isPartnerAvailabilityChanged &&
        !isClientDetailRequestLoading
      ) {
        setConfirmationData &&
          setConfirmationData((prev) => ({
            ...prev,
            isConfirmationModal: true,
            img: warningAnimationData,
            confirmationModalHeader: 'You have Unsaved Changes!',
            confirmationModalContent:
              "Please save the current changes using the 'Save' button. Alternatively, would you like to discard them and navigate to this page?",
            actionType: 'NAVIGATION_CONFIRMATION',
            actionData: {
              showScreen: null,
              tabValue: 1,
            },
            loading: false,
          }));
      } else if (formikContext?.values?.hasPartner && isPartnerAvailabilityChanged) {
        //if partner detail not saved show a tab validations
        isPartnerTabError = true;
        setPersonalDetailsTabValue && setPersonalDetailsTabValue(1);
      }
      handleChangeIsPartnerPresent &&
        handleChangeIsPartnerPresent(!!formikContext?.values?.hasPartner, isPartnerTabError);
    }
  }, [formikContext?.values?.hasPartner, clientDetail]);

  /**
   * Dynamic Checkbox disable AND Tab for Partner Present
   */
  useEffect(() => {
    if (
      !(
        formikContext?.values?.maritalStatus === 'married' ||
        formikContext?.values?.maritalStatus === 'de facto'
      )
    ) {
      if (formikContext?.values?.hasPartner) formikContext.setFieldValue('hasPartner', false);
    }
  }, [formikContext?.values?.maritalStatus]);

  return null;
};

/**
 * Dynamic Partner tab error validation
 * @param {*} props
 * @returns null
 */
export const DynamicPartnerTabErrorValidator = (props) => {
  const { isPartnerPresent, handleChangeIsPartnerPresent, clientDetail } = props;
  // formik context
  const formikContext = useFormikContext();
  const partnerFirstName = formikContext?.values?.firstName;
  const partnerSurName = formikContext?.values?.surName;
  /**
   * Effect on client detail change
   */
  useEffect(() => {
    let isPartnerTabError = false;
    let isPartnerDetailSaved = !!(partnerFirstName && partnerSurName);
    if (!isPartnerDetailSaved) {
      //if partner detail not saved show a tab validations
      isPartnerTabError = true;
    }
    handleChangeIsPartnerPresent &&
      handleChangeIsPartnerPresent(isPartnerPresent, isPartnerTabError);
  }, [handleChangeIsPartnerPresent, clientDetail, partnerFirstName, partnerSurName]);
  return null;
};

/**
 * Family members dynamic value setter on Edit
 * @param {*} props
 * @returns null
 */
export const FamilyMembersEditModeFormik = (props) => {
  const { editModeData } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  /**
   * Effect on Edit Family Member
   */
  useEffect(() => {
    if (editModeData?.isEditMode && editModeData?.data) {
      const editData = editModeData?.data;

      if (typeof editData === 'object' && editData?._id) {
        const { name, dateOfBirth, relationshipToClient, gender, isDependent, ageOfIndependence } =
          editData;
        const updateValues = {
          name,
          dateOfBirth,
          relationshipToClient,
          gender,
          isDependent,
          ageOfIndependence,
          isOptionDataLoading: formikContext.values?.isOptionDataLoading,
        };
        formikContext.setValues(updateValues, true);
      }
    } else {
      const resetValues = {
        name: '',
        dateOfBirth: null,
        relationshipToClient: '',
        gender: '',
        isDependent: false,
        ageOfIndependence: '',
        isOptionDataLoading: formikContext.values?.isOptionDataLoading,
      };
      formikContext.setValues(resetValues, true);
    }
  }, [editModeData?.isEditMode]);

  return null;
};

export const DynamicOccupationFieldGeneratorFormik = (props) => {
  const { occupationOptions } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  const [isInitialMountCompleted, setIsInitialMountCompleted] = useState(false);
  /**
   * Effect on occupation
   */
  useEffect(() => {
    if (isInitialMountCompleted) {
      if (
        formikContext?.values?.occupationStatus === 'Unspecified' ||
        formikContext?.values?.occupationStatus === 'Working'
      ) {
        formikContext.setFieldValue && formikContext.setFieldValue('occupation', '');
        formikContext.setFieldValue && formikContext.setFieldValue('occupationStartedDate', null);
        formikContext.setFieldValue && formikContext.setFieldValue('employerName', '');
        formikContext.setFieldValue && formikContext.setFieldValue('employmentStatus', '');
        formikContext.setFieldValue && formikContext.setFieldValue('employmentType', '');
        formikContext.setFieldValue && formikContext.setFieldValue('hoursWorked', '');
        formikContext.setFieldValue && formikContext.setFieldValue('yearsInCurrentOccupation', '');
        formikContext.setFieldValue && formikContext.setFieldValue('desiredRetirementAge', '');

        formikContext.setFieldTouched && formikContext.setFieldTouched('occupation', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('occupationStartedDate', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employerName', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employmentStatus', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employmentType', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('hoursWorked', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('yearsInCurrentOccupation', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('desiredRetirementAge', true);
      } else if (formikContext?.values?.occupationStatus === 'Home Duties') {
        if (occupationOptions && Array.isArray(occupationOptions)) {
          let homeDutiesOption = occupationOptions.find(
            (optionData) => optionData?.value === 'Homemaker'
          );
          if (homeDutiesOption) {
            formikContext.setFieldValue &&
              formikContext.setFieldValue('occupation', homeDutiesOption?.value);
          } else {
            formikContext.setFieldValue && formikContext.setFieldValue('occupation', '');
          }
        } else {
          formikContext.setFieldValue && formikContext.setFieldValue('occupation', '');
        }
        formikContext.setFieldValue && formikContext.setFieldValue('occupationStartedDate', null);
        formikContext.setFieldValue && formikContext.setFieldValue('employerName', '');
        formikContext.setFieldValue && formikContext.setFieldValue('employmentStatus', '');
        formikContext.setFieldValue && formikContext.setFieldValue('employmentType', '');
        formikContext.setFieldValue && formikContext.setFieldValue('hoursWorked', '');
        formikContext.setFieldValue && formikContext.setFieldValue('yearsInCurrentOccupation', '');
        formikContext.setFieldValue && formikContext.setFieldValue('desiredRetirementAge', '');

        formikContext.setFieldTouched && formikContext.setFieldTouched('occupation', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('occupationStartedDate', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employerName', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employmentStatus', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employmentType', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('hoursWorked', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('yearsInCurrentOccupation', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('desiredRetirementAge', true);
      } else {
        if (occupationOptions && Array.isArray(occupationOptions)) {
          let retiredOption = occupationOptions.find(
            (optionData) => optionData?.value === 'Retired'
          );
          if (retiredOption && formikContext?.values?.occupationStatus === 'Retired') {
            formikContext.setFieldValue &&
              formikContext.setFieldValue('occupation', retiredOption?.value);
          } else {
            formikContext.setFieldValue && formikContext.setFieldValue('occupation', '');
          }
        } else {
          formikContext.setFieldValue && formikContext.setFieldValue('occupation', '');
        }
        formikContext.setFieldValue && formikContext.setFieldValue('occupationStartedDate', null);
        formikContext.setFieldValue && formikContext.setFieldValue('employerName', '');
        formikContext.setFieldValue && formikContext.setFieldValue('employmentStatus', '');
        formikContext.setFieldValue && formikContext.setFieldValue('employmentType', '');
        formikContext.setFieldValue && formikContext.setFieldValue('hoursWorked', '');
        formikContext.setFieldValue && formikContext.setFieldValue('yearsInCurrentOccupation', '');
        formikContext.setFieldValue && formikContext.setFieldValue('desiredRetirementAge', '');

        formikContext.setFieldTouched && formikContext.setFieldTouched('occupation', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('occupationStartedDate', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employerName', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employmentStatus', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('employmentType', true);
        formikContext.setFieldTouched && formikContext.setFieldTouched('hoursWorked', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('yearsInCurrentOccupation', true);
        formikContext.setFieldTouched &&
          formikContext.setFieldTouched('desiredRetirementAge', true);
      }
    }
  }, [formikContext?.values?.occupationStatus, occupationOptions]);
  /**
   * Initial mount
   */
  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsInitialMountCompleted(true);
    }, 650);

    return () => clearTimeout(timerId);
  }, []);
  return null;
};

export const DynamicHoursWorkedGeneratorFormik = (props) => {
  const { type } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();

  const employmentType = formikContext?.values?.employmentType;
  /**
   * Effect on employment type
   */
  useEffect(() => {
    if (employmentType === 'Full-Time' && (type === 'CLIENT' || type === 'PARTNER')) {
      formikContext?.setFieldValue && formikContext.setFieldValue('hoursWorked', '');
      if (employmentType) {
        formikContext?.setFieldTouched && formikContext.setFieldTouched('hoursWorked', true, true);
      }
    }
  }, [employmentType]);
  return null;
};
