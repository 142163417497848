// assets
import { ReactComponent as IconDashboard } from 'assets/images/icons/dashboard.svg';
import { ReactComponent as IconClient } from 'assets/images/icons/client.svg';
import { ReactComponent as IconEmail } from 'assets/images/icons/email.svg';
import { ReactComponent as IconLead } from 'assets/images/icons/lead.svg';
import { ReactComponent as IconMember } from 'assets/images/icons/member.svg';
import { ReactComponent as IconTask } from 'assets/images/icons/task.svg';
import { ReactComponent as IconFile } from 'assets/images/icons/file.svg';
import { ReactComponent as IconVideo } from 'assets/images/icons/video.svg';
import { ReactComponent as IconModeling } from 'assets/images/icons/modeling.svg';
import { ReactComponent as IconSettings } from 'assets/images/icons/settings.svg';
import { ReactComponent as IconTemplates } from 'assets/images/icons/templates.svg';
import { ReactComponent as IconInsights } from 'assets/images/icons/insights.svg';
import { ReactComponent as IconMessages } from 'assets/images/icons/message-svgrepo-com.svg';
import { ReactComponent as IconVideoCall } from 'assets/images/icons/video-call.svg';
import { ReactComponent as IconPayments } from 'assets/images/icons/payments-outline.svg';

// constant
const icons = {
  IconDashboard,
  IconClient,
  IconEmail,
  IconLead,
  IconMember,
  IconTask,
  IconFile,
  IconVideo,
  IconModeling,
  IconSettings,
  IconTemplates,
  IconInsights,
  IconMessages,
  IconVideoCall,
  IconPayments,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: null,
  type: 'group',
  children: [
    {
      id: 'client-tab',
      title: 'Clients',
      type: 'item',
      url: '/main/clientList/individual',
      icon: icons.IconClient,
      breadcrumbs: false,
    },
    {
      id: 'lead-tab',
      title: 'Leads',
      type: 'item',
      url: '/main/lead',
      icon: icons.IconLead,
      breadcrumbs: false,
    },
    {
      id: 'team-member-tab',
      title: 'Team members',
      type: 'item',
      url: '/main/teamMembersList',
      icon: icons.IconMember,
      breadcrumbs: false,
    },
    {
      id: 'task-tab',
      title: 'Tasks',
      type: 'item',
      url: '/main/tasks',
      icon: icons.IconTask,
      breadcrumbs: false,
    },
    {
      id: 'email-tab',
      title: 'Email',
      type: 'item',
      url: '/main/email',
      icon: icons.IconEmail,
      breadcrumbs: false,
    },
    // {
    //   id: 'messages-tab',
    //   title: 'Messages',
    //   type: 'item',
    //   url: '/main/messages',
    //   icon: icons.IconMessages,
    //   breadcrumbs: false,
    // },
    // {
    //   id: 'video-call-tab',
    //   title: 'Video Call',
    //   type: 'item',
    //   url: '/main/videoCall',
    //   icon: icons.IconVideoCall,
    //   breadcrumbs: false,
    // },
    {
      id: 'files-tab',
      title: 'Files',
      type: 'item',
      url: '/main/files',
      icon: icons.IconFile,
      breadcrumbs: false,
    },
    // {
    //   id: 'video-tab',
    //   title: 'Video',
    //   type: 'item',
    //   url: '/main/videos',
    //   icon: icons.IconVideo,
    //   breadcrumbs: false,
    // },
    // {
    //   id: 'insight-tab',
    //   title: 'Insights',
    //   type: 'item',
    //   url: '/main/insight',
    //   icon: icons.IconInsights,
    //   breadcrumbs: false,
    // },
    {
      id: 'templates-tab',
      title: 'Templates',
      type: 'item',
      url: '/main/templates',
      icon: icons.IconTemplates,
      breadcrumbs: false,
    },
    {
      id: 'modelling-tab',
      title: 'Modelling',
      type: 'item',
      url: '/main/modelling',
      icon: icons.IconModeling,
      breadcrumbs: false,
    },
    {
      id: 'settings-tab',
      title: 'Settings',
      type: 'item',
      url: '/main/settings',
      icon: icons.IconSettings,
      breadcrumbs: false,
    },

    {
      id: 'payments-tab',
      title: 'Payments',
      type: 'item',
      url: '/main/payments',
      icon: icons.IconPayments,
      breadcrumbs: false,
    },
    {
      id: 'calculators-tab',
      title: 'Calculators',
      type: 'item',
      url: '/main/calculators',
      icon: icons.IconTemplates,
      breadcrumbs: false,
    },
  ],
};

export default utilities;
