import React from 'react';
import { Field } from 'formik';
import { MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Select } from 'formik-mui';

const FormikSelectNew = (props) => {
  const { label, name, options, disabled, required, placeholder, value, selectLabel, ...rest } =
    props;

  return (
    <FormControl sx={{ width: '100%', marginTop: 1 }}>
      {/* <InputLabel id="demo-simple-select-label">{label}</InputLabel> */}
      <Field
        component={Select}
        id={name}
        name={name}
        label={label}
        disabled={disabled}
        required={required}
        {...rest}
      >
        {options &&
          options.map((item, index) => (
            <MenuItem key={index} value={item[value]} disabled={!!item?.isDisabled}>
              {item[selectLabel]}
            </MenuItem>
          ))}
      </Field>
    </FormControl>
  );
};

export default FormikSelectNew;
