import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import http_request_without_token from 'utils/http-request-without-token';
import { requestCompositeData } from './compositesActions';
import { handleNotification } from './handleNotificationAction';

/**
 * Sign Up Action Request
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestSignUp =
  (requestBody, handleSignUpSuccessCallback, handleSignUpFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSignUpRequest' });

    const requestData = {
      url: API_URL.auth.authentication.POST_SIGN_UP,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSignUpSuccess', payload: response.data });
      Store.dispatch(
        handleNotification(
          `Sign-up successful! Finish the sign-up process by clicking the confirmation link in the email we've sent you. Once verified, you can start using your account.`,
          'success'
        )
      );
      handleSignUpSuccessCallback && handleSignUpSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSignUpFailed', payload: error });

      let message = 'Something went wrong. Try again';
      if (error?.response?.data?.name) {
        message = error?.response?.data?.name;
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleSignUpFailedCallback && handleSignUpFailedCallback();
    };
    http_request_without_token(requestData, successCallback, errorCallback);
  };
/**
 * Sign In Request Action
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestSignIn =
  (requestBody, handleNavigationCallback, handleLoginFailCallback, isReAuthentication = false) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postLoginRequest' });

    const requestData = {
      url: API_URL.auth.authentication.POST_SIGN_IN,
      method: 'POST',
      data: requestBody,
      timeout: 10000,
    };
    const successCallback = (response) => {
      if (response.data?.token) {
        dispatch({ type: 'postLoginSuccess', payload: response?.data });

        Store.dispatch(handleNotification(`Signed in Successfully !`, 'success'));
        handleNavigationCallback('/main/newDashboard');

        //get composites
        //* ENUMS
        dispatch(requestCompositeData());
      } else {
        dispatch({
          type: 'postLoginFailed',
          payload: { ...(response || {}), message: 'Authentication Failed !' },
        });
        handleNavigationCallback('/');

        //* Reset Composites
        dispatch({ type: 'resetCompositeData' });
      }
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postLoginFailed', payload: error });
      let message = error.response?.data?.name || 'Sign In is Failed. Please check your Details !';

      Store.dispatch(handleNotification(message, 'error'));
      handleLoginFailCallback && handleLoginFailCallback();

      //* reset composites
      dispatch({ type: 'resetCompositeData' });
    };
    http_request_without_token(requestData, successCallback, errorCallback, { noToken: true });
  };
/**
 * Handle Sign Out
 */
export const handleSignOut = (handleNavigationCallback) => async (dispatch, getState) => {
  handleNavigationCallback && handleNavigationCallback('/');
  dispatch({ type: 'handleUserSignOut' });

  const requestData = {
    url: API_URL.auth.signOut.GET_SIGN_OUT,
    method: 'GET',
  };

  await http_request(requestData);
  //* reset redux store
  dispatch({ type: 'DESTROY_SESSION' });
};

/**
 * Open Re-Authentication
 * @param {*} reAuthData
 * @returns
 */
export const openReAuthenticateModal =
  (reAuthData = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: 'openReAuthenticateModal', payload: reAuthData });
  };
/**
 * Close Re-Authentication
 * @returns
 */
export const closeReAuthenticateModal = () => async (dispatch, getState) => {
  dispatch({ type: 'closeReAuthenticateModal' });
};

/**
 * Request send password reset mail
 * @param {*} requestDetails
 * @param {*} handleSendMailRequestSuccessCallback
 * @param {*} handleSendMailRequestFailedCallback
 * @returns
 */
export const requestForgotPasswordSendMail =
  (
    requestDetails,
    handleSendMailRequestSuccessCallback = null,
    handleSendMailRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'forgotPasswordRequest' });

    const { requestBody } = requestDetails;

    const requestData = {
      url: API_URL.auth.authentication.POST_SEND_PASSWORD_RESET_LINK,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'forgotPasswordRequestSuccess', payload: response.data });
      Store.dispatch(
        handleNotification(
          `Password Reset Details has been Sent to Your Mail Successfully !`,
          'success'
        )
      );
      handleSendMailRequestSuccessCallback?.(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'forgotPasswordRequestFailed', payload: error });
      let message = 'Password Reset Detail Mail Send Failed !';
      if (error?.response?.data?.name) {
        message = error?.response?.data?.name || 'Please Check Details and Try Again !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleSendMailRequestFailedCallback && handleSendMailRequestFailedCallback(error);
    };
    http_request_without_token(requestData, successCallback, errorCallback);
  };

/**
 * Handle request reset user password
 * @param {*} requestDetails
 * @param {*} handleResetUserPasswordRequestSuccessCallback
 * @param {*} handleResetUserPasswordRequestFailedCallback
 * @returns
 */
export const requestResetUserPassword =
  (
    requestDetails,
    handleResetUserPasswordRequestSuccessCallback = null,
    handleResetUserPasswordRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'resetPasswordRequest' });

    const { requestBody, requestToken, userId } = requestDetails;
    const requestData = {
      url: API_URL.auth.authentication.POST_RESET_USER_PASSWORD.replace('{userId}', userId).replace(
        '{requestToken}',
        requestToken
      ),
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'resetPasswordRequestSuccess', payload: response.data });
      Store.dispatch(handleNotification(`Password Reset is Successful !`, 'success'));
      handleResetUserPasswordRequestSuccessCallback &&
        handleResetUserPasswordRequestSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'resetPasswordRequestFailed', payload: error });
      let message = 'Password Reset is Failed !';
      if (error?.response?.data?.name) {
        message = error?.response?.data?.name || 'Please Check Details and Try Again !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleResetUserPasswordRequestFailedCallback &&
        handleResetUserPasswordRequestFailedCallback(error);
    };
    http_request_without_token(requestData, successCallback, errorCallback);
  };

/**
 * Handle verify user email address
 * @param {*} requestDetails
 * @param {*} handleVerifyEmailCallback
 * @param {*} handleResetUserPasswordRequestFailedCallback
 * @returns
 */
export const requestVerifyUserEmail =
  (requestDetails, handleVerifyEmailCallback = null, handleVerifyEmailFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'verifyUserEmailRequest' });

    const { requestBody, verifyToken, userId } = requestDetails;
    const requestData = {
      url: API_URL.auth.authentication.POST_VERIFY_USER_EMAIL.replace('{userId}', userId).replace(
        '{requestToken}',
        verifyToken
      ),
      method: 'GET',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'verifyUserEmailRequestSuccess', payload: response.data });
      Store.dispatch(handleNotification(`Email Verification is Successful !`, 'success'));
      handleVerifyEmailCallback && handleVerifyEmailCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'verifyUserEmailRequestFailed', payload: error });
      let message = error?.response?.data?.name || 'Email Verification is Failed !';

      Store.dispatch(handleNotification(message, 'error'));
      handleVerifyEmailFailedCallback && handleVerifyEmailFailedCallback(error);
    };
    http_request_without_token(requestData, successCallback, errorCallback);
  };
