import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

const DynamicResetRequiredOnMultiAnswer = () => {
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();
  /**
   * Effect on field type
   */
  useEffect(() => {
    if (formikContext?.values?.fieldType === 'multipleFormat') {
      formikContext.setFieldValue('isRequired', false);
      formikContext.setFieldTouched('isRequired', true);
    }
  }, [formikContext?.values?.fieldType]);
  return null;
};

export default DynamicResetRequiredOnMultiAnswer;
