import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //File Notes Save
  isFileNotesSaveRequestLoading: false,
  fileNotesSaveRequestSuccessData: {},
  fileNotesSaveRequestFailedData: {},

  //get all file Notes
  isGetAllFileNotesRequestLoading: false,
  fileNotesGetAllRequestSuccessData: {},
  fileNotesGetAllRequestFailedData: {},

  //Update a file note
  isUpdateFileNoteRequestLoading: false,
  fileNoteUpdateRequestSuccessData: {},
  fileNoteUpdateRequestFailedData: {},

  //Delete a file note
  isDeleteFileNoteRequestLoading: false,
  fileNoteDeleteRequestSuccessData: {},
  fileNoteDeleteRequestFailedData: {},
};

/**
 * Client's File Notes reducer
 */
export const fileNotesReducer = createReducer(initialState, {
  // fileNotes posting request reducer
  postFileNotesRequest: (state) => {
    state.isFileNotesSaveRequestLoading = true;
    state.fileNotesSaveRequestSuccessData = {};
    state.fileNotesSaveRequestFailedData = {};
  },
  postFileNotesRequestSuccess: (state, action) => {
    state.isFileNotesSaveRequestLoading = false;
    state.fileNotesSaveRequestSuccessData = action.payload;
    state.fileNotesSaveRequestFailedData = {};
  },
  postFileNotesRequestFailed: (state, action) => {
    state.isFileNotesSaveRequestLoading = false;
    state.fileNotesSaveRequestSuccessData = {};
    state.fileNotesSaveRequestFailedData = action.payload;
  },

  //get all file Notes
  getAllFileNotesRequest: (state) => {
    state.isGetAllFileNotesRequestLoading = true;
    state.fileNotesGetAllRequestSuccessData = {};
    state.fileNotesGetAllRequestFailedData = {};
  },
  getAllFileNotesRequestSuccess: (state, action) => {
    state.isGetAllFileNotesRequestLoading = false;
    state.fileNotesGetAllRequestSuccessData = action.payload;
    state.fileNotesGetAllRequestFailedData = {};
  },
  getAllFileNotesRequestFailed: (state, action) => {
    state.isGetAllFileNotesRequestLoading = false;
    state.fileNotesGetAllRequestSuccessData = {};
    state.fileNotesGetAllRequestFailedData = action.payload;
  },

  //update a file note
  updateFileNoteRequest: (state) => {
    state.isUpdateFileNoteRequestLoading = true;
    state.fileNoteUpdateRequestSuccessData = {};
    state.fileNoteUpdateRequestFailedData = {};
  },
  updateFileNoteRequestSuccess: (state, action) => {
    state.isUpdateFileNoteRequestLoading = false;
    state.fileNoteUpdateRequestSuccessData = action.payload;
    state.fileNoteUpdateRequestFailedData = {};
  },
  updateFileNoteRequestFailed: (state, action) => {
    state.isUpdateFileNoteRequestLoading = false;
    state.fileNoteUpdateRequestSuccessData = {};
    state.fileNoteUpdateRequestFailedData = action.payload;
  },

  //delete a file note
  deleteFileNoteRequest: (state) => {
    state.isUpdateFileNoteRequestLoading = true;
    state.fileNoteUpdateRequestSuccessData = {};
    state.fileNoteUpdateRequestFailedData = {};
  },
  deleteFileNoteRequestSuccess: (state, action) => {
    state.isUpdateFileNoteRequestLoading = false;
    state.fileNoteUpdateRequestSuccessData = action.payload;
    state.fileNoteUpdateRequestFailedData = {};
  },
  deleteFileNoteRequestFailed: (state, action) => {
    state.isDeleteFileNoteRequestLoading = false;
    state.fileNoteDeleteRequestSuccessData = {};
    state.fileNoteDeleteRequestFailedData = action.payload;
  },
});
