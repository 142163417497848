import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Save SMSF Client Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestSaveSMSFClient =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveSMSFClientRequest' });

    const requestData = {
      url: API_URL.SMSF.smsfProfile.POST_SAVE_SMSF_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveSMSFClientSuccess', payload: response.data });
      const message = `SMSF is Saved Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveSMSFClientFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request SMSF Client List
 * @param {Function} handleClientListSuccessCallback
 * @param {Function} handleClientListFailedCallback
 * @returns
 */
export const requestSMSFClientList =
  (handleClientListSuccessCallback, handleClientListFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllSMSFClientsRequest' });

    const requestData = {
      url: API_URL.SMSF.smsfClientList.GET_ALL_SMSF_CLIENTS,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllSMSFClientsSuccess', payload: response.data });

      handleClientListSuccessCallback && handleClientListSuccessCallback(response.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllSMSFClientsFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleClientListFailedCallback && handleClientListFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle Request Find SMSF Detail by Id
 * @param {object} requestBody
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns void
 */
export const requestSMSFClientDetail =
  (requestBody, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getSMSFClientByIdRequest' });

    const requestData = {
      url: API_URL.SMSF.smsfProfile.GET_FIND_SMSF_CLIENT_BY_ID.replace(
        '{SMSFClientId}',
        requestBody?.clientId
      ),
      method: 'GET',
    };
    const successCallback = (response) => {
      handleRequestSuccessCallback && handleRequestSuccessCallback(response);
      dispatch({ type: 'getSMSFClientByIdRequestSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      handleRequestFailedCallback && handleRequestFailedCallback(error);
      dispatch({ type: 'getSMSFClientByIdRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Update SMSF Basic Profile Detail
 * @param {object} clientUpdaterRequestData {requestBody, clientDetail}
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestUpdateSMSFClient =
  (
    clientUpdaterRequestData,
    handleRequestSuccessCallback = null,
    handleRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateSMSFClientRequest' });
    let { requestBody, clientDetail } = clientUpdaterRequestData;
    let clientId = clientDetail?.clientId;
    const requestData = {
      url: API_URL.SMSF.smsfProfile.PATCH_UPDATE_SMSF_CLIENT_BY_ID.replace(
        '{SMSFClientId}',
        clientId
      ),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateSMSFClientRequestSuccess',
        payload: response.data,
      });
      const message = `SMSF is Updated Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleRequestSuccessCallback && handleRequestSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateSMSFClientRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleRequestFailedCallback && handleRequestFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Update SMSF Member by member id
 * @param {object} clientUpdaterRequestData {requestBody, clientDetail}
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestUpdateSMSFMember =
  (
    clientUpdaterRequestData,
    handleRequestSuccessCallback = null,
    handleRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateSMSFMemberRequest' });
    let { requestBody, clientDetail, memberId } = clientUpdaterRequestData;
    let clientId = clientDetail?.clientId;
    const requestData = {
      url: API_URL.SMSF.smsfProfile.PATCH_UPDATE_SMSF_MEMBER_BY_MEMBER_ID.replace(
        '{SMSFClientId}',
        clientId
      ).replace('{memberId}', memberId),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateSMSFMemberRequestSuccess',
        payload: response.data,
      });
      const message = `SMSF Member is Updated Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleRequestSuccessCallback && handleRequestSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateSMSFMemberRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleRequestFailedCallback && handleRequestFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save SMSF Member Action Request
 * @param {object} requestDetails
 * @param {Function} handleSaveNewSMSFMemberSuccessCallback
 * @param {Function} handleSaveNewSMSFMemberFailedCallback
 * @returns
 */
export const requestAddNewSMSFMember =
  (requestDetails, handleSaveNewSMSFMemberSuccessCallback, handleSaveNewSMSFMemberFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveNewSMSFMemberRequest' });

    const {
      requestBody,
      clientDetail: { clientId },
    } = requestDetails;

    const requestData = {
      url: API_URL.SMSF.smsfProfile.POST_CREATE_NEW_SMSF_MEMBER_ON_UPDATE.replace(
        '{SMSFClientId}',
        clientId
      ),
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveNewSMSFMemberRequestSuccess', payload: response.data });
      const message = `SMSF Member is Saved Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveNewSMSFMemberSuccessCallback && handleSaveNewSMSFMemberSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveNewSMSFMemberRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveNewSMSFMemberFailedCallback && handleSaveNewSMSFMemberFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Delete SMSF Member Action Request
 * @param {object} requestDetails
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestDeleteSMSFMember =
  (requestDetails, handleRequestSuccessCallback, handleRequestFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteSMSFMemberRequest' });

    const {
      clientDetail: { clientId },
      memberId,
    } = requestDetails;
    const requestData = {
      url: API_URL.SMSF.smsfProfile.DELETE_SMSF_MEMBER.replace('{SMSFClientId}', clientId).replace(
        '{memberId}',
        memberId
      ),
      method: 'DELETE',
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteSMSFMemberRequestSuccess', payload: response.data });
      const message = `SMSF Member is Deleted Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleRequestSuccessCallback && handleRequestSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteSMSFMemberRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleRequestFailedCallback && handleRequestFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle Request Find SMSF Dashboard Statistic Detail by Active Financial Id
 * @param {object} requestBody
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns void
 */
export const requestSMSFClientDashboardStatisticsDetail =
  (requestBody, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getSMSFClientDashboardStatByIdRequest' });

    const requestData = {
      url: API_URL.SMSF.smsfProfile.GET_SMSF_CLIENT_DASHBOARD_STATS_BY_FINANCIAL_POSITION.replace(
        '{activeFinancialPositionId}',
        requestBody?.activeFinancialPosition
      ),
      method: 'GET',
    };
    const successCallback = (response) => {
      handleRequestSuccessCallback && handleRequestSuccessCallback(response);
      dispatch({ type: 'getSMSFClientDashboardStatByIdRequestSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      handleRequestFailedCallback && handleRequestFailedCallback(error);
      dispatch({ type: 'getSMSFClientDashboardStatByIdRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save Multiple SMSF  Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestSaveMultipleSMSFClient =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveMultipleSMSFClientRequest' });

    const requestData = {
      url: API_URL.SMSF.smsfProfile.POST_SAVE_MULTIPLE_SMSF_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveMultipleSMSFClientSuccess', payload: response.data });
      const message = `SMSF is Saved Successfully !`;
      Store.dispatch(handleNotification(message, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveMultipleSMSFClientFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * filter SMSF  Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestFilterSMSF =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postFilterSMSFRequest' });

    const requestData = {
      url: API_URL.SMSF.smsfClientList.FILTER_SMSF,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postFilterSMSFSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postFilterSMSFFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * add linked entities smsf
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const addLinkedEntitiesSMSF =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'addLinkedEntitiesSMSFRequest' });

    const requestData = {
      url: API_URL.SMSF.smsfClientList.ADD_LINKED_ENTITIES,
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'addLinkedEntitiesSMSFSuccess', payload: response.data });
      const requestDetail = {
        clientId: requestBody.smsfId,
      };
      handleSaveClientSuccessCallback();
      !requestBody?.isNotGetting && dispatch(requestSMSFClientDetail(requestDetail));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'addLinkedEntitiesSMSFFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback?.(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * delete linked entities smsf
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const deleteLinkedEntitiesSMSF =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteLinkedEntitiesSMSFRequest' });

    const requestData = {
      url: API_URL.SMSF.smsfClientList.DELETE_LINKED_ENTITIES,
      method: 'DELETE',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteLinkedEntitiesSMSFSuccess', payload: response.data });
      const requestDetail = {
        clientId: requestBody.smsfId,
      };
      dispatch(requestSMSFClientDetail(requestDetail));
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteLinkedEntitiesSMSFFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
