import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Popover, useMediaQuery } from '@mui/material';

import { useFormikContext } from 'formik';

import { useCommonStyles } from 'styles/styles';

import QuestionBuilderFieldPopoverContent from './QuestionBuilderFieldPopoverContent';

import { useSelector } from 'react-redux';
import BooleanAnswerQuestion from './questionComponents/BooleanAnswerQuestion';
import TextAnswerQuestion from './questionComponents/TextAnswerQuestion';
import BooleanAndTextAnswerQuestion from './questionComponents/BooleanAndTextAnswerQuestion';
import MultipleTableAnswerQuestion from './questionComponents/MultipleTableAnswerQuestion';
import MultipleFormatAnswerQuestion from './questionComponents/MultipleFormatAnswerQuestion';
import SignatureQuestion from './questionComponents/SignatureQuestion';

const QuestionnaireFieldBuilder = (props) => {
  const {
    data,
    dataIndex,
    handleAddNewQuestionnaire,
    handleRemoveQuestion,
    selectedItem,
    setSelectedItem,
    questionnaireRef,
    clientType,
    isTemplateBuilder,
  } = props;
  /**
   * Uses common classes
   */
  const {
    classes: { formSubContainer },
  } = useCommonStyles();
  /**
   * Uses formik context
   */
  const { values: formikValues, setFieldValue } = useFormikContext();
  // lead store
  const leadsReducer = useSelector((state) => state?.leadsReducer);
  //client store
  const clientReducer = useSelector((state) => state?.clientReducer);

  const selectedClientDetail =
    clientType === 'LEAD'
      ? leadsReducer?.findLeadRequestSuccessData
      : clientReducer?.selectedClientDetail;

  const isClientDetailRequestLoading =
    clientType === 'LEAD'
      ? leadsReducer?.isFindLeadRequestLoading
      : clientReducer?.isClientDetailRequestLoading;
  //_showing answer preview
  const hasPartner = !isClientDetailRequestLoading && !!selectedClientDetail?.hasPartner;
  const isShowPartnerAnswer =
    hasPartner && (data?.personType === 'BOTH' || data?.personType === 'PARTNER');
  const isShowClientAnswer = !!(data?.personType === 'BOTH' || data?.personType === 'CLIENT');

  //_partner details
  const partnerDetails = useMemo(() => selectedClientDetail?.partner || {}, [selectedClientDetail]);
  //_media queries
  const isSmallScreens = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmallerScreens = useMediaQuery('(max-width:616px)');
  //_refs
  const fieldTypeBtnRef = useRef();
  const fieldPersonTypeRef = useRef();
  //_states
  const [isQuestionEdit, setIsQuestionEdit] = useState(false);
  const [isQuestionCaptionEdit, setIsQuestionCaptionEdit] = useState(false);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popperData, setPopperData] = useState({
    action: 'EDIT_FIELD_TYPE', //EDIT_PERSON_TYPE, EDIT_FIELD_TYPE
    questionData: data,
    dataIndex,
  });
  const [editData, setEditData] = useState({
    question: '',
    questionCaption: '',
  });
  const [contentSectionElementHeight, setContentSectionElementHeight] = useState();
  // const [activeQuestionSectionHeight, setActiveQuestionSectionHeight] = useState('fit-content');
  //_memo
  const isSelectedQuestionnaire = useMemo(() => {
    return (
      selectedItem?.component === 'input' &&
      selectedItem &&
      selectedItem?.questionnaireKey === data?.questionnaireKey
    );
  }, [data, selectedItem]);
  /**
   * Handle edit question
   * @param {*} event
   * @param {*} questionIndex
   * @param {*} data
   */
  const handleEditQuestion = (event, questionIndex) => {
    setEditData((prev) => ({
      ...prev,
      question: prev?.question || data?.question,
    }));
    setIsQuestionEdit(true);
  };
  /**
   * Handle edit question caption
   * @param {*} event
   * @param {*} questionIndex
   * @param {*} data
   */
  const handleEditQuestionCaption = (event, questionIndex) => {
    setEditData((prev) => ({
      ...prev,
      questionCaption: prev?.questionCaption || data?.questionCaption,
    }));
    setIsQuestionCaptionEdit(true);
  };
  /**
   *Handle save edited question text
   * @param {*} event
   */
  const handleSaveQuestion = useCallback(
    (event) => {
      event.stopPropagation();
      let updatedQuestionnaires = [...(formikValues?.questionnaires || [])];
      if (
        updatedQuestionnaires?.[dataIndex] &&
        typeof updatedQuestionnaires?.[dataIndex] === 'object'
      ) {
        updatedQuestionnaires[dataIndex] = {
          ...(updatedQuestionnaires[dataIndex] || {}),
          question: editData?.question,
        };
        updatedQuestionnaires = updatedQuestionnaires.filter(
          (questionData) => !!questionData?.component
        );

        setFieldValue('questionnaires', updatedQuestionnaires);
        if (questionnaireRef?.current) {
          questionnaireRef.current = { ...{} };
        }
      }
    },
    [formikValues?.questionnaires, dataIndex, editData, questionnaireRef, setFieldValue]
  );

  /**
   *Handle save edited question text
   * @param {*} event
   */
  const handleSaveQuestionCaption = useCallback(
    (event) => {
      event.stopPropagation();
      let updatedQuestionnaires = [...(formikValues?.questionnaires || [])];
      if (
        updatedQuestionnaires?.[dataIndex] &&
        typeof updatedQuestionnaires?.[dataIndex] === 'object'
      ) {
        updatedQuestionnaires[dataIndex] = {
          ...(updatedQuestionnaires[dataIndex] || {}),
          questionCaption: editData?.questionCaption,
        };
        updatedQuestionnaires = updatedQuestionnaires.filter(
          (questionData) => !!questionData?.component
        );
        setFieldValue('questionnaires', updatedQuestionnaires);
        if (questionnaireRef?.current) {
          questionnaireRef.current = { ...{} };
        }
      }
    },
    [formikValues, dataIndex, editData, setFieldValue, questionnaireRef]
  );
  /**
   * Open Field Type Edit Popover
   * @param {*} event
   */
  const handleOnFieldTypeBtnClick = useCallback(
    (event) => {
      setPopoverAnchorEl(fieldTypeBtnRef.current);
      setPopperData((prev) => ({
        ...prev,
        action: 'EDIT_FIELD_TYPE',
      }));
      setTimeout(() => {
        setIsPopoverOpen(true);
      }, 10);
    },
    [fieldTypeBtnRef]
  );
  /**
   * Open Person Type Edit Popover
   * @param {*} event
   */
  const handleOnPersonTypeBtnClick = useCallback(
    (event) => {
      setPopoverAnchorEl(fieldPersonTypeRef.current);
      setPopperData((prev) => ({
        ...prev,
        action: 'EDIT_PERSON_TYPE',
      }));
      setTimeout(() => {
        setIsPopoverOpen(true);
      }, 10);
    },
    [fieldPersonTypeRef]
  );
  /**
   * Handle set height on ref node capture
   */
  const handleContentElementRefCallback = useCallback(
    (node) => {
      if (isSelectedQuestionnaire) {
        setContentSectionElementHeight(node?.scrollHeight);
      }
      setTimeout(() => {
        setContentSectionElementHeight(node?.scrollHeight);
      }, 500);
    },
    [selectedItem, isSelectedQuestionnaire, data?.fieldType]
  );
  /**
   * Close popover
   * @param {*} event
   */
  const handlePopoverClose = (event) => {
    setIsPopoverOpen(false);
    setPopoverAnchorEl(null);
  };
  /**
   * Handle move question
   * @param {*} actionType
   * @param {*} actionIndex
   * @param {*} event
   */
  const handleMoveQuestion = useCallback(
    (actionType, actionIndex, event) => {
      event.stopPropagation();
      let newQuestionnaireArray = [...(formikValues?.questionnaires || [])];
      if (actionType === 'UP' && actionIndex >= 2) {
        const moveToIndex = actionIndex - 1;
        if (moveToIndex >= 0 && moveToIndex <= formikValues?.questionnaires?.length - 1) {
          const firstSectionArray =
            actionIndex > 0 ? newQuestionnaireArray.slice(0, actionIndex - 1) : [];
          const movingSectionArray = newQuestionnaireArray.slice(actionIndex, actionIndex + 1);
          const switchingSectionArray = newQuestionnaireArray.slice(moveToIndex, moveToIndex + 1);
          const lastSectionArray = newQuestionnaireArray.slice(moveToIndex + 2);
          newQuestionnaireArray = [
            ...firstSectionArray,
            ...movingSectionArray,
            ...switchingSectionArray,
            ...lastSectionArray,
          ];
        }
      } else if (actionType === 'DOWN' && actionIndex !== newQuestionnaireArray?.length - 1) {
        const moveToIndex = actionIndex + 1;
        if (moveToIndex >= 0 && moveToIndex <= formikValues?.questionnaires?.length - 1) {
          const firstSectionArray = newQuestionnaireArray.slice(0, actionIndex);
          const movingSectionArray = newQuestionnaireArray.slice(actionIndex, actionIndex + 1);
          const switchingSectionArray = newQuestionnaireArray.slice(moveToIndex, moveToIndex + 1);
          const lastSectionArray = newQuestionnaireArray.slice(moveToIndex + 2);
          newQuestionnaireArray = [
            ...firstSectionArray,
            ...switchingSectionArray,
            ...movingSectionArray,
            ...lastSectionArray,
          ];
        }
      }
      newQuestionnaireArray = newQuestionnaireArray.filter(
        (questionData) => !!questionData?.component
      );

      setFieldValue('questionnaires', newQuestionnaireArray);
      setSelectedItem(null);
      if (questionnaireRef?.current) {
        questionnaireRef.current = { ...{} };
      }
    },
    [questionnaireRef, formikValues?.questionnaires, setSelectedItem, setFieldValue]
  );
  /**
   * handle get question field dynamic data
   */
  const handleGeFieldTypeData = useCallback(
    (dataName) => {
      const FIELD_TYPES = {
        BOOLEAN: 'boolean',
        TEXT: 'text',
        BOOLEAN_AND_TEXT: 'booleanAndText',
        MULTIPLE_TABULAR: 'multipleTabular',
        MULTIPLE_FORMAT: 'multipleFormat',
        SIGNATURE_AGREEMENT: 'signatureAgreement'
      };
      const FIELD_DATA = {
        FIELD_TYPE_BTN_LABEL: 'fieldTypeBtnLabel',
        BOOLEAN_QUESTION_COMPONENT: 'booleanQuestionComponent',
      };
      if (data?.fieldType === FIELD_TYPES.BOOLEAN) {
        if (dataName === FIELD_DATA.FIELD_TYPE_BTN_LABEL) {
          return 'Yes/No';
        }
      } else if (data?.fieldType === FIELD_TYPES.TEXT) {
        if (dataName === FIELD_DATA.FIELD_TYPE_BTN_LABEL) {
          return 'Text';
        }
      } else if (data?.fieldType === FIELD_TYPES.BOOLEAN_AND_TEXT) {
        if (dataName === FIELD_DATA.FIELD_TYPE_BTN_LABEL) {
          return 'Yes/No with Text';
        }
      } else if (data?.fieldType === FIELD_TYPES.MULTIPLE_TABULAR) {
        if (dataName === FIELD_DATA.FIELD_TYPE_BTN_LABEL) {
          return 'Table';
        }
      } else if (data?.fieldType === FIELD_TYPES.MULTIPLE_FORMAT) {
        if (dataName === FIELD_DATA.FIELD_TYPE_BTN_LABEL) {
          return 'Multiple Answers';
        }
      } else if (data?.fieldType === FIELD_TYPES.SIGNATURE_AGREEMENT) {
        if (dataName === FIELD_DATA.FIELD_TYPE_BTN_LABEL) {
          return 'Agreement/Signature';
        }
      }
    },
    [data]
  );
  /**
   * Handle get question component
   */
  const handleGetQuestionComponent = useCallback(() => {
    const FIELD_COMPONENTS = {
      BOOLEAN_ANSWER_QUESTION: 'boolean',
      TEXT_QUESTION: 'text',
      BOOLEAN_AND_TEXT_QUESTION: 'booleanAndText',
      MULTIPLE_TABULAR_QUESTION: 'multipleTabular',
      MULTIPLE_FORMAT_QUESTION: 'multipleFormat',
      SIGNATURE_AGREEMENT_QUESTION: 'signatureAgreement'
    };

    const propsData = {
      questionData: data,
      questionDataIndex: dataIndex,
      isSelectedQuestionnaire,
      isSmallScreens,
      isQuestionEdit,
      editData,
      setEditData,
      handleSaveQuestion,
      isPopoverOpen,
      popperData,
      isSmallerScreens,
      fieldTypeBtnRef,
      handleOnFieldTypeBtnClick,
      handleGeFieldTypeData,
      fieldPersonTypeRef,
      handleOnPersonTypeBtnClick,
      partnerDetails,
      selectedClientDetail,
      isQuestionCaptionEdit,
      isShowClientAnswer,
      isShowPartnerAnswer,
      handleAddNewQuestionnaire,
      handleRemoveQuestion,
      handleMoveQuestion,
      handleSaveQuestionCaption,
      clientType,
      isTemplateBuilder,
    };

    if (data?.fieldType === FIELD_COMPONENTS.BOOLEAN_ANSWER_QUESTION) {
      return <BooleanAnswerQuestion {...propsData} />;
    } else if (data?.fieldType === FIELD_COMPONENTS.TEXT_QUESTION) {
      return <TextAnswerQuestion {...propsData} />;
    } else if (data?.fieldType === FIELD_COMPONENTS.BOOLEAN_AND_TEXT_QUESTION) {
      return <BooleanAndTextAnswerQuestion {...propsData} />;
    } else if (data?.fieldType === FIELD_COMPONENTS.MULTIPLE_TABULAR_QUESTION) {
      return <MultipleTableAnswerQuestion {...propsData} />;
    } else if (data?.fieldType === FIELD_COMPONENTS.MULTIPLE_FORMAT_QUESTION) {
      return <MultipleFormatAnswerQuestion {...propsData} />;
    } else if (data?.fieldType === FIELD_COMPONENTS.SIGNATURE_AGREEMENT_QUESTION) {
      return <SignatureQuestion {...propsData} />;
    }
  }, [
    data,
    dataIndex,
    isSelectedQuestionnaire,
    isSmallScreens,
    isQuestionEdit,
    editData,
    setEditData,
    handleSaveQuestion,
    isPopoverOpen,
    popperData,
    isSmallerScreens,
    fieldTypeBtnRef,
    handleOnFieldTypeBtnClick,
    handleGeFieldTypeData,
    fieldPersonTypeRef,
    handleOnPersonTypeBtnClick,
    partnerDetails,
    selectedClientDetail,
    isQuestionCaptionEdit,
    isShowClientAnswer,
    isShowPartnerAnswer,
    handleAddNewQuestionnaire,
    handleRemoveQuestion,
    handleMoveQuestion,
    handleSaveQuestionCaption,
    clientType,
    isTemplateBuilder,
  ]);
  /**
   * Effect on questionnaire selection
   */
  useEffect(() => {
    if (!isSelectedQuestionnaire) {
      setIsQuestionEdit(false);
      setIsQuestionCaptionEdit(false);
    }
  }, [isSelectedQuestionnaire]);

  return (
    <Box
      className={formSubContainer}
      ref={handleContentElementRefCallback}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem(data);
        handleEditQuestionCaption(e, dataIndex);
        handleEditQuestion(e, dataIndex);
      }}
      sx={
        {
          // minHeight: 0,
          // height: contentSectionElementHeight || 'fit-content',
          // maxHeight: 'calc(fit-content + 16)',
          // overflow: 'hidden',
        }
      }
    >
      {handleGetQuestionComponent()}

      <Popover
        open={isPopoverOpen}
        onClose={handlePopoverClose}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={(theme) => ({
          zIndex: theme.zIndex.modal - 1,
        })}
      >
        <Box>
          <QuestionBuilderFieldPopoverContent
            popperData={popperData}
            questionnaireRef={questionnaireRef}
            leadDetails={selectedClientDetail}
            hasPartner={hasPartner}
            partnerDetails={partnerDetails}
            handlePopoverClose={handlePopoverClose}
            isSmallScreens={isSmallScreens}
            clientType={clientType}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default memo(QuestionnaireFieldBuilder);
