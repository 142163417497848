export const handleModals = (modalStatus, modalName, modalData = null) => async (dispatch, getState) => {
  const modalDetails = {
    modalName: modalName,
    modalStatus: modalStatus,
    modalData: modalData
  };
  dispatch({
    type: 'HANDLE_MODAL',
    payload: modalDetails,
  });
};
