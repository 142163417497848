
import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
//actions
import { handleNotification } from './handleNotificationAction';
import defaultQuestionnairesData from 'views/lead/createLead/data/defaultQuestionnairesData.json';
/**
 * Get Lead Questionnaires
 * @param {*} handleGetLeadQuestionnaireSuccessCallback
 * @param {*} handleGetLeadQuestionnaireFailedCallback
 * @returns {async function}
 */
export const requestGetLeadQuestionnaire =
    (requestDetails, handleGetLeadQuestionnaireSuccessCallback = null, handleGetLeadQuestionnaireFailedCallback = null) =>
        async (dispatch, getState) => {
            dispatch({ type: 'getLeadQuestionnaireRequest' });

            const {
                leadId
            } = requestDetails;

            const leadQuestionnaireRequestData = {
                url: API_URL.lead.leadQuestionnaire.GET_LEAD_QUESTIONNAIRES.replace(
                    '{leadId}',
                    leadId
                ),
                method: 'GET',
            };
            const requestGetLeadQuestionnaireSuccess = (response) => {
                dispatch({ type: 'getLeadQuestionnaireRequestSuccess', payload: response?.data });
                handleGetLeadQuestionnaireSuccessCallback &&
                    handleGetLeadQuestionnaireSuccessCallback(response?.data);
            };
            const requestGetLeadQuestionnaireFailed = (error) => {
                dispatch({ type: 'getLeadQuestionnaireRequestFailed', payload: defaultQuestionnairesData });
                handleGetLeadQuestionnaireFailedCallback && handleGetLeadQuestionnaireFailedCallback(error);
            };

            http_request(
                leadQuestionnaireRequestData,
                requestGetLeadQuestionnaireSuccess,
                requestGetLeadQuestionnaireFailed
            );
        };

/**
 * Update Lead Questionnaires
 * @param {*} handleUpdateLeadQuestionnaireSuccessCallback
 * @param {*} handleUpdateLeadQuestionnaireFailedCallback
 * @returns {async function}
 */
export const requestUpdateLeadQuestionnaire =
    (
        updateRequestData,
        handleUpdateLeadQuestionnaireSuccessCallback = null,
        handleUpdateLeadQuestionnaireFailedCallback = null
    ) =>
        async (dispatch, getState) => {
            dispatch({ type: 'updateLeadQuestionnaireRequest' });

            const { requestBody, questionnaireDataId } = updateRequestData;

            const leadQuestionnaireRequestData = {
                url: API_URL.lead.leadQuestionnaire.PATCH_UPDATE_LEAD_QUESTIONNAIRE.replace(
                    '{questionnaireDataId}',
                    questionnaireDataId
                ),
                method: 'PATCH',
                data: requestBody,
            };
            const requestUpdateLeadQuestionnaireSuccess = (response) => {
                dispatch({ type: 'updateLeadQuestionnaireRequestSuccess', payload: response?.data });
                const successMessage =  `Lead Questionnaire is Updated Successfully !`;
                Store.dispatch(handleNotification(successMessage, 'success'));
                handleUpdateLeadQuestionnaireSuccessCallback &&
                    handleUpdateLeadQuestionnaireSuccessCallback(response?.data);
            };
            const requestUpdateLeadQuestionnaireFailed = (error) => {
                dispatch({ type: 'updateLeadQuestionnaireRequestFailed', payload: error });
                let message = error?.response?.data?.name || 'Lead Questionnaire Update is Failed !';

                Store.dispatch(handleNotification(message, 'error'));
                handleUpdateLeadQuestionnaireFailedCallback && handleUpdateLeadQuestionnaireFailedCallback(error);
            };

            http_request(
                leadQuestionnaireRequestData,
                requestUpdateLeadQuestionnaireSuccess,
                requestUpdateLeadQuestionnaireFailed
            );
        };




/**
 * Delete Questionnaire Question
 * @param {*} updateRequestData 
 * @param {*} handleDeleteRequestSuccessCallback 
 * @param {*} handleDeleteRequestFailedCallback 
 * @returns 
 */
export const requestDeleteQuestionnaireQuestion =
    (
        deleteRequestData,
        handleDeleteRequestSuccessCallback = null,
        handleDeleteRequestFailedCallback = null
    ) =>
        async (dispatch, getState) => {
            dispatch({ type: 'deleteQuestionnaireRequest' });

            const { questionnaireDetailId, questionnaireRootId } = deleteRequestData;

            const leadQuestionnaireRequestData = {
                url: API_URL.lead.leadQuestionnaire.DELETE_QUESTIONNAIRE_QUESTION_BY_ID.replace(
                    '{questionnaireDataId}',
                    questionnaireRootId
                ).replace(
                    '{questionId}',
                    questionnaireDetailId
                ),
                method: 'DELETE',
            };
            const requestUpdateLeadQuestionnaireSuccess = (response) => {
                dispatch({ type: 'deleteQuestionnaireRequestSuccess', payload: response?.data });
                const successMessage = `Question is Deleted Successfully !`;
                Store.dispatch(handleNotification(successMessage, 'success'));
                handleDeleteRequestSuccessCallback &&
                    handleDeleteRequestSuccessCallback(response?.data);
            };
            const requestUpdateLeadQuestionnaireFailed = (error) => {
                dispatch({ type: 'deleteQuestionnaireRequestFailed', payload: error });
                let message = error?.response?.data?.name || 'Question Delete is Failed !';

                Store.dispatch(handleNotification(message, 'error'));
                handleDeleteRequestFailedCallback && handleDeleteRequestFailedCallback(error);
            };

            http_request(
                leadQuestionnaireRequestData,
                requestUpdateLeadQuestionnaireSuccess,
                requestUpdateLeadQuestionnaireFailed
            );
        };