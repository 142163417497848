import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // get all assignable members tags
  isGetAllAssignableMembersLoading: false,
  assignableMembersRequestSuccessData: {},
  assignableMembersRequestFailedData: {},


};

/**
 * Questionnaire template tag reducer
 */
export const assignableMembersReducer = createReducer(initialState, {
  // get all assignable members
  getAssignableMembersRequest: (state) => {
    state.isGetAllAssignableMembersLoading = true;
    state.assignableMembersRequestSuccessData = {};
    state.assignableMembersRequestFailedData = {};
  },
  getAssignableMembersRequestSuccess: (state, action) => {
    state.isGetAllAssignableMembersLoading = false;
    state.assignableMembersRequestSuccessData = action.payload;
    state.assignableMembersRequestFailedData = {};
  },
  getAssignableMembersRequestFailed: (state, action) => {
    state.isGetAllAssignableMembersLoading = false;
    state.assignableMembersRequestSuccessData = {};
    state.assignableMembersRequestFailedData = action.payload;
  },

});
